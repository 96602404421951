import { useEffect, useState } from "react";

import { Grid, Switch } from "@mui/material";

import { experiences, locationType } from "../../experienceIndex";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ExperienceCard from "../../components/experienceCard";

import { Link } from "react-router-dom";
import { useGlobalContext } from "../../App";
import Map from "../findExperienceDeprecated/Map";

import Filters, {
  cities,
  defaultEventBudget,
  defaultEventDuration,
  defaultEventType,
  defaultGroupSize,
  defaultLocation,
  getFilteredExperiences,
} from "../../components/filters/filters";

const defaultMapLocation: locationType = {
  fullAddress: "Harrisburg, PA",
  shortAddress: "Harrisburg, PA",
  lat: 40.276729002884416,
  long: -76.87740020874793,
};

export default function FindExperience() {
  const [groupSizeFilter, setGroupSizeFilter] = useState(defaultGroupSize);
  const [eventLocationFilter, setEventLocationFilter] = useState(defaultLocation); // index of the cities array
  const [eventTypeFilter, setEventTypeFilter] = useState(defaultEventType);
  const [eventDurationFilter, setEventDurationFilter] = useState(defaultEventDuration);
  const [eventBudgetFilter, setEventBudgetFilter] = useState(defaultEventBudget);

  const [filteredExperiences, setFilteredExperiences] = useState(
    experiences.map((exp) => {
      return exp;
    })
  );

  const [mapViewEnabled, setMapViewEnabled] = useState(false);

  const setContactQuizActive = useGlobalContext().setContactQuizActive;
  const isMobile = useGlobalContext().isMobile;

  useEffect(() => {
    setFilteredExperiences(
      getFilteredExperiences(
        eventLocationFilter,
        eventDurationFilter,
        eventBudgetFilter,
        eventTypeFilter,
        groupSizeFilter
      )
    );
    /* when using sliders, this debounces so that every increment doesn't trigger a re-render
    const timeOutId = setTimeout(() => filterExperiences(), 500);
    return () => clearTimeout(timeOutId);
    */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupSizeFilter,
    eventLocationFilter,
    eventTypeFilter,
    eventBudgetFilter,
    eventDurationFilter,
  ]);

  return (
    <>
      <Navbar section title="find an experience" />

      <section className="landing-section">
        <div
          className="landing-div"
          style={{ position: "relative", height: "100%", padding: "1rem 0 2rem 0" }}
        >
          <Filters
            groupSizeFilter={groupSizeFilter}
            setGroupSizeFilter={setGroupSizeFilter}
            eventLocationFilter={eventLocationFilter}
            setEventLocationFilter={setEventLocationFilter}
            eventTypeFilter={eventTypeFilter}
            setEventTypeFilter={setEventTypeFilter}
            eventDurationFilter={eventDurationFilter}
            setEventDurationFilter={setEventDurationFilter}
            eventBudgetFilter={eventBudgetFilter}
            setEventBudgetFilter={setEventBudgetFilter}
          />
        </div>
      </section>

      <section className="landing-section">
        <div
          className="landing-div"
          style={{ display: "flex", flexDirection: "column", justifyContent: "left" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              width: "90%",
              margin: "1rem 5% 0 5%",
            }}
          >
            <Switch
              checked={mapViewEnabled}
              onChange={(e) => setMapViewEnabled(!mapViewEnabled)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span className="desktop-body">Map view</span>
          </div>

          {!mapViewEnabled && (
            <Grid
              container
              width="90%"
              margin={"0 5% 0 5%"}
              justifyContent={filteredExperiences.length === 0 ? "center" : "left"}
            >
              {filteredExperiences.length === 0 && (
                <h5>
                  <span style={{ margin: "4rem 0 4rem 0" }}>
                    We don't have any pre-built experiences matching these criteria.{"  "}
                    {!isMobile && (
                      <Link
                        to={"/pages/findExperience"}
                        onClick={(e) => setContactQuizActive(true)}
                      >
                        <span style={{ color: "info" }}>Contact us </span>
                      </Link>
                    )}
                    {isMobile && (
                      <Link to={"/pages/contactQuiz"}>
                        <span style={{ color: "info" }}>Contact us </span>
                      </Link>
                    )}
                    to create your perfect event!
                  </span>
                </h5>
              )}
              {filteredExperiences.map((exp) => {
                return (
                  <Grid item xs={12} md={4} lg={3} p={1} key={"topGrid" + exp.id} mb={"1rem"}>
                    <ExperienceCard experience={exp} key={"expCard" + exp.id} />
                  </Grid>
                );
              })}
            </Grid>
          )}

          {mapViewEnabled && (
            <div
              style={{
                width: "90%",
                margin: "1rem 5% 0 5%",
              }}
            >
              <Map
                mapOrigin={
                  eventLocationFilter !== defaultLocation
                    ? {
                        fullAddress: cities[eventLocationFilter].name,
                        shortAddress: cities[eventLocationFilter].name,
                        lat: cities[eventLocationFilter].lat,
                        long: cities[eventLocationFilter].long,
                      }
                    : defaultMapLocation
                }
                filteredExperiences={filteredExperiences}
                distanceSliderValue={4}
                height="800px"
              />
            </div>
          )}
        </div>
      </section>

      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}
