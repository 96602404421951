import { useGlobalContext } from "../App";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function PostabilityCaseStudy() {
  return (
    <>
      <Navbar section />
      <section className="landing-section">
        <div
          className="landing-div"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <div
            style={{
              width: useGlobalContext().isMobile ? "100%" : "80%",
              margin: "0 auto",
              textAlign: "left",
            }}
          >
            <h4>
              Empowering corporate success: a case study in streamlined event planning with Townish
            </h4>
            <img
              src={require("../img/eventPhotos/Postability (4).png")}
              alt="postability event action shot"
              style={{ width: "100%", height: "40vh", objectFit: "cover", borderRadius: "30px" }}
            />
            <h5 style={{ margin: "3rem 0 1rem 0" }}>Introduction</h5>
            <span className="desktop-body">
              At Townish, we're dedicated to simplifying and enhancing the corporate event planning
              process by connecting companies with top-quality, vetted services. Our platform
              streamlines planning, saving businesses time and resources while ensuring memorable
              and engaging corporate retreats and offsite events for their employees. Corporate
              event planning can be complex and time-consuming, involving multiple vendors,
              scheduling challenges, and high expectations for a successful outcome. Companies often
              struggle to find reliable and high-quality services that meet their unique needs and
              budget.
            </span>
            <h5 style={{ margin: "3rem 0 1rem 0" }}>Case Study: Postability</h5>
            <span className="desktop-body">
              We had the opportunity to partner with Postability, a rapidly growing technology
              company, to support their post-Covid team-building efforts. We collaborated on several
              events, including full-day sessions incorporating company presentations, team-building
              activities, and social gatherings. Our services helped Postability foster a stronger
              sense of community among their employees, boosting morale and engagement. One notable
              event was a day-long corporate retreat that combined business updates with fun and
              interactive team-building exercises. Our platform enabled Postability to easily
              coordinate multiple services, including a unique venue, catered meals, and team
              building activity. The event was a resounding success, with attendees commenting on
              the seamless organization and engaging activities. Another event was an offsite summer
              retreat focused on a fun and engaging activity. We call it 'Summer Camp for Adults' –
              a full day of experiences at Hollows Camp, including archery, kayaking, a scavenger
              hunt, BBQ, and a campfire with s'mores! This event was a hit, and Postability’s team
              enjoyed every aspect of it.
            </span>
            <h5 style={{ margin: "3rem 0 1rem 0" }}>The Future</h5>
            <span className="desktop-body">
              As we look to the future, we are committed to continuing to expand our offerings and
              enhance our platform to meet the evolving needs of businesses like Postability. Our
              goal is to remain the go-to solution for stress-free, engaging, and memorable
              corporate events, including retreats and offsite gatherings. By choosing Townish,
              businesses can streamline their event planning process, access top-quality services,
              and create engaging experiences for their employees. We invite you to join us in
              transforming the world of corporate events!
            </span>
          </div>
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}
