import { useState } from "react";
import { Link } from "react-router-dom";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { SubmitHandler, useForm } from "react-hook-form";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SvgIcon,
  TextField,
} from "@mui/material";

import { useGlobalContext } from "../../App";

import mixpanel from "mixpanel-browser";
import { budgetOptions, cities, eventDurationOptions, groupSizeOptions } from "../filters/filters";

const helpOptions = [
  "accommodation",
  "meeting space",
  "food",
  "team building activities",
  "speakers & workshops",
  "transportation",
  "travel",
  "other",
];

function QuizInput(options: {
  type: string;
  dateValue: Date | null;
  setDateValue: React.Dispatch<React.SetStateAction<Date | null>>;
  durationValue: string;
  setDurationValue: React.Dispatch<React.SetStateAction<string>>;
  nameValue: string;
  setNameValue: React.Dispatch<React.SetStateAction<string>>;
  emailValue: string;
  setEmailValue: React.Dispatch<React.SetStateAction<string>>;
  phoneValue: string;
  setPhoneValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <div
      style={{
        display: "flex",
        width: "80%",
        margin: "4rem auto 0 auto",
      }}
    >
      <div
        style={{
          margin: "0 auto 0 auto",
        }}
      >
        {options.type === "date" && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Event start date"
                value={options.dateValue}
                onChange={(newValue) => {
                  options.setDateValue(newValue);
                }}
                key="date-picker"
              />
            </LocalizationProvider>
            <div style={{ marginTop: "1.5rem" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="duration-select-label">Expected duration</InputLabel>
                <Select
                  labelId="duration-select-label"
                  id="duration-select"
                  label="Expected duration"
                  value={options.durationValue}
                  onChange={(e) =>
                    options.setDurationValue(
                      eventDurationOptions[
                        eventDurationOptions.findIndex(function (edo) {
                          return edo.name === e.target.value;
                        })
                      ].name
                    )
                  }
                >
                  {eventDurationOptions.map((edo) => {
                    return (
                      <MenuItem value={edo.name} key={edo.name}>
                        {edo.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        )}
        {options.type === "email" && (
          <div
            id="contact-entry-div"
            key="contact-entry-div"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              placeholder="name"
              id="name-entry-textfield"
              key="name-entry-textfield"
              value={options.nameValue}
              onChange={(e) => options.setNameValue(e.target.value)}
            />
            <TextField
              placeholder="phone"
              id="phone-entry-textfield"
              key="phone-entry-textfield"
              style={{ marginTop: "1.5rem" }}
              value={options.phoneValue}
              onChange={(e) => options.setPhoneValue(e.target.value)}
            />
            <TextField
              placeholder="work email"
              id="email-entry-textfield"
              key="email-entry-textfield"
              style={{ marginTop: "1.5rem" }}
              value={options.emailValue}
              onChange={(e) => options.setEmailValue(e.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function ContactQuiz(props: { quizType: string }) {
  // quizType "overlay" or "route"
  const setContactQuizActive = useGlobalContext().setContactQuizActive;
  const isMobile = useGlobalContext().isMobile;

  const [quizStep, setQuizStep] = useState(0);

  const [addedGoals, setAddedGoals] = useState<string[]>([]);

  const [groupSizeValue, setGroupSizeValue] = useState("");
  const [budgetValue, setBudgetValue] = useState("");
  const [dateValue, setDateValue] = useState<Date | null>(new Date());
  const [durationValue, setDurationValue] = useState("");
  const [locationValue, setLocationValue] = useState("");

  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);

  type Inputs = {
    contactQuizGoals: string;
    contactQuizGroupSize: string;
    contactQuizBudget: string;
    contactQuizDate: string;
    contactQuizDuration: string;
    contactQuizLocation: string;
    contactQuizName: string;
    contactQuizEmail: string;
    contactQuizPhone: string;
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const submitContactQuiz: SubmitHandler<Inputs> = (data) => {
    mixpanel.track("Quiz completed", { Env: process.env.REACT_APP_ENV });

    const contactInfo = {
      contactQuizGoals: addedGoals,
      contactQuizGroupSize: groupSizeValue,
      contactQuizBudget: budgetValue,
      contactQuizDate: dateValue,
      contactQuizDuration: durationValue,
      contactQuizLocation: locationValue,
      contactQuizName: nameValue,
      contactQuizEmail: emailValue,
      contactQuizPhone: phoneValue,
    };

    const encode = (dataToEncode: any) => {
      return Object.keys(dataToEncode)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(dataToEncode[key]))
        .join("&");
    };

    fetch("/pages/eventSuccess", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "submitContactQuiz", ...contactInfo }),
      redirect: "follow",
    })
      .then(() => setShowSuccess(true))
      .catch((error) => console.log(error));
  };

  console.log(errors);

  const quizQuestions = [
    {
      img: require("../../img/eventPhotos/Compass2.jpeg"),
      title: "Please answer a few questions and we'll get in touch.",
      subtitle: "To start with, what do you want help with?",
      subtitleOnMobile: true,
      element: <QuizMultiSelect options={helpOptions} type={"goal"} />,
    },
    {
      img: require("../../img/eventPhotos/Sorso2.jpeg"),
      title: "How many people do you expect to attend? ",
      subtitle: "If you're not sure, it's fine to enter a best guess.",
      subtitleOnMobile: false,
      element: <QuizSingleSelect options={groupSizeOptions} type={"groupSize"} />,
    },
    {
      img: require("../../img/contactQuiz/meal.jpg"),
      title: "What's your expected per-person budget for this event?",
      subtitle: "Longer events, such as multi-day offsites, typically cost more.",
      subtitleOnMobile: false,
      element: <QuizSingleSelect options={budgetOptions} type={"budget"} />,
    },
    {
      img: require("../../img/eventPhotos/Postability (4).jpg"),
      title: "Do you have a date in mind?",
      subtitle: "If you're not sure, it's fine to enter a best guess.",
      subtitleOnMobile: false,
      element: (
        <QuizInput
          type={"date"}
          dateValue={dateValue}
          setDateValue={setDateValue}
          durationValue={durationValue}
          setDurationValue={setDurationValue}
          nameValue={nameValue}
          setNameValue={setNameValue}
          phoneValue={phoneValue}
          setPhoneValue={setPhoneValue}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
        />
      ),
    },
    {
      img: require("../../img/contactQuiz/NhU0nUR7920.jpg"),
      title: "Are you thinking of a particular destination?",
      subtitle: "Pick the closest major city.",
      element: <QuizSingleSelect options={cities} type={"location"} />,
    },
    {
      img: require("../../img/contactQuiz/cheers.png"),
      title: "What's the best way for us to reach you?",
      element: (
        <QuizInput
          type={"email"}
          dateValue={dateValue}
          setDateValue={setDateValue}
          durationValue={durationValue}
          setDurationValue={setDurationValue}
          nameValue={nameValue}
          setNameValue={setNameValue}
          phoneValue={phoneValue}
          setPhoneValue={setPhoneValue}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
        />
      ),
    },
  ];

  function QuizMultiSelect(options: { options: string[]; type: string }) {
    return (
      <Grid container spacing={1} alignItems={"center"} mt="1rem">
        {options.options.length > 0 &&
          options.options.map((opt) => {
            return (
              <Grid container item xs={6} lg={4} key={"Grid" + opt} justifyContent="center">
                <button
                  className="basic-button"
                  style={{
                    width: "12rem",
                    height: props.quizType === "overlay" ? "6rem" : "5rem",
                    backgroundColor: checkSelectionAdded(opt, options.type)
                      ? "var(--lime-green-transparent)"
                      : "",
                    padding: "0 .5rem",
                  }}
                  onClick={(e) => toggleSelection(opt, options.type)}
                >
                  <span className="desktop-body" style={{ fontSize: "18px" }}>
                    {opt}
                  </span>
                </button>
              </Grid>
            );
          })}
      </Grid>
    );
  }

  function QuizSingleSelect(options: { options: any[]; type: string }) {
    return (
      <div
        style={{
          display: "flex",
          width: "80%",
          margin: "4rem auto 0 auto",
        }}
      >
        {options.type === "location" && (
          <Autocomplete
            disablePortal
            id="location-select"
            key="location-select"
            value={locationValue === "" ? options.options[0].name : locationValue}
            onChange={(e, value: any) => setLocationValue(value)}
            options={options.options.map((opt) => {
              return opt.name;
            })}
            renderInput={(params) => <TextField {...params} label="location" />}
            style={{ width: "50%", margin: "0 auto 0 auto" }}
          />
        )}
        {options.type !== "location" && (
          <RadioGroup
            aria-labelledby="radio-buttons-group-groupsize"
            value={getSelectionForType(options.type)}
            name="radio-buttons-group"
            onChange={(event, value) => addSelection(value, options.type)}
            style={{
              margin: "0 auto 0 auto",
            }}
          >
            {options.options &&
              options.options.length > 0 &&
              options.options.map((opt, i) => {
                return (
                  <FormControlLabel
                    value={opt.name}
                    control={<Radio />}
                    label={opt.name}
                    key={"radio-option" + opt.name}
                  />
                );
              })}
          </RadioGroup>
        )}
      </div>
    );
  }

  //"groupSize""budget""date""location""email"

  const getSelectionForType = (type: string): string => {
    if (type === "groupSize") {
      return groupSizeValue;
    } else if (type === "budget") {
      return budgetValue;
    } else if (type === "location") {
      return locationValue;
    } else if (type === "duration") {
      return durationValue;
    } else {
      return "";
    }
  };

  const checkSelectionAdded = (sel: string, type: string): boolean => {
    if (type === "goal") {
      return addedGoals.some(function (el) {
        return sel === el;
      });
    } else if (type === "groupSize") {
      return sel === groupSizeValue;
    } else if (type === "budget") {
      return sel === budgetValue;
    } else if (type === "location") {
      return sel === locationValue;
    } else if (type === "duration") {
      return sel === durationValue;
    } else {
      console.log("invalid type passed to checkSelectionAdded");
      return false;
    }
  };

  const addSelection = (sel: string, type: string) => {
    if (type === "goal") {
      let newGoals = [...addedGoals];
      newGoals.push(sel);

      setAddedGoals(newGoals);
    } else if (type === "groupSize") {
      setGroupSizeValue(sel);
    } else if (type === "budget") {
      setBudgetValue(sel);
    } else if (type === "location") {
      setLocationValue(sel);
    } else if (type === "duration") {
      setDurationValue(sel);
    } else {
      console.log("invalid type passed to addSelection");
    }
  };

  const removeSelection = (sel: string, type: string) => {
    if (type === "goal") {
      let newGoals = [...addedGoals.filter((el) => sel !== el)];

      setAddedGoals(newGoals);
    } else {
      console.log("invalid type passed to addSelection");
    }
  };

  const toggleSelection = (target: string, type: string) => {
    if (target !== undefined) {
      if (checkSelectionAdded(target, type)) {
        if (type !== "formality" && type !== "cost") {
          removeSelection(target, type);
        }
      } else {
        addSelection(target, type);
      }
    } else {
      console.log("undefined selection in toggleSelection");
    }
  };

  const handleNextPrev = (type: string) => {
    switch (quizStep) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      case 6:
        break;
      default:
        console.log("invalid quizStep in handleNextPrev()");
    }
    setQuizStep(type === "next" ? quizStep + 1 : quizStep - 1);
  };

  return (
    <Grid
      container
      direction={props.quizType === "overlay" ? "row" : "column"}
      height={props.quizType === "overlay" ? "100%" : ""}
      minHeight={props.quizType === "overlay" ? "" : "100vh"}
    >
      <Grid item xs={12} lg={4} height={props.quizType === "overlay" ? "100%" : "40vh"}>
        <img
          src={quizQuestions[quizStep].img}
          alt="backdrop"
          style={{
            objectFit: "cover",
            width: "100%",
            height: props.quizType === "overlay" ? "100%" : "40vh",
            borderRadius: props.quizType === "overlay" ? "5px 0 0 8px" : "",
            float: "left",
            maxHeight: props.quizType === "overlay" ? "" : "30vh",
          }}
        />
      </Grid>
      {!showSuccess && (
        <Grid
          container
          item
          xs={12}
          lg={8}
          direction="column"
          minHeight={props.quizType === "overlay" ? "inherit" : "70vh"}
          justifyContent="center"
          padding="1rem"
        >
          <h5 style={{ margin: "1rem 0 1rem 0" }}>{quizQuestions[quizStep].title}</h5>
          <div style={{ height: "2.5rem" }}>
            <span className="desktop-body">
              {(props.quizType === "overlay" || quizQuestions[quizStep].subtitleOnMobile) &&
                quizQuestions[quizStep].subtitle}
            </span>
          </div>
          {quizQuestions[quizStep].element}

          <Grid container item direction="row" marginTop="auto" paddingTop="auto" mt={"auto"}>
            {quizStep === 0 && (
              <Grid item xs={12}>
                <Link to={"/pages/findExperience"} onClick={(e) => setContactQuizActive(false)}>
                  <span className="desktop-body" style={{ color: "info" }}>
                    Browse experiences instead?
                  </span>
                </Link>
              </Grid>
            )}
            <Grid container item xs={4} justifyContent={"start"} alignItems="end">
              {quizStep > 0 && (
                <button
                  onClick={() => handleNextPrev("prev")}
                  className="button-cream button-small"
                >
                  <span className="button-text-cream small-button-text">Back</span>
                </button>
              )}
            </Grid>

            <Grid container item xs={4} justifyContent={"center"} alignItems="end">
              {!isMobile && quizStep <= 5 && (
                <span
                  className="desktop-body"
                  style={{
                    fontWeight: "light",
                    color: "var(--forest-green)",
                    fontSize: "16px",
                  }}
                >
                  Step {quizStep + 1} of {quizQuestions.length}
                </span>
              )}
            </Grid>

            <Grid
              container
              item
              xs={4}
              style={{
                justifyContent: "end",
                alignItems: "end",
                margin: "0 0 0 auto",
              }}
            >
              {quizStep < quizQuestions.length - 1 && (
                <button onClick={() => handleNextPrev("next")} className="button-lime button-small">
                  <span className="button-text-lime small-button-text">Next</span>
                </button>
              )}

              {quizStep === quizQuestions.length - 1 && !showSuccess && emailValue === "" && (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                  <span className="desktop-body" style={{ fontSize: 16 }}>
                    Enter email to continue
                  </span>
                  <button
                    className="button-transparent button-small"
                    style={{ marginLeft: "auto" }}
                  >
                    <span className="button-text-cream small-button-text">Talk to us!</span>
                  </button>
                </div>
              )}
              {quizStep === quizQuestions.length - 1 && !showSuccess && emailValue !== "" && (
                <button
                  className="button-forest button-small"
                  onClick={handleSubmit(submitContactQuiz)}
                >
                  <span className="button-text-forest small-button-text">Talk to us!</span>
                </button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {showSuccess && (
        <Grid container item xs={8} direction="column" padding="2rem">
          <Grid item mt="2rem" p="5rem">
            <SvgIcon
              component={CheckCircleOutlineIcon}
              color="success"
              sx={{
                transform: "scale(7)",
              }}
            />
          </Grid>
          <Grid item mb="2rem">
            <h5 style={{ margin: "1rem 0 1rem 0" }}>Thanks for getting in touch!</h5>
            <span className="desktop-body" style={{ color: "info" }}>
              Townish event planners will contact you within 1-2 business days to help plan your
              event.
            </span>
          </Grid>

          {props.quizType === "route" && (
            <Grid container item justifyContent={"center"}>
              <Link to="/">
                <button className="button-small button-transparent">close</button>
              </Link>
            </Grid>
          )}
          {props.quizType === "overlay" && (
            <Grid container item justifyContent={"center"}>
              <button
                className="button-small button-transparent"
                onClick={(e) => setContactQuizActive(false)}
              >
                close
              </button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default function ContactQuizOverlay() {
  const setContactQuizActive = useGlobalContext().setContactQuizActive;

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(200, 200, 200, 0.6)",
        zIndex: 10001,
        backdropFilter: "blur(6px)",
        WebkitBackfaceVisibility: "hidden",
        WebkitPerspective: 1000,
        WebkitTransform: "translate3d(0, 0, 0) translateZ(0)",
        backfaceVisibility: "hidden",
        perspective: 1000,
        transform: "translate3d(0, 0, 0) translateZ(0)",
      }}
      onClick={(e) => setContactQuizActive(false)}
    >
      <div
        style={{
          width: "75%",
          height: "75%",
          overflow: "auto",
          margin: "5% auto 0 auto",
          backgroundColor: "white",
          border: "2px solid",
          borderRadius: "8px",
        }}
        onClick={handleClick}
      >
        <ContactQuiz quizType={"overlay"} />
      </div>
    </div>
  );
}
