import { Grid } from "@mui/material";
import { useGlobalContext } from "../../App";

export default function ValueProp() {
  return (
    <section
      className="value-prop-background"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "75px",
        paddingBottom: "50px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: useGlobalContext().isMobile ? 0 : "10vw",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <span
            className="overline value-prop-overline"
            style={{ textAlign: useGlobalContext().isMobile ? "center" : "left" }}
          >
            EFFICIENT
          </span>
          <hr
            className="value-prop-hr"
            style={{ marginLeft: useGlobalContext().isMobile ? "auto" : 0, marginRight: "auto" }}
          />
          <h3
            className="value-prop-h3"
            style={{ textAlign: useGlobalContext().isMobile ? "center" : "left" }}
          >
            Save time & money
          </h3>
          <span
            className="desktop-body value-prop-desktop-body"
            style={{
              marginRight: "auto",
              marginLeft: useGlobalContext().isMobile ? "auto" : "",
              textAlign: "left",
              width: "80%",
            }}
          >
            We connect you with hundreds of venues, activities, and food experiences with pricing
            exclusive to Townish.
          </span>
        </Grid>
        <Grid item xs={12} md={5}>
          <img
            src={require("../../img/valueProp/vendor_array_sm.png")}
            alt="vendor array"
            style={{
              maxWidth: "80%",
              objectFit: "cover",
              padding: useGlobalContext().isMobile ? "10px" : 0,
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction={"row-reverse"}
        style={{
          marginTop: "50px",
        }}
        justifyContent={"left"}
      >
        <Grid
          item
          xs={12}
          md={4.5}
          alignItems={"left"}
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: useGlobalContext().isMobile ? 0 : "10vw",
            marginTop: "auto",
            marginBottom: "auto",
          }}
          order={1}
        >
          <span
            className="overline value-prop-overline"
            style={{ textAlign: useGlobalContext().isMobile ? "center" : "left" }}
          >
            DYNAMIC
          </span>
          <hr
            className="value-prop-hr"
            style={{ marginLeft: useGlobalContext().isMobile ? "auto" : 0, marginRight: "auto" }}
          />
          <h3
            className="value-prop-h3"
            style={{ textAlign: useGlobalContext().isMobile ? "center" : "left" }}
          >
            Zero hassle
          </h3>
          <span
            className="desktop-body value-prop-desktop-body"
            style={{
              marginRight: "auto",
              marginLeft: useGlobalContext().isMobile ? "auto" : "",
              textAlign: "left",
              width: "80%",
            }}
          >
            No time to go through hundreds of places, calling vendors to check availability and find
            just the right fit for your team? We do it all for you, in minutes!
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          md={4.5}
          style={{
            display: "flex",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: useGlobalContext().isMobile ? "15vw" : "10vw",
          }}
          order={2}
        >
          <img
            src={require("../../img/valueProp/phone_notifications.png")}
            alt="notifications on phone"
            style={{
              maxWidth: "80%",
              objectFit: "cover",
              padding: useGlobalContext().isMobile ? "10px" : 0,
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          marginTop: "50px",
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: useGlobalContext().isMobile ? 0 : "10vw",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <span
            className="overline value-prop-overline"
            style={{ textAlign: useGlobalContext().isMobile ? "center" : "left" }}
          >
            CONNECTED
          </span>
          <hr
            className="value-prop-hr"
            style={{ marginLeft: useGlobalContext().isMobile ? "auto" : 0, marginRight: "auto" }}
          />
          <h3
            className="value-prop-h3"
            style={{ textAlign: useGlobalContext().isMobile ? "center" : "left" }}
          >
            Embrace flexibility
          </h3>
          <span
            className="desktop-body value-prop-desktop-body"
            style={{
              marginRight: "auto",
              textAlign: "left",
              width: "80%",
              marginLeft: useGlobalContext().isMobile ? "auto" : "",
            }}
          >
            Teams come in all shapes and sizes. However you work, Townish can bring your team closer
            together.
          </span>
        </Grid>
        <Grid item xs={12} md={5}>
          <img
            src={require("../../img/valueProp/schedule.png")}
            alt="event schedule"
            style={{
              maxWidth: "80%",
              objectFit: "cover",
              padding: useGlobalContext().isMobile ? "10px" : 0,
            }}
          />
        </Grid>
      </Grid>
    </section>
  );
}
