import Navbar from "../../components/Navbar";
import CTA from "./CTA";
import CaseStudy from "./CaseStudy";
import Categories from "./Categories";
import FeaturedExperiences from "./FeaturedExperiences";
import LPFooter from "./Footer";
import Hero from "./Hero";
import LogoBar from "./LogoBar";
import ProblemStatement from "./ProblemStatement";
import Testimonials from "./Testimonials";
import TrustBar from "./TrustBar";
import ValueProp from "./ValueProp";

import PreFooter from "./PreFooter";
import "./landingpage.css";

export default function LandingPage() {
  return (
    <div>
      <Navbar section float />

      <section className="landing-section">
        <div className="landing-div">
          <Hero />
        </div>
      </section>
      <section className="landing-section">
        <TrustBar />
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <LogoBar />
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <FeaturedExperiences />
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <ValueProp />
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Testimonials />
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Categories />
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <CTA />
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <ProblemStatement />
          <CaseStudy />
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <PreFooter />
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <LPFooter />
        </div>
      </section>
    </div>
  );
}
