import { Grid } from "@mui/material";
import { useGlobalContext } from "../../App";
import { Link } from "react-router-dom";

export default function CTA() {
  const setContactQuizActive = useGlobalContext().setContactQuizActive;

  return (
    <section
      className="landing-div cta-background"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "100px",
        paddingBottom: "100px",
      }}
    >
      <Grid container direction="row" style={{ padding: "0 10vw 0 10vw" }}>
        <Grid
          item
          xs={12}
          lg={6}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3
            className="cta-h3"
            style={{
              marginRight: "auto",
              textAlign: "left",
              width: useGlobalContext().isMobile ? "100%" : "85%",
            }}
          >
            Let our experts take care of your team
          </h3>
          <span
            className="desktop-body cta-desktop-body"
            style={{
              marginRight: "auto",
              textAlign: "left",
              width: useGlobalContext().isMobile ? "100%" : "80%",
            }}
          >
            Our team will be happy to hear from you and get you going.
          </span>
          <Grid container spacing={"1rem"} style={{ marginTop: ".5rem" }}>
            <Grid item xs={12} lg={5}>
              <Link to="/pages/findExperience">
                <button className="button-forest" style={{ width: "100%" }}>
                  <span className="button-text button-text-forest">Get started</span>
                </button>
              </Link>
            </Grid>
            <Grid item xs={12} lg={5}>
              {!useGlobalContext().isMobile && (
                <button
                  className="button-cream"
                  style={{ width: "100%", marginBottom: "2rem" }}
                  onClick={(e) => setContactQuizActive(true)}
                >
                  <span className="button-text button-text-cream">Contact us</span>
                </button>
              )}

              {useGlobalContext().isMobile && (
                <Link to="/pages/contactQuiz">
                  <button className="button-cream" style={{ width: "100%", marginBottom: "2rem" }}>
                    <span className="button-text button-text-cream">Contact us</span>
                  </button>
                </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={6}>
          <img
            src={require("../../img/CTA/CTA_collage.png")}
            alt="vendor array"
            style={{
              maxWidth: "100%",
              objectFit: "cover",
              marginLeft: "auto",
              paddingLeft: "5vw",
              marginRight: 0,
            }}
          />
        </Grid>
      </Grid>
    </section>
  );
}
