import { useParams } from "react-router-dom";
import VendorDetailElement from "../vendorDetail/vendorDetailElement";
import BookableDetailElement from "./bookableDetailElement";

import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

import {
  fetchAmenitiesForVendor,
  fetchBookable,
  fetchVendor,
  internalBookable,
  internalVendor,
} from "../../ServiceUtils";

import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { Amenity } from "../../API";
import featureActive from "../../toggles";

export default function BookableDetail() {
  const { bookableID } = useParams();
  const [bookableloading, setBookableLoading] = useState(true);
  const [vendorLoading, setVendorLoading] = useState(true);
  const [bookable, setBookable] = useState<internalBookable>();
  const [vendor, setVendor] = useState<internalVendor>();
  const [amenities, setAmenities] = useState<Amenity[]>([]);

  useEffect(() => {
    setBookableLoading(true);

    if (bookableID) {
      (async () => {
        const fetchedBookable = await fetchBookable(bookableID);
        setBookable(fetchedBookable);
        setBookableLoading(false);
      })();
    }
  }, [bookableID]);

  useEffect(() => {
    setVendorLoading(true);

    (async () => {
      if (bookable?.vendorBookablesVendorID) {
        const fetchedVendor = await fetchVendor(bookable.vendorBookablesVendorID);
        setVendor(fetchedVendor);

        const fetchedAmenities = await fetchAmenitiesForVendor(bookable.vendorBookablesVendorID);
        setAmenities(fetchedAmenities);

        setVendorLoading(false);
      }
    })();
  }, [bookable]);

  if (!featureActive("serviceExplorer")) {
    return <></>;
  }

  return (
    <>
      <Navbar section />
      <section className="landing-section">
        <div className="landing-div" style={{ padding: "1rem" }}>
          {bookableloading && (
            <Player
              autoplay
              loop
              src={require("../../img/anim/1708183249198.json")}
              style={{ height: "100px", width: "100px", margin: "0 auto 0 auto" }}
            >
              <Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} />
            </Player>
          )}
          {!bookableloading && !bookable && (
            <h5 style={{ margin: 0, padding: "2rem 0" }}>
              We couldn't find details for that service.
            </h5>
          )}
          {!bookableloading && bookable && <BookableDetailElement bookable={bookable} />}
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div" style={{ padding: "1rem" }}>
          {vendorLoading && (
            <Player
              autoplay
              loop
              src={require("../../img/anim/1708183249198.json")}
              style={{ height: "100px", width: "100px", margin: "0 auto 0 auto" }}
            >
              <Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} />
            </Player>
          )}
          {!vendorLoading && vendor && (
            <VendorDetailElement bookableView vendor={vendor} amenities={amenities} />
          )}
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}
