import { useGlobalContext } from "../App";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function SorsoCaseStudy() {
  return (
    <>
      <Navbar section />
      <section className="landing-section">
        <div
          className="landing-div"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <div
            style={{
              width: useGlobalContext().isMobile ? "100%" : "80%",
              margin: "0 auto",
              textAlign: "left",
            }}
          >
            <h4>
              Townish-powered team building: How we strengthened bonds for Sorso Wellness Water’s
              distributed team
            </h4>
            <img
              src={require("../img/eventPhotos/Sorso4.jpeg")}
              alt="postability event action shot"
              style={{ width: "100%", height: "40vh", objectFit: "cover", borderRadius: "30px" }}
            />
            <h5 style={{ margin: "3rem 0 1rem 0" }}>Introduction</h5>
            <p className="desktop-body">
              At Townish, we understand that corporate events play a vital role in fostering a
              positive company culture and boosting employee engagement. Our mission is to simplify
              the event planning process by connecting businesses with top-quality, vetted services
              that make team-building activities, offsites, and other events fun, engaging, and
              stress-free.
            </p>

            <h5 style={{ margin: "3rem 0 1rem 0" }}>The Challenge</h5>
            <p className="desktop-body">
              Planning successful team-building events and offsites can be time-consuming and
              complex, requiring coordination of multiple vendors and attention to detail. Companies
              often struggle to find reliable, high-quality services that align with their goals and
              budget. This is even more challenging for remote and distributed companies, where
              employees are located in different regions / countries.
            </p>
            <h5 style={{ margin: "3rem 0 1rem 0" }}>Our Solution</h5>
            <p className="desktop-body">
              Our online marketplace offers an extensive range of services specifically tailored for
              team-building events and offsites, including activity providers, caterers, unique
              venues, travel arrangements, and accommodations. All our partners are carefully vetted
              to ensure they meet the highest standards of quality and reliability. With our
              platform, businesses can easily browse, compare, and book services to create a
              customized event package that meets their specific needs and objectives. With our
              event planning tools, we bring the best services in the best location for your team.
            </p>
            <img
              src={require("../img/eventPhotos/Sorso3Cropped.jpeg")}
              alt="postability event action shot"
              style={{
                width: "100%",
                height: "40vh",
                objectFit: "cover",
                objectPosition: "50% 35%",
                borderRadius: "30px",
              }}
            />

            <h5 style={{ margin: "3rem 0 1rem 0" }}>Case Study: Sorso Wellness Water</h5>
            <p className="desktop-body">
              We had the pleasure of partnering with Sorso Wellness Water, a thriving e-commerce and
              wellness brand that prioritizes employee well-being. The company is headquartered in
              Toronto with a distributed team in the U.S, Eastern Canada, South Africa and Colombia.
            </p>

            <p className="desktop-body">
              The company is experiencing tremendous growth and wanted to take this opportunity to
              bring together the team to celebrate, as well as getting to know each other, in
              person.
            </p>

            <p className="desktop-body">
              Together, we organized a 3-day offsite retreat that included travel, accommodation,
              and team building activities designed to promote wellness and camaraderie. Our
              services helped Sorso Wellness Water strengthen their company culture and employee
              engagement.
            </p>

            <p className="desktop-body">
              The offsite event was a standout success, with participants enjoying a seamless
              experience from start to finish. We coordinated comfortable accommodations, healthy
              catered meals, and expert-led workshops on mindfulness and meditation, all while
              incorporating the natural beauty of the retreat’s surroundings. The event was a
              testament to the power of well-planned, intentional team-building activities in
              fostering a positive and engaged company culture.
            </p>

            <img
              src={require("../img/eventPhotos/Sorso1.jpeg")}
              alt="postability event action shot"
              style={{
                width: "100%",
                height: "40vh",
                objectFit: "cover",
                objectPosition: "50% 25%",
                borderRadius: "30px",
              }}
            />

            <h5 style={{ margin: "3rem 0 1rem 0" }}>The Future:</h5>
            <p className="desktop-body">
              Looking ahead, we remain dedicated to expanding our offerings and refining our
              platform to cater to the unique needs of businesses like Sorso Wellness Water. Our
              goal is to be the go-to solution for engaging, memorable, and stress-free
              team-building events, offsites, and other corporate events that reflect each company’s
              values and objectives.
            </p>

            <p className="desktop-body">
              By choosing Townish, businesses can streamline their event planning process, access
              top-quality services, and create engaging experiences that foster a strong sense of
              teamwork and camaraderie. Join us in revolutionizing the world of corporate events and
              team-building activities.
            </p>
          </div>
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}
