import { Link } from "react-router-dom";
import { useGlobalContext } from "../../App";

export default function Categories() {
  return (
    <section
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        padding: "50px 10px 50px 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          width: "fit-content",
          alignItems: "center",
        }}
      >
        {!useGlobalContext().isMobile && (
          <h3 className="categories-font-color">
            Hundreds of venues and vendors on offer
            <hr
              className="categories-hr"
              style={{ marginTop: "5px", marginLeft: 0, marginRight: "auto" }}
            />
          </h3>
        )}
        {useGlobalContext().isMobile && (
          <>
            <h4 style={{ color: "var(--forest-green)" }}>
              Hundreds of venues and vendors on offer
            </h4>
            <hr
              className="categories-hr"
              style={{ marginTop: "5px", marginLeft: "3vw", marginRight: "auto" }}
            />
          </>
        )}
        <img
          src={require("../../img/categories/allCategories.png")}
          alt="All Categories"
          style={{
            width: useGlobalContext().isMobile ? "95%" : "80%",
            marginBottom: "30px",
            borderRadius: "8px",
          }}
        />
        <Link to="/pages/findExperience">
          <button className="button-lime">
            <span className="button-text button-text-lime">Explore more</span>
          </button>
        </Link>
      </div>
    </section>
  );
}
