import { useEffect, useState } from "react";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Chip, Grid } from "@mui/material";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ExperienceFAQ from "../pages/experienceDetail/experienceFAQ/experienceFAQ";
import PricingBar from "../pages/experienceDetail/experiencePricingBar/experiencePricingBar";

import { experienceDetailsType, experienceIndexType } from "../experienceIndex";
import ExperienceMediaCarousel from "../pages/experienceDetail/experienceMediaCarousel/experienceMediaCarousel";
import Map from "../pages/findExperienceDeprecated/Map";

import Carousel from "react-multi-carousel";
import { useGlobalContext } from "../App";
import { fetchVendor, internalVendor } from "../ServiceUtils";
import VendorCard from "../components/vendorCard";
import { findExperienceDetailsByID } from "../experienceUtils";
import { responsiveCarousel } from "../pages/LandingPage/FeaturedExperiences";
import Testimonials from "../pages/LandingPage/Testimonials";
import ExperienceAgenda from "../pages/experienceDetail/experienceAgenda";
import ExperienceFeatures from "../pages/experienceDetail/experienceFeatures";
import ExperienceHighlights from "../pages/experienceDetail/experienceHighlights";
import ExperienceOptions from "../pages/experienceDetail/experienceOptions/experienceOptions";
import featureActive from "../toggles";

export default function ExperienceLayout(props: { exp?: experienceIndexType }) {
  const [expDetails, setExpDetails] = useState<experienceDetailsType | undefined>(undefined);
  const [vendors, setVendors] = useState<internalVendor[]>([]);
  const [selectedOption, setSelectedOption] = useState("");
  const isMobile = useGlobalContext().isMobile;

  useEffect(() => {
    if (props.exp) {
      let experienceDetails = findExperienceDetailsByID(props.exp.id);
      setExpDetails(experienceDetails);

      if (experienceDetails) {
        (async () => {
          let fetchedVendors: internalVendor[] = [];
          let tempVendors = await Promise.all(
            experienceDetails.experienceLocations.map(
              async (expLoc): Promise<internalVendor | undefined> => {
                let fetchedVendor = await fetchVendor(expLoc.id);

                if (fetchedVendor) {
                  fetchedVendors.push(fetchedVendor);
                }
                return fetchedVendor;
              }
            )
          );
          setVendors(fetchedVendors);
        })();
      }
    }
  }, [props.exp]);

  return (
    <>
      {props.exp && expDetails && !isMobile && (
        <>
          <div style={{ position: "fixed", bottom: 0, justifyContent: "flex-end", zIndex: 11 }}>
            <PricingBar
              expDetails={expDetails}
              selectedOption={selectedOption}
              duration={props.exp.experienceDuration}
              price={
                props.exp.pricePerPerson
                  ? props.exp.pricePerPerson.toLocaleString() + " per person"
                  : props.exp.totalPrice
                  ? props.exp.totalPrice.toLocaleString()
                  : ""
              }
              message={expDetails.priceMessage}
            />
          </div>
          <Navbar />

          <section
            style={{
              height: "739px",
              backgroundColor: "#ffffff",
              alignItems: "center",
              position: "relative",
              marginBottom: "12rem",
            }}
          >
            <img
              className="header-image"
              src={expDetails.experienceMainImg}
              alt="experience header"
              style={{
                height: "100%",
                minHeight: "850px",
                objectFit: "cover",
                objectPosition: "center",
                width: "100%",
              }}
            />
          </section>

          <div
            className="text-over-image"
            style={{
              width: "33%",
              marginTop: "33%",
              marginLeft: "2rem",
            }}
          >
            <h3
              style={{
                fontWeight: "bold",
                marginBottom: 0,
                textShadow: "2px 2px #000000",
                color: "white",
              }}
            >
              {props.exp.experienceName}
            </h3>
            <h5 style={{ color: "white", marginTop: "1rem", textShadow: "1px 1px #000000" }}>
              {expDetails.tagline}
            </h5>
          </div>

          <div
            style={{
              background: "var(--lime-green-transparent)",
              display: "flex",
              paddingTop: "2rem",
              borderTop: "2px solid gray",
              borderBottom: "2px solid gray",
            }}
          >
            <div style={{ width: "80%", textAlign: "left", margin: "0 auto 0 auto" }}>
              {typeof expDetails.longDescription === "string" ? (
                <p className="desktop-body">{expDetails.longDescription}</p>
              ) : (
                expDetails.longDescription.map((desc, i) => {
                  if (desc[0] === "*") {
                    return (
                      <li
                        className="desktop-body"
                        key={"desc-li-" + i}
                        style={{ marginLeft: "2rem" }}
                      >
                        {desc.slice(2)}
                      </li>
                    );
                  } else {
                    return (
                      <p className="desktop-body" key={"desc-paragraph-" + i}>
                        {desc}
                      </p>
                    );
                  }
                })
              )}
              <Grid container direction="row" paddingTop="2.5rem" marginBottom="1rem">
                <Grid item xs={7.5} mr={"2rem"}>
                  <h4
                    style={{
                      color: "var(--forest-green)",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: "1rem",
                    }}
                  >
                    Included in the experience
                  </h4>
                  <ExperienceFeatures features={expDetails.features} />
                </Grid>
                <Grid item xs={4} mt="4.5rem" zIndex={0}>
                  <Map
                    vendorLocations
                    mapOrigin={expDetails.experienceLocations[0].location}
                    filteredExperiences={expDetails.experienceLocations}
                    distanceSliderValue={4}
                    zoomLevelOverride={7}
                    height={"350px"}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ width: "80%", margin: "0 auto 0 auto" }}>
            <ExperienceHighlights highlights={expDetails.highlights} />
          </div>
          {expDetails.testimonials && <Testimonials testimonials={expDetails.testimonials} />}
          <div>
            <ExperienceMediaCarousel experienceImgs={expDetails.experienceImgs} />
          </div>

          <div
            style={{
              background: "var(--lime-green-transparent",
              padding: "2rem",
              borderTop: "2px solid gray",
              borderBottom: "2px solid gray",
            }}
          >
            <div style={{ width: "80%", margin: "0 auto 0 auto", textAlign: "left" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <h4
                  style={{
                    color: "var(--forest-green)",
                    textAlign: "left",
                    margin: "1rem 0 1rem 0",
                  }}
                >
                  Suggested agenda
                </h4>
                <Chip
                  variant="outlined"
                  icon={<AutoFixHighIcon fontSize="medium" />}
                  label="Customizable"
                  sx={{
                    ml: "2rem",
                    px: 1,
                    backgroundColor: "white",
                    border: "2px solid",
                    borderColor: "var(--forest-green)",
                  }}
                />
              </div>

              <ExperienceAgenda agenda={expDetails.agenda} />
            </div>
            {expDetails.experienceOptions && expDetails.experienceOptions.length > 0 && (
              <div
                style={{
                  padding: "2rem",
                  width: "80%",
                  margin: "0 auto 0 auto",
                  textAlign: "left",
                }}
              >
                <ExperienceOptions
                  expOptions={expDetails.experienceOptions}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </div>
            )}
          </div>

          {featureActive("serviceExplorer") && vendors.length > 0 && (
            <div
              style={{
                width: "80%",
                marginTop: "2rem",
                marginLeft: "10%",
                textAlign: "left",
              }}
            >
              <h4 style={{ margin: "2rem 0 0 0", fontSize: 30 }}>
                Vendors included in {props.exp.experienceName}
              </h4>
              <Carousel
                renderButtonGroupOutside={true}
                arrows={true}
                responsive={responsiveCarousel}
                autoPlay={true}
                autoPlaySpeed={6000}
                swipeable={false}
                draggable={false}
                showDots={false}
                infinite={true}
                partialVisible={true}
                dotListClass="custom-dot-list-style"
              >
                {vendors.map((vendor: internalVendor, i: number) => {
                  return (
                    <div className="slider" key={i} style={{ margin: "0 .5rem" }}>
                      <VendorCard vendor={vendor} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
          <div style={{ width: "80%", margin: "0 auto 2rem auto" }}>
            <ExperienceFAQ FAQ={expDetails.FAQ} />
          </div>

          <Footer />
          <div style={{ height: "8rem" }} />
        </>
      )}
      {props.exp && expDetails && isMobile && (
        <>
          <div style={{ position: "fixed", bottom: 0, justifyContent: "flex-end", zIndex: 11 }}>
            <PricingBar
              expDetails={expDetails}
              selectedOption={selectedOption}
              duration={props.exp.experienceDuration}
              price={
                props.exp.pricePerPerson
                  ? props.exp.pricePerPerson.toLocaleString() + " per person"
                  : props.exp.totalPrice
                  ? props.exp.totalPrice.toLocaleString()
                  : ""
              }
              message={expDetails.priceMessage}
            />
          </div>
          <Navbar />

          <section
            style={{
              height: "70vh",
              backgroundColor: "#ffffff",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              className="header-image"
              src={expDetails.experienceMainImg}
              alt="experience header"
              style={{
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                width: "100%",
              }}
            />
          </section>

          <div style={{ textAlign: "left", paddingLeft: "1rem" }}>
            <h3 style={{ marginTop: "2rem", marginBottom: "1rem" }}>{props.exp.experienceName}</h3>
            <h4 style={{ marginTop: "1rem" }}>{expDetails.tagline}</h4>
          </div>

          <div
            style={{
              background: "var(--lime-green-transparent)",
              display: "flex",
              paddingTop: "2rem",
              borderTop: "2px solid gray",
              borderBottom: "2px solid gray",
            }}
          >
            <div style={{ width: "80%", textAlign: "left", margin: "0 auto 0 auto" }}>
              {typeof expDetails.longDescription === "string" ? (
                <p className="desktop-body">{expDetails.longDescription}</p>
              ) : (
                expDetails.longDescription.map((desc, i) => {
                  return (
                    <p className="desktop-body" key={"desc-paragraph-" + i}>
                      {desc}
                    </p>
                  );
                })
              )}
              <Grid container direction="column" paddingTop="2.5rem" marginBottom="1rem">
                <Grid item mt="auto" mb="1rem" zIndex={0}>
                  <Map
                    vendorLocations
                    mapOrigin={expDetails.experienceLocations[0].location}
                    filteredExperiences={expDetails.experienceLocations}
                    distanceSliderValue={4}
                    zoomLevelOverride={7}
                    height={"350px"}
                  />
                </Grid>
                <Grid item>
                  <h5
                    style={{
                      color: "var(--forest-green)",
                      textAlign: "left",

                      marginBottom: "1rem",
                    }}
                  >
                    Included in the experience
                  </h5>
                  <ExperienceFeatures features={expDetails.features} />
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ width: "80%", margin: "0 auto 0 auto" }}>
            <ExperienceHighlights highlights={expDetails.highlights} />
          </div>

          <div>
            <ExperienceMediaCarousel experienceImgs={expDetails.experienceImgs} />
          </div>

          <div
            style={{
              background: "var(--lime-green-transparent",
              padding: "2rem",
              borderTop: "2px solid gray",
              borderBottom: "2px solid gray",
            }}
          >
            <div style={{ width: "95%", margin: "0 auto 0 auto", textAlign: "left" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: ".5rem",
                }}
              >
                <h3
                  style={{
                    color: "var(--forest-green)",
                    textAlign: "left",
                    margin: "1rem 0 1rem 0",
                  }}
                >
                  Suggested agenda
                </h3>
                <Chip
                  variant="outlined"
                  icon={<AutoFixHighIcon fontSize="small" />}
                  label="Customizable"
                  sx={{
                    width: "50%",
                    px: 1,
                    backgroundColor: "white",
                    border: "2px solid",
                    borderColor: "var(--forest-green)",
                  }}
                />
              </div>

              <ExperienceAgenda agenda={expDetails.agenda} />
            </div>
            {expDetails.experienceOptions && expDetails.experienceOptions.length > 0 && (
              <div
                style={{
                  padding: "2rem",
                  width: "95%",
                  margin: "0 auto 0 auto",
                  textAlign: "left",
                }}
              >
                <ExperienceOptions
                  expOptions={expDetails.experienceOptions}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </div>
            )}
          </div>

          <div style={{ width: "95%", margin: "0 auto 2rem auto" }}>
            <ExperienceFAQ FAQ={expDetails.FAQ} />
          </div>

          {featureActive("serviceExplorer") && vendors.length > 0 && (
            <div style={{ width: "95%", margin: "0 auto 2rem auto" }}>
              <div
                style={{
                  width: "80%",
                  marginTop: "2rem",
                  marginLeft: "10%",
                  textAlign: "left",
                }}
              >
                <h5 style={{ margin: "2rem 0 0 0" }}>
                  Vendors included in {props.exp.experienceName}
                </h5>
                <Carousel
                  renderButtonGroupOutside={true}
                  arrows={true}
                  responsive={responsiveCarousel}
                  autoPlay={true}
                  autoPlaySpeed={6000}
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  infinite={true}
                  partialVisible={true}
                  dotListClass="custom-dot-list-style"
                >
                  {vendors.map((vendor: internalVendor, i: number) => {
                    return (
                      <div className="slider" key={i} style={{ margin: "0 .5rem" }}>
                        <VendorCard vendor={vendor} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          )}
          <Footer />
          <div style={{ height: "8rem" }} />
        </>
      )}
    </>
  );
}
