import { Button, Grid } from "@mui/material";
import { Bookable } from "../../API";
import { getS3ImagesInFolder, s3ImgParams } from "../../ServiceUtils";
import { useEffect, useState } from "react";
import Map from "../findExperienceDeprecated/Map";
import { locationType, serviceLocationType } from "../../experienceIndex";

export default function BookableDetailElement(props: { bookable: Bookable }) {
  const [imgs, setImgs] = useState<string[]>([]);
  const [imgsLoading, setImgsLoading] = useState(true);
  const bookableLocation: locationType = JSON.parse(props.bookable.bookableLocation);

  useEffect(() => {
    setImgsLoading(true);

    (async () => {
      const fetchedImages = await getS3ImagesInFolder(props.bookable.bookableImgPath);
      setImgs(fetchedImages);
      setImgsLoading(false);
    })();
  }, [props.bookable]);

  return (
    <>
      <Grid container direction="column">
        <Grid
          container
          item
          direction="row"
          style={{
            width: "80%",
            margin: "0 auto 0 auto",
            height: "50vh",
            minHeight: "500px",
          }}
        >
          <Grid item xs={8} padding="1rem .5rem" height="100%">
            <div
              style={{
                display: "flex",
                backgroundColor: "var(--smoke-grey)",
                borderRadius: "20px",
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src={s3ImgParams.s3ImgPath + encodeURIComponent(imgs[0])}
                alt="service main"
                style={{
                  borderRadius: "20px",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </Grid>
          <Grid container item xs={4} direction="column" height="100%" padding="1rem .5rem">
            <Grid item xs={6} height="50%" paddingBottom=".5rem">
              <div
                style={{
                  backgroundColor: "var(--smoke-grey)",
                  borderRadius: "20px",
                  width: "100%",
                  height: "100%",
                }}
              >
                {imgs.length >= 2 && (
                  <img
                    src={s3ImgParams.s3ImgPath + encodeURIComponent(imgs[1])}
                    alt="service main"
                    style={{
                      borderRadius: "20px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={6} height="50%" paddingTop=".5rem">
              <div
                style={{
                  backgroundColor: "var(--smoke-grey)",
                  borderRadius: "20px",
                  width: "100%",
                  height: "100%",
                }}
              >
                {imgs.length >= 3 && (
                  <img
                    src={s3ImgParams.s3ImgPath + encodeURIComponent(imgs[2])}
                    alt="service main"
                    style={{
                      borderRadius: "20px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item direction="row" style={{ width: "80%", margin: "1rem auto 0 auto" }}>
          <Grid container item xs={8} direction="column" paddingRight="1.5rem">
            <div style={{ textAlign: "left" }}>
              <h5 style={{ margin: "1rem 0" }}>{props.bookable.bookableName}</h5>
            </div>
            {props.bookable.bookableSubheading && (
              <div style={{ textAlign: "left" }}>
                <h5
                  style={{
                    color: "var(--forest-green)",
                    marginTop: 0,
                    fontSize: "22px",
                  }}
                >
                  {props.bookable.bookableSubheading}
                </h5>
              </div>
            )}
            <div style={{ textAlign: "left", margin: "0 auto 2rem auto" }}>
              <span className="desktop-body">{props.bookable.bookableDescription}</span>
            </div>
          </Grid>
          <Grid container item xs={4} direction="column" padding=".5rem">
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "1rem",
                border: "2px solid var(--smoke-grey)",
                borderRadius: "15px",
                padding: "1rem",
                textAlign: "left",
              }}
            >
              <div>
                <span className="desktop-body">from </span>
                <span className="desktop-body" style={{ fontWeight: "bold" }}>
                  ${props.bookable.bookablePrice}
                </span>
              </div>
              <div>
                <span className="desktop-body">
                  {props.bookable.bookableMinOccupancy +
                    "-" +
                    props.bookable.bookableMaxOccupancy +
                    " people"}
                </span>
              </div>
              <div>
                <span className="desktop-body">{props.bookable.bookableDuration + " hours"}</span>
              </div>

              <div style={{ marginTop: "1rem", textAlign: "center" }}>
                <Button variant="contained" color={"info"} sx={{ minWidth: "100%" }}>
                  Book {props.bookable.bookableName}
                </Button>
                <span className="desktop-body" style={{ fontWeight: "light", fontSize: "14px" }}>
                  We'll confirm availability for your desired booking date.
                </span>
              </div>
            </div>
            <div style={{ padding: ".5rem", marginRight: "1rem" }}>
              <Map
                vendorLocations
                mapOrigin={bookableLocation}
                filteredExperiences={[
                  {
                    id: props.bookable.vendorBookablesVendorID
                      ? props.bookable.vendorBookablesVendorID
                      : "",
                    location: bookableLocation,
                    experienceName: props.bookable.bookableName,
                    experienceThumbnail: props.bookable.bookableMainImg,
                  },
                ]}
                distanceSliderValue={4}
                zoomLevelOverride={7}
                height={"300px"}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
