import { useEffect, useState } from "react";

import { Divider, FormControlLabel, Grid, Popover, Radio, RadioGroup } from "@mui/material";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

import { locationType, eventTypes, experiences } from "../../experienceIndex";
import { isPointWithinRadius } from "geolib";
import { Bookable, Service } from "../../API";

export let cities = [
  {
    name: "anywhere",
    lat: 0,
    long: 0,
  },
  {
    name: "New York, NY",
    lat: 40.712728,
    long: -74.006015,
  },
  {
    name: "Boston, MA",
    lat: 42.355433,
    long: -71.060511,
  },
  {
    name: "Philadelphia, PA",
    lat: 39.952724,
    long: -75.163526,
  },
  {
    name: "Toronto, ON",
    lat: 43.653225,
    long: -79.383186,
  },
  {
    name: "Montreal, QC",
    lat: 45.503182,
    long: -73.569806,
  },
  {
    name: "Kitchener / Waterloo, ON",
    lat: 43.464951,
    long: -80.523911,
  },
  {
    name: "Guelph, ON",
    lat: 43.544811,
    long: -80.248108,
  },

  {
    name: "London, ON",
    lat: 42.984924,
    long: -81.245277,
  },
  {
    name: "Windsor, ON",
    lat: 42.314938,
    long: -83.036362,
  },
  {
    name: "Ottawa, ON",
    lat: 45.420878,
    long: -75.690111,
  },

  {
    name: "Kingston, ON",
    lat: 44.305415,
    long: -76.428378,
  },

  {
    name: "Albany, NY",
    lat: 42.651167,
    long: -73.754968,
  },
  {
    name: "Buffalo, NY",
    lat: 42.886717,
    long: -78.878392,
  },
  {
    name: "Rochester, NY",
    lat: 43.157285,
    long: -77.615214,
  },
  {
    name: "Hartford, CT",
    lat: 41.764582,
    long: -72.690855,
  },

  {
    name: "Pittsburgh, PA",
    lat: 40.441694,
    long: -79.990086,
  },
  /* NEED PROPER LAT/LONG */
  {
    name: "Hamilton, ON",
    lat: 43.256531,
    long: -79.87442,
  },
  {
    name: "Oshawa, ON",
    lat: 43.897095,
    long: -78.865791,
  },
  {
    name: "St. Catharines, ON",
    lat: 43.159988,
    long: -79.247017,
  },
  {
    name: "Niagara Falls",
    lat: 43.082817,
    long: -79.074165,
  },
  {
    name: "Barrie, ON",
    lat: 44.389339,
    long: -79.685516,
  },
  {
    name: "Hempstead, NY",
    lat: 40.673681,
    long: -73.619074,
  },
  {
    name: "Brookhaven, NY",
    lat: 40.779265,
    long: -72.915383,
  },
  {
    name: "Islip, NY",
    lat: 40.730069,
    long: -73.210727,
  },
  {
    name: "Oyster, NY",
    lat: 40.769657,
    long: -73.499861,
  },

  {
    name: "Babylon, NY",
    lat: 40.695655,
    long: -73.325675,
  },
];

export const eventDurationOptions = [
  {
    name: "any",
    days: 0,
  },
  {
    name: "part of a day",
    days: 1,
  },
  {
    name: "1 day",
    days: 1,
  },
  {
    name: "2 days",
    days: 2,
  },
  {
    name: "3 days",
    days: 3,
  },
  {
    name: "4+ days",
    days: 4,
  },
];

export const groupSizeOptions = [
  {
    name: "any",
    min: 0,
    max: 100,
  },
  {
    name: "1 - 10",
    min: 1,
    max: 10,
  },
  {
    name: "11 - 20",
    min: 11,
    max: 20,
  },
  {
    name: "21 - 35",
    min: 21,
    max: 35,
  },
  {
    name: "36+",
    min: 36,
    max: 100,
  },
];

export const budgetOptions = [
  {
    name: "any",
    min: -1,
    max: -1,
  },
  {
    name: "up to $200",
    min: 0,
    max: 200,
  },
  {
    name: "up to $500",
    min: 0,
    max: 500,
  },
  {
    name: "up to $1000",
    min: 0,
    max: 1000,
  },
  {
    name: "up to $2500",
    min: 0,
    max: 2500,
  },
];

export const serviceExplorerCategories = [
  {
    name: "Offsite events",
    showBookable: false,
    subCategoryID: "63d47686-234e-4c37-b881-bbb8f830dbca",
  },
  {
    name: "Meeting space",
    showBookable: false,
    subCategoryID: "86c70a50-75d7-45cd-9293-1b07a088bb47",
  },
  {
    name: "Keynote speakers",
    showBookable: true,
    subCategoryID: "ad37116b-0379-47b2-9478-cf19d654f012",
  },
  {
    name: "Getaways",
    showBookable: false,
    subCategoryID: "0cdfce03-d847-4473-b4b0-ef77bd38d569",
  },
  {
    name: "Team building",
    showBookable: true,
    subCategoryID: "23d58649-b2fb-4344-9c29-dbb71b0ede0d",
  },
  {
    name: "Professional development",
    showBookable: true,
    subCategoryID: "3a95bef1-ddc7-4d42-907a-bf850329682f",
  },
  {
    name: "Catered meals",
    showBookable: true,
    subCategoryID: "797194c6-0bf3-4950-b57c-92f027aede0d",
  },
  {
    name: "Outdoor activities",
    showBookable: true,
    subCategoryID: "74c8b88f-0e3f-4837-bf3a-f8e42eca2715",
  },
];

const eventTypeOptions = [{ name: "any", icon: CategoryOutlinedIcon }, ...eventTypes];

const mainCityIndex = 6; // the index after which the second list divider will be placed

export const defaultLocation = 0;
export const defaultGroupSize = 0;
export const defaultEventType = 0;
export const defaultEventDuration = 0;
export const defaultEventBudget = 0;

export function experienceLocationMatch(
  experienceLocation: locationType,
  eventLocationFilter: number
): boolean {
  if (cities[eventLocationFilter].name === "anywhere") {
    return true;
  } else if (cities[eventLocationFilter].lat === -1 || cities[eventLocationFilter].long === -1) {
    console.log("error in location match: -1 coords");
    return false;
  } else {
    return isPointWithinRadius(
      { latitude: experienceLocation.lat, longitude: experienceLocation.long },
      { latitude: cities[eventLocationFilter].lat, longitude: cities[eventLocationFilter].long },
      400000
    );
  }
}

export function experienceDurationMatch(
  experienceDuration: number,
  eventDurationFilter: number
): boolean {
  if (eventDurationFilter === defaultEventDuration) {
    return true;
  } else if (eventDurationFilter === eventDurationOptions.length - 1) {
    return experienceDuration >= eventDurationOptions[eventDurationFilter].days;
  } else {
    return experienceDuration === eventDurationOptions[eventDurationFilter].days;
  }
}

export function experienceBudgetMatch(
  eventBudgetFilter: number,
  experiencePricePerPerson?: number
): boolean {
  if (eventBudgetFilter.toString() === defaultEventBudget.toString() || !experiencePricePerPerson) {
    return true;
  } else {
    return (
      experiencePricePerPerson > budgetOptions[eventBudgetFilter].min &&
      experiencePricePerPerson < budgetOptions[eventBudgetFilter].max
    );
  }
}

export function experienceEventTypeMatch(
  experienceTypes: string[],
  eventTypeFilter: number
): boolean {
  if (eventTypeFilter === defaultEventType) {
    return true;
  } else {
    return experienceTypes.includes(eventTypeOptions[eventTypeFilter].name);
  }
}

export function groupSizeOverlap(groupSizeFilter: number, max: number, min?: number): boolean {
  if (!min) {
    min = 0;
  }

  if (groupSizeOptions[groupSizeFilter].min < min) {
    return groupSizeOptions[groupSizeFilter].max >= min;
  } else {
    return groupSizeOptions[groupSizeFilter].min <= max;
  }
}

export function getFilteredExperiences(
  eventLocationFilter: number,
  experienceDurationFilter: number,
  eventBudgetFilter: number,
  eventTypeFilter: number,
  groupSizeFilter: number
) {
  const updatedExperiences = [
    ...experiences.filter((fe) => {
      return (
        groupSizeOverlap(groupSizeFilter, fe.maxGroupSize, fe.minGroupSize) &&
        experienceLocationMatch(fe.location, eventLocationFilter) &&
        experienceDurationMatch(fe.experienceDuration, experienceDurationFilter) &&
        experienceBudgetMatch(eventBudgetFilter, fe.pricePerPerson) &&
        experienceEventTypeMatch(fe.experienceTypes, eventTypeFilter)
      );
    }),
  ];
  return updatedExperiences;
}

export async function getFilteredServices(
  services: Service[],
  eventLocationFilter: number,
  eventBudgetFilter: number,
  eventTypeFilter: number,
  groupSizeFilter: number
) {
  const updatedServices = [
    ...services.filter((fs) => {
      return groupSizeOverlap(groupSizeFilter, fs.serviceMaxOccupancy) /* &&
        experienceLocationMatch(fe.location, eventLocationFilter) &&
        experienceBudgetMatch(eventBudgetFilter, fs.) &&
        experienceEventTypeMatch(fe.experienceTypes, eventTypeFilter)*/;
    }),
  ];
  return updatedServices;
}
/*



*/

export async function getFilteredBookables(
  bookables: Bookable[],
  eventLocationFilter: number,
  eventBudgetFilter: number,
  eventDurationFilter: number,
  groupSizeFilter: number
) {
  const updatedBookables = [
    ...bookables.filter((bk) => {
      return groupSizeOverlap(groupSizeFilter, bk.bookableMaxOccupancy) /* &&
          experienceLocationMatch(fe.location, eventLocationFilter) &&
          experienceBudgetMatch(eventBudgetFilter, fs.) &&
          experienceEventTypeMatch(fe.experienceTypes, eventTypeFilter)*/;
    }),
  ];
  return updatedBookables;
}

export default function Filters(filters: {
  serviceExplorerLayout?: boolean;
  groupSizeFilter: number;
  setGroupSizeFilter: React.Dispatch<React.SetStateAction<number>>;
  eventLocationFilter: number;
  setEventLocationFilter: React.Dispatch<React.SetStateAction<number>>;
  eventTypeFilter?: number;
  setEventTypeFilter?: React.Dispatch<React.SetStateAction<number>>;
  eventDurationFilter?: number;
  setEventDurationFilter?: React.Dispatch<React.SetStateAction<number>>;
  eventBudgetFilter: number;
  setEventBudgetFilter: React.Dispatch<React.SetStateAction<number>>;
  categoryFilter?: number;
  setCategoryFilter?: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [filterOpen, setFilterOpen] = useState(""); // location, groupsize, eventtype, duration, budget, category
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    let mainCities = cities.slice(0, mainCityIndex + 1);
    let otherCities = cities.slice(mainCityIndex + 1).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    cities = mainCities.concat(otherCities);
  }, []);

  function handleFilterClose() {
    setAnchorEl(null);
    setFilterOpen("");
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>, filterMenu: string) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      setFilterOpen(filterMenu);
    } else {
      handleFilterClose();
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (filterOpen) {
      case "location":
        filters.setEventLocationFilter(Number((event.target as HTMLInputElement).value));
        break;
      case "groupsize":
        filters.setGroupSizeFilter(Number((event.target as HTMLInputElement).value));
        break;
      case "duration":
        filters.setEventDurationFilter &&
          filters.setEventDurationFilter(Number((event.target as HTMLInputElement).value));
        break;
      case "eventtype":
        filters.setEventTypeFilter &&
          filters.setEventTypeFilter(Number((event.target as HTMLInputElement).value));
        break;
      case "budget":
        filters.setEventBudgetFilter(Number((event.target as HTMLInputElement).value));
        break;
      case "category":
        filters.setCategoryFilter &&
          filters.setCategoryFilter(Number((event.target as HTMLInputElement).value));
        break;
      default:
        console.log("unknown filterOpen value in handeFilterChange()");
        return;
    }
    handleFilterClose();
  };

  const filtersActive = () => {
    return (
      filters.eventLocationFilter !== defaultLocation ||
      filters.groupSizeFilter !== defaultGroupSize ||
      filters.eventBudgetFilter !== defaultEventBudget ||
      filters.eventDurationFilter !== defaultEventDuration ||
      filters.eventTypeFilter !== defaultEventType ||
      filters.categoryFilter !== defaultEventType
    );
  };

  function clearFilters() {
    filters.setEventLocationFilter(defaultLocation);
    filters.setGroupSizeFilter(defaultGroupSize);
    filters.setEventTypeFilter && filters.setEventTypeFilter(defaultEventType);
    filters.setEventDurationFilter && filters.setEventDurationFilter(defaultEventDuration);
    filters.setEventBudgetFilter(defaultEventBudget);
    filters.setCategoryFilter && filters.setCategoryFilter(defaultEventType);
  }

  return (
    <>
      {filters.serviceExplorerLayout && (
        <>
          <Grid
            container
            direction="row"
            m="0 auto 0 auto"
            width="inherit"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <h5 style={{ margin: "2rem 1rem" }}>I'm looking for</h5>
            {filters.categoryFilter !== undefined && (
              <Grid container item xs={6} sm={4} md={1.5} p={1} justifyContent={"center"}>
                <button
                  onClick={(e) => {
                    handleFilterClick(e, "category");
                  }}
                  className="button-small basic-button"
                  style={{
                    overflow: "hidden",
                    border: "2px solid grey",
                  }}
                >
                  <span
                    className={
                      filters.categoryFilter === defaultEventType
                        ? "small-button-text"
                        : "small-button-text"
                    }
                    style={{ color: "dimgray" }}
                  >
                    {serviceExplorerCategories[filters.categoryFilter].name}
                  </span>
                </button>
              </Grid>
            )}
            <h5 style={{ margin: "2rem 1rem" }}>in</h5>
            <Grid container item xs={6} sm={4} md={1.5} p={1} justifyContent={"center"}>
              <button
                onClick={(e) => {
                  handleFilterClick(e, "location");
                }}
                className="button-small basic-button"
                style={{
                  overflow: "hidden",
                  border: "2px solid grey",
                }}
              >
                <span
                  className={
                    filters.eventLocationFilter === defaultLocation
                      ? "small-button-text"
                      : "small-button-text"
                  }
                  style={{ color: "dimgray" }}
                >
                  {cities[filters.eventLocationFilter].name}
                </span>
              </button>
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        container
        direction="row"
        m="0 auto 0 auto"
        width="inherit"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid container item xs={12} md={1.5} lg={1.1} ml="1rem" justifyContent={"left"}>
          <h5 style={{ margin: 0, color: "gray" }}>Filter by:</h5>
        </Grid>

        {!filters.serviceExplorerLayout && (
          <Grid container item xs={6} sm={4} md={1.5} p={1} justifyContent={"center"}>
            <button
              onClick={(e) => {
                handleFilterClick(e, "location");
              }}
              className="button-small basic-button"
              style={{
                overflow: "hidden",
                backgroundColor:
                  filters.eventLocationFilter === defaultLocation
                    ? ""
                    : "var(--lime-green-transparent)",
              }}
            >
              <span
                className={
                  filters.eventLocationFilter === defaultLocation
                    ? "small-button-text"
                    : "small-button-text"
                }
                style={{ color: "dimgray" }}
              >
                {filters.eventLocationFilter === defaultLocation
                  ? "Departing from"
                  : cities[filters.eventLocationFilter].name}
              </span>
            </button>
          </Grid>
        )}

        <Grid container item xs={6} sm={4} md={1.5} p={1} justifyContent={"center"}>
          <button
            onClick={(e) => {
              handleFilterClick(e, "groupsize");
            }}
            className="button-small basic-button"
            style={{
              overflow: "hidden",
              backgroundColor:
                filters.groupSizeFilter === defaultGroupSize ? "" : "var(--lime-green-transparent)",
            }}
          >
            <span
              className={
                filters.groupSizeFilter === defaultGroupSize
                  ? "small-button-text"
                  : "small-button-text"
              }
              style={{ color: "dimgray" }}
            >
              {filters.groupSizeFilter === defaultGroupSize
                ? "Group size"
                : groupSizeOptions[filters.groupSizeFilter].name + " people"}
            </span>
          </button>
        </Grid>
        {filters.eventTypeFilter !== undefined && (
          <Grid
            container
            item
            xs={6}
            sm={4}
            md={1.5}
            p={1}
            justifyContent={"center"}
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              onClick={(e) => {
                handleFilterClick(e, "eventtype");
              }}
              className="button-small basic-button"
              style={{
                overflow: "hidden",
                backgroundColor:
                  filters.eventTypeFilter === defaultEventType
                    ? ""
                    : "var(--lime-green-transparent)",
              }}
            >
              <span
                className={
                  filters.eventTypeFilter === defaultEventType
                    ? "small-button-text"
                    : "small-button-text"
                }
                style={{
                  color: "dimgray",
                  overflow: "hidden",
                  fontSize: eventTypeOptions[filters.eventTypeFilter].name.length > 18 ? 13 : 14,
                }}
              >
                {filters.eventTypeFilter === defaultEventType
                  ? "Best for"
                  : eventTypeOptions[filters.eventTypeFilter].name}
              </span>
            </button>
          </Grid>
        )}

        {filters.eventDurationFilter !== undefined && (
          <Grid container item xs={6} sm={4} md={1.5} p={1} justifyContent={"center"}>
            <button
              onClick={(e) => {
                handleFilterClick(e, "duration");
              }}
              className="button-small basic-button"
              style={{
                overflow: "hidden",
                backgroundColor:
                  filters.eventDurationFilter === defaultEventDuration
                    ? ""
                    : "var(--lime-green-transparent)",
              }}
            >
              <span
                className={
                  filters.eventDurationFilter === defaultEventDuration
                    ? "small-button-text"
                    : "small-button-text"
                }
                style={{ color: "dimgray" }}
              >
                {filters.eventDurationFilter === defaultEventDuration
                  ? "Duration"
                  : eventDurationOptions[filters.eventDurationFilter].name}
              </span>
            </button>
          </Grid>
        )}

        <Grid container item xs={6} sm={4} md={1.5} p={1} justifyContent={"center"}>
          <button
            onClick={(e) => {
              handleFilterClick(e, "budget");
            }}
            className="button-small basic-button"
            style={{
              overflow: "hidden",
              backgroundColor:
                filters.eventBudgetFilter === defaultEventBudget
                  ? ""
                  : "var(--lime-green-transparent)",
            }}
          >
            <span
              className={
                filters.eventBudgetFilter === defaultEventBudget
                  ? "small-button-text"
                  : "small-button-text"
              }
              style={{ color: "dimgray" }}
            >
              {filters.eventBudgetFilter === defaultEventBudget
                ? "Budget"
                : budgetOptions[filters.eventBudgetFilter].name}
            </span>
          </button>
        </Grid>

        <Grid container item xs={6} sm={4} md={1.5} p={1} justifyContent={"center"}>
          <button
            onClick={clearFilters}
            className="button-small button-transparent"
            style={{ borderColor: filtersActive() ? "rgba(255,0,0,.5)" : "lightgrey" }}
          >
            <span
              className="button-text"
              style={{ color: filtersActive() ? "grey" : "var(--smoke-grey)" }}
            >
              Clear filters
            </span>
          </button>
        </Grid>
      </Grid>

      <Popover
        id={"filter-popper"}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={filterOpen !== ""}
        onClose={handleFilterClose}
        anchorEl={anchorEl}
      >
        <div
          className="landing-div"
          style={{
            width: "300px",
            minHeight: "200px",
            maxHeight: "500px",
            overflow: "scroll",
          }}
        >
          {filterOpen === "location" && (
            <RadioGroup
              aria-labelledby="radio-buttons-group-location"
              value={filters.eventLocationFilter}
              name="radio-buttons-group"
              onChange={handleFilterChange}
            >
              {cities.map((city, i) => {
                return (
                  <div key={"location-div" + city.name}>
                    <FormControlLabel
                      value={i}
                      control={<Radio />}
                      label={city.name}
                      key={"radio-option" + city.name}
                      style={{ width: "90%", margin: "0 auto 0 auto" }}
                    />
                    {(i === defaultLocation || i === mainCityIndex) && (
                      <Divider
                        sx={{ width: "80%", pb: "2px", margin: ".5rem auto .5rem auto" }}
                        key={"location-divider" + city.name}
                      />
                    )}
                  </div>
                );
              })}
            </RadioGroup>
          )}

          {filterOpen === "groupsize" && (
            <RadioGroup
              aria-labelledby="radio-buttons-group-groupsize"
              value={filters.groupSizeFilter}
              name="radio-buttons-group"
              onChange={handleFilterChange}
            >
              {groupSizeOptions.map((gso, i) => {
                return (
                  <FormControlLabel
                    value={i}
                    control={<Radio />}
                    label={gso.name}
                    key={"radio-option" + gso.name}
                    style={{ width: "80%", margin: "0 auto 0 auto" }}
                  />
                );
              })}
            </RadioGroup>
          )}

          {filterOpen === "eventtype" && (
            <RadioGroup
              aria-labelledby="radio-buttons-group-eventtype"
              value={filters.eventTypeFilter}
              name="radio-buttons-group"
              onChange={handleFilterChange}
            >
              {eventTypeOptions.map((et, i) => {
                return (
                  <FormControlLabel
                    value={i}
                    control={<Radio />}
                    label={et.name}
                    key={"radio-option" + et.name}
                    style={{ width: "80%", margin: "0 auto 0 auto" }}
                  />
                );
              })}
            </RadioGroup>
          )}

          {filterOpen === "duration" && (
            <RadioGroup
              aria-labelledby="radio-buttons-group-eventdurationtype"
              value={filters.eventDurationFilter}
              name="radio-buttons-group"
              onChange={handleFilterChange}
            >
              {eventDurationOptions.map((edo, i) => {
                return (
                  <FormControlLabel
                    value={i}
                    control={<Radio />}
                    label={edo.name}
                    key={"radio-option" + edo.name}
                    style={{ width: "80%", margin: "0 auto 0 auto" }}
                  />
                );
              })}
            </RadioGroup>
          )}

          {filterOpen === "budget" && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="desktop-body" style={{ margin: ".5rem auto 0 auto" }}>
                per person
              </span>
              <RadioGroup
                aria-labelledby="radio-buttons-group-budget"
                value={filters.eventBudgetFilter}
                name="radio-buttons-group"
                onChange={handleFilterChange}
              >
                {budgetOptions.map((bo, i) => {
                  return (
                    <FormControlLabel
                      value={i}
                      control={<Radio />}
                      label={bo.name}
                      key={"radio-option" + bo.name}
                      style={{ width: "80%", margin: "0 auto 0 auto" }}
                    />
                  );
                })}
              </RadioGroup>
            </div>
          )}

          {filterOpen === "category" && (
            <RadioGroup
              aria-labelledby="radio-buttons-group-category"
              value={filters.categoryFilter}
              name="radio-buttons-group"
              onChange={handleFilterChange}
            >
              {serviceExplorerCategories.map((sec, i) => {
                return (
                  <div key={"location-div" + sec.name}>
                    <FormControlLabel
                      value={i}
                      control={<Radio />}
                      label={sec.name}
                      key={"radio-option" + sec.name}
                      style={{ width: "90%", margin: "0 auto 0 auto" }}
                    />
                  </div>
                );
              })}
            </RadioGroup>
          )}
        </div>
      </Popover>
    </>
  );
}
