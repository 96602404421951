import { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import {
  Checkbox,
  Divider,
  Grid,
  Input,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { useGlobalContext } from "../../App";

export const CustomSliderStyles = {
  "& .MuiSlider-thumb": {
    color: "#749761",
    height: 24,
    width: 24,
  },
  "& .MuiSlider-track": {
    color: "#749761",
    height: 12,
    width: 12,
  },

  "& .MuiSlider-active": {
    color: "#f5e278",
    height: 14,
    width: 14,
  },
};

interface addonType {
  id: string;
  friendly: string;
  type: string;
  costPP: number;
}

const addons = [
  {
    id: "teamBuilding",
    friendly: "Team building",
    type: "activity",
    costPP: 30,
  },
  {
    id: "offsiteFaciliator",
    friendly: "Offsite faciliator",
    type: "activity",
    costPP: 50,
  },
  {
    id: "guestSpeakers",
    friendly: "Guest speakers",
    type: "activity",
    costPP: 50,
  },
  {
    id: "workshop",
    friendly: "Workshop",
    type: "activity",
    costPP: 100,
  },
  {
    id: "training",
    friendly: "Training",
    type: "activity",
    costPP: 75,
  },
  {
    id: "breakfasts",
    friendly: "Breakfasts",
    type: "food",
    costPP: 40,
  },
  {
    id: "lunches",
    friendly: "Lunches",
    type: "food",
    costPP: 40,
  },
  {
    id: "dinners",
    friendly: "Dinners",
    type: "food",
    costPP: 100,
  },
  {
    id: "alcohol",
    friendly: "Alcohol",
    type: "food",
    costPP: 40,
  },
  {
    id: "snackBreaks",
    friendly: "Snack & coffee breaks",
    type: "food",
    costPP: 25,
  },
  {
    id: "meetingSpace",
    friendly: "Meeting space",
    type: "extra",
    costPP: 50,
  },
  {
    id: "swag",
    friendly: "Swag",
    type: "extra",
    costPP: 25,
  },
  {
    id: "transportation",
    friendly: "Transportation",
    type: "extra",
    costPP: 75,
  },
  {
    id: "photographer",
    friendly: "Photographer / videographer",
    type: "extra",
    costPP: 20,
  },
];

const eventLengthModifiers = [
  { name: "A few hours", modifier: 0.25 },
  { name: "Full day", modifier: 0.5 },
  { name: "2 days", modifier: 2 },
  { name: "3 days", modifier: 3 },
  { name: "4 days", modifier: 4 },
  { name: "5 days", modifier: 5 },
];
const eventBudgetModifiers = [
  { name: "Lower", modifier: 0.85 },
  { name: "Mid-range", modifier: 1 },
  { name: "Higher", modifier: 1.15 },
];

const eventTypeModifiers = [
  { name: "Mostly work", modifier: 0.95 },
  { name: "Mostly team building", modifier: 0.1 },
  { name: "Mostly fun", modifier: 1.05 },
  { name: "Mix of everything!", modifier: 1.1 },
];

const locationModifiers = [
  { name: "Sun destinations", modifier: 450 },
  { name: "Rustic", modifier: 250 },
  { name: "Creme of the crop", modifier: 500 },
  { name: "Urban", modifier: 400 },
  { name: "Close to headquarters", modifier: 300 },
];

export default function CostEstimator() {
  const [expanded, setExpanded] = useState<string | false>("eventParams");

  const [totalCost, setTotalCost] = useState(0);
  const [eventCost, setEventCost] = useState(0);
  const [activityCost, setActivityCost] = useState(0);
  const [foodCost, setFoodCost] = useState(0);
  const [extrasCost, setExtrasCost] = useState(0);

  const [eventParams, setEventParams] = useState({
    numAttendees: 0,
    eventLength: 0.5,
    eventType: 1,
    budget: 1,
    location: 400,
    numFlights: 0,
    flightLength: 700,
  });
  const [selectedAddons, setSelectedAddons] = useState<addonType[]>([]);
  const [eventLengthSliderPosition, setEventLengthSliderPosition] = useState(1);
  const [eventBudgetSliderPosition, setEventBudgetSliderPosition] = useState(1);

  const [eventTypeSelector, setEventTypeSelector] = useState("Mostly team building");
  const [locationSelector, setLocationSelector] = useState("Urban");

  const isMobile = useGlobalContext().isMobile;

  useEffect(() => {
    function calculateCosts() {
      if (eventParams.numAttendees !== 0) {
        let newEventCost = 0;
        let newActivityCost = 0;
        let newFoodCost = 0;
        let newExtrasCost = 0;

        let baseCost =
          eventParams.location *
          eventParams.eventType *
          eventParams.budget *
          eventParams.eventLength;
        newEventCost = eventParams.numAttendees * baseCost;
        newEventCost += eventParams.numFlights * eventParams.flightLength;

        selectedAddons.forEach((sao) => {
          switch (sao.type) {
            case "activity":
              newActivityCost += eventParams.numAttendees * sao.costPP;
              break;
            case "food":
              newFoodCost += eventParams.numAttendees * sao.costPP;
              break;
            case "extra":
              newExtrasCost += eventParams.numAttendees * sao.costPP;
              break;
            default:
              console.log("invalid addon type in calculateCosts");
              break;
          }
        });

        setEventCost(newEventCost);
        setActivityCost(newActivityCost);
        setFoodCost(newFoodCost);
        setExtrasCost(newExtrasCost);
        setTotalCost(newEventCost + newActivityCost + newFoodCost + newExtrasCost);
      }
    }

    calculateCosts();
  }, [eventParams, selectedAddons]);

  const handleAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  function handleCheckbox(event: React.ChangeEvent<HTMLInputElement>, addon: addonType) {
    if (event.target.checked) {
      const updatedAddons = [...selectedAddons, { ...addon }];
      setSelectedAddons(updatedAddons);
    } else if (!event.target.checked) {
      const updatedAddons = [
        ...selectedAddons.filter((sao) => {
          return sao.id !== addon.id;
        }),
      ];
      setSelectedAddons(updatedAddons);
    } else {
      console.log("invalid checkbox state in handleCheckbox");
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let updatedEventParams = { ...eventParams };

    updatedEventParams.numAttendees = Number(value);

    setEventParams(updatedEventParams);
  };

  const handleSlider = (e: Event) => {
    if (e.target) {
      let updatedEventParams = { ...eventParams };

      if ((e.target as HTMLInputElement).name === "eventLengthSlider") {
        setEventLengthSliderPosition(Number((e.target as HTMLInputElement).value));
        updatedEventParams.eventLength =
          eventLengthModifiers[Number((e.target as HTMLInputElement).value)].modifier;
        setEventParams(updatedEventParams);
      } else if ((e.target as HTMLInputElement).name === "eventBudgetSlider") {
        setEventBudgetSliderPosition(Number((e.target as HTMLInputElement).value));
        updatedEventParams.budget =
          eventBudgetModifiers[Number((e.target as HTMLInputElement).value)].modifier;
        setEventParams(updatedEventParams);
      } else {
        console.log("invalid slider target in handleSlider");
      }
    }
  };

  function handleOptionSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    let updatedEventParams = { ...eventParams };

    if (e.target.id === "select-eventType") {
      updatedEventParams.eventType =
        eventTypeModifiers[
          eventTypeModifiers.findIndex(function (et) {
            return et.name === e.target.value;
          })
        ].modifier;

      setEventTypeSelector(e.target.value);
      setEventParams(updatedEventParams);
    } else if (e.target.id === "select-location") {
      updatedEventParams.location =
        locationModifiers[
          locationModifiers.findIndex(function (lm) {
            return lm.name === e.target.value;
          })
        ].modifier;
      setLocationSelector(e.target.value);
      setEventParams(updatedEventParams);
    } else {
      console.log("invalid id in handleOptionSelect");
    }
  }

  return (
    <>
      <Navbar section />
      <section className="landing-section">
        <div
          className="landing-div"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <div
            style={{
              width: isMobile ? "100%" : "80%",
              margin: "0 auto 0 auto",
            }}
          >
            {eventParams.numAttendees === 0 && (
              <h5 style={{ color: "var(--forest-green)" }}>
                Enter the number of attendees and other event details to get an estimated cost
              </h5>
            )}
            {eventParams.numAttendees !== 0 && (
              <>
                <Grid container direction="row" alignItems={"center"}>
                  <Grid item xs={12} lg={6}>
                    <h4 style={{ color: "var(--forest-green)", margin: "1rem" }}>
                      Total cost:{" "}
                      <span style={{ color: "var(--palm-green)", margin: 0 }}>
                        ${totalCost.toLocaleString()}
                      </span>
                    </h4>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <h4 style={{ color: "var(--forest-green)", margin: "1rem" }}>
                      Per person:{" "}
                      <span style={{ color: "var(--palm-green)", margin: 0 }}>
                        ${(totalCost / eventParams.numAttendees).toLocaleString()}
                      </span>
                    </h4>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems={"center"}
                  width="80%"
                  mx="auto"
                  mb="2rem"
                >
                  <Grid item xs={6} lg={3}>
                    <h5 style={{ color: "var(--forest-green)", margin: "1rem" }}>Base cost: </h5>
                    <span className="h6b" style={{ color: "var(--palm-green)" }}>
                      ${eventCost.toLocaleString()}
                    </span>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <h5 style={{ color: "var(--forest-green)", margin: "1rem" }}>Activities: </h5>
                    <span className="h6b" style={{ color: "var(--palm-green)" }}>
                      ${activityCost.toLocaleString()}
                    </span>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <h5 style={{ color: "var(--forest-green)", margin: "1rem" }}>Food: </h5>
                    <span className="h6b" style={{ color: "var(--palm-green)" }}>
                      ${foodCost.toLocaleString()}
                    </span>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <h5 style={{ color: "var(--forest-green)", margin: "1rem" }}>Add-ons: </h5>
                    <span className="h6b" style={{ color: "var(--palm-green)" }}>
                      ${extrasCost.toLocaleString()}
                    </span>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </div>
      </section>
      <section className="landing-section">
        <div
          className="landing-div"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "auto",
              marginRight: "auto",
              width: isMobile ? "100%" : "80%",
            }}
          >
            <Accordion
              expanded={expanded === "eventParams"}
              onChange={handleAccordion("eventParams")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel-content"}
                id={"panel-header"}
              >
                <span className="h6b" style={{ color: "var(--forest-green)" }}>
                  Event Parameters
                </span>
              </AccordionSummary>
              <AccordionDetails style={{ textAlign: "left" }}>
                <Grid container mt="1rem">
                  <Grid item xs={5} lg={3}>
                    <span className="desktop-body" style={{ color: "var(--forest-green)" }}>
                      How many attendees?
                    </span>
                  </Grid>
                  <Grid item xs={7} lg={9}>
                    <Input
                      type="text"
                      name="numAttendees"
                      placeholder="Number of attendees"
                      value={eventParams.numAttendees}
                      onChange={handleInput}
                    />
                  </Grid>
                </Grid>
                <Divider style={{ width: "60%", margin: "1.5rem auto 1.5rem auto" }} />
                <Grid container direction="row" mt="1rem">
                  <Grid item xs={3}>
                    <span className="desktop-body" style={{ color: "var(--forest-green)" }}>
                      Event duration
                    </span>
                  </Grid>
                  <Grid item xs={5} lg={4}>
                    <Slider
                      aria-label="Duration slider"
                      name="eventLengthSlider"
                      marks
                      min={0}
                      max={5}
                      step={1}
                      value={eventLengthSliderPosition}
                      onChange={(e) => handleSlider(e)}
                      sx={CustomSliderStyles}
                    />
                  </Grid>
                  <Grid item xs={3} ml="1rem">
                    <span
                      className="desktop-body"
                      style={{ color: "var(--forest-green)", fontSize: 17 }}
                    >
                      {eventLengthModifiers[eventLengthSliderPosition].name}
                    </span>
                  </Grid>
                </Grid>
                <Grid container direction="row" mt="1rem">
                  <Grid item xs={3}>
                    <span className="desktop-body" style={{ color: "var(--forest-green)" }}>
                      Overall budget
                    </span>
                  </Grid>
                  <Grid item xs={5} lg={4}>
                    <Slider
                      aria-label="Budget slider"
                      name="eventBudgetSlider"
                      marks
                      min={0}
                      max={2}
                      step={1}
                      value={eventBudgetSliderPosition}
                      onChange={(e) => handleSlider(e)}
                      sx={CustomSliderStyles}
                    />
                  </Grid>
                  <Grid item xs={3} ml="1rem">
                    <span
                      className="desktop-body"
                      style={{ color: "var(--forest-green)", fontSize: 17 }}
                    >
                      {eventBudgetModifiers[eventBudgetSliderPosition].name}
                    </span>
                  </Grid>
                </Grid>
                <Divider style={{ width: "60%", margin: "1.5rem auto 1.5rem auto" }} />
                <Grid container>
                  <Grid item xs={4} lg={2} mb={"1.5rem"}>
                    <span className="desktop-body" style={{ color: "var(--forest-green)" }}>
                      Type of event
                    </span>
                  </Grid>
                  <Grid item xs={7} lg={10} mb={"1.5rem"} justifyContent={"left"}>
                    <select
                      id="select-eventType"
                      className="select-round"
                      onChange={(e) => handleOptionSelect(e)}
                      defaultValue={eventTypeSelector}
                      style={{ backgroundColor: "transparent" }}
                    >
                      {eventTypeModifiers.map((etm) => {
                        return (
                          <option value={etm.name} key={etm.name}>
                            {etm.name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item xs={4} lg={2} mb={"1.5rem"}>
                    <span className="desktop-body" style={{ color: "var(--forest-green)" }}>
                      Preferred location
                    </span>
                  </Grid>
                  <Grid item xs={7} lg={10} mb={"1.5rem"}>
                    <select
                      id="select-location"
                      className="select-round"
                      onChange={(e) => handleOptionSelect(e)}
                      defaultValue={locationSelector}
                      style={{ backgroundColor: "transparent" }}
                    >
                      {locationModifiers.map((loc) => {
                        return (
                          <option value={loc.name} key={loc.name}>
                            {loc.name}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "activities"}
              onChange={handleAccordion("activities")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel-content"}
                id={"panel-header"}
              >
                <span className="h6b" style={{ color: "var(--forest-green)" }}>
                  Activities
                </span>
              </AccordionSummary>
              <AccordionDetails style={{ textAlign: "left" }}>
                <Table style={{ width: "50%" }}>
                  <TableBody>
                    {addons
                      .filter((fao) => {
                        return fao.type === "activity";
                      })
                      .map((ao) => {
                        return (
                          <TableRow
                            key={"tableRow" + ao.id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell key={"tableCellName" + ao.id} component="th" scope="row">
                              <span
                                key={"span" + ao.id}
                                className="desktop-body"
                                style={{ color: "var(--forest-green)" }}
                              >
                                {ao.friendly}
                              </span>
                            </TableCell>
                            <TableCell key={"tableCellCheckbox" + ao.id}>
                              <Checkbox
                                key={"checkbox" + ao.id}
                                onChange={(e) => handleCheckbox(e, ao)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "food"} onChange={handleAccordion("food")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel-content"}
                id={"panel-header"}
              >
                <span className="h6b" style={{ color: "var(--forest-green)" }}>
                  Food & Drink
                </span>
              </AccordionSummary>
              <AccordionDetails style={{ textAlign: "left" }}>
                <Table style={{ width: "50%" }}>
                  <TableBody>
                    {addons
                      .filter((fao) => {
                        return fao.type === "food";
                      })
                      .map((ao) => {
                        return (
                          <TableRow
                            key={"tableRow" + ao.id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell key={"tableCellName" + ao.id} component="th" scope="row">
                              <span
                                key={"span" + ao.id}
                                className="desktop-body"
                                style={{ color: "var(--forest-green)" }}
                              >
                                {ao.friendly}
                              </span>
                            </TableCell>
                            <TableCell key={"tableCellCheckbox" + ao.id}>
                              <Checkbox
                                key={"checkbox" + ao.id}
                                onChange={(e) => handleCheckbox(e, ao)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "addons"} onChange={handleAccordion("addons")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel-content"}
                id={"panel-header"}
              >
                <span className="h6b" style={{ color: "var(--forest-green)" }}>
                  Add-ons
                </span>
              </AccordionSummary>
              <AccordionDetails style={{ textAlign: "left" }}>
                <Table style={{ width: "50%" }}>
                  <TableBody>
                    {addons
                      .filter((fao) => {
                        return fao.type === "extra";
                      })
                      .map((ao) => {
                        return (
                          <TableRow
                            key={"tableRow" + ao.id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell key={"tableCellName" + ao.id} component="th" scope="row">
                              <span
                                key={"span" + ao.id}
                                className="desktop-body"
                                style={{ color: "var(--forest-green)" }}
                              >
                                {ao.friendly}
                              </span>
                            </TableCell>
                            <TableCell key={"tableCellCheckbox" + ao.id}>
                              <Checkbox
                                key={"checkbox" + ao.id}
                                onChange={(e) => handleCheckbox(e, ao)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}
