import { ChangeEvent, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { Controls, Player } from "@lottiefiles/react-lottie-player";

import { Box, Button, Input, Typography } from "@mui/material";

// @ts-ignore

import { useGlobalContext } from "../../../App";
import { experienceDetailsType } from "../../../experienceIndex";
import { format } from "date-fns";

import mixpanel from "mixpanel-browser";

type Inputs = {
  eventExperience: string;
  eventDate: Date;
  eventNumAttendees: string;
  eventContactName: string;
  eventContactEmail: string;
  eventContactPhone: string;
};

export default function ExperienceRequestQuote(props: {
  expDetails: experienceDetailsType | undefined;
  selectedOption: string;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [confirmBooking, setConfirmBooking] = useState("unconfirmed"); // unconfirmed, inProgress, success, error
  //const [favorite, setFavorite] = useState(gsprops.pkg != undefined ? isPackageFavorite(gsprops.pkg.packageID) : gsprops.svc != undefined ? isServiceFavorite(gsprops.svc.serviceID) : false);
  const [dateValue, setDateValue] = useState<Date | null>(new Date());

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<Inputs>();

  let isMobile = useGlobalContext().isMobile;

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    sessionStorage.setItem(name, value);
  };
  const submitQuoteRequest: SubmitHandler<Inputs> = async (data: any) => {
    mixpanel.track("Quote requested", {
      ExperienceID: props.expDetails?.id,
      Env: process.env.REACT_APP_ENV,
    });

    const contactInfo = {
      eventExperience: props.expDetails?.id,
      eventOptionSelection: props.selectedOption,
      eventDate: dateValue,
      eventNumAttendees: data.eventNumAttendees,
      eventContactName: data.eventContactName,
      eventContactEmail: data.eventContactEmail,
      eventContactPhone: data.eventContactPhone,
    };

    console.log(contactInfo);

    const encode = (dataToEncode: any) => {
      return Object.keys(dataToEncode)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(dataToEncode[key]))
        .join("&");
    };

    fetch("/pages/eventSuccess", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "submitQuoteRequest", ...contactInfo }),
      redirect: "follow",
    })
      .then(() => setConfirmBooking("success"))
      .catch((error) => {
        setConfirmBooking("error");
        console.log(error);
      });
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: isMobile ? "100%" : "30vw",
          marginBottom: isMobile ? "3rem" : "",
          padding: 4,
          boxShadow: 6,
          bgcolor: "grey.100",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h5" mb={2}>
            Make an inquiry
          </Typography>

          <button
            onClick={(e) => props.setExpand(false)}
            className="button-small button-transparent"
            style={{ border: "none", width: "50px", marginLeft: "1rem" }}
          >
            <HighlightOffIcon fontSize="large" style={{ color: "var(--forest-green)" }} />
          </button>
        </div>
        <Grid item xs={12} mb={2}>
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Event date
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={dateValue}
                onChange={(newValue) => {
                  setDateValue(newValue);
                }}
                key="date-picker"
                slotProps={{ textField: { variant: "standard" } }}
                sx={{ marginTop: ".5rem" }}
              />
            </LocalizationProvider>
            {/* 
            <Input
              type="date"
              placeholder="Date"
              {...register("eventDate", { required: true })}
              onChange={handleInput}
              error={errors.eventDate ? true : false}
              defaultValue={sessionStorage.getItem("eventDate")}
              required
              fullWidth
            />*/}
          </Box>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Number of attendees
            </Typography>

            <Input
              type="number"
              placeholder="How many people will join?"
              {...register("eventNumAttendees", { required: true })}
              onChange={handleInput}
              error={errors.eventNumAttendees ? true : false}
              defaultValue={sessionStorage.getItem("eventNumAttendees")}
              required
              fullWidth
            />
          </Box>
        </Grid>

        <Box style={{ marginTop: "1rem" }}>
          <Box>
            <Typography variant="body2" fontWeight="bold">
              Contact info
            </Typography>
            <Input
              type="text"
              placeholder="Contact name"
              {...register("eventContactName", { required: true })}
              error={errors.eventContactName ? true : false}
              defaultValue={sessionStorage.getItem("eventContactName")}
              fullWidth
            />

            <Input
              type="text"
              placeholder="Contact phone"
              {...register("eventContactPhone", { required: false })}
              error={errors.eventContactPhone ? true : false}
              defaultValue={sessionStorage.getItem("eventContactPhone")}
              style={{ marginTop: "1rem" }}
              fullWidth
            />

            <Input
              type="text"
              placeholder="Contact email"
              {...register("eventContactEmail", { required: true })}
              error={errors.eventContactEmail ? true : false}
              defaultValue={sessionStorage.getItem("eventContactEmail")}
              style={{ marginTop: "1rem" }}
              required
              fullWidth
            />

            {confirmBooking === "unconfirmed" && (
              <Button
                onClick={handleSubmit(submitQuoteRequest)}
                variant="contained"
                color={"info"}
                sx={{ mt: 2, minWidth: "100%" }}
              >
                Confirm request
              </Button>
            )}
            {confirmBooking === "inProgress" && (
              <Button
                variant={"contained"}
                color={"info"}
                size="large"
                sx={{ mt: 2, minWidth: "100%" }}
              >
                <Player
                  autoplay
                  loop
                  src={require("../../../img/anim/1708183249198.json")}
                  style={{ height: "30px", width: "30px", marginRight: 5 }}
                >
                  <Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} />
                </Player>
                Contacting vendor...
              </Button>
            )}
            {confirmBooking === "success" && (
              <Box>
                <Button
                  variant={"contained"}
                  color={"success"}
                  size="large"
                  sx={{ mt: 2, minWidth: "100%" }}
                >
                  <CheckIcon sx={{ mr: 1 }} />
                  Request sent
                </Button>
                <Typography align="center" variant="body2" mb={1}>
                  We&apos;re contacting the vendor to confirm availability. You&apos;ll receive a
                  response by email.
                </Typography>
              </Box>
            )}
            {confirmBooking === "error" && (
              <Box>
                <Button
                  variant={"contained"}
                  color={"error"}
                  size="large"
                  sx={{ mt: 2, minWidth: "100%" }}
                >
                  <CloseIcon sx={{ mr: 1 }} />
                  Booking request failed
                </Button>
                <Typography align="center" variant="body2" mb={1}>
                  We weren&apos;t able to confirm availability with the vendor. Please email us at
                  info@townish.ca to proceed.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {/*
        <Grid container item justifyContent="center" xs={12} mb={2}>
          <Button variant={"outlined"} color={"info"} size="small" sx={{ mt: 2, minWidth: "100%" }}>
            <Favorite color="error" sx={{ mr: 1 }} />
            {"Add to Favorites"}
          </Button>
        </Grid>
        */}

        <Grid container item xs={12} mt={1}>
          <Typography align="center" variant="body2" fontSize={12} mb={1}>
            Prices shown are estimates. Pricing will be finalized once availability and
            customizations are confirmed by our specialists, and you&apos;ll have a chance to review
            before the event is booked.
          </Typography>
        </Grid>
      </Card>
    </>
  );
}
