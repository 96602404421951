import Carousel, { ButtonGroupProps } from "react-multi-carousel";
import ExperienceCard from "../../components/experienceCard";
import { experiences } from "../../experienceIndex";

import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { useGlobalContext } from "../../App";
import "../../pages/experienceDetail/experienceMediaCarousel/experienceImageCarouselStyles.css";
import { breakpoints } from "../../theme/breakpoints";

export const responsiveCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 4,
  },
  medium: {
    breakpoint: { max: 1499, min: 800 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 799, min: 1 },
    items: 1,
  },
};

export const CustomButtonGroupAsArrows = ({ next, previous }: ButtonGroupProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "end",
      }}
    >
      <h4
        style={{
          padding: "2rem 0 0 .5rem",
          color: "dimgray",
          margin: "0",
        }}
      >
        Picture your team here
      </h4>

      {window.innerWidth > breakpoints.values.md && (
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
          }}
        >
          <button
            onClick={previous}
            className="button-grey"
            style={{
              marginLeft: "auto",
              marginRight: ".5rem",
              width: "3.5rem",
              marginTop: ".5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavigateBeforeOutlinedIcon style={{ fontSize: "36px" }} />
          </button>

          <button
            onClick={next}
            className="button-grey"
            style={{
              marginRight: "1rem",
              width: "3.5rem",
              marginTop: ".5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavigateNextOutlinedIcon style={{ fontSize: "36px" }} />
          </button>
        </div>
      )}
    </div>
  );
};

export default function FeaturedExperiences() {
  const contactQuizActive = useGlobalContext().contactQuizActive;

  return (
    <div
      style={{
        padding: "0 3% 0 3%",

        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <Carousel
        customButtonGroup={<CustomButtonGroupAsArrows />}
        renderButtonGroupOutside={true}
        arrows={useGlobalContext().isMobile ? true : false}
        responsive={responsiveCarousel}
        autoPlay={!contactQuizActive}
        autoPlaySpeed={6000}
        swipeable={false}
        draggable={false}
        showDots={false}
        infinite={true}
        partialVisible={true}
        dotListClass="custom-dot-list-style"
      >
        {experiences.map((exp, i) => {
          return (
            <div className="slider" key={i} style={{ margin: "0 .5rem" }}>
              <ExperienceCard experience={exp} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
