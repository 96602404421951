import { Grid } from "@mui/material";
import { useState } from "react";
import { useGlobalContext } from "../../../App";

interface experienceOptionType {
  name: string;
  description: string;
}

export default function ExperienceOptions(props: {
  expOptions: experienceOptionType[];
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [selectedOptionDescription, setSelectedOptionDescription] = useState("");
  const isMobile = useGlobalContext().isMobile;

  return (
    <>
      <h5 style={{ color: "var(--forest-green)", textAlign: "left", margin: "1rem 0 2rem 0" }}>
        Optional - include a facilitated activity
      </h5>
      <Grid container direction="row" px={isMobile ? "" : "10%"}>
        {props.expOptions.map((opt) => {
          return (
            <Grid
              item
              xs={12}
              lg={4}
              textAlign="center"
              my={isMobile ? ".5rem" : ""}
              key={"opt-item-" + opt.name}
            >
              <button
                className={"button-cream"}
                style={{
                  background: props.selectedOption === opt.name ? "var(--smoke-grey)" : "",
                  paddingLeft: ".5rem",
                  paddingRight: ".5rem",
                }}
                onClick={() =>
                  props.selectedOption === opt.name
                    ? (props.setSelectedOption(""), setSelectedOptionDescription(""))
                    : (props.setSelectedOption(opt.name),
                      setSelectedOptionDescription(opt.description))
                }
                key={"opt-btn-" + opt.name}
              >
                <span className="button-text button-text-cream" key={"opt-span-" + opt.name}>
                  {opt.name}
                </span>
              </button>
            </Grid>
          );
        })}
      </Grid>
      {selectedOptionDescription !== "" && (
        <div
          style={{
            width: isMobile ? "100%" : "80%",
            margin: "2rem auto 0 auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            className="desktop-body"
            style={{
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            {selectedOptionDescription}
          </span>
        </div>
      )}
      {props.selectedOption !== "" && selectedOptionDescription === "" && (
        <div
          style={{
            width: "80%",
            margin: "2rem auto 0 auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            className="desktop-body"
            style={{
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            {props.selectedOption +
              " - we'll work with you on the details as part of the booking process."}
          </span>
        </div>
      )}
    </>
  );
}

export type { experienceOptionType };
