import { Grid } from "@mui/material";
import { useGlobalContext } from "../../App";
import { Link } from "react-router-dom";

export default function PreFooter() {
  const setContactQuizActive = useGlobalContext().setContactQuizActive;
  const isMobile = useGlobalContext().isMobile;

  return (
    <section
      className="landing-div cta-background"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "100px",
        paddingBottom: "100px",
      }}
    >
      <Grid container direction="row" style={{ padding: "0 10vw 0 10vw" }}>
        <Grid
          item
          xs={12}
          lg={7}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3
            className="cta-h3"
            style={{
              marginRight: "auto",
              textAlign: "left",
            }}
          >
            Haven't found what you need?
          </h3>
          <span
            className="desktop-body cta-desktop-body"
            style={{
              marginRight: "auto",
              textAlign: "left",
              width: isMobile ? "100%" : "80%",
            }}
          >
            If you're thinking about an event with your team, talk to us. There's no pressure and
            we're happy to help refine your idea.
          </span>
          {!isMobile && (
            <button
              className="button-cream"
              style={{
                margin: isMobile ? "2rem auto 0 auto" : "1rem 0 0 0",
              }}
              onClick={(e) => setContactQuizActive(true)}
            >
              <span className="button-text button-text-cream">We can help</span>
            </button>
          )}
          {isMobile && (
            <Link to="/pages/contactQuiz">
              <button
                className="button-cream"
                style={{
                  margin: isMobile ? "2rem auto 0 auto" : "",
                }}
              >
                <span className="button-text button-text-cream">We can help</span>
              </button>
            </Link>
          )}
        </Grid>
        <Grid container item xs={12} lg={5}>
          <img
            src={require("../../img/eventPhotos/Compass2.jpeg")}
            alt="vendor array"
            style={{
              height: "18rem",
              width: isMobile ? "100%" : "80%",
              objectFit: "cover",
              margin: "1rem auto 0 auto",
              borderRadius: "8px",
            }}
          />
        </Grid>
      </Grid>
    </section>
  );
}
