import { useEffect, useState } from "react";

import Carousel from "react-multi-carousel";
import useEmblaCarousel from "embla-carousel-react";

import "./experienceImageCarouselStyles.css";
import { ClickAwayListener, Modal } from "@mui/material";
import { useGlobalContext } from "../../../App";

export default function ExperienceMediaCarousel(props: { experienceImgs: string[] }) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | undefined>();
  const isMobile = useGlobalContext().isMobile;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    dragFree: true,
    startIndex: selectedImageIndex,
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 5,
    },
    medium: {
      breakpoint: { max: 1499, min: 800 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 799, min: 1 },
      items: 1,
    },
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={(e) => {
          setModalOpen(false);
        }}
        onClick={(e) => {
          setModalOpen(false);
        }}
        sx={{
          background: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <>
          <div className="embla" ref={emblaRef}>
            <div
              className="embla__container"
              style={{
                height: "90vh",
                margin: isMobile ? "auto auto auto auto" : "5vh auto 5vh auto",
              }}
            >
              {props.experienceImgs.map((img, i) => {
                return (
                  <img
                    className="embla__slide"
                    src={img}
                    key={img}
                    alt="experience"
                    style={{
                      objectFit: "contain",
                    }}
                    onClick={(e) => {
                      setModalOpen(false);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </>
      </Modal>

      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        swipeable={true}
        draggable={false}
        showDots={false}
        infinite={true}
        partialVisible={true}
        dotListClass="custom-dot-list-style"
      >
        {props.experienceImgs.map((img, i) => {
          return (
            <div className="slider" key={i}>
              <img
                src={img}
                key={img}
                alt="experience"
                onClick={(e) => {
                  setSelectedImageIndex(i);
                  setModalOpen(true);
                }}
              />
            </div>
          );
        })}
      </Carousel>
    </>
  );
}
