import "./App.css";

// react-router components
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import routes from "./routes";

import LandingPage from "./pages/LandingPage";
import { breakpoints } from "./theme/breakpoints";

import { Hub } from "aws-amplify/utils";
import { LoginOverlay, checkUserSignedIn, signInAsGuest } from "./Login";

import mixpanel from "mixpanel-browser";

export interface globalContextType {
  isMobile: boolean;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  contactQuizActive: boolean;
  setContactQuizActive: React.Dispatch<React.SetStateAction<boolean>>;
  awsCredentials: any;
  setAwsCredentials: React.Dispatch<React.SetStateAction<any>>;
}

export const GlobalContext = createContext<globalContextType>({
  isMobile: false,
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  contactQuizActive: false,
  setContactQuizActive: () => {},
  awsCredentials: {},
  setAwsCredentials: () => {},
});
export const useGlobalContext = () => useContext(GlobalContext);

function App() {
  const { pathname } = useLocation();

  mixpanel.init("061518bbc3199d8030d41c57f9db2b6d", { debug: false });
  mixpanel.set_config({ persistence: "localStorage" });

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 1;
    document.scrollingElement!.scrollTop = 1;

    document.title = "Townish | Corporate and Team events";

    mixpanel.track("Route changed", {
      Route: pathname,
      Env: process.env.REACT_APP_ENV,
    });
  }, [pathname]);

  // @ts-ignore
  const getRoutes = (allRoutes) =>
    // @ts-ignore
    allRoutes.map((route) => {
      if (route.authenticated) {
        return (
          <Route
            path={route.route}
            element={
              <>
                <LoginOverlay children={route.component} />
              </>
            }
            key={route.route}
          />
        );
      }

      if (route.route) {
        // @ts-ignore
        return <Route path={route.route} element={route.component} key={route.route} />;
      }

      return null;
    });

  const [contactQuizActive, setContactQuizActive] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoints.values.md);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [awsCredentials, setAwsCredentials] = useState({});

  const listener = (data: any) => {
    console.log(data);

    if (data.payload?.event && data.payload.event === "signedIn") {
      checkUserSignedIn().then((result) => {
        setIsAuthenticated(result);
      });
    }
  };

  Hub.listen("auth", listener);

  function displayView() {
    if (window.innerWidth < breakpoints.values.lg) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  async function getAWSCredentials() {
    let credentials = await signInAsGuest();

    setAwsCredentials(credentials);
  }

  useEffect(() => {
    window.addEventListener("resize", displayView);
    displayView();

    checkUserSignedIn().then((result) => {
      setIsAuthenticated(result);
    });
    getAWSCredentials();

    return () => window.removeEventListener("resize", displayView);
  }, []);

  useEffect(() => {
    if (contactQuizActive) {
      mixpanel.track("Quiz started", { Env: process.env.REACT_APP_ENV });
    }
  }, [contactQuizActive]);

  return (
    <GlobalContext.Provider
      value={{
        isMobile: isMobile,
        isAuthenticated: isAuthenticated,
        setIsAuthenticated: setIsAuthenticated,
        contactQuizActive: contactQuizActive,
        setContactQuizActive: setContactQuizActive,
        awsCredentials: awsCredentials,
        setAwsCredentials: setAwsCredentials,
      }}
    >
      <div className="App">
        <link href="https://fonts.googleapis.com/css?family=Libre Caslon Text" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Cabin" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        ></link>

        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
