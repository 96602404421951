import LandingPage from "./pages/LandingPage";

import PostabilityCaseStudy from "./caseStudies/PostabilityCaseStudy";
import SorsoCaseStudy from "./caseStudies/SorsoCaseStudy";
import { ContactQuiz } from "./components/ContactQuizOverlay";
import BookableDetail from "./pages/bookableDetail";
import CostEstimator from "./pages/costEstimator";
import ExperienceDetail from "./pages/experienceDetail";
import FindExperience from "./pages/findExperience";
import AboutTownish from "./pages/footerPages/AboutTownish";
import Contact from "./pages/footerPages/Contact";
import HowItWorks from "./pages/footerPages/HowItWorks";
import Privacy from "./pages/footerPages/Privacy";
import TOS from "./pages/footerPages/TOS";
import ServiceExplorer from "./pages/serviceExplorer";
import VendorDetail from "./pages/vendorDetail";

const routes = [
  {
    name: "landingPage",
    route: "/",
    component: <LandingPage />,
  },
  {
    name: "findExperience",
    route: "/pages/findExperience/:eventType?/:searchLocation?",
    component: <FindExperience />,
  },
  {
    name: "experienceDetail",
    route: "/pages/experienceDetail/:experienceID",
    component: <ExperienceDetail />,
    // authenticated: true,
  },
  {
    name: "serviceExplorer",
    route: "/pages/serviceExplorer/:category?/:location?",
    component: <ServiceExplorer />,
  },
  {
    name: "costEstimator",
    route: "/pages/costEstimator",
    component: <CostEstimator />,
  },
  {
    name: "AboutTownish",
    route: "/pages/about",
    component: <AboutTownish />,
  },
  {
    name: "Privacy",
    route: "/pages/privacy",
    component: <Privacy />,
  },
  {
    name: "TOS",
    route: "/pages/terms",
    component: <TOS />,
  },
  {
    name: "Contact",
    route: "/pages/contact",
    component: <Contact />,
  },
  {
    name: "HowItWorks",
    route: "/pages/howItWorks",
    component: <HowItWorks />,
  },
  {
    name: "ContactQuiz",
    route: "/pages/contactQuiz",
    component: <ContactQuiz quizType={"route"} />,
  },

  {
    name: "PostabilityCaseStudy",
    route: "/pages/PostabilityCaseStudy",
    component: <PostabilityCaseStudy />,
  },
  {
    name: "SorsoCaseStudy",
    route: "/pages/SorsoCaseStudy",
    component: <SorsoCaseStudy />,
  },

  {
    name: "bookableDetail",
    route: "/pages/bookableDetail/:bookableID",
    component: <BookableDetail />,
  },
  {
    name: "vendorDetail",
    route: "/pages/vendorDetail/:vendorID",
    component: <VendorDetail />,
  },
];

export default routes;
