import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import config from "./amplifyconfiguration.json";
import "@aws-amplify/ui-react/styles.css";

import "./Login.css";

/* This overrides the redirect values from aws-exports so that login/signup can work from any env */
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: window.location.origin + "/",
    redirectSignOut: window.location.origin + "/",
  },
};

//console.log(updatedAwsConfig);
Amplify.configure(updatedAwsConfig);

//Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Authenticator.Provider>
        <App />
      </Authenticator.Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
