import { useEffect, useState } from "react";

import { Controls, Player } from "@lottiefiles/react-lottie-player";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

import { Grid } from "@mui/material";
import { Bookable } from "../../API";
import { fetchBookablesInSubcategory, fetchVendor, internalVendor } from "../../ServiceUtils";
import BookableCard from "../../components/bookableCard";
import Filters, {
  defaultEventBudget,
  defaultEventDuration,
  defaultEventType,
  defaultGroupSize,
  getFilteredBookables,
  serviceExplorerCategories,
} from "../../components/filters/filters";
import VendorCard from "../../components/vendorCard";
import featureActive from "../../toggles";

export default function ServiceExplorer() {
  //const { category, location } = useParams();

  const [groupSizeFilter, setGroupSizeFilter] = useState(defaultGroupSize);
  const [eventDurationFilter, setEventDurationFilter] = useState(defaultEventDuration);
  const [eventBudgetFilter, setEventBudgetFilter] = useState(defaultEventBudget);
  const [categoryFilter, setCategoryFilter] = useState(defaultEventType);
  const [eventLocationFilter, setEventLocationFilter] = useState(1);

  const [loading, setLoading] = useState(true);

  const [bookablesInCategory, setBookablesInCategory] = useState<Bookable[]>([]);
  const [filteredBookables, setFilteredBookables] = useState<Bookable[]>([]);
  const [filteredVendors, setFilteredVendors] = useState<internalVendor[]>([]);

  const fetchBookablesInCategory = async (categoryID: string) => {
    await fetchBookablesInSubcategory(categoryID).then((bk) => setBookablesInCategory(bk));
  };

  const fetchVendorsForBookables = async (bookables: Bookable[]) => {
    let fetchedVendors: internalVendor[] = [];
    let tempVendors = await Promise.all(
      bookables.map(async (bkbl): Promise<internalVendor | undefined> => {
        if (bkbl.vendorBookablesVendorID) {
          let fetchedVendor = await fetchVendor(bkbl.vendorBookablesVendorID);

          if (
            fetchedVendor &&
            !fetchedVendors.some(function (fv) {
              return fetchedVendor!.vendorID === fv.vendorID;
            })
          ) {
            fetchedVendors.push(fetchedVendor);
          }

          return fetchedVendor;
        }
      })
    );
    return fetchedVendors;
  };

  const updateBookablesList = async () => {
    let updatedBookables = await getFilteredBookables(
      bookablesInCategory,
      eventLocationFilter,
      eventBudgetFilter,
      eventDurationFilter,
      groupSizeFilter
    );
    setFilteredBookables(updatedBookables);

    if (!serviceExplorerCategories[categoryFilter].showBookable) {
      let updatedVendors = await fetchVendorsForBookables(updatedBookables);

      setFilteredVendors(updatedVendors);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    updateBookablesList();
  }, [groupSizeFilter, eventLocationFilter, eventBudgetFilter, eventDurationFilter]);

  useEffect(() => {
    setLoading(true);
    fetchBookablesInCategory(serviceExplorerCategories[categoryFilter].subCategoryID);
  }, [categoryFilter]);

  useEffect(() => {
    setLoading(true);
    updateBookablesList();
  }, [bookablesInCategory]);

  if (!featureActive("serviceExplorer")) {
    return <></>;
  }

  console.log(filteredBookables);

  return (
    <>
      <Navbar section />
      <section className="landing-section">
        <div className="landing-div" style={{ padding: "2rem 0" }}>
          <Filters
            serviceExplorerLayout
            groupSizeFilter={groupSizeFilter}
            setGroupSizeFilter={setGroupSizeFilter}
            eventLocationFilter={eventLocationFilter}
            setEventLocationFilter={setEventLocationFilter}
            eventDurationFilter={eventDurationFilter}
            setEventDurationFilter={setEventDurationFilter}
            eventBudgetFilter={eventBudgetFilter}
            setEventBudgetFilter={setEventBudgetFilter}
            categoryFilter={categoryFilter}
            setCategoryFilter={setCategoryFilter}
          />
        </div>
      </section>
      <section className="landing-section">
        <div
          className="landing-div"
          style={{
            minHeight: "600px",
          }}
        >
          {loading && (
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "2rem" }}>
              <Player
                autoplay
                loop
                src={require("../../img/anim/1708183249198.json")}
                style={{ height: "100px", width: "100px", margin: "0 auto 0 auto" }}
              >
                <Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} />
              </Player>
            </div>
          )}
          {!loading && filteredBookables.length > 0 && (
            <Grid
              container
              p={"1.5rem 1.2rem 1.5rem 1.2rem"}
              justifyContent={"center"}
              columnSpacing={2}
            >
              {serviceExplorerCategories[categoryFilter].showBookable &&
                filteredBookables.map((fbk) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      md={2.5}
                      key={"BookableGrid" + fbk.bookableID}
                      marginBottom="1rem"
                    >
                      <BookableCard bk={fbk} key={"BookableCard" + fbk.bookableID} />
                    </Grid>
                  );
                })}
              {!serviceExplorerCategories[categoryFilter].showBookable &&
                filteredVendors.map((fvnd) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      md={2.5}
                      key={"VendorGrid" + fvnd.vendorID}
                      marginBottom="1rem"
                    >
                      <VendorCard vendor={fvnd} key={"VendorCard" + fvnd.vendorID} />
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}
