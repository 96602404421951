import { Divider, Grid } from "@mui/material";
import { useGlobalContext } from "../../App";

interface experienceHighlightType {
  heading: string;
  img: string;
  description: string;
}

export default function ExperienceHighlights(props: { highlights: experienceHighlightType[] }) {
  const isMobile = useGlobalContext().isMobile;

  return (
    <Grid container my={"2rem"}>
      {props.highlights.map((hi, i) => {
        if (isMobile || i % 2 === 0) {
          return (
            <div key={"div" + hi.heading} style={{ width: "100%" }}>
              <Divider
                key={"Divider" + hi.heading}
                style={{ display: i === 0 ? "none" : "block" }}
              />
              <Grid container item my={"2rem"} alignItems="center" key={"GridCont" + hi.heading}>
                <Grid
                  container
                  item
                  lg={4}
                  key={"GridItemImg" + hi.heading}
                  justifyContent={isMobile ? "center" : ""}
                >
                  <img
                    src={hi.img}
                    alt={hi.heading}
                    style={{
                      height: "250px",
                      maxWidth: isMobile ? "100%" : "300px",
                      objectFit: "cover",
                    }}
                    key={"Img" + hi.heading}
                  />
                </Grid>
                <Grid
                  item
                  lg={8}
                  textAlign={"left"}
                  key={"GridItemText" + hi.heading}
                  pr={isMobile ? "" : "2rem"}
                >
                  <h5
                    style={{
                      color: "var(--forest-green)",
                      margin: isMobile ? "1rem 0 1rem 0" : "0 0 1rem 0",
                      textAlign: "left",
                    }}
                    key={"h5" + hi.heading}
                  >
                    {hi.heading}
                  </h5>
                  <span
                    className="desktop-body"
                    style={{ fontSize: 17 }}
                    key={"spanDesc" + hi.heading}
                  >
                    {hi.description}
                  </span>
                </Grid>
              </Grid>
            </div>
          );
        } else {
          return (
            <div key={"div" + hi.heading} style={{ width: "100%" }}>
              <Divider
                key={"Divider" + hi.heading}
                style={{ display: i === 0 ? "none" : "block" }}
              />
              <Grid container item my={"2rem"} alignItems="center" key={"GridCont" + hi.heading}>
                <Grid item lg={8} textAlign={"left"} key={"GridItemText" + hi.heading} pl="2rem">
                  <h5
                    style={{
                      color: "var(--forest-green)",
                      margin: "0 0 1rem 0",
                      textAlign: "left",
                    }}
                    key={"h5" + hi.heading}
                  >
                    {hi.heading}
                  </h5>
                  <span
                    className="desktop-body"
                    style={{ fontSize: 17 }}
                    key={"spanDesc" + hi.heading}
                  >
                    {hi.description}
                  </span>
                </Grid>
                <Grid item lg={4} key={"GridItemImg" + hi.heading}>
                  <img
                    src={hi.img}
                    alt={hi.heading}
                    style={{ height: "250px", maxWidth: "300px", objectFit: "cover" }}
                    key={"Img" + hi.heading}
                  />
                </Grid>
              </Grid>
            </div>
          );
        }
      })}
    </Grid>
  );
}

export type { experienceHighlightType };
