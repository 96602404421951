import { Link } from "react-router-dom";

import { Grid } from "@mui/material";

import { experienceIndexType } from "../../experienceIndex";

/*

  location: locationType;


*/

export default function ExperienceCard(props: { experience: experienceIndexType }) {
  return (
    <Link
      to={"/pages/experienceDetail/" + props.experience.id}
      style={{ textDecoration: "none", color: "var(--forest-green)" }}
      key={"Link" + props.experience.id}
    >
      <div
        className="basic-div"
        style={{
          minHeight: "400px",
          borderRadius: "8px",
        }}
        key={"div" + props.experience.id}
      >
        <Grid container direction="column" key={"Grid-cont" + props.experience.id}>
          <Grid item key={"Grid-item" + props.experience.id}>
            <div
              style={{
                backgroundColor: "var(--smoke-grey)",
                width: "100%",
                borderRadius: "8px",
                display: "block",
              }}
            >
              <img
                src={props.experience.experienceThumbnail}
                alt={props.experience.experienceName + " thumbnail"}
                style={{
                  width: "100%",
                  aspectRatio: 1,
                  objectFit: "cover",
                  borderRadius: "8px",
                  display: "block",
                }}
                key={"Img" + props.experience.id}
              />
            </div>
          </Grid>
          <Grid container item key={"Grid-cont2" + props.experience.id}>
            <h5
              style={{
                margin: ".1rem 0 0 .5rem",
                color: "var(--forest-green)",
                overflow: "hidden",
                textAlign: "left",
              }}
              key={"name" + props.experience.id}
            >
              {props.experience.experienceName}
            </h5>
          </Grid>

          <Grid container direction="row">
            <Grid container item xs={6}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  margin: "0 0 0 .5rem",
                }}
                key={"Grid-item2" + props.experience.id}
              >
                <span
                  className="desktop-body2"
                  style={{
                    fontSize: 16,
                    textAlign: "left",
                    maxHeight: "1.5rem",
                    overflow: "hidden",
                  }}
                  key={"address" + props.experience.id}
                >
                  {props.experience.location.shortAddress}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  margin: "-.5rem 0 0 .5rem",
                }}
                key={"Grid-item3" + props.experience.id}
              >
                <span
                  className="desktop-body2"
                  style={{ fontSize: 16 }}
                  key={"groupSize" + props.experience.id}
                >
                  Groups up to {props.experience.maxGroupSize}
                </span>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              justifyContent={"right"}
              textAlign={"right"}
              key={"Grid-item-price" + props.experience.id}
            >
              {props.experience.pricePerPerson && (
                <span
                  className="desktop-body2"
                  style={{ fontSize: 16 }}
                  key={"priceFrom" + props.experience.id}
                >
                  from ${props.experience.pricePerPerson.toLocaleString()} per person
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Link>
  );
}
