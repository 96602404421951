import { Link } from "react-router-dom";

import { Grid } from "@mui/material";
import { useGlobalContext } from "../../App";

import { socials } from "../../components/Footer";

export default function LPFooter() {
  const setContactQuizActive = useGlobalContext().setContactQuizActive;

  return (
    <section
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "4rem",
        paddingBottom: "50px",
        maxWidth: "inherit",
      }}
    >
      <Grid
        container
        direction={"column"}
        textAlign={"left"}
        px={useGlobalContext().isMobile ? "3%" : "10%"}
      >
        {!useGlobalContext().isMobile && <h2>Be your team's superhero</h2>}
        {useGlobalContext().isMobile && (
          <h3 style={{ color: "var(--forest-green)", marginBottom: ".5rem" }}>
            Be your team's superhero
          </h3>
        )}
        <span
          className="desktop-body testimonial-font-color"
          style={{ textAlign: "left", marginTop: "1rem" }}
        >
          Interested in joining our growing community? Subscribe for timely updates on the newest
          developments
        </span>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            maxWidth: useGlobalContext().isMobile ? "90%" : "50%",
          }}
        >
          <iframe
            src="https://embeds.beehiiv.com/43c68d23-713a-41ef-9ac6-3534ef543300?slim=true"
            data-test-id="beehiiv-embed"
            title="newsletter-subscribe-embed"
            height="52"
            width="80%"
            style={{
              margin: 0,
              border: "0px",
              borderRadius: " 0px !important",
              backgroundColor: "transparent",
              overflow: "hidden",
            }}
          ></iframe>
        </div>
      </Grid>
      <Grid
        container
        direction={useGlobalContext().isMobile ? "column" : "row"}
        textAlign={"left"}
        px={useGlobalContext().isMobile ? "5%" : "10%"}
      >
        {!useGlobalContext().isMobile && (
          <Grid item xs={12} md={2}>
            <img
              src={require("../../img/Townish_logo_inverted_circle.png")}
              alt="vendor array"
              style={{ width: "80%", maxWidth: "120px", marginTop: "3rem" }}
            />
          </Grid>
        )}
        <Grid container item xs={8} md={3} mt={"3rem"} direction="column">
          <span
            className="desktop-body"
            style={{ width: "67%", color: "var(--forest-green)", fontWeight: "bold" }}
          >
            Product
          </span>
          <Link
            className="link-text link-text-forest"
            to="/pages/howItWorks"
            style={{ marginTop: "1rem" }}
          >
            How it Works
          </Link>
          <Link
            className="link-text link-text-forest"
            to="/pages/findExperience"
            style={{ marginTop: "1rem" }}
          >
            Experiences
          </Link>
          <>
            {useGlobalContext().isMobile && (
              <Link
                className="link-text link-text-forest"
                to="/pages/contactQuiz"
                style={{ marginTop: "1rem" }}
              >
                Get in Touch
              </Link>
            )}
            {!useGlobalContext().isMobile && (
              <span
                className="link-text link-text-forest"
                onClick={() => setContactQuizActive(true)}
                style={{ cursor: "pointer", marginTop: "1rem" }}
              >
                Get in touch
              </span>
            )}
          </>
          <Link
            className="link-text link-text-forest"
            to="/pages/costEstimator"
            style={{ marginTop: "1rem" }}
          >
            Budget Estimator
          </Link>
        </Grid>
        <Grid container item xs={12} md={3} mt={"3rem"} direction="column">
          <span
            className="desktop-body"
            style={{ width: "67%", color: "var(--forest-green)", fontWeight: "bold" }}
          >
            Company
          </span>
          <Link
            className="link-text link-text-forest"
            to="/pages/about"
            style={{ marginTop: "1rem" }}
          >
            About
          </Link>
          <Link
            className="link-text link-text-forest"
            to="/pages/privacy"
            style={{ marginTop: "1rem" }}
          >
            Privacy
          </Link>
          <Link
            className="link-text link-text-forest"
            to="/pages/terms"
            style={{ marginTop: "1rem" }}
          >
            Terms of Service
          </Link>
          <Link
            className="link-text link-text-forest"
            to="/pages/contact"
            style={{ marginTop: "1rem" }}
          >
            Contact
          </Link>
          <Link
            className="link-text link-text-forest"
            to="https://townish.team/blog"
            style={{ marginTop: "1rem" }}
          >
            Blog
          </Link>
        </Grid>
        <Grid container item xs={12} md={3} mt={"3rem"} direction="column">
          <span
            className="desktop-body"
            style={{ color: "var(--forest-green)", fontWeight: "bold" }}
          >
            Social media
          </span>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
            {socials.map((social) => (
              <Link key={social.link} to={social.link} style={{ marginRight: "1rem" }}>
                {social.icon}
              </Link>
            ))}
          </div>
        </Grid>
        {useGlobalContext().isMobile && (
          <Grid item xs={12} md={2}>
            <img
              src={require("../../img/Townish_logo_inverted_circle.png")}
              alt="vendor array"
              style={{ width: "80%", maxWidth: "100px", marginTop: "3rem" }}
            />
          </Grid>
        )}
      </Grid>

      <div style={{ marginTop: "6rem", color: "var(--forest-green)" }}>
        <span className="b2">Copyright © 2024 by Townish.</span>
      </div>
    </section>
  );
}
