const toggles = [
  {
    featureName: "serviceExplorer",
    active: false,
  },
];

export default function featureActive(featureName: string): boolean {
  return toggles.some((ft) => {
    return ft.featureName === featureName && ft.active === true;
  });
}
