import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import VendorDetailElement from "./vendorDetailElement";

import { Controls, Player } from "@lottiefiles/react-lottie-player";

import { fetchAmenitiesForVendor, fetchVendor, internalVendor } from "../../ServiceUtils";
import { Amenity } from "../../API";
import featureActive from "../../toggles";

export default function VendorDetail() {
  const { vendorID } = useParams();
  const [vendorLoading, setVendorLoading] = useState(true);
  const [vendor, setVendor] = useState<internalVendor>();
  const [amenities, setAmenities] = useState<Amenity[]>([]);
  // const [vendorBookables, setVendorBookables] = useState([]);

  useEffect(() => {
    setVendorLoading(true);

    if (vendorID) {
      (async () => {
        const fetchedVendor = await fetchVendor(vendorID);
        setVendor(fetchedVendor);

        const fetchedAmenities = await fetchAmenitiesForVendor(vendorID);
        setAmenities(fetchedAmenities);

        // const fetchedVendorBookables = await fetchBookablesForVendor(vendorID);
        // setVendorBookables(vendorBookables);

        setVendorLoading(false);
      })();
    }
  }, [vendorID]);

  if (!featureActive("serviceExplorer")) {
    return <></>;
  }

  return (
    <>
      <Navbar section />

      <section className="landing-section">
        <div className="landing-div" style={{ padding: "1rem 0" }}>
          {vendorLoading && (
            <Player
              autoplay
              loop
              src={require("../../img/anim/1708183249198.json")}
              style={{ height: "100px", width: "100px", margin: "0 auto 0 auto" }}
            >
              <Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} />
            </Player>
          )}
          {!vendorLoading && !vendor && (
            <h5 style={{ margin: 0, padding: "2rem 0" }}>Vendor not found.</h5>
          )}
          {!vendorLoading && vendor && (
            <VendorDetailElement vendor={vendor} amenities={amenities} />
          )}
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}
