import { isPointWithinRadius } from "geolib";
import {
  experienceDetails,
  experienceDetailsType,
  experienceIndexType,
  experiences,
  locationType,
} from "./experienceIndex";

function importAll(r: __WebpackModuleApi.RequireContext): string[] {
  return r.keys().map(r) as string[];
}

function findExperienceByID(experienceID: string): experienceIndexType | undefined {
  return experiences.find(function (exp) {
    return exp.id === experienceID;
  });
}

function findExperienceDetailsByID(experienceID: string): experienceDetailsType | undefined {
  return experienceDetails.find(function (expD) {
    return expD.id === experienceID;
  });
}

function experienceHasType(experience: experienceIndexType, eventType: string) {
  return experience.experienceTypes.some((et) => {
    return eventType === "all events" || et === eventType;
  });
}
/*
function getFilteredExperiences(mapOrigin: locationType, searchRadius: number, eventType: string) {
  if (searchRadius === -1) {
    return experiences.filter((exp) => {
      return experienceHasType(exp, eventType);
    });
  } else {
    const filteredExperiences = experiences
      .filter((exp) => {
        return isPointWithinRadius(
          { latitude: exp.location.lat, longitude: exp.location.long },
          { latitude: mapOrigin.lat, longitude: mapOrigin.long },
          searchRadius
        );
      })
      .filter((exp) => {
        return experienceHasType(exp, eventType);
      });
    return filteredExperiences;
  }
}
*/
export { findExperienceByID, findExperienceDetailsByID, importAll };
