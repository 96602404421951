import { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

interface FAQType {
  question: string;
  answer: string;
}

export default function ExperienceFAQ(props: { FAQ: FAQType[]; backgroundColor?: string }) {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <h4 style={{ color: "var(--forest-green)", textAlign: "left", marginBottom: "1rem" }}>FAQ</h4>
      {props.FAQ.map((faq, i) => {
        return (
          <Accordion
            expanded={expanded === "faq" + i}
            onChange={handleChange("faq" + i)}
            style={{ backgroundColor: props.backgroundColor }}
            key={"faq-accordion" + faq.question}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel" + i + "-content"}
              id={"panel" + i + "-header"}
              key={"faq-accordionSummary" + faq.question}
            >
              <span
                className="h6b"
                style={{ color: "var(--forest-green)", textAlign: "left" }}
                key={"faq-span" + faq.question}
              >
                {faq.question}
              </span>
            </AccordionSummary>
            <AccordionDetails style={{ textAlign: "left" }} key={"faq-details" + faq.question}>
              <span
                className="desktop-body"
                style={{ color: "var(--forest-green)", fontSize: 17 }}
                key={"faq-span" + faq.question}
              >
                {faq.answer}
              </span>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

export type { FAQType };
