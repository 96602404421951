import { useCallback, useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../../App";

const logos = [
  require("../../img/logoBar/CrowdRiff.png"),
  require("../../img/logoBar/pokpok_logo.png"),
  require("../../img/logoBar/Postability.png"),
  require("../../img/logoBar/FaireLogo.png"),
  require("../../img/logoBar/simpliHome.png"),
  require("../../img/logoBar/CompassDigitalLogo.png"),
  require("../../img/logoBar/softRainsText.jpeg"),
  require("../../img/logoBar/Sorso.jpg"),
  require("../../img/logoBar/Pinecone-Primary-Logo-Black.png"),
  require("../../img/logoBar/CADTH.png"),
  require("../../img/logoBar/Minerva.png"),
  require("../../img/logoBar/PeakPower.png"),
];

export default function LogoBar() {
  const contactQuizActive = useGlobalContext().contactQuizActive;

  const InfiniteLooper = function InfiniteLooper({
    speed,
    direction,
    children,
  }: {
    speed: number;
    direction: "right" | "left";
    children: React.ReactNode;
  }) {
    const [looperInstances, setLooperInstances] = useState(1);
    const outerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    function resetAnimation() {
      if (innerRef?.current) {
        innerRef.current.setAttribute("data-animate", "false");

        setTimeout(() => {
          if (innerRef?.current) {
            innerRef.current.setAttribute("data-animate", "true");
          }
        }, 10);
      }
    }

    const setupInstances = useCallback(() => {
      if (!innerRef?.current || !outerRef?.current) return;

      const { width } = innerRef.current.getBoundingClientRect();

      const { width: parentWidth } = outerRef.current.getBoundingClientRect();

      const widthDeficit = parentWidth - width;

      const instanceWidth = width / innerRef.current.children.length;

      if (widthDeficit) {
        setLooperInstances(looperInstances + Math.ceil(widthDeficit / instanceWidth) + 1);
      }

      resetAnimation();
    }, [looperInstances]);

    /*
      6 instances, 200 each = 1200
      parent = 1700
    */

    useEffect(() => setupInstances(), [setupInstances]);

    useEffect(() => {
      window.addEventListener("resize", setupInstances);

      return () => {
        window.removeEventListener("resize", setupInstances);
      };
    }, [looperInstances, setupInstances]);

    return (
      <div className="looper" ref={outerRef}>
        <div className="looper__innerList" ref={innerRef} data-animate="true">
          {[...Array(looperInstances)].map((_, ind) => (
            <div
              key={ind}
              className={contactQuizActive ? "" : "looper__listInstance"}
              style={{
                animationDuration: `${speed}s`,
                animationDirection: direction === "right" ? "reverse" : "normal",
              }}
            >
              {children}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "2rem",
        paddingBottom: "2rem",
      }}
    >
      {!useGlobalContext().isMobile && (
        <h5 style={{ color: "var(--forest-green)", margin: "0 0 0 0" }}>
          Join dozens of companies choosing Townish to create unique experiences every month
        </h5>
      )}
      <div style={{ marginTop: "2rem" }}>
        <InfiniteLooper speed={45} direction="left">
          {logos.map((logo) => {
            return (
              <img
                src={logo}
                alt="logo"
                key={logo}
                width="200px"
                style={{ objectFit: "contain", margin: "0 3rem 0 3rem", maxHeight: "100px" }}
              />
            );
          })}
        </InfiniteLooper>
      </div>
    </div>
  );
}
