import { Grid } from "@mui/material";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { useGlobalContext } from "../../App";

function AboutTownish() {
  return (
    <>
      <Navbar section />
      <section
        className="landing-section"
        style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      >
        <img
          src={require("../../img/aboutTownish/largeGroupGathering.jpg")}
          alt="large-group-gathering"
          width="100%"
          style={{
            objectFit: "cover",
            objectPosition: "50% 75%",
            maxHeight: useGlobalContext().isMobile ? "40vh" : "60vh",
          }}
        />
      </section>
      <section className="landing-section">
        <div className="landing-div" style={{ display: "flex", flexDirection: "column" }}>
          <h3>Townish is where great events take place. </h3>
          <span
            className="desktop-body"
            style={{
              width: "100%",

              marginBottom: "1rem",
              textAlign: "center",
              fontSize: "28px",
            }}
          >
            We believe in:
          </span>
          <Grid
            container
            padding={useGlobalContext().isMobile ? "0 2rem" : "0 3rem"}
            width={useGlobalContext().isMobile ? "100%" : "90%"}
            marginX={"auto"}
            justifyContent={"space-between"}
            textAlign={"left"}
          >
            <Grid item xs={12} lg={3.5} marginBottom="3rem">
              <img
                src={require("../../img/eventPhotos/Postability (1).jpg")}
                alt="large-group-gathering"
                width="100%"
                style={{
                  borderRadius: "8px",
                  objectFit: "cover",
                  height: "30vh",
                }}
              />
              <p className="desktop-body" style={{ fontWeight: "bold", margin: ".5rem 0 .5rem 0" }}>
                Flexibility
              </p>
              <p className="desktop-body" style={{ margin: ".5rem 0 .5rem 0" }}>
                We believe the world is changing, work culture included. We think employees will be
                increasingly dispersed - however they will still want to meet. In person.
              </p>
            </Grid>
            <Grid item xs={12} lg={3.5} marginBottom="3rem">
              <img
                src={require("../../img/eventPhotos/Postability (11).jpg")}
                alt="in-person-connections"
                width="100%"
                style={{
                  borderRadius: "8px",
                  objectFit: "cover",
                  height: "30vh",
                }}
              />
              <p className="desktop-body" style={{ fontWeight: "bold", margin: ".5rem 0 .5rem 0" }}>
                In person connections
              </p>
              <p className="desktop-body" style={{ margin: ".5rem 0 .5rem 0" }}>
                It’s so important to intentionally gather and meet face to face. It helps with
                motivation, performance and building connections.
              </p>
            </Grid>
            <Grid item xs={12} lg={3.5} marginBottom="3rem">
              <img
                src={require("../../img/eventPhotos/Sorso4.jpeg")}
                alt="large-group-gathering"
                width="100%"
                style={{
                  borderRadius: "8px",
                  objectFit: "cover",
                  height: "30vh",
                }}
              />

              <p className="desktop-body" style={{ fontWeight: "bold", margin: ".5rem 0 .5rem 0" }}>
                Eco-system
              </p>
              <p className="desktop-body" style={{ margin: ".5rem 0 .5rem 0" }}>
                We’re building a win-win-win community. We put our customers and partners first and
                believe this is the way to build a company.
              </p>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Grid
            container
            padding={useGlobalContext().isMobile ? "2rem" : "3rem"}
            width={useGlobalContext().isMobile ? "100%" : "90%"}
            marginX={"auto"}
            alignItems={"stretch"}
          >
            <Grid
              container
              item
              xs={12}
              lg={4}
              margin={useGlobalContext().isMobile ? "0 0 2rem 0" : "0 2rem 0 0"}
            >
              <img
                src={require("../../img/aboutTownish/RoyCamMeetingCows.jpg")}
                alt="roy-and-cam-meeting-cows"
                width="100%"
                style={{
                  borderRadius: "15px",
                  objectFit: "cover",
                  objectPosition: "0 40%",
                  maxHeight: useGlobalContext().isMobile ? "40vh" : "60vh",
                }}
              />
            </Grid>
            <Grid container item xs={12} lg={7.5} direction="column" textAlign="left">
              <h5 style={{ margin: "0 0 1rem 0" }}>A Letter from our founders</h5>
              <p className="desktop-body">
                Townish was born out of necessity. Cam and Roy, both tech executives for years, felt
                the world changing during the pandemic. They saw the world becoming more distributed
                and remote, and they saw how hard it was to bring teams together. Each event was
                built from scratch, sourcing, following up, scheduling, and booking. There was so
                much mundane, manual, and expensive work.
              </p>
              <p className="desktop-body">
                But team gatherings are worth it. They boost performance and productivity; they help
                build connections; and they help us work together towards achieving our goals. So we
                decided to build a solution where teams can come together and create the best
                version of themselves. We’re thrilled to have customers and partners be a part of
                the thriving ecosystem we’re building and help each other succeed.
              </p>
              <p className="desktop-body">
                Here’s to intentional gatherings and creating the best teams possible.
              </p>
              <h5 style={{ marginTop: 0 }}>Cam & Roy</h5>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}

export default AboutTownish;
