import {
  Amenity,
  Bookable,
  BookablesCategoriesByBookableBookableIDQuery,
  BookablesCategoriesBySubCategoryIdQuery,
  Service,
  ServicesAmenitiesByServiceIdQuery,
  ServicesCategoriesBySubCategoryIdQuery,
  VendorsAmenitiesByVendorVendorIDQuery,
} from "./API";

import { generateClient } from "aws-amplify/api";
import {
  bookablesCategoriesBySubCategoryId,
  getBookable,
  getService,
  getVendor,
  servicesAmenitiesByServiceId,
  servicesCategoriesBySubCategoryId,
  vendorsAmenitiesByVendorVendorID,
} from "./graphql/queries";

import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { ListObjectsV2Command, S3Client } from "@aws-sdk/client-s3";

const amplifyClient = generateClient();

export const s3ImgParams = {
  s3ImgPath: "https://townish-serviceimages-dev.s3.ca-central-1.amazonaws.com/",
  Bucket: "townish-serviceimages-dev",
  Prefix: "",
  Delimiter: "/",
};

// due to a quirk of the generated graphQL queries, nested objects return values that offend typescript (depending on how deep the nested query goes).  These internal types cut off the nesting at a known point so that types are preserved intact.
interface internalBookable {
  __typename: "Bookable";
  bookableID: string;
  bookableName: string;
  bookableDescription: string;
  bookableLocation: string;
  bookableImgPath: string;
  bookableMainImg: string;
  bookablePrice: number;
  bookableDuration: number;
  bookableMinOccupancy: number;
  bookableMaxOccupancy: number;
  createdAt: string;
  updatedAt: string;
  vendorBookablesVendorID?: string | null;
}

interface internalAmenity {
  __typename: "VendorsAmenities";
  id: string;
  amenityId: string;
  vendorVendorID: string;
  createdAt: string;
  updatedAt: string;
}

interface internalVendor {
  __typename: "Vendor";
  vendorID: string;
  vendorName: string;
  vendorSubheading: string;
  vendorDescription: string;
  vendorImgPath: string;
  vendorMainImg: string;
  amenities?: {
    __typename: "ModelVendorsAmenitiesConnection";
    items: Array<internalAmenity | null>;
    nextToken?: string | null;
  } | null;
  bookables?: {
    __typename: "ModelBookableConnection";
    items: Array<internalBookable | null>;
    nextToken?: string | null;
  } | null;
  vendorExperiences?: string | null;
  createdAt: string;
  updatedAt: string;
}

const client = new S3Client({
  region: "ca-central-1",
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: "ca-central-1" },
    identityPoolId: "ca-central-1:0741b2ec-53e5-454a-80d5-3c0419d3dc91",
    logins: {
      // Optional tokens, used for authenticated login.
    },
  }),
});

export const getS3ImagesInFolder = async (folder: string) => {
  let path = parseImgPath(folder);

  s3ImgParams.Prefix = path;

  const command = new ListObjectsV2Command(s3ImgParams);
  const response = await client.send(command);

  let imgs: string[] = [];
  response.Contents?.forEach((s3Img) => {
    if (s3Img.Key) {
      imgs.push(s3Img.Key);
    }
  });

  return imgs;
};

async function fetchBookable(bookableID: string) {
  if (!bookableID) {
    return undefined;
  }

  const fetchedBookable = (await amplifyClient.graphql({
    query: getBookable,
    variables: { bookableID: bookableID },
  })) as {
    data: {
      getBookable?: internalBookable;
      errors: any[];
    };
  };

  if (fetchedBookable.data!.getBookable) {
    return fetchedBookable.data.getBookable;
    //  setBookable({ ...fetchedBookable.data.getBookable });
    //  setBookableLoading(false);
  } else {
    return undefined;
  }
}

async function fetchVendor(vendorID: string) {
  if (!vendorID) {
    return undefined;
  }

  const fetchedVendor = (await amplifyClient.graphql({
    query: getVendor,
    variables: { vendorID: vendorID },
  })) as {
    data: {
      getVendor?: internalVendor;
    };
  };

  if (fetchedVendor.data?.getVendor) {
    return fetchedVendor.data.getVendor;
    //  setVendor({ ...fetchedVendor.data.getVendor });
    //  setVendorLoading(false);
  } else {
    return undefined;
  }
}

async function fetchServiceOnly(serviceId: string): Promise<Service | undefined> {
  const fetchedService = (await amplifyClient.graphql({
    query: getService,
    variables: { id: serviceId },
  })) as {
    data: {
      getService?: {
        __typename: "Service";
        id: string;
        serviceName: string;
        serviceLocation: string;
        serviceImgPath: string;
        serviceHeading: string;
        serviceSubheading: string;
        servicePrice: string;
        servicePriceType: string;
        serviceAdditionalInfo: string;
        serviceIdealOccupancy: number;
        serviceMaxOccupancy: number;
        serviceDescription: string;
        serviceVendorEmail?: string | null;
        serviceURL: string;
        showCategoryImage?: boolean | null;
        WorkFunTeam?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      errors: any[];
    };
  };

  if (fetchedService.data.getService) {
    return fetchedService.data.getService;
  } else {
    return undefined;
  }
}

async function fetchCatalogServicesInSubcategory(subCategoryId: string): Promise<Service[]> {
  const fetchedCatalogServices = (await amplifyClient.graphql({
    query: servicesCategoriesBySubCategoryId,
    variables: { subCategoryId: subCategoryId },
  })) as { data: ServicesCategoriesBySubCategoryIdQuery; errors: any[] };

  if (
    fetchedCatalogServices.data.servicesCategoriesBySubCategoryId &&
    fetchedCatalogServices.data.servicesCategoriesBySubCategoryId.items.length > 0
  ) {
    return fetchedCatalogServices.data.servicesCategoriesBySubCategoryId!.items.map((svc) => {
      return svc!.service;
    });
  } else {
    return [];
  }
}

async function fetchBookablesInSubcategory(subCategoryId: string): Promise<Bookable[]> {
  const fetchedBookables = (await amplifyClient.graphql({
    query: bookablesCategoriesBySubCategoryId,
    variables: { subCategoryId: subCategoryId },
  })) as { data: BookablesCategoriesBySubCategoryIdQuery; errors: any[] };

  if (
    fetchedBookables.data.bookablesCategoriesBySubCategoryId &&
    fetchedBookables.data.bookablesCategoriesBySubCategoryId.items.length > 0
  ) {
    return fetchedBookables.data.bookablesCategoriesBySubCategoryId!.items.map((bk) => {
      return bk!.bookable;
    });
  } else {
    return [];
  }
}

async function fetchAmenitiesForService(serviceID: string): Promise<Amenity[]> {
  const fetchedAmenities = (await amplifyClient.graphql({
    query: servicesAmenitiesByServiceId,
    variables: { serviceId: serviceID },
  })) as { data: ServicesAmenitiesByServiceIdQuery; errors: any[] };

  if (
    fetchedAmenities.data.servicesAmenitiesByServiceId &&
    fetchedAmenities.data.servicesAmenitiesByServiceId.items.length > 0
  ) {
    return fetchedAmenities.data.servicesAmenitiesByServiceId!.items.map((amn) => {
      return amn!.amenity;
    });
  } else {
    return [];
  }
}

async function fetchAmenitiesForVendor(vendorID: string): Promise<Amenity[]> {
  const fetchedAmenities = (await amplifyClient.graphql({
    query: vendorsAmenitiesByVendorVendorID,
    variables: { vendorVendorID: vendorID },
  })) as { data: VendorsAmenitiesByVendorVendorIDQuery; errors: any[] };

  if (
    fetchedAmenities.data.vendorsAmenitiesByVendorVendorID &&
    fetchedAmenities.data.vendorsAmenitiesByVendorVendorID.items.length > 0
  ) {
    return fetchedAmenities.data.vendorsAmenitiesByVendorVendorID!.items.map((amn) => {
      return amn!.amenity;
    });
  } else {
    return [];
  }
}

export function parseImgPath(folder: string): string {
  let path = folder.split("./img/").pop();

  if (!path) {
    console.log("invalid folder value in getImagesForService");
    return "";
  }

  return path;
}

export {
  fetchBookable,
  fetchVendor,
  fetchServiceOnly,
  fetchAmenitiesForService,
  fetchAmenitiesForVendor,
  fetchCatalogServicesInSubcategory,
  fetchBookablesInSubcategory,
};

export type { internalBookable, internalVendor, internalAmenity };
