import { Navigate, useLocation } from "react-router";

import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

import { useGlobalContext } from "./App";

export function RequireAuth({ children }: any) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

interface awsCredentialType {
  AccessKeyId: string;
  SecretAccessKey: string;
  SessionToken: string;
}

export function LoginOverlay({ children }: any) {
  return (
    <>
      <div>
        {!useGlobalContext().isAuthenticated && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(200, 200, 200, 0.6)",
              zIndex: 10001,
              backdropFilter: "blur(6px)",
              WebkitBackfaceVisibility: "hidden",
              WebkitPerspective: 1000,
              WebkitTransform: "translate3d(0, 0, 0) translateZ(0)",
              backfaceVisibility: "hidden",
              perspective: 1000,
              transform: "translate3d(0, 0, 0) translateZ(0)",
            }}
          >
            <View className="auth-wrapper" style={{ margin: "auto auto auto auto" }}>
              <div
                style={{
                  maxWidth: "50%",
                  maxHeight: "66%",
                  overflow: "auto",
                  margin: "5% auto auto auto",
                  backgroundColor: "white",
                  border: "2px solid",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "2rem",
                }}
              >
                <h5 style={{ margin: "0 auto 2rem auto", maxWidth: "80%" }}>
                  <p style={{ marginBottom: "1rem" }}>
                    Townish has dozens of pre-built, customizable experiences that are perfect for
                    your team!
                  </p>
                  <p style={{ marginBottom: 0 }}>Sign in to your account to get started</p>
                </h5>
                <Authenticator />
              </div>
            </View>
          </div>
        )}
        {children}
      </div>
    </>
  );
}
/*
export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <>
      {!useGlobalContext().isAuthenticated && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(200, 200, 200, 0.6)",
            zIndex: 10,
            backdropFilter: "blur(6px)",
          }}
        >
          <View className="auth-wrapper" style={{ margin: "auto auto auto auto" }}>
            <Authenticator />
          </View>
        </div>
      )}
    </>
  );
}
*/
export async function signInAsGuest() {
  let credentials: any = {};

  try {
    const authSession = await fetchAuthSession();

    credentials = authSession.credentials;

    /*
    let headers = {};

    if (authSession && authSession.credentials?.sessionToken) {
      headers = {
        Authorization: "Bearer " + authSession.credentials?.sessionToken,
        "Access-Control-Allow-Origin": "*",
      };
    }

    const requestOptions = {
      method: "GET",
      headers: headers,
      withCredentials: "false",
    };

    let req = await fetch(
      "https://townish-serviceimages-dev.s3.ca-central-1.amazonaws.com/svcs208/02d914cae221315a7784826906825c38.jpg",
      requestOptions
    );
    if (req.ok === true) {
      console.log(req);
    }
    */
  } catch (error) {
    console.error("Error signing in as a guest:", error);
  }

  return credentials;
}

export async function checkUserSignedIn() {
  try {
    const { /*username,*/ userId } = await getCurrentUser();

    return Boolean(userId);
  } catch (err) {
    return false;
  }
}

export type { awsCredentialType };
