import { Grid, SvgIcon } from "@mui/material";
import { useGlobalContext } from "../../App";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";

import MaximizeIcon from "@mui/icons-material/Maximize";

export default function CaseStudy() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1 },
      items: 1,
    },
  };

  const CustomDot = ({
    onMove,
    index,
    onClick,
    active,
  }: {
    onMove?: any;
    index?: any;
    onClick?: any;
    active?: any;
  }) => {
    return (
      <li
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
        style={{ width: "144px", height: "10px" }}
      >
        <SvgIcon
          component={MaximizeIcon}
          color="success"
          sx={{
            color: active ? "var(--lime-green)" : "gray",
            transform: "scaleX(8) scaleY(2)",
          }}
        />
      </li>
    );
  };

  return (
    <section
      className="value-prop-background"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "6rem",
        paddingBottom: "6rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: useGlobalContext().isMobile ? "center" : "right",
          marginRight: useGlobalContext().isMobile ? "10%" : "10%",
          marginLeft: useGlobalContext().isMobile ? "10%" : "",
        }}
      >
        <h2 style={{ marginBottom: 0, color: "var(--cream)" }}>Beyond Expectations</h2>
        <h3 style={{ marginTop: 0, color: "var(--cream)" }}>
          Case studies in optimized event execution
        </h3>
      </div>

      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={10000}
        swipeable={true}
        draggable={false}
        showDots={true}
        arrows={false}
        infinite={true}
        customDot={<CustomDot />}
        sliderClass=""
        partialVisible={true}
        dotListClass="custom-dot-list-style"
      >
        <div
          style={{
            borderRadius: "30px",
            backgroundColor: "white",
            width: "80%",
            margin: "0 auto 0 auto",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              lg={5.5}
              padding={useGlobalContext().isMobile ? "1rem" : "1.5rem 1rem 1.5rem 1.5rem"}
            >
              <img
                src={require("../../img/eventPhotos/Postability (2).png")}
                alt="postability event action shot"
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "30px" }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={6.5}
              padding={useGlobalContext().isMobile ? "1rem" : "1.5rem 1.5rem 1.5rem 1rem"}
              direction="column"
              textAlign="left"
            >
              <img
                src={require("../../img/logoBar/Postability.png")}
                alt="postability logo"
                style={{ width: "50%", objectFit: "cover" }}
              />
              <h4 style={{ margin: "1rem 0 1rem 0" }}>
                Empowering corporate success: a case study in streamlined event planning
              </h4>
              <span className="desktop-body" style={{ margin: "0 0 1rem 0", fontSize: 18 }}>
                We had the opportunity to partner with Postability, a rapidly growing technology
                company, to support their post-Covid team-building efforts. We collaborated on
                several events, including full-day sessions incorporating company presentations,
                team-building activities, and social gatherings. Our services helped Postability
                foster a stronger sense of community among their employees, boosting morale and
                engagement.
              </span>
              <div
                style={{
                  marginTop: "auto",
                  marginRight: "auto",
                  marginLeft: useGlobalContext().isMobile ? "auto" : "",
                }}
              >
                <Link to="/pages/PostabilityCaseStudy">
                  <button className="button-cream">
                    <span className="button-text button-text-cream">Read more</span>
                  </button>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            borderRadius: "30px",
            backgroundColor: "white",
            width: "80%",
            margin: "0 auto 0 auto",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              lg={5.5}
              padding={useGlobalContext().isMobile ? "1rem" : "1.5rem 1rem 1.5rem 1.5rem"}
            >
              <img
                src={require("../../img/eventPhotos/Sorso3Cropped.jpeg")}
                alt="postability event action shot"
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "30px" }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={6.5}
              padding={useGlobalContext().isMobile ? "1rem" : "1.5rem 1.5rem 1.5rem 1rem"}
              direction="column"
              textAlign="left"
            >
              <img
                src={require("../../img/logoBar/Sorso.jpg")}
                alt="postability logo"
                style={{ width: "40%", objectFit: "cover" }}
              />
              <h4 style={{ margin: "1rem 0 1rem 0" }}>
                Townish-powered team building: How we strengthened bonds for Sorso Wellness Water’s
                distributed team
              </h4>
              <span className="desktop-body" style={{ margin: "0 0 1rem 0", fontSize: 18 }}>
                Planning successful team-building events and offsites can be time-consuming and
                complex, requiring coordination of multiple vendors and attention to detail.
                Companies often struggle to find reliable, high-quality services that align with
                their goals and budget. This is even more challenging for remote and distributed
                companies, where employees are located in different regions / countries.
              </span>
              <div
                style={{
                  marginTop: "auto",
                  marginRight: "auto",
                  marginLeft: useGlobalContext().isMobile ? "auto" : "",
                }}
              >
                <Link to="/pages/SorsoCaseStudy">
                  <button className="button-cream">
                    <span className="button-text button-text-cream">Read more</span>
                  </button>
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </Carousel>
    </section>
  );
}
