import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

import { useGlobalContext } from "../../App";
import "./animatedBackgroundImage.css";

const images = [
  require("../../img/eventPhotos/SoftRains.jpg"),
  require("../../img/eventPhotos/Sorso4.jpeg"),
  require("../../img/eventPhotos/Compass3.jpeg"),
  require("../../img/heroImages/keynoteSpeaker.jpg"),
  require("../../img/heroImages/athleticActivities.jpg"),
  require("../../img/eventPhotos/Postability (11).png"),
  require("../../img/eventPhotos/Postability (20).png"),
  require("../../img/eventPhotos/Sorso3Cropped.jpeg"),
];
/*
const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
*/
const AnimatedImageElement = () => {
  const contactQuizActive = useGlobalContext().contactQuizActive;

  const [num, setNum] = useState<number>(0);
  const incNum = () => setNum((v) => (v < images.length - 1 ? v + 1 : 0));

  let interval: any;
  useEffect(() => {
    interval = contactQuizActive
      ? clearInterval(interval)
      : setInterval(() => {
          incNum();
        }, 8000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [contactQuizActive]);

  //return <FadeChanger url={images[num]} />;
  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <AnimatePresence initial={false}>
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: "tween",
              duration: 0.8,
            }}
            src={images[num]}
            key={images[num]}
            alt=""
            style={{
              position: "absolute",
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: useGlobalContext().isMobile ? "50% 40%" : "",
              overflow: "hidden",
              zIndex: 1,
            }}
          />
        </AnimatePresence>
      </div>
    </>
  );
};

export default function AnimatedBackgroundImage() {
  return <AnimatedImageElement />;
}
