import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

// @mui material components
import Grid from "@mui/material/Grid";

import { Input } from "@mui/material";
import { useGlobalContext } from "../../App";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

/*
contactName
contactEmail
contactPhone
contactCompany
contactNote
*/

type Inputs = {
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactCompany: string;
  contactNote: string;
};

function Contact() {
  const [showSuccess, setShowSuccess] = useState(false);
  const isMobile = useGlobalContext().isMobile;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const contactInfo = {
      contactName: data.contactName,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      contactCompany: data.contactCompany,
      contactNote: data.contactNote,
    };

    const encode = (dataToEncode: any) => {
      return Object.keys(dataToEncode)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(dataToEncode[key]))
        .join("&");
    };

    fetch("/pages/eventSuccess", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "submitContactForm", ...contactInfo }),
      redirect: "follow",
    })
      .then(() => setShowSuccess(true))
      .catch((error) => alert(error));
  };

  return (
    <>
      <Navbar section />
      <section className="landing-section">
        <div className="landing-div">
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {showSuccess && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                  >
                    <h3>Thanks!</h3>
                  </Grid>

                  <Grid
                    container
                    m={3}
                    mt={5}
                    sx={{ mx: "auto", justifyContent: "center", textAlign: "center" }}
                    width="80%"
                  >
                    <span className="desktop-body" style={{ marginBottom: "1rem" }}>
                      We appreciate you getting in touch and will respond as soon as possible.
                    </span>
                  </Grid>
                </>
              )}
              {!showSuccess && (
                <>
                  <h3>Contact us</h3>
                  <span className="desktop-body" style={{ marginTop: "1rem" }}>
                    We&apos;d love to hear from you - please provide your details below and
                    we&apos;ll do our best to respond within 24 hours.
                  </span>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={8}
                    justifyContent="center"
                    sx={{
                      mx: "auto",
                      textAlign: "center",
                      padding: "3rem",
                      width: isMobile ? "100%" : "80%",
                    }}
                  >
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container item xs={12} spacing={2} mt={2}>
                        <Grid item xs={12} lg={6}>
                          <Input
                            required
                            type="text"
                            placeholder="Name"
                            {...register("contactName", { required: true })}
                            error={errors.contactName ? true : false}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Input
                            type="text"
                            placeholder="Company"
                            {...register("contactCompany", {})}
                            error={errors.contactCompany ? true : false}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Input
                            required
                            type="text"
                            placeholder="Email"
                            {...register("contactEmail", {
                              required: true,
                              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            })}
                            error={errors.contactEmail ? true : false}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Input
                            type="text"
                            placeholder="Phone"
                            {...register("contactPhone", {})}
                            error={errors.contactPhone ? true : false}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} mt={2}>
                          <Input
                            multiline
                            rows={3}
                            type="text"
                            placeholder="How can we help?"
                            {...register("contactNote", {})}
                            error={errors.contactNote ? true : false}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={6} mt={3} ml="auto" display="flex" justifyContent="flex-end">
                          <button className="button-lime" onClick={handleSubmit(onSubmit)}>
                            <span className="button-text button-text-lime">Talk to us</span>
                          </button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}

export default Contact;
