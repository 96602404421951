import { ElementType } from "react";
import { importAll } from "./experienceUtils";
import { agendaDayType } from "./pages/experienceDetail/experienceAgenda";
import { FAQType } from "./pages/experienceDetail/experienceFAQ/experienceFAQ";
import { experienceHighlightType } from "./pages/experienceDetail/experienceHighlights";
import { experienceOptionType } from "./pages/experienceDetail/experienceOptions/experienceOptions";

import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import NaturePeopleOutlinedIcon from "@mui/icons-material/NaturePeopleOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { testimonialType } from "./pages/LandingPage/Testimonials";

interface locationType {
  fullAddress: string;
  shortAddress: string;
  lat: number;
  long: number;
}

interface experienceIndexTypeItem {
  id: string;
  element?: JSX.Element;
  experienceName: string;
  experienceThumbnail: string;
  location: locationType;
  maxGroupSize: number;
  minGroupSize?: number;
  pricePerPerson?: number;
  totalPrice?: number;
  experienceDuration: number; // days
  experienceTypes: string[];
}

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

type experienceIndexType = RequireAtLeastOne<
  experienceIndexTypeItem,
  "pricePerPerson" | "totalPrice"
>;

interface experienceDetailsType {
  id: string;
  tagline: string;
  longDescription: string | string[];
  priceMessage: string;
  experienceLocations: serviceLocationType[];
  experienceMainImg: string;
  experienceImgs: string[];
  features: string[];
  agenda: agendaDayType[];
  experienceOptions?: experienceOptionType[];
  testimonials?: testimonialType[];
  highlights: experienceHighlightType[];
  FAQ: FAQType[];
}

interface serviceLocationType {
  id: string;
  location: locationType;
  experienceName: string;
  experienceThumbnail: string;
}

interface eventTypeType {
  name: string;
  icon: ElementType;
}

const eventTypes: eventTypeType[] = [
  { name: "team building", icon: CategoryOutlinedIcon },
  { name: "learning & development", icon: SchoolOutlinedIcon },
  { name: "sales & marketing", icon: LoyaltyOutlinedIcon },
  { name: "retreats", icon: NaturePeopleOutlinedIcon },
  { name: "team meetings", icon: GroupsOutlinedIcon },
];

const experiences: experienceIndexType[] = [
  {
    id: "summer-camp-for-adults-bradford-ontario",
    experienceName: "Summer Camp for Adults",
    experienceThumbnail: require("./img/experiences/CampForAdults/DSC_0157-1024x686.jpg"),
    location: {
      fullAddress: "3155 Line 13, Bradford, ON L3Z 3P7",
      shortAddress: "Bradford, ON",
      lat: 44.17892,
      long: -79.62217,
    },
    maxGroupSize: 100,
    pricePerPerson: 175,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "retreats"],
  },
  {
    id: "heartwood-farm-experience-erin-ontario",
    experienceName: "Heartwood Farm Experience",
    experienceThumbnail: require("./img/experiences/HeartwoodFarm/Aerial.jpg"),
    location: {
      fullAddress: "5438 Second Line, Erin, ON L7J 2L9",
      shortAddress: "Erin, ON",
      lat: 43.657551,
      long: -80.078781,
    },
    maxGroupSize: 20,
    pricePerPerson: 100,
    experienceDuration: 1, // days
    experienceTypes: ["team building"],
  },
  {
    id: "farmstay-retreat-wawarsing-newyork",
    experienceName: "Farmstay Retreat",
    experienceThumbnail: require("./img/experiences/FarmstayRetreat/lundyhomepage15.jpg"),
    location: {
      fullAddress: "895 Lundy Rd, Wawarsing, NY 12489",
      shortAddress: "Hudson Valley, NY",
      lat: 41.81069,
      long: -74.37713,
    },
    maxGroupSize: 13,
    pricePerPerson: 1940,
    experienceDuration: 3, // days
    experienceTypes: ["retreats"],
  },

  {
    id: "startup-summit-kenoza-lake-newyork",
    experienceName: "Startup Summit",
    experienceThumbnail: require("./img/experiences/StartupSummit/2020-07-Kenoza-Hall-Lawrence-Braun-0016-DJI_0333+(2) (1).jpg"),
    location: {
      fullAddress: "5762 NY-52, Kenoza Lake, NY 12750",
      shortAddress: "Kenoza Lake, NY",
      lat: 41.74651,
      long: -74.956741,
    },
    maxGroupSize: 22,
    pricePerPerson: 1485,
    experienceDuration: 3, // days
    experienceTypes: ["team building", "retreats"],
  },
  {
    id: "executive-leadership-retreat-amenia-newyork",
    experienceName: "Executive Leadership Retreat",
    experienceThumbnail: require("./img/experiences/ExecutiveLeadershipRetreat/5e243c_97c3e5c2bace4e86a6244e09da3de28cf000 (1).jpg"),
    location: {
      fullAddress: "515 Leedsville Rd, Amenia, New York",
      shortAddress: "Amenia, NY",
      lat: 41.85502,
      long: -73.51517,
    },
    maxGroupSize: 10,
    pricePerPerson: 2380,
    experienceDuration: 3, // days
    experienceTypes: ["team meetings", "retreats"],
  },
  {
    id: "sales-kickoff-oliverea-valley-newyork",
    experienceName: "Sales Kickoff",
    experienceThumbnail: require("./img/experiences/SalesKickoff/510d16_1a12a8f721024ecfb2644ebf4f51ffca~mv2.jpg"),
    location: {
      fullAddress: "5088 NY-23, Windham, NY 12496, United States",
      shortAddress: "Oliverea Valley, NY",
      lat: 42.30855,
      long: -74.23037,
    },
    maxGroupSize: 10,
    pricePerPerson: 1650,
    experienceDuration: 3, // days
    experienceTypes: ["sales & marketing"],
  },
  {
    id: "elevate-innovate-lenox-mass",
    experienceName: "Elevate & Innovate",
    experienceThumbnail: require("./img/experiences/ElevateInnovate/Winthrope-estate-2537.jpg"),
    location: {
      fullAddress: "101 Yokun Ave, Lenox, MA 01240",
      shortAddress: "Lenox, MA",
      lat: 42.36399,
      long: -73.29063,
    },
    maxGroupSize: 10,
    pricePerPerson: 1625,
    experienceDuration: 3, // days
    experienceTypes: ["learning & development", "sales & marketing", "team building"],
  },
  {
    id: "product-inspiration-coxsackie-newyork",
    experienceName: "Product Inspiration",
    experienceThumbnail: require("./img/experiences/ProductInspiration/Unobstructed+views+of+canopy+from+Gather+Greene+cabin.jpg"),
    location: {
      fullAddress: "176 Levett Rd, Coxsackie, NY 12051",
      shortAddress: "Coxsackie, NY",
      lat: 42.32452,
      long: -73.88159,
    },
    maxGroupSize: 10,
    pricePerPerson: 1980,
    experienceDuration: 3, // days
    experienceTypes: ["learning & development", "team meetings"],
  },
  {
    id: "team-bonding-windham-newyork",
    experienceName: "Team Bonding",
    experienceThumbnail: require("./img/experiences/TeamBonding/9 (2).jpeg"),
    location: {
      fullAddress: "5088 NY-23, Windham, NY 12496, United States",
      shortAddress: "Oliverea Valley, NY",
      lat: 42.30855,
      long: -74.23037,
    },
    minGroupSize: 4,
    maxGroupSize: 26,
    pricePerPerson: 1570,
    experienceDuration: 3, // days
    experienceTypes: ["team building", "team meetings"],
  },
  {
    id: "revitalize-team-motivation-getaway-milton-newyork",
    experienceName: "Revitalize: Team Motivation Getaway",
    experienceThumbnail: require("./img/experiences/RevitalizeGetaway/Revitalize.png"),
    location: {
      fullAddress: "220 N Rd, Milton, NY 12547, United States",
      shortAddress: "Milton, NY",
      lat: 41.66747085751032,
      long: -73.95720294251487,
    },
    minGroupSize: 3,
    maxGroupSize: 17,
    pricePerPerson: 1980,
    experienceDuration: 3, // days
    experienceTypes: ["team building", "team meetings"],
  },
  {
    id: "leadership-escape-milton-newyork",
    experienceName: "The Leadership Escape",
    experienceThumbnail: require("./img/experiences/LeadershipEscape/Buttermilk-Falls-Inn-Grounds2.jpg"),
    location: {
      fullAddress: "220 N Rd, Milton, NY 12547, United States",
      shortAddress: "Milton, NY",
      lat: 41.66747085751032,
      long: -73.95720294251487,
    },
    minGroupSize: 3,
    maxGroupSize: 17,
    pricePerPerson: 835,
    experienceDuration: 2, // days
    experienceTypes: ["retreats", "learning & development", "team building"],
  },
  {
    id: "design-thinking-offsite-caledon-village-ontario",
    experienceName: "Design Thinking Offsite",
    experienceThumbnail: require("./img/experiences/DesignThinkingOffsite/Mount+Alverno+Luxury+Resorts.jpg"),
    location: {
      fullAddress: "20706 Heart Lake Rd, Caledon Village, ON L7K 2A2",
      shortAddress: "Caledon Village, ON",
      lat: 43.92421819894657,
      long: -80.03849067839779,
    },
    minGroupSize: 5,
    maxGroupSize: 22,
    pricePerPerson: 1830,
    experienceDuration: 3, // days
    experienceTypes: ["retreats", "learning & development", "team meetings"],
  },
  {
    id: "leadership-get-together-caledon-village-ontario",
    experienceName: "Leadership Get Together",
    experienceThumbnail: require("./img/experiences/LeadershipGetTogether/Heartwood+Farm++Cidery+Erin+(15).jpg"),
    location: {
      fullAddress: "20706 Heart Lake Rd, Caledon Village, ON L7K 2A2",
      shortAddress: "Caledon Village, ON",
      lat: 43.92421819894657,
      long: -80.03849067839779,
    },
    minGroupSize: 4,
    maxGroupSize: 22,
    pricePerPerson: 1650,
    experienceDuration: 3, // days
    experienceTypes: ["retreats", "team building"],
  },
  {
    id: "ontario-road-trip-durham-york-region",
    experienceName: "Ontario Road Trip",
    experienceThumbnail: require("./img/experiences/OntarioRoadTrip/beer-flight-brewery-2665077.jpg"),
    location: {
      fullAddress: "York and Durham regions, ON",
      shortAddress: "York and Durham, ON",
      lat: 44.289184,
      long: -79.279259,
    },
    minGroupSize: 1,
    maxGroupSize: 14,
    pricePerPerson: 180,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "team meetings", "sales & marketing"],
  },
  {
    id: "urban-startup-summit-toronto-ontario",
    experienceName: "Urban Startup Summit",
    experienceThumbnail: require("./img/eventPhotos/SoftRains.jpg"),
    location: {
      fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
      shortAddress: "Toronto, ON",
      lat: 43.63685493015649,
      long: -79.4263043031508,
    },
    minGroupSize: 6,
    maxGroupSize: 20,
    pricePerPerson: 780,
    experienceDuration: 5, // days
    experienceTypes: ["team building", "team meetings", "learning & development"],
  },
  {
    id: "distributed-team-offsite-south-frontenac-ontario",
    experienceName: "Distributed Team Offsite",
    experienceThumbnail: require("./img/experiences/DistributedTeamOffsite/IMG_2553.jpg"),
    location: {
      fullAddress: "Loughborough Lake, South Frontenac, ON",
      shortAddress: "South Frontenac, ON",
      lat: 44.452331425506216,
      long: -76.43013633519476,
    },
    minGroupSize: 4,
    maxGroupSize: 20,
    pricePerPerson: 1830,
    experienceDuration: 3, // days
    experienceTypes: ["team building", "team meetings", "retreats"],
  },
  {
    id: "ai-workshop-toronto-ontario",
    experienceName: "AI Workshop",
    experienceThumbnail: require("./img/experiences/AIWorkshop/MustHaveImage.f467ffaaaf1110afb922.jpg"),
    location: {
      fullAddress: "786 King St W, Toronto, ON M5V 1M5",
      shortAddress: "Toronto, ON",
      lat: 43.64344217368745,
      long: -79.4064464,
    },
    minGroupSize: 4,
    maxGroupSize: 50,
    pricePerPerson: 1350,
    experienceDuration: 2, // days
    experienceTypes: ["learning & development"],
  },

  {
    id: "leadership-enhancement-toronto-ontario",
    experienceName: "Leadership Enhancement",
    experienceThumbnail: require("./img/experiences/LeadershipEnhancement/f8fa5384-c072-41b5-afb1-8e62e5ff7784.jpeg"),
    location: {
      fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
      shortAddress: "Toronto, ON",
      lat: 43.63685493015649,
      long: -79.4263043031508,
    },
    minGroupSize: 6,
    maxGroupSize: 20,
    pricePerPerson: 850,
    experienceDuration: 2, // days
    experienceTypes: ["team meetings", "learning & development"],
  },
  {
    id: "island-retreat-toronto-ontario",
    experienceName: "Toronto Island Retreat",
    experienceThumbnail: require("./img/experiences/TorontoIslandRetreat/02d914cae221315a7784826906825c38.jpg"),
    location: {
      fullAddress: "4 Omaha Avenue, Toronto, Ontario M5J 1Z5",
      shortAddress: "Toronto, ON",
      lat: 43.62897103423158,
      long: -79.35790000315109,
    },
    minGroupSize: 4,
    maxGroupSize: 30,
    pricePerPerson: 130,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "retreats"],
  },
  {
    id: "team-glamping-grafton-ontario",
    experienceName: "Team Glamping",
    experienceThumbnail: require("./img/experiences/TeamGlamping/jalsphotography-whisperingspringssp_0040.jpg"),
    location: {
      fullAddress: "141 Mercer Ln, Grafton, ON K0K 2G0",
      shortAddress: "Grafton, ON",
      lat: 44.0469665210862,
      long: -77.94938052883553,
    },
    minGroupSize: 6,
    maxGroupSize: 25,
    pricePerPerson: 1420,
    experienceDuration: 3, // days
    experienceTypes: ["team building", "team meetings", "retreats"],
  },
  {
    id: "nature-wellness-grafton-ontario",
    experienceName: "Nature + Wellness",
    experienceThumbnail: require("./img/experiences/NatureWellness/WhisperingSpringsJune14PromoShoot89.jpg"),
    location: {
      fullAddress: "141 Mercer Ln, Grafton, ON K0K 2G0",
      shortAddress: "Grafton, ON",
      lat: 44.0469665210862,
      long: -77.94938052883553,
    },
    minGroupSize: 6,
    maxGroupSize: 25,
    pricePerPerson: 1150,
    experienceDuration: 3, // days
    experienceTypes: ["team building", "retreats"],
  },
  {
    id: "city-getaway-governors-island-newyork",
    experienceName: "A City Getaway",
    experienceThumbnail: require("./img/experiences/CityGetaway/Great-Shot-IMGP2208-IMGP2210-1.jpg.webp"),
    location: {
      fullAddress: "825 Gresham Rd, New York, NY 10004, United States",
      shortAddress: "Governors Island, NY",
      lat: 40.68916205327216,
      long: -74.02158524012098,
    },
    minGroupSize: 4,
    maxGroupSize: 34,
    pricePerPerson: 890,
    experienceDuration: 2, // days
    experienceTypes: ["team building", "retreats"],
  },
  {
    id: "day-in-soho-newyork",
    experienceName: "A Day in SoHo",
    experienceThumbnail: require("./img/experiences/DayInSoho/Ramen_1_ofyxad.jpg"),
    location: {
      fullAddress: "447 Broadway 2nd floor, New York, NY 10013, United States",
      shortAddress: "Manhattan, NY",
      lat: 40.72060384596152,
      long: -74.00147107408209,
    },
    minGroupSize: 8,
    maxGroupSize: 24,
    pricePerPerson: 320,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "team meetings"],
  },
  {
    id: "active-afternoon-newyork",
    experienceName: "Active Afternoon",
    experienceThumbnail: require("./img/experiences/ActiveAfternoon/IMG_2887.edsmll.jpg"),
    location: {
      fullAddress: "28 Liberty St SC1, New York, NY 10005, United States",
      shortAddress: "Manhattan, NY",
      lat: 40.7075636144756,
      long: -74.00902390819172,
    },
    minGroupSize: 4,
    maxGroupSize: 30,
    pricePerPerson: 370,
    experienceDuration: 1, // days
    experienceTypes: ["team building"],
  },
  {
    id: "town-hall-toronto-ontario",
    experienceName: "Town Hall",
    experienceThumbnail: require("./img/experiences/TownHall/1415033148.png"),
    location: {
      fullAddress: "375 University Ave #102, Toronto, ON M5G 2J5",
      shortAddress: "Toronto, ON",
      lat: 43.6535168085003,
      long: -79.38683333198559,
    },
    minGroupSize: 10,
    maxGroupSize: 75,
    pricePerPerson: 65,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "team meetings", "sales & marketing"],
  },
  {
    id: "team-workshop-toronto-ontario",
    experienceName: "Team Workshop",
    experienceThumbnail: require("./img/experiences/TeamWorkshop/_MIP5540.jpg"),
    location: {
      fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
      shortAddress: "Toronto, ON",
      lat: 43.63685493015649,
      long: -79.4263043031508,
    },
    minGroupSize: 10,
    maxGroupSize: 25,
    pricePerPerson: 260,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "team meetings", "learning & development"],
  },
  {
    id: "finding-common-ground-toronto-ontario",
    experienceName: "Finding Common Ground",
    experienceThumbnail: require("./img/experiences/FindCommonGround/Moshe Mikanovsky Workshop 1.png"),
    location: {
      fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
      shortAddress: "Toronto, ON",
      lat: 43.63685493015649,
      long: -79.4263043031508,
    },
    minGroupSize: 5,
    maxGroupSize: 15,
    pricePerPerson: 410,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "team meetings", "learning & development"],
  },
  {
    id: "work-hard-play-hard-port-jervis-newyork",
    experienceName: "Work Hard, Play Hard",
    experienceThumbnail: require("./img/experiences/WorkHardPlayHard/Amphitheatre-1555x1155-c-default.jpg"),
    location: {
      fullAddress: "1 Team Usa Way, Port Jervis, NY 12771, USA",
      shortAddress: "Port Jervis, NY",
      lat: 41.3463349361544,
      long: -74.64346005767108,
    },
    minGroupSize: 5,
    maxGroupSize: 40,
    pricePerPerson: 2025,
    experienceDuration: 3, // days
    experienceTypes: [
      "team building",
      "team meetings",
      "learning & development",
      "retreats",
      "sales & marketing",
    ],
  },
  {
    id: "hybrid-destination-toronto-ontario",
    experienceName: "Hybrid Destination in Toronto",
    experienceThumbnail: require("./img/experiences/HybridDestination/UNI-Staples_GreatHall_V1_VERT_Ppl_247.jpg"),
    location: {
      fullAddress: "375 University Ave #102, Toronto, ON M5G 2J5",
      shortAddress: "Toronto, ON",
      lat: 43.653726427411954,
      long: -79.38677968465785,
    },
    minGroupSize: 20,
    maxGroupSize: 50,
    pricePerPerson: 65,
    experienceDuration: 1, // days
    experienceTypes: [
      "team building",
      "team meetings",
      "learning & development",
      "retreats",
      "sales & marketing",
    ],
  },
  {
    id: "indoor-outdoor-team-gathering-toronto-ontario",
    experienceName: "Indoor-Outdoor Team Gathering",
    experienceThumbnail: require("./img/experiences/IndoorOutdoorTeamGathering/CORK-TIA_STAPLES-OFFICE-5.jpg"),
    location: {
      fullAddress: "517 Richmond St E, Toronto, ON M5A 2W7",
      shortAddress: "Toronto, ON",
      lat: 43.654157525050024,
      long: -79.3644767,
    },
    minGroupSize: 20,
    maxGroupSize: 50,
    pricePerPerson: 65,
    experienceDuration: 1, // days
    experienceTypes: [
      "team building",
      "team meetings",
      "learning & development",
      "retreats",
      "sales & marketing",
    ],
  },

  {
    id: "team-sushioke-toronto-ontario",
    experienceName: "Team Sushioke",
    experienceThumbnail: require("./img/experiences/Sushioke/JP_025_DSC05632.jpg"),
    location: {
      fullAddress: "14 Logan Ave A, Toronto, ON M4M 2M8",
      shortAddress: "Toronto, ON",
      lat: 43.654645031084584,
      long: -79.34034675767109,
    },
    minGroupSize: 20,
    maxGroupSize: 70,
    pricePerPerson: 50,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "team meetings", "sales & marketing"],
  },

  {
    id: "communication-funday-toronto-ontario",
    experienceName: "Communication Funday",
    experienceThumbnail: require("./img/experiences/CommunicationFunday/Tovit_workshop AMAN.jpeg"),
    location: {
      fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
      shortAddress: "Toronto, ON",
      lat: 43.63685493015649,
      long: -79.4263043031508,
    },
    minGroupSize: 7,
    maxGroupSize: 20,
    pricePerPerson: 700,
    experienceDuration: 1, // days
    experienceTypes: ["team building", "team meetings", "learning & development"],
  },
];

/*

  {
    id: "",
    tagline: "",
    longDescription: "",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "",
        location: {
          fullAddress: "",
          shortAddress: "",
          lat: 43.92421819894657,
          long: -80.03849067839779,
        },
        experienceName: "",
        experienceThumbnail: require("./img/experiences/"),
      },
    ],
    experienceMainImg: require("./img/experiences/"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/DesignThinkingOffsite/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [],
      },
    ],
    experienceOptions: [
      {
        name: "",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "",
        img: require("./img/experiences/"),
        description: "",
      },
    ],
    FAQ: [
      {
        question: "",
        answer: "",
      },
    ],
  },

*/

const experienceDetails: experienceDetailsType[] = [
  {
    id: "design-thinking-offsite-caledon-village-ontario",
    tagline: "Unlocking Innovation: A Design Thinking Offsite Retreat",
    longDescription:
      "An immersive journey into innovation and creativity at our Design Thinking Retreat at Mount Alverno. Nestled amidst Ontario's serene landscapes, this exclusive offsite experience combines luxurious accommodations, farm-to-table cuisine, and engaging sessions led by renowned design thinking expert, Moshe Mikanovsky. Discover new perspectives, hone your problem-solving skills, and unleash your team's creative potential through interactive workshops and hands-on activities. Indulge in a culinary adventure with a cooking class using locally sourced ingredients, and enjoy seamless transportation for a stress-free getaway. Elevate your team's collaboration and drive tangible results in a breathtaking setting that inspires innovation at every turn.",
    priceMessage:
      "Price per team of 10 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "mount-alverno-luxury-resorts",
        location: {
          fullAddress: "20706 Heart Lake Rd, Caledon Village, ON L7K 2A2",
          shortAddress: "Caledon Village, ON",
          lat: 43.92421819894657,
          long: -80.03849067839779,
        },
        experienceName: "Mount Alverno",
        experienceThumbnail: require("./img/experiences/DesignThinkingOffsite/Mount+Alverno+Luxury+Resorts.jpg"),
      },
      {
        id: "heartwood-farm-cidery-erin-ontario",
        location: {
          fullAddress: "5438 Second Line, Erin, ON L7J 2L9",
          shortAddress: "Erin, ON",
          lat: 43.657551,
          long: -80.078781,
        },
        experienceName: "Heartwood Farm & Cidery",
        experienceThumbnail: require("./img/experiences/DesignThinkingOffsite/Aerial.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/DesignThinkingOffsite/Mount+Alverno+Luxury+Resorts.jpg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/DesignThinkingOffsite/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Transportation",
      "Two nights stay in a beautiful property with high end rooms and facilities",
      "Three chef prepared meals a day",
      "100 acres of lush greenery and mature wooded areas",
      "conference rooms and meeting spaces",
      "Design thinking sessions with Moshe Mikanovsky",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: Departure for Mount Alverno with transportation provided.",
          "Afternoon: Arrival at Mount Alverno and lunch. Welcome reception and orientation.",
          "Late Afternoon: Settle into accommodations and enjoy leisure time to explore the grounds or relax.",
          "Evening: Dinner featuring local cuisine and wines.",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: Breakfast at Mount Alverno.",
          "Late Morning: Design thinking sessions with Moshe Mikanovsky ",
          "Lunch: Picnic lunch amidst the natural beauty of Mount Alverno's surroundings.",
          "Afternoon: Team building activities, such as hiking trails around Mount Alverno, designed to foster collaboration and communication.",
          "Evening: Dinner featuring farm-to-table cuisine, showcasing the flavors of the region. Optional bonfire and networking session under the stars.",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: Breakfast at Mount Alverno.",
          "Late Morning: Culinary experience, such as a cooking class using locally sourced ingredients, encouraging teamwork and creativity.",
          "Lunch: Enjoy a delicious lunch prepared during the cooking class.",
          "Afternoon: Reflection and goal-setting session, allowing participants to discuss insights gained from the retreat and plan for future success.",
          "Late Afternoon: Farewell ceremony and closing remarks.",
          "Evening: Departure from Mount Alverno, returning to respective locations with memories and newfound inspiration from the retreat experience.",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Painting Workshop",
        description: "",
      },
      {
        name: "Wine Tasting",
        description: "",
      },
      {
        name: "Trivia Night",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Design thinking",
        img: require("./img/experiences/DesignThinkingOffsite/Moshe Mikanovsky Workshop 1.png"),
        description:
          "Explore the power of design thinking with Moshe Mikanovsky from York University. Dive into interactive sessions where you'll learn practical techniques to solve complex problems creatively. Gain valuable insights and unleash your team's innovation potential in these dynamic workshops.",
      },
      {
        heading: "Hotel stay",
        img: require("./img/experiences/DesignThinkingOffsite/Mount+Alverno+Luxury+Resorts.jpg"),
        description:
          "Escape the hustle and bustle of city life and immerse your team in the tranquility of Mount Alverno. Nestled in Ontario's scenic beauty, the luxurious retreat offers the perfect blend of relaxation and inspiration for leaderships gatherings. With upscale accommodations, farm-to-table cuisine, and breathtaking views, Mount Alverno provides the ideal setting to recharge, bond, and elevate your team's performance.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/DesignThinkingOffsite/12.jpeg"),
        description:
          "At Mount Alverno, every meal is a celebration of local flavors and culinary craftsmanship. The talented chefs transform fresh, seasonal ingredients into delectable dishes that awaken the senses and delight the palate. From farm-fresh produce to artisanal creations, each bite tells a story of Ontario's rich culinary heritage. ",
      },
      {
        heading: "Team building activities",
        img: require("./img/experiences/DesignThinkingOffsite/15.jpeg"),
        description:
          "Explore the beauty of Mount Alverno with exhilarating hikes and engaging team-building activities. Strengthen your team's bonds amidst nature's splendor.",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "Yes, transportation is included in the package and can be removed.",
      },
      {
        question: "What accommodation options are available at the estate?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer: "Yes, guests have the option to work with the hotel and choose their meals.",
      },
      {
        question:
          "What activities are included besides the winery tour and the team building activity on day 3?",
        answer:
          "Guests can hike, explore the trails, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team needs.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer: "Yes, the hotel has conference and meeting rooms..",
      },
      {
        question: "Is Wi-Fi available at the estate?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are 22 rooms. Please contact us for specific inquiries regarding large groups..",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "summer-camp-for-adults-bradford-ontario",
    tagline: "A full day experience at Hollows Camp",
    longDescription:
      "Just one hour north of Toronto, this magic camp setting gets your team out of the city and into nature, where you can reconnect with yourself and others. The camp is a summer gem and brings adults back to their childhood days, playing different sports and activities, having campfire and swimming in the lake.",
    priceMessage: "Transportation not included.",
    experienceLocations: [
      {
        id: "hollows-camp-bradford-ontario",
        location: {
          fullAddress: "3155 Line 13, Bradford, ON L3Z 3P7",
          shortAddress: "Bradford, ON",
          lat: 44.17892,
          long: -79.62217,
        },
        experienceName: "Hollows Camp",
        experienceThumbnail: require("./img/experiences/CampForAdults/DSC_0157-1024x686.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/CampForAdults/DSC_0157-1024x686.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/CampForAdults/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "87 acres of rolling meadow and lush forest",
      "Rustic cabins with accommodation for up to 80 people",
      "Private lake with kayaking, canoeing & beach swimming",
      "Lunch and dinner",
      "Dining Hall with full service catering, customizable vegan & vegetarian meal options",
      "Bonfire pit under the stars",
      "Activities: tennis, archery, trampoline, paintball,yoga",
      "Kilometers of handcrafted trails",
      "In ground Olympic grade trampoline & aerial silk hook ups",
      "Indoor & outdoor yoga shala space",
      "Meditation areas",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Arrive and welcome",
          "Camp activities (split into groups and enjoy tennis, archery, paintballing or even a little murder mystery)",
          "Lunch",
          "Afternoon session (your choice between team meeting, one of our guided sessions, or a second round of activities)",
          "Depart",
          "Customization and transportation options available.",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Charge Your Mind",
        description:
          "Set that out of office, turn off your phone, reconnect with your team and find your center again. Remembering to bring mindfulness into our daily routines can help us become better colleagues, leaders and friends. Many successful people will tell you meditation changed their lives and their ability to concentrate during their non-stop days. Allow The Hollows Camp to remind your team the importance of self-care in their journey toward success, with a day of forest walks, goal-setting meditation and yoga with our in-house, certified teacher.",
      },
      {
        name: "City detox & cleanse",
        description:
          "Are you looking to treat your team to a relaxing day without a lesson plan? Bring them to The Hollows Camp for a total reset. You can swim in the lake, play lawn games or partake in a yoga class, and how about we bring the wine tasting to you? This less structured day allows your employees to organically interact, while unwinding and reconnecting with each other in the soul reviving setting of nature. Don’t worry, for those who prefer a slightly more energetic day, pick-up sports and canoeing are available.",
      },
      {
        name: "Custom",
        description: "Work with us to design the workshop or activity of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Get active",
        img: require("./img/experiences/CampForAdults/Tug-of-War.webp"),
        description:
          "A huge variety of activities that all teams will enjoy, such as archery, kayaking, yoga - even paintball!",
      },
      {
        heading: "Food",
        img: require("./img/experiences/CampForAdults/DSC_0115-1024x686.jpg"),
        description:
          "Hollows Camp promises a delightful dining experience for all campers! The camp offers nutritious and tasty meals, accommodating various dietary needs, from gluten-free to vegan options. BBQ, campfire with s’mores - the camp has it all! ",
      },
      {
        heading: "Step away from business as usual",
        img: require("./img/experiences/CampForAdults/DSC_0157-1024x686.jpg"),
        description:
          "Many teams find that simply getting out of the office (or home office) and meeting their colleagues in a different context creates opportunities for new conversations and relationships. These benefits last long after the camp experience.",
      },
    ],
    FAQ: [
      {
        question: "Can I mix and match camp activities with my own meetings and working sessions?",
        answer:
          "Of course! We have a number of indoor and outdoor settings which are perfect for all-hands meetings, strategy sessions, or whatever else you need.",
      },
      {
        question: "Where do I park?",
        answer:
          "Many teams take advantage of our group rates for bus transportation, but the camp has an extensive on-site parking if you prefer to drive up.",
      },
      {
        question: "What activities are available for day campers?",
        answer:
          "We offer a variety of fun and engaging activities, such as swimming, canoeing, rock climbing, arts & crafts, archery, and more. Reach out for more information!",
      },
      {
        question: "Can you accommodate dietary restrictions?",
        answer:
          "Yes, we provide a nutritious and delicious lunch for all campers, and we can accommodate most dietary restrictions with advance notice.",
      },
      {
        question: "Are there any additional fees for specific activities or equipment rentals?",
        answer:
          "Most activities are included in the day trip fee, but some optional extras, such as specialized equipment rentals or extended activities, may incur additional ",
      },
      {
        question: "What are your policies on refunds or cancellations for day trips?",
        answer:
          "Cancellation policies may vary depending on the specific program or circumstances. Generally, we require advance notice for cancellations, and refunds may be subject to processing fees. Please contact us directly for more details on our cancellation and refund policies.",
      },
    ],
  },
  {
    id: "leadership-get-together-caledon-village-ontario",
    tagline: "There’s no better time and place for planning your next quarter",
    longDescription:
      "A thrilling three-day Executive Leadership Retreat at Mount Alverno in Ontario! Starting at Heartwood Gatherings for a farm experience, then getting to the hotel and enjoying gourmet cuisine, outdoor adventures, a cooking class, and expert coaching. Elevate your leadership game in a breathtaking setting!",
    priceMessage:
      "Price estimate based on a team of 10 people, subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "mount-alverno-luxury-resorts",
        location: {
          fullAddress: "20706 Heart Lake Rd, Caledon Village, ON L7K 2A2",
          shortAddress: "Caledon Village, ON",
          lat: 43.92421819894657,
          long: -80.03849067839779,
        },
        experienceName: "Mount Alverno",
        experienceThumbnail: require("./img/experiences/DesignThinkingOffsite/Mount+Alverno+Luxury+Resorts.jpg"),
      },
      {
        id: "heartwood-farm-cidery-erin-ontario",
        location: {
          fullAddress: "5438 Second Line, Erin, ON L7J 2L9",
          shortAddress: "Erin, ON",
          lat: 43.657551,
          long: -80.078781,
        },
        experienceName: "Heartwood Farm & Cidery",
        experienceThumbnail: require("./img/experiences/DesignThinkingOffsite/Aerial.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/LeadershipGetTogether/Heartwood+Farm++Cidery+Erin+(15).jpg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/LeadershipGetTogether/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Private visit at Heartwood Gatherings",
      "Two nights stay in a beautiful property with high end rooms and facilities",
      "Three chef prepared meals a day",
      "100 acres of lush greenery and mature wooded areas",
      "Conference rooms and meeting spaces",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: Stop at Heartwood Gatherings for a farm experience. Enjoy interacting with farm animals, participate in cider-making workshops, and savor farm-fresh treats.",
          "Afternoon: Depart Heartwood Gatherings and continue journey to Mount Alverno.",
          "Late Afternoon: Check-in at Mount Alverno and settle into accommodations.",
          "Evening: Welcome reception and orientation. Dinner featuring local cuisine and wines. Optional leisure time to explore the grounds or relax.",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: Breakfast at Mount Alverno.",
          "Late Morning: Team building activities, such as hiking trails around Mount Alverno, designed to foster collaboration and communication.",
          "Lunch: Picnic lunch amidst the natural beauty of Mount Alverno's surroundings.",
          "Afternoon: Interactive workshops focused on leadership development or skill-building, facilitated by expert coaches.",
          "Evening: Dinner featuring farm-to-table cuisine, showcasing the flavors of the region. Optional bonfire and networking session under the stars.",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: Breakfast at Mount Alverno.",
          "Late Morning: Culinary experience, such as a cooking class using locally sourced ingredients, encouraging teamwork and creativity.",
          "Lunch: Enjoy a delicious lunch prepared during the cooking class.",
          "Afternoon: Reflection and goal-setting session, allowing participants to discuss insights gained from the retreat and plan for future success.",
          "Late Afternoon: Farewell ceremony and closing remarks.",
          "Evening: Departure from Mount Alverno, returning to respective locations with memories and newfound inspiration from the retreat experience.",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Leadership coaching session",
        description: "",
      },
      {
        name: "Public Speaking workshop",
        description: "A facilitated workshop to level up presenting skills for the whole team.",
      },
      {
        name: "5 Disfunctions of a Team",
        description:
          "A deep dive into common patterns of team disfunction, as well as how to recognize and resolve them.",
      },
    ],
    highlights: [
      {
        heading: "Heartwood Gatherings",
        img: require("./img/experiences/LeadershipGetTogether/Heartwood+Farm++Cidery+Erin+(16).jpg"),
        description:
          "Experience the essence of farm life at Heartwood Gatherings. Immerse yourself in the tranquility of our scenic farm, where lush fields and friendly animals await. Take part in hands-on cider-making workshops, where you'll learn the art of crafting delicious ciders from fresh, hand-picked fruits. Explore the grounds, interact with our farm animals, and savor the unique flavors of our homemade ciders.",
      },
      {
        heading: "Hotel stay",
        img: require("./img/experiences/LeadershipGetTogether/Mount+Alverno+Luxury+Resorts.jpg"),
        description:
          "Escape the hustle and bustle of city life and immerse your team in the tranquility of Mount Alverno. Nestled in Ontario's scenic beauty, the luxurious retreat offers the perfect blend of relaxation and inspiration for leaderships gatherings. With upscale accommodations, farm-to-table cuisine, and breathtaking views, Mount Alverno provides the ideal setting to recharge, bond, and elevate your team's performance.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/LeadershipGetTogether/12.jpeg"),
        description:
          "At Mount Alverno, every meal is a celebration of local flavors and culinary craftsmanship. The talented chefs transform fresh, seasonal ingredients into delectable dishes that awaken the senses and delight the palate. From farm-fresh produce to artisanal creations, each bite tells a story of Ontario's rich culinary heritage.",
      },
      {
        heading: "Team building activities",
        img: require("./img/experiences/LeadershipGetTogether/15.jpeg"),
        description:
          "Explore the beauty of Mount Alverno with exhilarating hikes and engaging team-building activities. Strengthen your team's bonds amidst nature's splendor.",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "No, but can be added upon request.",
      },
      {
        question: "What accommodation options are available at the hotel?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer: "Yes, guests have the option to work with the hotel and choose their meals",
      },
      {
        question: "What activities are included besides Heartwood Gatherings?",
        answer:
          "Guests can hike, explore the trails, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team needs. ",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer: "Yes, the hotel has conference and meeting rooms.",
      },
      {
        question: "Is Wi-Fi available at the hotel?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are 22 rooms. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "ontario-road-trip-durham-york-region",
    tagline: "Discover the road less traveled…",
    longDescription:
      "Private tour from Toronto to explore rural Ontario’s Headwaters, Durham, and York regions. Located just north of Toronto, these regions are filled with small-batch beverage producers, farm-to-fork culinary and agricultural experiences, arts, culture, stories, and lore, which you can experience in one day of a fun road trip!",
    priceMessage: "Price varies based on specific destinations and activities",
    experienceLocations: [
      {
        id: "york-and-durham-regions",
        location: {
          fullAddress: "York and Durham regions, ON",
          shortAddress: "York and Durham, ON",
          lat: 44.289184,
          long: -79.279259,
        },
        experienceName: "Rural Route Tour Co",
        experienceThumbnail: require("./img/experiences/OntarioRoadTrip/garvin-st-villier-14583865-scaled.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/OntarioRoadTrip/beer-flight-brewery-2665077.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/OntarioRoadTrip/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Premium transportation in a cool van!",
      "Fully guided experience",
      "On-board non-alcoholic beverages & light snack",
      "Food (mainly sharables) at stops along the way",
      "Behind-the-scenes look at some of the venues",
      "A super fun day out!",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 am - leave downtown Toronto",
          "10:00 am - 4 pm - tour in Ontario, sample different gems and eat a lot!",
          "4 pm - head back to Toronto",
          "5:30 - back at home!",
          "Customization and transportation options available.",
        ],
      },
    ],

    highlights: [
      {
        heading: "The van",
        img: require("./img/experiences/OntarioRoadTrip/garvin-st-villier-14583865-scaled.jpg"),
        description:
          "You’ll spend your day in this comfortable and spacious van, jumping around between all the cool spots.",
      },
      {
        heading: "Guided experience",
        img: require("./img/experiences/OntarioRoadTrip/Old-Flame-Brewing-Co-Flight.png"),
        description:
          "Experience a guided tour and tasting from small-batch beverage producer; follow the footsteps of Canada’s most prolific writers and artists; savour fine pastries from a backroad bakery, and enjoy picturesque views of Ontario’s most scenic rural landscapes.",
      },
      {
        heading: "Tours",
        img: require("./img/experiences/OntarioRoadTrip/IMG_2048.jpg"),
        description:
          "Choose between brewery tours, maple tours, cidery & distilleries tour or sideroad tours where you get a chance to enjoy the rich history and food of Ontario.",
      },
    ],
    FAQ: [
      {
        question: "How long are the tours?",
        answer:
          "It depends on the tour and the traffic. Public tours range from 7 to 8 hours. Private and custom tours can be tailored to a specified duration.",
      },
      {
        question: "Where do I meet to start the tour?",
        answer:
          "All tours have a central meeting point, with some including additional pick-up points depending on the tour. Custom or private tours will have a single pick-up location that has been determined prior to the tour date.",
      },
      {
        question: "What kind of food can I expect?",
        answer:
          "It depends on the tour stop and the tour! In any case, you can expect to enjoy some delicious culinary creations at one or two stops, along with snacks and non-alcoholic beverages on the van. For those with dietary restrictions and allergies, let us know in advance. We’ll make every effort to accommodate, however please note that due to the nature of the tour stops and their offerings, we may not be able meet all dietary requests.",
      },
      {
        question: "What are your policies on refunds or cancellations for day trips?",
        answer:
          "Cancellation policies may vary depending on the specific program or circumstances. Generally, we require advance notice for cancellations, and refunds may be subject to processing fees. Please contact us directly for more details on our cancellation and refund policies.",
      },
    ],
  },
  {
    id: "urban-startup-summit-toronto-ontario",
    tagline: "A week of work and team building",
    longDescription:
      "A dynamic week in the heart of Toronto, where you’ll be working every day from the vibrant Sorry Studio. Immerse yourself in the bustling city atmosphere as you collaborate and innovate with your peers. Enhance your experience with a cooking class one evening, where you'll learn to create culinary delights, and unwind with a paint workshop on another, allowing for creative expression and relaxation. Don't miss this opportunity to blend work and leisure and enjoy the time with your team.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc. ",
    experienceLocations: [
      {
        id: "sorry-studio-toronto-ontario",
        location: {
          fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
          shortAddress: "Toronto, ON",
          lat: 43.63685493015649,
          long: -79.4263043031508,
        },
        experienceName: "Sorry Studio",
        experienceThumbnail: require("./img/experiences/UrbanStartupSummit/NDK3259.jpeg"),
      },
    ],
    experienceMainImg: require("./img/experiences/UrbanStartupSummit/5e754adae2472b9af4d9945a92283bd8-cover-large.jpg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/UrbanStartupSummit/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Access to Sorry Studio, creative studio and multipurpose space, for the week",
      "Daily working sessions in a dynamic and collaborative environment.",
      "Sushi making workshop",
      "A paint workshop session for creative expression and relaxation",
      "Daily lunch per request",
      "Workshops per request",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: Arrival and check-in at Sorry Studio.",
          "Afternoon: Welcome session and introductions, followed by a collaborative work session.",
          "Evening: Dinner at a local restaurant to kick off the week and get to know each other.",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: Work session at Sorry Studio.",
          "Afternoon: Cooking class experience, where you'll learn to prepare a gourmet meal.",
          "Evening: Enjoy the fruits of your labor with a delicious dinner cooked during the cooking class.",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: Work session at Sorry Studio.",
          "Afternoon: Group outing or activity to explore Toronto's vibrant culture or attractions.",
          "Evening: Free time to unwind or explore the city on your own.",
        ],
      },
      {
        name: "Day 4",
        agendaItems: [
          "Morning: Work session at Sorry Studio.",
          "Afternoon: Paint workshop for creative expression and relaxation.",
          "Evening: Group dinner to share experiences and reflect on the week so far.",
        ],
      },
      {
        name: "Day 5",
        agendaItems: [
          "Morning: Final work session at Sorry Studio to tie up loose ends and finalize projects.",
          "Afternoon: Farewell lunch and closing ceremony to celebrate achievements and say goodbye.",
          "Departure in the afternoon.",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Team building workshop",
        description: "",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Sorry Studio",
        img: require("./img/experiences/UrbanStartupSummit/NDK3176.jpeg"),
        description:
          "Welcome to Sorry Studio, your creative oasis in Liberty Village, Toronto. The 1,200 square foot multipurpose space features 12-foot ceilings, white walls, and large windows, perfect for startup workshops and creative sessions. Equipped with furniture and lighting, plus an outdoor area for added versatility. Unleash your creativity and make magic happen at Sorry Studio.",
      },
      {
        heading: "Sushi making",
        img: require("./img/experiences/UrbanStartupSummit/CAD+PESCATARIAN+KIT.jpeg"),
        description:
          "Roll This Way Sushi is a unique culinary experience that brings the art of sushi-making to your fingertips. Their expert chefs guide you through the intricate process of crafting delicious sushi rolls, from selecting the freshest ingredients to mastering the art of rolling. Whether you're a sushi aficionado or a novice, this hands-on workshop promises an unforgettable journey through the world of sushi, perfect for team building and fun!",
      },
      {
        heading: "Art workshop",
        img: require("./img/experiences/UrbanStartupSummit/f8fa5384-c072-41b5-afb1-8e62e5ff7784.jpeg"),
        description:
          "An art workshop is the ideal way to promote creativity and inject energy into your team. Freehand School of Art's collaborative and individual projects encourage communication, enhance creative problem solving, and facilitate confidence and self-expression. Your team will learn to think outside the box and develop innovative ideas all while reducing stress and cultivating personal connections with their colleagues. Teams can choose from acrylic painting or printmaking and the theme is up to you!",
      },
    ],
    FAQ: [
      {
        question: "Is accommodation included in the package?",
        answer: "No, but can be added upon request.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals, eat at the restaurant or buy their own food",
      },
      {
        question: "What activities are included besides the sushi and painting workshops?",
        answer:
          "In addition to those, guests can engage in team-building activities, workshops, and outdoor games. We’re happy to help setting those!",
      },
      {
        question: "Is Wi-Fi and AV available at the studio?",
        answer: "Yes, Wi-Fi and projector are available",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes, and the studio can fit up to 20 people. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question:
          "Is the sushi workshop suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "distributed-team-offsite-south-frontenac-ontario",
    tagline: "It’s time to meet IRL!",
    longDescription:
      "Unwind and be inspired at the magical Bloom Holistic Retreat. Connect with fellow colleagues while enjoying a guided tour of Little John Farm. Stay in luxurious accommodations, indulge in chef-prepared meals, and strengthen your network during collaborative work sessions. Experience the perfect blend of professional growth and relaxation at this unforgettable countryside retreat.",
    priceMessage:
      "Transportation included. Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "bloom-holistic-retreat-south-frontenac-ontario",
        location: {
          fullAddress: "Loughborough Lake, South Frontenac, ON",
          shortAddress: "South Frontenac, ON",
          lat: 44.452331425506216,
          long: -76.43013633519476,
        },
        experienceName: "Bloom Holistic Retreat",
        experienceThumbnail: require("./img/experiences/DistributedTeamOffsite/IMG_2553.jpg"),
      },
      {
        id: "littlejohn-farm-picton-ontario",
        location: {
          fullAddress: "Littlejohn Farm, 908 County Rd 10, Picton, ON K0K 2T0",
          shortAddress: "Picton, ON",
          lat: 43.97198553568596,
          long: -77.18819293902375,
        },
        experienceName: "Littlejohn Farm",
        experienceThumbnail: require("./img/experiences/DistributedTeamOffsite/87a00c_df65be9bd6ff4189b076c53c96bd66e8~mv2.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/DistributedTeamOffsite/IMG_2553.jpg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/DistributedTeamOffsite/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Transportation from Toronto and back",
      "Two nights stay in a beautiful summer retreat with high end rooms and facilities",
      "Fine dining experience with in house chef",
      "Outdoor lake view seating",
      "Start the morning with Yoga",
      "Communal spaces for work and team building",
      "Endless amount of hiking trails",
      "Culinary experience at Little John Farm",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: Departure from Toronto and travel to Little john Farm",
          "Lunch: Guided tour at Little John Farm, including tasty lunch!",
          "Afternoon: Continue travel to Bloom Holistic Retreat",
          "Evening: Check-in at and welcome reception",
          "Dinner: Fine dining experience with Bloom’s chef",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: Yoga and meditation session overlooking the Lake",
          "Breakfast at the retreat",
          "Late morning: Interactive workshops and panel discussions",
          "Lunch: Farm-to-table picnic lunch at the retreat outdoor seating area",
          "Afternoon: Team building activities or outdoor exploration ",
          "Evening: Hiking",
          "Dinner: Chef's table experience featuring freshly made pizza",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: Yoga and meditation session overlooking the Lake",
          "Breakfast at the retreat",
          "Late morning: Group hike or nature walk in the surrounding area",
          "Noon: start driving back to Toronto, with stop for lunch",
          "Evening: back in Toronto!",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Team building activity",
        description: "",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Culinary Experience",
        img: require("./img/experiences/DistributedTeamOffsite/87a00c_884b3cd564984a1d892c2a12859e11ba~mv2.jpg"),
        description:
          "Discover a culinary journey at Little John Farms, where farm-to-table dining takes center stage. Indulge in exquisite dishes crafted from the freshest local ingredients, harvested right from our own fields. This will be an awesome stop on the way to the retreat! ",
      },
      {
        heading: "Retreat stay",
        img: require("./img/experiences/DistributedTeamOffsite/IMG_2475-Edit.jpg"),
        description:
          "Ignite your offsite adventure with a stay at Bloom Holistic Retreat. Bloom Holistic Retreat is a tranquil wellness sanctuary situated on 681 acres of protected boreal forest in Eastern Ontario, overlooking the serene Loughborough Lake. This alcohol and drug-free venue serves delicious vegetarian cuisine and offers yoga, meditation, Pilates, saunas, and nature walks, ensuring a rejuvenating and restorative experience. With 16 rooms, each featuring its own ensuite, this makes it an ideal destination for those seeking balance, healing, and a deeper connection with nature.",
      },
      {
        heading: "Environment",
        img: require("./img/experiences/DistributedTeamOffsite/IMG_8841.jpg"),
        description:
          "Enjoy outdoor dining on the deck. Sweeping lake views are always included! The culinary team offers a tremendous vegetarian kitchen with a pizza oven just near the lake. ",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer:
          "Yes, transportation from Toronto and back is included in the package. This can be removed.",
      },
      {
        question: "What accommodation options are available at the hotel?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer: "No, guests will enjoy chef-prepared meals. The kitchen is vegetarian.",
      },
      {
        question: "What activities are included besides the farm?",
        answer:
          "In addition to the tour, guests can hike, explore the canoe on the lake, engage in team-building activities, workshops, and outdoor games.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the hotel?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are 16 separate rooms. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available. The retreat is vegetarian and alcohol and smoke free.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "ai-workshop-toronto-ontario",
    tagline: "Bring AI to your product with expert help",
    longDescription:
      "Join us for a two-day immersion at Startwell in Toronto, where innovation meets strategy. Led by experts Ramy Nassar and Professor Alexander Manu, this session integrates AI into your team's roadmap for transformative growth. With Ramy's technology and innovation prowess and Alexander's strategic foresight, unlock new possibilities and chart a course for success in today's dynamic landscape. Don't miss this opportunity to empower your team and drive innovation forward.",
    priceMessage:
      "Cost estimated based on a team of 10 people, and is subject to changes based on food menus, activities etc. ",
    experienceLocations: [
      {
        id: "startwell-toront-ontario",
        location: {
          fullAddress: "786 King St W, Toronto, ON M5V 1M5",
          shortAddress: "Toronto, ON",
          lat: 43.64344217368745,
          long: -79.4064464,
        },
        experienceName: "Startwell",
        experienceThumbnail: require("./img/experiences/AIWorkshop/StartWell-LOFT_Book-meeting-space_Toronto_2023-10.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/AIWorkshop/MustHaveImage.f467ffaaaf1110afb922.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/AIWorkshop/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Two days in a beautiful studio in downtown Toronto",
      "Sessions with Prof. Alexander Manu, innovation advisor in many fortune 500 companies",
      "Sessions with Ramy Nassar, strategic foresight and AI expert",
      "Team building activity in the evening (cooking class / paint workshop)",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 AM - 9:30 AM: Registration and welcome coffee",
          "9:30 AM - 10:30 AM: Introduction session",
          "10:30 AM - 12:30 PM: Strategic foresight and innovation session by Ramy Nassar",
          "12:30 PM - 1:30 PM: Lunch",
          "1:30 PM - 3:30 PM: Group work session - Applying insights from morning session to team projects",
          "3:30 PM - 5:30 PM: Discussion and Q&A session on strategic foresight and innovation",
          "6:30 PM onwards: Optional team building activity - Cooking class or paint workshop (Choose one)",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "9:00 AM - 9:30 AM: Recap and morning coffee",
          "9:30 AM - 11:30 AM: Creativity and product design session by Alexander Manu",
          "11:30 AM - 1:00 PM: Team work session - Applying insights from morning session to refine team projects",
          "1:00 PM - 2:00 PM: Networking lunch",
          "2:00 PM - 4:00 PM: Group presentation and feedback session on refined team projects",
          "4:00 PM - 5:00 PM: Closing remarks and next steps discussion",
          "6:00 PM onwards: Optional networking dinner and socializing event",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Marketing speaker",
        description: "",
      },
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Additional team building activity",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Startwell",
        img: require("./img/experiences/AIWorkshop/StartWell_Event-Studio_03-2023-2-scaled.jpg"),
        description:
          "Bring your team together for a day in this dedicated offsite venue which is the perfect place for working sessions and meetings.The place features lounge space, common work tables plus high tops and conferencing area as well as options for breakout space and flex offices in connected areas on campus. Tons of space for your full team plus its own street entrance for privacy!",
      },
      {
        heading: "AI sessions",
        img: require("./img/experiences/AIWorkshop/Designpartners07_215.2b36b893c00dc842a89e (1).jpg"),
        description:
          "Dive into the future with AI experts Ramy Nassar and Alexander Manu. In this exclusive session, explore the transformative power of AI guided by industry pioneers. With Ramy's expertise in emerging technology and strategic foresight and Alexander's innovative insights, discover how AI can revolutionize your business. Join us for an immersive experience that will inspire, enlighten, and propel your organization forward into the digital age.",
      },
      {
        heading: "Team building activity",
        img: require("./img/experiences/UrbanStartupSummit/f8fa5384-c072-41b5-afb1-8e62e5ff7784.jpeg"),
        description:
          "Unleash creativity and strengthen bonds with our team-building activities. Choose between a hands-on cooking class or a lively paint workshop, where collaboration and camaraderie take center stage. Whether you're crafting culinary delights or unleashing your artistic flair, these engaging experiences will inspire teamwork and foster connections that last long after the session ends. ",
      },
    ],
    FAQ: [
      {
        question: "What accommodation options are available?",
        answer:
          "This experience doesn’t include accommodation, but accommodations in downtown Toronto can be added upon request",
      },
      {
        question: "What food options are available?",
        answer:
          "Guests have the option to enjoy chef-prepared meals, prepare their own food, or order in. We’re happy to help with any choice!",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests will choose between cooking class and painting session, but this can be customized upon request",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, Startwell is equipped with whiteboards and TVs that are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their sessions.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes. Startwell can accommodate up to 75 people comfortably. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question:
          "Is the experience suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "leadership-enhancement-toronto-ontario",
    tagline: "Prepare your management for the challenges of the future",
    longDescription:
      "Join us for a two-day journey at Sorry Studio in Toronto, where leadership meets strategic planning. Guided by renowned experts Erin Skimson and Kevin Lau, this experience seamlessly integrates leadership coaching and goal-setting into your team's trajectory, fostering transformative growth. Harnessing Erin's expertise in leadership development and Kevin's strategic acumen, unlock new possibilities and navigate the ever-evolving landscape with confidence. Seize this opportunity to empower your team and drive innovation forward.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "sorry-studio-toronto-ontario",
        location: {
          fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
          shortAddress: "Toronto, ON",
          lat: 43.63685493015649,
          long: -79.4263043031508,
        },
        experienceName: "Sorry Studio",
        experienceThumbnail: require("./img/experiences/UrbanStartupSummit/NDK3259.jpeg"),
      },
    ],
    experienceMainImg: require("./img/experiences/LeadershipEnhancement/f8fa5384-c072-41b5-afb1-8e62e5ff7784.jpeg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/LeadershipEnhancement/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Two days in a beautiful studio in downtown Toronto",
      "Sessions with Erin Skimson, leadership development expert",
      "Sessions with Kevin Lau, strategic expert",
      "Team building activity in the evening (cooking class / paint workshop)",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 AM - 9:30 AM: Registration and welcome coffee",
          "9:30 AM - 10:30 AM: Introduction session",
          "10:30 AM - 12:30 PM: The Five Dysfunctions of a Team workshop by Erin Skimson",
          "12:30 PM - 1:30 PM: Lunch",
          "1:30 PM - 3:30 PM: Group work session - Applying insights from morning session to team projects",
          "3:30 PM - 5:30 PM: Leadership coaching and development session by Erin Skimson",
          "5:30 PM onwards: Optional team building activity - Cooking class or paint workshop (Choose one)",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "9:00 AM - 9:30 AM: Recap and morning coffee",
          "9:30 AM - 11:30 AM:  OKR and goal-setting workshop by Kevin Lau",
          "11:30 AM - 1:00 PM: Team work session - Applying insights from morning session to refine team projects",
          "1:00 PM - 2:00 PM: Lunch",
          "2:00 PM - 4:00 PM: Group presentation and feedback session on refined team projects",
          "4:00 PM - 5:00 PM: Closing remarks and next steps discussion",
          "6:00 PM onwards: Optional networking dinner and socializing event",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Marketing speaker",
        description: "",
      },
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Additional team building activity",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Sorry Studio",
        img: require("./img/experiences/LeadershipEnhancement/NDK3214.jpeg"),
        description:
          "Welcome to Sorry Studio, your creative oasis in Liberty Village, Toronto. The 1,200 square foot multipurpose space features 12-foot ceilings, white walls, and large windows, perfect for startup workshops and creative sessions. Equipped with furniture and lighting, plus an outdoor area for added versatility. Unleash your creativity and make magic happen at Sorry Studio.",
      },
      {
        heading: "Leadership sessions",
        img: require("./img/experiences/LeadershipEnhancement/ErinSkimson.69eab3e2113a5f281c95.jpeg"),
        description:
          "Embark on a journey into the future with leadership and strategy experts Erin Skimson and Kevin Lau. In this exclusive session, delve into the transformative power of leadership coaching and goal-setting guided by industry leaders. With Erin's expertise in leadership development and Kevin's strategic acumen, explore how these elements can revolutionize your business. Join us for an immersive experience that will empower, enlighten, and drive your organization forward into a new era of success.",
      },
      {
        heading: "Team building activity",
        img: require("./img/experiences/LeadershipEnhancement/image0.jpeg"),
        description:
          "Unleash creativity and strengthen bonds with our team-building activities. Choose between a hands-on cooking class or a lively paint workshop, where collaboration and camaraderie take center stage. Whether you're crafting culinary delights or unleashing your artistic flair, these engaging experiences will inspire teamwork and foster connections that last long after the session ends. ",
      },
    ],
    FAQ: [
      {
        question: "What accommodation options are available?",
        answer:
          "This experience doesn’t include accommodation, but accommodations in downtown Toronto can be added upon request.",
      },
      {
        question: "What food options are available?",
        answer:
          "Guests have the option to enjoy chef-prepared meals, prepare their own food, or order in. We’re happy to help with any choice!",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests will choose between cooking class to painting session, but this can be customized upon request.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, Sorry Studio is equipped with projector, Wi-Fi and everything needed for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their sessions.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes. Sorry Studio can accommodate up to 20 people comfortably. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question:
          "Is the experience suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "island-retreat-toronto-ontario",
    tagline: "Open air oasis for summer get together",
    longDescription:
      "Escape the hustle and bustle of the city with a rejuvenating day at Toronto Island Retreat. Nestled in the tranquil oasis of Toronto Island, this retreat offers a refreshing break from the daily grind. Immerse yourself in a day of wellness and productivity, starting with a healthy and fresh food experience to fuel your body and mind. Engage in invigorating yoga sessions to center yourself and prepare for a day of productive meetings and activities on the island. With the freedom to breathe in the fresh air and soak in the natural beauty of the surroundings, Toronto Island Retreat provides the perfect backdrop for collaboration, creativity, and team bonding. Treat yourself and your team to a day of relaxation and rejuvenation amidst the serene landscape of Toronto Island.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "toronto-island-oasis",
        location: {
          fullAddress: "4 Omaha Avenue, Toronto, Ontario M5J 1Z5",
          shortAddress: "Toronto, ON",
          lat: 43.62897103423158,
          long: -79.35790000315109,
        },
        experienceName: "Toronto Island Oasis",
        experienceThumbnail: require("./img/experiences/TorontoIslandRetreat/02d914cae221315a7784826906825c38.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/TorontoIslandRetreat/02d914cae221315a7784826906825c38.jpg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/TorontoIslandRetreat/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Rental of the venue for the day",
      "Fresh and healthy food throughout the day",
      "Workshops in a serene environment",
      "Optional workshops, team building activities and yoga session",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 am - leave downtown Toronto",
          "09:30 am - 11 am - welcome and breakfast",
          "11 am - 11 pm - team sessions",
          "1 pm - 2 pm - lunch with a guest speaker",
          "2 pm to 5 pm - team building activities in the island",
          "5:30 - head back home!",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Team building workshop",
        description: "",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Toronto Island Retreat",
        img: require("./img/experiences/TorontoIslandRetreat/41d43efb3d730d9a4c98d302439ebc7b.jpg"),
        description:
          "Open-air studio immersed in lush gardens & trees, with access to beaches, meadows, parks & trails. A short sailboat from downtown Toronto, and your team will find themselves in this oasis.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/TorontoIslandRetreat/8e811a9103134c4feb626384aa82bc45.jpg"),
        description:
          "Guests will start with a Welcome Spread, a variety of healthy lighter foods and beverages. Later on is the main meal, where everyone sits down to enjoy a meal - everything is nutritionist-designed and chef prepared with fresh ingredients.",
      },
      {
        heading: "Team Building Activities",
        img: require("./img/experiences/TorontoIslandRetreat/6c4609524b20f00c0644e612dc595eb3.jpg"),
        description:
          "Choose from team building activities on the island, yoga session or other workshops for a day of wellness and relaxation",
      },
    ],
    FAQ: [
      {
        question: "Is accommodation included in the package?",
        answer: "No, but can be added upon request.",
      },
      {
        question: "Can we choose our meals?",
        answer: "Yes, guests have the option to customize their menu",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can engage in team-building activities, workshops, and outdoor games. We’re happy to help set those up!",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes up to 35 people. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "team-glamping-grafton-ontario",
    tagline: "Getting away with the colleagues",
    longDescription:
      "Embark on a luxurious three-day retreat at Whispering Spring, nestled in the serene countryside. Escape the hustle and bustle of everyday life and immerse yourself in nature's tranquility. Our package includes transportation to and from the retreat, ensuring a seamless journey for you and your team. Experience the epitome of glamping with our upscale tents, providing the perfect blend of comfort and adventure. Engage in tailored team building activities amidst the picturesque surroundings, fostering collaboration and camaraderie. Relax and rejuvenate in the tranquil ambiance of Whispering Spring, where the whispers of nature will inspire and invigorate your spirit. Unwind, connect, and create lasting memories with your team on this unforgettable retreat.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc. ",
    experienceLocations: [
      {
        id: "whispering-springs-grafton-ontario",
        location: {
          fullAddress: "141 Mercer Ln, Grafton, ON K0K 2G0",
          shortAddress: "Grafton, ON",
          lat: 44.0469665210862,
          long: -77.94938052883553,
        },
        experienceName: "Whispering Springs",
        experienceThumbnail: require("./img/experiences/TeamGlamping/20180520-WhisperingSprings-promo-0159+copy.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/TeamGlamping/jalsphotography-whisperingspringssp_0040.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/TeamGlamping/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Transportation: Enjoy comfortable transportation to and from Toronto, ensuring a hassle-free journey.",
      "Glamping accommodation: Experience two nights of luxury in high-end tents equipped with modern facilities and amenities.",
      "Catered dining: Indulge in delicious catered meals prepared with locally-sourced ingredients, offering a taste of the region's culinary delights.",
      "Outdoor seating with Lake Views: Relax and unwind in the picturesque outdoor seating areas overlooking the tranquil lake.",
      "Communal workspaces: Collaborate and bond with your team in spacious communal areas designed for work and team building activities.",
      "Hiking trails: Explore endless hiking trails surrounding the retreat, perfect for outdoor enthusiasts and nature lovers.",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:30 - Departure from Toronto",
          "12:00 PM - 1:00 PM: Arrival and check-in",
          "1:00 PM - 2:00 PM: Welcome orientation and introduction to the retreat",
          "2:00 PM - 3:00 PM: Gourmet picnic lunch by the lake",
          "3:00 PM - 5:00 PM: Guided nature walk exploring the surrounding trails",
          "6:00 PM onwards: Campfire social with s'mores and storytelling",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "8:00 AM - 9:00 AM: Sunrise yoga session by the lake",
          "9:00 AM - 10:00 AM: Breakfast buffet",
          "10:00 AM - 12:00 PM: Team building activities in communal spaces",
          "12:00 PM - 1:00 PM: Catered lunch at the campsite",
          "1:00 PM - 3:00 PM: Outdoor team building challenge course",
          "3:00 PM - 5:00 PM: Relaxation time or optional activities such as kayaking or volleyball",
          "6:00 PM onwards: Evening bonfire with live acoustic music",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "8:00 AM - 9:00 AM: Breakfast buffet",
          "9:00 AM - 11:00 AM: Guided hike to explore nearby trails and scenic viewpoints",
          "11:00 AM onwards: Departure and check-out",
          "2:00 pm - Back in Toronto",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Live music",
        description: "",
      },
      {
        name: "Distillery visit",
        description: "",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Accommodation",
        img: require("./img/experiences/TeamGlamping/20180520-WhisperingSprings-promo-0180.jpg"),
        description:
          "Indulge in the epitome of luxury amidst the tranquil countryside setting of Whispering Spring, with a unique blend of comfort and adventure with high-end tents that redefine the glamping experience. Each tent is meticulously designed to provide a cozy retreat, complete with modern amenities and plush furnishings, ensuring a restful night's sleep under the stars. Wake up to the soothing sounds of nature and bask in the serene ambiance of your surroundings. ",
      },
      {
        heading: "Food",
        img: require("./img/experiences/TeamGlamping/whisperingsprings-113.jpg"),
        description:
          "Our catered meals, prepared with locally-sourced ingredients, offer a delightful fusion of flavors that celebrate the essence of the countryside. Picture yourself gathered around the crackling fire pit, roasting marshmallows for s'mores as the sun sets over the tranquil landscape. ",
      },
      {
        heading: "Environment",
        img: require("./img/experiences/TeamGlamping/20180520-WhisperingSprings-promo-0145.jpg"),
        description:
          "Experience the harmonious blend of nature and luxury at Whispering Spring. Nestled amidst the serene countryside, the camp is a sanctuary of tranquility where lush greenery meets modern comfort.. With sprawling grounds and endless vistas, Whispering Spring offers the perfect backdrop for relaxation, exploration, and rejuvenation amidst the beauty of the great outdoors.",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer:
          "Yes, transportation from Toronto and back is included in the package. This can be removed.",
      },
      {
        question: "What accommodation options are available at the hotel?",
        answer:
          "Guests will stay in high-end tents with modern facilities to ensure comfort during their retreat. Most rooms have an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer: "Guests can choose between catered meals and buying their own food.",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can enjoy a saltwater pool, sauna, hiking, playing volleyball and lawn games and many more activities",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this site, there are multiple tents that can fit up to 20 people. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available. The retreat is vegetarian and alcohol and smoke free. ",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "nature-wellness-grafton-ontario",
    tagline: "Enjoy nature with your team",
    longDescription:
      "Embark on a luxurious three-day retreat at Whispering Spring, nestled in the serene countryside. Escape the hustle and bustle of everyday life and immerse yourself in nature's tranquility. Experience the epitome of glamping with our upscale tents, providing the perfect blend of comfort and adventure. Engage in tailored team building activities amidst the picturesque surroundings, fostering collaboration and camaraderie. Relax and rejuvenate in the tranquil ambiance of Whispering Spring, where the whispers of nature will inspire and invigorate your spirit. Enjoy spa treatment including massages and Reiki. Unwind, connect, and create lasting memories with your team on this unforgettable retreat.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc. ",
    experienceLocations: [
      {
        id: "whispering-springs-grafton-ontario",
        location: {
          fullAddress: "141 Mercer Ln, Grafton, ON K0K 2G0",
          shortAddress: "Grafton, ON",
          lat: 44.0469665210862,
          long: -77.94938052883553,
        },
        experienceName: "Whispering Springs",
        experienceThumbnail: require("./img/experiences/TeamGlamping/20180520-WhisperingSprings-promo-0159+copy.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/NatureWellness/WhisperingSpringsJune14PromoShoot89.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/NatureWellness/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Glamping accommodation: Experience three nights of luxury in high-end tents equipped with modern facilities and amenities.",
      "Catered dining: Indulge in delicious catered meals prepared with locally-sourced ingredients, offering a taste of the region's culinary delights.",
      "Outdoor seating with pond Views: Relax and unwind in the picturesque outdoor seating areas.",
      "Communal workspaces: Collaborate and bond with your team in spacious communal areas designed for work and team building activities.",
      "Pizza night and s’mores bar!",
      "Massages and Reiki for the team",
      "Cooking class on the way back home",
      "Hiking trails: Explore endless hiking trails surrounding the retreat, perfect for outdoor enthusiasts and nature lovers",
      "Endless amount of Team building activities ",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:30 - Departure from Toronto",
          "12:00 PM - 1:00 PM: Arrival and check-in",
          "1:00 PM - 2:00 PM: Welcome orientation and introduction to the retreat",
          "2:00 PM - 3:00 PM: Gourmet picnic lunch by the lake",
          "3:00 PM - 5:00 PM: Guided nature walk exploring the surrounding trails",
          "6:00 PM onwards: Campfire social with s'mores and storytelling",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "8:00 AM - 9:00 AM: Sunrise yoga session by the lake",
          "9:00 AM - 10:00 AM: Breakfast buffet",
          "10:00 AM - 12:00 PM: Team building activities in communal spaces",
          "12:00 PM - 1:00 PM: Catered lunch at the campsite",
          "1:00 PM - 3:00 PM: Outdoor team building challenge course",
          "3:00 PM - 5:00 PM: personal massage and Reiki",
          "6:00 PM onwards: Evening bonfire with live acoustic music",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "8:00 AM - 9:00 AM: Breakfast buffet",
          "9:00 AM - 11:00 AM: Guided hike to explore nearby trails and scenic viewpoints",
          "11:00 AM onwards: Departure and check-out",
          "1:00 pm: Cooking class on the way back home + lunch",
          "4:00 pm - Back in Toronto",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Outdoor yoga",
        description: "",
      },
      {
        name: "Fire meditation",
        description: "",
      },
      {
        name: "Equine experience",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Accommodation",
        img: require("./img/experiences/NatureWellness/070A8999.jpg"),
        description:
          "Indulge in the epitome of luxury amidst the tranquil countryside setting of Whispering Spring, with a unique blend of comfort and adventure with high-end tents that redefine the glamping experience. Each tent is meticulously designed to provide a cozy retreat, complete with modern amenities and plush furnishings, ensuring a restful night's sleep under the stars. Wake up to the soothing sounds of nature and bask in the serene ambiance of your surroundings. ",
      },
      {
        heading: "Spa",
        img: require("./img/experiences/NatureWellness/11.png"),
        description:
          "At Whispering Springs Spa, there is an harmonious blend of traditional massage therapies and holistic energy healing practices like Reiki. The team consists of expert therapists who combine the therapeutic benefits of massage techniques with the gentle touch of Reiki to promote balance, harmony, and deep relaxation. ",
      },
      {
        heading: "Environment",
        img: require("./img/experiences/NatureWellness/WhisperingSprings2018-69.jpg"),
        description:
          "Experience the harmonious blend of nature and luxury at Whispering Spring. Nestled amidst the serene countryside, the camp is a sanctuary of tranquility where lush greenery meets modern comfort. With sprawling grounds and endless vistas, Whispering Spring offers the perfect backdrop for relaxation, exploration, and rejuvenation amidst the beauty of the great outdoors.",
      },
      {
        heading: "Sushi making ",
        img: require("./img/experiences/NatureWellness/CAD_PESCATARIAN_KIT_1400x.progressive (1).jpg"),
        description:
          "On the way back home, stop by at Roll This Way Sushi for a unique culinary experience that brings the art of sushi-making to your fingertips. Their expert chefs guide you through the intricate process of crafting delicious sushi rolls, from selecting the freshest ingredients to mastering the art of rolling. Whether you're a sushi aficionado or a novice, this hands-on workshop promises an unforgettable journey through the world of sushi, perfect for team building and fun!",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "No, but can be added upon request.",
      },
      {
        question: "What accommodation options are available at the retreat?",
        answer:
          "Guests will stay in high-end tents with modern facilities to ensure comfort during their retreat. Most rooms have an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer: "Guests can choose between catered meals and buying their own food.",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can enjoy a saltwater pool, sauna, hiking, playing volleyball and lawn games and many more activities. Additionally, guests will enjoy the spa and a personal massage and cooking class - both can be removed. ",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this site, there are multiple tents that can fit up to 20 people. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available. The retreat is vegetarian and alcohol and smoke free. ",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "heartwood-farm-experience-erin-ontario",
    tagline: "Make your next gathering truly remarkable",
    longDescription: [
      "Heartwood is a regenerative farm with fresh air, good soil, healthy plants and animals, and a lively kitchen table. It offers a place to reconnect with your team and deepen the bonds that sustain happy and productive workplaces. There are innumerable studies proving that time spent in nature has a positive effect on people, but there are also boundless lessons to be learned from the living systems of the natural world. At Heartwood, we live alongside these natural principles that are so desirable in professional organizations. A farm is a great place to observe them in action.",
      "An offsite event at Heartwood can be centered around a variety of seasonally-appropriate farm activities such as making maple syrup or delicious ciders, or even milking cows. Or, it can be a venue and a backdrop for activities that you choose to conduct, such as leadership meetings in our 26' Mongolian yurt or the expansive woodland patio (supported by fiber-backed wifi, of course). Some teams enjoy blending their own agenda with ouractivities, and find that time at Heartwood helps people connect to land, plants and animals in a way that opens new insights.",
    ],
    priceMessage:
      "Price per team of up to 20 people, subject to change based on food menus, activities etc. Transportation not included.",
    experienceLocations: [
      {
        id: "heartwood-farm-cidery-erin-ontario",
        location: {
          fullAddress: "5438 Second Line, Erin, ON L7J 2L9",
          shortAddress: "Erin, ON",
          lat: 43.657551,
          long: -80.078781,
        },
        experienceName: "Heartwood Farm & Cidery",
        experienceThumbnail: require("./img/experiences/DesignThinkingOffsite/Aerial.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/HeartwoodFarm/Aerial.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/HeartwoodFarm/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Access to powered workspaces with high-speed wifi",
      "Locally-sourced prepared meal",
      "Farm-focused team activity Working sessions (bring your own agenda)",
      "(Optional) Workshop with a leading expert",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:30 am - arrive and welcome",
          "10:00 - farm tour",
          "11:00 - seasonal activity (e.g. making maple syrup, making delicious ciders, milking a cow, or interacting with the livestock)",
          "1:00pm - lunch",
          "2:30 - afternoon session (your choice between team meeting, workshop, or land-based farm activities)",
          "5:00 - depart",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Team building workshop",
        description: "",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Time to connect",
        img: require("./img/experiences/HeartwoodFarm/firepit.jpg"),
        description:
          "Modern workstyle can be isolating. Heartwood offers a place to reconnect with your team and deepen the bonds that sustain happy and productive workplaces",
      },
      {
        heading: "Step away from business as usual",
        img: require("./img/experiences/HeartwoodFarm/applepressing.jpg"),
        description:
          "Engage as a team with seasonal farm operations, enjoy our setting for your own goals, or combine both into an unique and unforgettable experience.",
      },
      {
        heading: "A natural connection",
        img: require("./img/experiences/HeartwoodFarm/HeartwoodFarmCideryErin.jpg"),
        description:
          "Heartwood is a working farm and we’re happy to share. We love to educate and share details about this setting - often there are lessons that continue to resonate off the farm.",
      },
    ],
    FAQ: [
      {
        question:
          "I’m looking for an interesting setting for a team meeting. Can we do this at Heartwood?",
        answer:
          "For sure. We know that unique settings, particularly those connected with nature, can shift a group’s focus and produce different results than you’d get if you just met at the office.",
      },
      {
        question: "What kind of food do you provide?",
        answer:
          "For our Team Retreats, we serve lunches made with local, seasonal food with a story. Meals are prepared by local caterers who cook our shared values into every morsel. Please note that we do our best to accommodate special dietary needs.",
      },
      {
        question: "You're all about nature, but what about technology?",
        answer:
          "We understand the importance of technology even in the context of ecological retreats. In fact, we now have Fibre Optic internet at Heartwood, which is accessible via Wifi from any of our meeting places. And despite the rustic charm of our facilities, the meeting places are fully powered, so no problem connecting laptops, monitors, and even zooming in your colleagues from across the globe.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "city-getaway-governors-island-newyork",
    tagline: "A quick boat ride from Manhattan",
    longDescription:
      "Embark on a unique overnight escape at Collective Governors Island. Begin your day with a breakfast in Lower Manhattan before a quick boat ride transports you to this tranquil oasis. Engage in team building activities, enjoy nightly s'mores, and unwind in luxury accommodations with stunning harbor views. Wake up to morning yoga and explore the island's attractions before sailing back to Manhattan in the afternoon. Experience relaxation and adventure like never before in the heart of New York Harbor.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc. ",
    experienceLocations: [
      {
        id: "collective-retreats-governors-island-newyork",
        location: {
          fullAddress: "825 Gresham Rd, New York, NY 10004, United States",
          shortAddress: "Governors Island, NY",
          lat: 40.68916205327216,
          long: -74.02158524012098,
        },
        experienceName: "Collective Retreats",
        experienceThumbnail: require("./img/experiences/CityGetaway/sunset_terrace.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/CityGetaway/Great-Shot-IMGP2208-IMGP2210-1.jpg.webp"),
    experienceImgs: importAll(
      require.context("./img/experiences/CityGetaway/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Boat transportation to Collective Governors Island",
      "Team building activities and communal lawn games",
      "Nightly s'mores by the campfire",
      "Morning yoga sessions",
      "Optional island activities like scavenger hunts, walking tours, bike rentals",
      "Unique food and beverage offerings rooted in the island's scenic backdrop",
      "Luxury accommodations with full electricity, temperature control, and thoughtful amenities",
      "Stunning harbor views from suites and tents",
      "Afternoon sail back to Manhattan",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:30 am: Breakfast at waterfront restaurant in Lower Manhattan",
          "11:00 am: Board boat transportation to Collective Governors Island",
          "12:00 pm: Arrival and lunch",
          "1:00 pm - 3:00 pm: Team building activities and games",
          "3:00 pm - 6:00 pm: Team sessions",
          "6:00 pm: Dinner with unique food offerings",
          "8:00 pm: Nightly s'mores gathering by the campfire",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "8:00 am: Morning yoga session",
          "9:00 am: Breakfast at your leisure",
          "10:00 am - 12:00 pm: Optional island excursion (scavenger hunt, walking tour, bike rental)",
          "1:00 pm: Lunch with scenic backdrop",
          "2:00 pm: Departure via boat transportation back to Lower Manhattan",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "5 Disfunctions of a Team",
        description:
          "A deep dive into common patterns of team disfunction, as well as how to recognize and resolve them.",
      },
      {
        name: "Design thinking",
        description: "A facilitated workshop to elevate product development focus within the team.",
      },
      {
        name: "Offsite facilitation",
        description:
          "Elevate your offsite experience with an offsite facilitator focused on creating transformational moments within team offsites. The facilitator will guide discussions around team building, problem solving, and leveraging skills on the team.",
      },
    ],
    highlights: [
      {
        heading: "Hotel",
        img: require("./img/experiences/CityGetaway/CGI-Summit_Tent_exterior.webp"),
        description:
          "Collective Governors Island offers a serene escape just a quick boat ride away from Lower Manhattan. With breathtaking views of the New York Harbor and city skyline, this oasis boasts 34 luxury guest tents, indoor/outdoor event spaces, and unique food & beverage offerings. Enjoy a blend of relaxation and adventure amidst an expansive outdoor setting, perfect for team building activities, nightly s'mores, and morning yoga sessions. Explore the island's attractions, including scavenger hunts, walking tours, and bike rentals, for an unforgettable experience in the heart of the harbor.",
      },
      {
        heading: "Rooms",
        img: require("./img/experiences/CityGetaway/Summit_Tent-_Interior.webp"),
        description:
          "At Collective Governors Island, choose from a variety of luxury accommodations tailored to your preferences. The Suites and Outlook Shelters feature king beds and private bathrooms, offering a serene retreat with stunning harbor views. For a cozy getaway, the Journey Tents provide either a king bed or double twin beds, with shared bathrooms conveniently located nearby. Each room is equipped with full electricity, temperature control, and thoughtful amenities to ensure a comfortable and rejuvenating overnight experience amidst the beauty of New York Harbor.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/CityGetaway/2021_25-medium.jpg.webp"),
        description:
          "Indulge in a culinary journey like no other at Collective Governors Island. The unique food and beverage offerings are rooted in the island's scenic backdrop, providing a delightful blend of flavors and experiences. Whether you're savoring a gourmet meal overlooking the New York Harbor or enjoying a cozy evening under the stars with campfire s'mores, each dining experience promises to tantalize your taste buds and leave you craving more. With a focus on fresh, locally sourced ingredients and creative dishes, dining at Collective Governors Island is an unforgettable experience for all senses.",
      },
      {
        heading: "Team Building Activities",
        img: require("./img/experiences/CityGetaway/great_lawn_games.jpg"),
        description:
          "Engage in dynamic team building activities amidst the picturesque surroundings of Collective Governors Island. From communal lawn games that foster friendly competition to nightly s'mores gatherings around the campfire that encourage bonding and camaraderie, our activities are designed to strengthen teamwork, communication, and collaboration. Whether you're participating in outdoor challenges or enjoying morning yoga sessions to invigorate the mind and body, each activity offers a unique opportunity for your team to connect, grow, and thrive in the tranquil setting of New York Harbor.",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer:
          "No, the ferry ride to Governors Island is 8 minutes, departing from the Battery Maritime Building on 10 South Street in lower Manhattan (click here to view ferry schedule).",
      },
      {
        question: "What accommodation options are available at the island?",
        answer:
          "The Island offers a variety of accommodations, including Suites, Outlook Shelters, Summit Tents, and Journey Tents. All rooms include full electricity, temperature control, and amenities for a comfortable stay. Suites and Outlook Shelters feature private bathrooms, while Summit Tents offer both private and shared options. ",
      },
      {
        question: "Can we choose our meals?",
        answer: "Yes, guests can choose from multiple catering options",
      },
      {
        question: "What activities are included besides team building activity on the first day?",
        answer:
          "Guests can hike, explore the trails, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team needs. ",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, there is a meeting space available for rent that includes Wi-Fi and an LCD screen with video conferencing capabilities",
      },
      {
        question: "Is Wi-Fi available at the hotel?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are 34 rooms. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "day-in-soho-newyork",
    tagline: "A team get-together in SoHo - how about that?!",
    longDescription:
      "Experience 'A Day in SoHo' with our exclusive package, combining business and culinary delights in the heart of New York City. Begin with a productive team meeting at Farm SoHo, followed by an evening cooking class at Home Cooking NY. Bond over business strategies in the vibrant atmosphere of SoHo, then indulge in a hands-on culinary adventure, creating delicious dishes together. This package promises a perfect blend of work and leisure, fostering team collaboration and unforgettable experiences.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "the-farm-soho-NYC",
        location: {
          fullAddress: "447 Broadway 2nd floor, New York, NY 10013, United States",
          shortAddress: "Manhattan, NY",
          lat: 40.72060384596152,
          long: -74.00147107408209,
        },
        experienceName: "The Farm SoHo NYC",
        experienceThumbnail: require("./img/experiences/DayInSoho/confroom-3.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/DayInSoho/Cooking-class-in-New-York-City-NY-1024x683.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/DayInSoho/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Full-day access to meeting facilities at Farm SoHo",
      "State-of-the-art amenities and stylish atmosphere for productive discussions",
      "Evening cooking class at Home Cooking NY",
      "Guided culinary experience led by skilled chefs",
      "Hands-on cooking session creating delicious dishes",
      "All necessary ingredients and equipment provided",
      "Opportunity for team bonding and collaboration in a relaxed setting",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 AM: Arrival and welcome at Farm SoHo",
          "9:30 AM - 12:00 PM: Productive team meeting or brainstorming session",
          "12:00 PM - 1:00 PM: Lunch break ",
          "1:00 PM - 5:00 PM: Continuation of team meeting or breakout sessions",
          "5:30 PM - 8:30 PM: Evening cooking class at Home Cooking NY + dinner",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Product workshops",
        description:
          "Choose from facilitated workshops covering discovery, roadmapping, and goal-setting.",
      },
      {
        name: "Offsite facilitation",
        description:
          "Elevate your offsite experience with an offsite facilitator focused on creating transformational moments within team offsites. The facilitator will guide discussions around team building, problem solving, and leveraging skills on the team.",
      },
      {
        name: "Additional team building activity",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Farm SoHo",
        img: require("./img/experiences/DayInSoho/coworking-space-nyc-2-2-scaled-e1690410880200.jpg"),
        description:
          "Discover Farm SoHo, the ultimate venue for meetings and events in New York City's bustling SoHo neighborhood. With modern elegance and versatile spaces, we offer the ideal setting for productive meetings, brainstorming sessions, and networking events. Experience the perfect blend of style, sophistication, and functionality at Farm SoHo, where creativity thrives and connections flourish.",
      },
      {
        heading: "Cooking class",
        img: require("./img/experiences/DayInSoho/Ramen_1_ofyxad.jpg"),
        description:
          "Embark on a culinary journey with Home Cooking NY, your gateway to delicious and memorable cooking experiences in the heart of the city. The expert chefs bring passion and expertise to the kitchen, guiding you through hands-on classes that inspire creativity and elevate your culinary skills. From classic comfort foods to international cuisines, the classes offer a diverse range of recipes to explore and master. Home Cooking NY provides an unforgettable cooking adventure filled with fun, flavor, and fantastic food.",
      },
    ],
    FAQ: [
      {
        question: "What cooking classes are available?",
        answer:
          "Guests have the option to enjoy Italian, Mexican, Persian, Spanish, Indian, Mediterranean or Korean cuisine. We’re happy to help with any choice!",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can replace cooking class with other activities in the area like painting, bouldering and more",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, Farm SoHo is equipped with projector, Wi-Fi and everything needed for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their sessions.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes. Farm SoHo and Home Cooking NY can accommodate up to 24 people comfortably. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question:
          "Is the experience suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "active-afternoon-newyork",
    tagline: "Team building in the financial district",
    longDescription:
      "Elevate your team-building game with our 'Active Afternoon' experience, a thrilling fusion of sportsmanship and culinary creativity! Start the afternoon off with a spirited round of pickleball at Court 16, where teamwork and laughter take center court. Then, unleash your inner chef at Self Up's competitive cooking class, where colleagues become culinary comrades in a high-stakes cook-off. With expert guidance from seasoned chefs and plenty of friendly rivalry, this dynamic adventure promises unforgettable memories and delicious rewards. Get ready to rally on the court and reign supreme in the kitchen – book your experience today and turn your team into champions!",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "court-16-manhattan-newyork",
        location: {
          fullAddress: "28 Liberty St SC1, New York, NY 10005, United States",
          shortAddress: "Manhattan, NY",
          lat: 40.7075636144756,
          long: -74.00902390819172,
        },
        experienceName: "Court 16",
        experienceThumbnail: require("./img/experiences/ActiveAfternoon/IMG_2887.edsmll.jpg"),
      },
      {
        id: "selfup-manhattan-newyork",
        location: {
          fullAddress: "16 Beaver St, New York, NY 10004, USA",
          shortAddress: "Manhattan, NY",
          lat: 40.70483749397979,
          long: -74.01250204083598,
        },
        experienceName: "Self Up New York",
        experienceThumbnail: require("./img/experiences/ActiveAfternoon/DSC_1147_7944b4d0-59e9-4d54-8c31-5cac936f1e10.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/ActiveAfternoon/IMG_2887.edsmll.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/ActiveAfternoon/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Energetic pickleball session at Court 16, including professional instruction and equipment rental.",
      "Competitive cooking class at Self Up, complete with ingredients, cooking equipment, and expert guidance from seasoned chefs.",
      "Engaging team-building activities designed to foster collaboration, communication, and camaraderie.",
      "Memorable experiences and lasting memories in the heart of the Financial District.",
      "Opportunities for friendly competition and bonding with colleagues in a dynamic and fun-filled environment.",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "3:00 PM: Arrival at Court 16",
          "3:30 AM - 5:00 PM: Thrilling pickleball game",
          "5:30 PM - 8:30 PM: Competitive cooking class at Self Up",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Scavenger Hunt",
        description: "",
      },
      {
        name: "Meeting room ",
        description: "",
      },
      {
        name: "Additional team building activity",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Pickleball",
        img: require("./img/experiences/ActiveAfternoon/IMG_4648ed.jpg"),
        description:
          "Court 16 is not just a place to play – it's where passion meets precision, and every swing tells a story. Step onto the state-of-the-art courts in the heart of the Financial District and experience pickleball like never before. With expert instruction, top-notch facilities, and a vibrant community of players, Court 16 is your ultimate destination for fun, fitness, and friendly competition. ",
      },
      {
        heading: "Cooking class",
        img: require("./img/experiences/ActiveAfternoon/800X600-10.png"),
        description:
          "At Self Up, cooking is more than just a skill – it's a thrilling challenge. Join us in our state-of-the-art kitchen for an unforgettable culinary experience where teamwork and competition reign supreme. Under the guidance of the expert chefs, you and your team will face off in a high-energy cooking showdown, creating delectable dishes and making memories that will last a lifetime. Get ready to spice up your team-building game and discover the joy of cooking at Self Up.",
      },
    ],
    FAQ: [
      {
        question: "What cooking classes are available?",
        answer:
          "Guests have the option to enjoy Italian, French or Asian cuisine. We’re happy to help with any choice! ",
      },
      {
        question: "What activities are included?",
        answer: "Pickleball and cooking class - both can be replaced upon demand ",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes, but are mostly for larger teams. Please contact us for specific inquiries regarding smaller groups. ",
      },
      {
        question:
          "Is the experience suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available. ",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date. ",
      },
    ],
  },
  {
    id: "town-hall-toronto-ontario",
    tagline: "Remote company get together in the heart of downtown TO",
    longDescription:
      "Gather your remote team for an unforgettable day of connection and camaraderie with our Town Hall experience in downtown Toronto. Step into the vibrant atmosphere of Staples Studio, where collaboration comes to life in the heart of the city. Engage in meaningful discussions, brainstorming sessions, and bond with your colleagues in person. Then, prepare for some friendly competition with Coconut Quiz's trivia event, where laughter and knowledge collide for an evening of fun and excitement. Join us for a memorable day of togetherness and teamwork!",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "staples-studio-university-toronto-ontario",
        location: {
          fullAddress: "375 University Ave #102, Toronto, ON M5G 2J5",
          shortAddress: "Toronto, ON",
          lat: 43.6535168085003,
          long: -79.38683333198559,
        },
        experienceName: "Staples Studio University",
        experienceThumbnail: require("./img/experiences/TownHall/1415033148.png"),
      },
    ],
    experienceMainImg: require("./img/experiences/TownHall/1415033148.png"),
    experienceImgs: importAll(
      require.context("./img/experiences/TownHall/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Team gathering at Staples Studio in downtown.",
      "Engaging discussions, brainstorming sessions, and team-building activities.",
      "Trivia event hosted by Coconut Quiz for a fun and competitive evening.",
      "Opportunities for networking, collaboration, and bonding with team members.",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "1:00 PM - 3:00 PM: Town hall Trivia Event with Coconut Quiz",
          "4:00 PM - 6:00 PM: Trivia challenge - compete with your team members in a series of trivia rounds covering a variety of topics.",
          "6:00 PM - 7:00 PM: Networking and Closing Remarks",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Team building activity",
        description: "",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Staples Studio",
        img: require("./img/experiences/TownHall/Staples_Studio_University.jpg"),
        description:
          "Immerse yourself in the dynamic energy of Staples Studio, a premier destination for creativity and collaboration in owntown Toronto. With its modern design and versatile spaces, Staples Studio provides the perfect backdrop for your team gathering. From brainstorming sessions to presentations, this innovative workspace fosters productivity and inspiration at every turn. Elevate your remote company get-together with the state-of-the-art amenities and vibrant atmosphere of Staples Studio.",
      },
      {
        heading: "Trivia",
        img: require("./img/experiences/TownHall/7.png"),
        description:
          "Prepare for an evening of excitement and laughter with Coconut Quiz, the ultimate trivia experience that brings teams together like never before. With a diverse range of categories and challenging questions, Coconut Quiz guarantees a fun and engaging event for all participants. Whether you're a trivia master or a newcomer to the game, you'll enjoy the thrill of competition and the camaraderie of teamwork. Join us for a memorable trivia night with Coconut Quiz and test your knowledge while creating unforgettable memories with your remote team.",
      },
    ],
    FAQ: [
      {
        question: "Is accommodation included in the package?",
        answer: "No, but can be added upon request.",
      },
      {
        question: "What activities are included?",
        answer:
          "Trivia event hosted by Coconut quiz. More team building activities can be added upon demand. ",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes up to 75 people. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "team-workshop-toronto-ontario",
    tagline: "An eventful day in the city!",
    longDescription:
      "Gather your team for an unforgettable day of connection and camaraderie with a team workshop experience in downtown Toronto. Step into the vibrant atmosphere of Sorry Studio, where collaboration thrives in the heart of the city. Begin your day with a rejuvenating wellness workshop led by Mariana Paz, setting the tone for meaningful discussions and team bonding. Choose an offsite facilitator or a speaker to shape the day. Then, cap off the day with a memorable team event at Longslice Brewery, where you'll enjoy craft brews and camaraderie in a relaxed setting. Join us for a day of togetherness, growth, and teamwork!",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "sorry-studio-toronto-ontario",
        location: {
          fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
          shortAddress: "Toronto, ON",
          lat: 43.63685493015649,
          long: -79.4263043031508,
        },
        experienceName: "Sorry Studio",
        experienceThumbnail: require("./img/experiences/UrbanStartupSummit/NDK3259.jpeg"),
      },
      {
        id: "longslice-brewery-toronto-ontario",
        location: {
          fullAddress: "484 Front St E, Toronto, ON M5A 0J1",
          shortAddress: "Toronto, ON",
          lat: 43.65374029626441,
          long: -79.35461484547896,
        },
        experienceName: "Longslice Brewery",
        experienceThumbnail: require("./img/experiences/TeamWorkshop/Brew House.jpeg"),
      },
    ],
    experienceMainImg: require("./img/experiences/TeamWorkshop/_MIP5540.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/TeamWorkshop/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Participation in a wellness workshop led by Mariana Paz at Sorry Studio.",
      "Engaging team-building activities and meaningful discussions in a dynamic workspace.",
      "Guest speaker / workshop of your choice.",
      "Access to the team event at Eastbound Brewery, including craft brews and camaraderie in a relaxed setting.",
      "Catered healthy lunch.",
      "Opportunities for networking, collaboration, and bonding with remote team members.",
      "Memorable experiences and lasting connections in the vibrant atmosphere of downtown Toronto.",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 AM - 9:30 AM: Arrival and Welcome",
          "9:30 AM - 11:00 AM: Wellness Workshop with Mariana Paz - Engage in a rejuvenating wellness workshop led by Mariana Paz, focusing on mindfulness and team well-being.",
          "11:00 AM - 12:30 PM: Team-Building Activities - Participate in engaging team-building exercises and discussions to strengthen bonds and foster collaboration.",
          "12:30 PM - 1:30 PM: Lunch Break",
          "1:30 PM - 4:00 PM: Team sessions / workshop",
          "5:00 PM - 7:00 PM: Brewery Social -Head to Eastbound Brewery for a memorable team event, where you'll enjoy craft brews and camaraderie in a relaxed setting.",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Product workshops",
        description:
          "Choose from facilitated workshops covering discovery, roadmapping, and goal-setting.",
      },
      {
        name: "Team building activity",
        description: "",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Sorry Studio",
        img: require("./img/experiences/TeamWorkshop/_MIP5497.jpg"),
        description:
          "Immerse yourself in the creative energy of Sorry Studio, your premier destination for collaboration and innovation in downtown Toronto. With its modern design and versatile space, Sorry Studio provides the perfect backdrop for productive workshops, brainstorming sessions, and team gatherings. Whether you're seeking inspiration, fostering connections, or unleashing creativity, Sorry Studio offers the ideal environment to make your ideas come to life. Join us at Sorry Studio and elevate your team's potential in a dynamic and vibrant setting.",
      },
      {
        heading: "Wellness Workshop",
        img: require("./img/experiences/TeamWorkshop/IMG_3131.ad82c1bbf501bb6108df (2).jpg"),
        description:
          "Mariana is a physiotherapist and a clinic owner in Toronto, and has helped thousands of people recover from chronic musculoskeletal pain and disability. She is passionate about educating workforces to maintain proper care of their spine and age with an elegant and healthy posture. In her sessions she educates on the cause of mechanical neck and back pain that's prevalent in the remote-working and office-working world and demonstrating simple and effective self-care techniques to implement immediately. She is also focusing on how to apply prevention strategies in daily lifestyle and provides workstation setup audit and strategies to promote proper ergonomics.",
      },
      {
        heading: "Longslice Brewery",
        img: require("./img/experiences/TeamWorkshop/Patio (1).jpeg"),
        description:
          "Indulge in a taste of Toronto's craft beer culture at Longslice Brewery, where passion meets innovation in every pint. Nestled in the heart of the city, Longslice Brewery is renowned for its handcrafted brews, each bursting with flavor and character. From crisp lagers to bold ales, there's something to satisfy every palate. Step into our taproom and immerse yourself in the vibrant atmosphere, where friends gather, stories flow, and memories are made. Join us at Longslice Brewery and raise a glass to good times and great beer. Cheers to unforgettable moments in the heart of Toronto's brewery scene!",
      },
    ],
    FAQ: [
      {
        question: "Is accommodation included in the package?",
        answer: "No, but can be added upon request. ",
      },
      {
        question: "Is food included in the package?",
        answer: "Yes, but can be removed upon request. ",
      },
      {
        question: "What activities are included?",
        answer: "Wellness sessions and optional workshops. These can change upon request. ",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes up to 25 people. Please contact us for specific inquiries regarding large groups. ",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date. ",
      },
    ],
  },
  {
    id: "finding-common-ground-toronto-ontario",
    tagline: "Aligning our journeys - one toast at a time",
    longDescription:
      "Embark on a full-day journey of team alignment and collaboration at Sorry Studio, where expert product builder and coach Moshe Mikanovsky will lead you through a transformative session. Alongside Moshe's insightful guidance, engage in interactive team sessions and discussions aimed at unifying your user journey and fostering alignment within your team. Explore the complexity of diverse perspectives and discover practical strategies to find common ground and drive consensus. Leave with valuable tools and insights to implement immediately with your team and users, along with newfound clarity and cohesion. Join us for a dynamic day of learning, growth, and team empowerment at Sorry Studio.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "sorry-studio-toronto-ontario",
        location: {
          fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
          shortAddress: "Toronto, ON",
          lat: 43.63685493015649,
          long: -79.4263043031508,
        },
        experienceName: "Sorry Studio",
        experienceThumbnail: require("./img/experiences/UrbanStartupSummit/NDK3259.jpeg"),
      },
    ],
    experienceMainImg: require("./img/experiences/FindCommonGround/Moshe Mikanovsky Workshop 1.png"),
    experienceImgs: importAll(
      require.context("./img/experiences/FindCommonGround/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Guided session with veteran product builder and coach Moshe Mikanovsky",
      "Practical exercises to unify user journey and foster alignment",
      "Tools and resources to implement immediately with your team and users",
      "Catered healthy breakfast and lunch",
      "Full day in the creative and beautiful Sorry Studio",
      "Take-home materials and resources for continued learning and implementation",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 AM - 9:30 AM: Arrival and breakfast",
          "9:30 AM - 11:00 AM: Workshop with Moshe Mikanovsky - ‘Aligning our journeys, one toast at a time’",
          "11:00 AM - 12:30 PM: Team sessions / workshop",
          "12:30 PM - 1:30 PM: Lunch Break",
          "1:30 PM - 4:00 PM: Team sessions / workshop",
          "5:00 PM - 7:00 PM: Optional team building activity",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Cooking class",
        description:
          "An on-site cooking experience with a talented chef.  This workshop includes selection from a variety of menus as well as preparing a full meal!",
      },
      {
        name: "Art workshop",
        description: "An ideal way to promote creativity and inject energy into your team.",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Sorry Studio",
        img: require("./img/experiences/FindCommonGround/_MIP5497.jpg"),
        description:
          "Immerse yourself in the creative energy of Sorry Studio, your premier destination for collaboration and innovation in downtown Toronto. With its modern design and versatile space, Sorry Studio provides the perfect backdrop for productive workshops, brainstorming sessions, and team gatherings. Whether you're seeking inspiration, fostering connections, or unleashing creativity, Sorry Studio offers the ideal environment to make your ideas come to life. Join us at Sorry Studio and elevate your team's potential in a dynamic and vibrant setting.",
      },
      {
        heading: "Moshe Mikanovasky",
        img: require("./img/experiences/FindCommonGround/Moshe Mikanovsky Workshop 2.jpg"),
        description:
          "Step into the world of product building and team alignment with the seasoned expertise of Moshe Mikanovsky. With a wealth of experience as a veteran product builder and coach, Moshe brings a unique blend of practical knowledge and insightful guidance to the table. Known for his dynamic approach and engaging teaching style, Moshe empowers teams to navigate complex challenges, foster alignment, and drive impactful outcomes. Through interactive sessions and thought-provoking discussions, Moshe inspires teams to unlock their full potential and achieve success in their projects. Join Moshe on a journey of discovery, collaboration, and growth, and elevate your team's performance to new heights.",
      },
    ],
    FAQ: [
      {
        question: "Is accommodation included in the package?",
        answer: "No, but can be added upon request.",
      },
      {
        question: "Is food included in the package?",
        answer: "Yes, but can be removed upon request.",
      },
      {
        question: "What activities are included?",
        answer:
          "Workshop led by Moshe Mikanovasky to align your team. These can change upon request.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes up to 15 people. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "work-hard-play-hard-port-jervis-newyork",
    tagline: "Feels just like summer camp",
    longDescription:
      "Escape the hustle and bustle of city life and immerse yourself in the natural beauty of Cedar Lakes Estate with our 'Work Hard Play Hard' experience. Designed as a summer camp for adults, this three-day getaway offers the perfect blend of work and play amidst rolling green hills and fresh mountain air, just 70 miles from New York City. Collaborate with your team in dedicated indoor and outdoor meeting spaces, surrounded by rustic elegance and 5-star comforts. Engage in a variety of outdoor activities, including swimming, fishing, hiking, and lawn games, or simply unwind by the bonfire under the starlit sky. Indulge in seasonal menus crafted by the in-house chefs, featuring produce from our onsite organic garden, and sip on craft cocktails at the bar. Create an unforgettable retreat experience for your team that rejuvenates mind, body, and spirit.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "cedar-lakes-estate-port-jervis-newyork",
        location: {
          fullAddress: "1 Team Usa Way, Port Jervis, NY 12771, USA",
          shortAddress: "Port Jervis, NY",
          lat: 41.3463349361544,
          long: -74.64346005767108,
        },
        experienceName: "Cedar Lakes Estate",
        experienceThumbnail: require("./img/experiences/WorkHardPlayHard/ME_CedarLakesWedding_ScottAndrewStudio_2_Details-28-1-1555x1155-c-default.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/WorkHardPlayHard/Amphitheatre-1555x1155-c-default.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/WorkHardPlayHard/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Access to all recreational activities like swimming lakes, pools, fishing, rowboats, paddleboards, canoeing, hiking, tennis and basketball courts, lawn games, and sports fields",
      "Relaxing evenings by the bonfire",
      "Dedicated indoor and outdoor meeting spaces for workshops and discussions",
      "Rustic elegance combined with 5-star comforts, including steaming rain showers and feather down beds",
      "Seasonal menus crafted by in-house chefs using produce from the onsite organic garden",
      "Craft cocktails available at the bar for post-work relaxation",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Noon: Arrive at Cedar Lakes Estate and check into your cozy cabins or cottages.",
          "Afternoon: Gather for a delicious farm-to-table lunch at our onsite restaurant, followed by a brief orientation session.",
          "Late Afternoon: Dive into team-building activities such as a scavenger hunt or friendly sports competitions on our outdoor fields.",
          "Evening: Enjoy a relaxing dinner under the stars, followed by a bonfire with s'mores and storytelling.",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: Wake up to a hearty breakfast and energizing yoga session overlooking the serene landscape.",
          "Late Morning: Split into groups for collaborative workshops or brainstorming sessions in our dedicated indoor meeting spaces.",
          "Afternoon: Take a break from work and enjoy leisure activities such as hiking, fishing, canoeing, or lounging by the pool.",
          "Evening: Gather for a festive barbecue dinner with live music and dancing.",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: Start the day with a leisurely breakfast and a group reflection session to discuss insights and goals from the retreat.",
          "Late Morning: Engage in a creative team-building activity, such as a group art project or cooking class.",
          "Afternoon: Wrap up the retreat with a final team-building challenge or outdoor adventure before departing for home.",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
      {
        name: "Public Speaking workshop",
        description: "A facilitated workshop to level up presenting skills for the whole team.",
      },
      {
        name: "Team activity en route",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Team building activities",
        img: require("./img/experiences/WorkHardPlayHard/ActivitiesLake4-765x565-c-default.jpg"),
        description:
          "Elevate teamwork and camaraderie amidst the serene backdrop of Cedar Lakes Estate with engaging team-building activities. From collaborative challenges to outdoor adventures, the tailored experiences are designed to foster connection and synergy among your group. Embark on a thrilling scavenger hunt through lush trails, test your skills with friendly competitions on our sports fields, or bond over a shared fishing excursion on our tranquil lakes. With activities suited for all interests and abilities, our team-building programs promise to ignite inspiration and forge lasting bonds in the heart of nature.",
      },
      {
        heading: "Hotel stay",
        img: require("./img/experiences/WorkHardPlayHard/Cedar-Lakes_182-1555x1155-c-default.jpg"),
        description:
          "Experience the rustic charm and modern comforts of Cedar Lakes Estate with our cozy cabins and cottages. Nestled amidst towering trees and rolling hills, the accommodations offer a serene retreat from the everyday hustle and bustle. Each cabin or cottage features thoughtfully appointed interiors, blending rustic elegance with contemporary amenities for a truly luxurious stay. Unwind in the comfort of feather-down beds, relax in the warmth of a crackling fireplace, and rejuvenate with a soothing rain shower. ",
      },
      {
        heading: "Food",
        img: require("./img/experiences/WorkHardPlayHard/Garden-1-1555x1155-c-default.jpg"),
        description:
          "Indulge in a culinary journey like no other at Cedar Lakes Estate, where farm-to-table dining meets gourmet innovation. The in-house chefs craft seasonal menus inspired by the bounty of our onsite organic garden, ensuring each dish bursts with freshness and flavor. Savor delectable creations made with locally sourced ingredients, from vibrant salads to succulent grilled meats and artisanal desserts. Whether you're dining al fresco under the stars or enjoying a cozy meal by the fireside, every bite is a celebration of nature's bounty and culinary artistry. ",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "No, transportation is not included in the package but can be added.",
      },
      {
        question: "What accommodation options are available at the hotel?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom. Guests can choose between cottages and cabins",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals in the restaurant or bring their own food",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can hike, explore the trails, enjoy swimming, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team needs. ",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the hotel?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are dozens of rooms. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "elevate-innovate-lenox-mass",
    tagline: "Ignite growth, inspire excellence, and transform your team",
    longDescription:
      " Experience a transformative offsite retreat at the Winthrop Estate, nestled in the serene Berkshires. Our tailored program combines team-building activities and dynamic learning sessions for a team. Strengthen bonds and enhance communication through engaging exercises, while expert-led workshops empower personal and professional growth. Indulge in gourmet dining and moments of reflection amidst the estate's natural beauty. Retreat, recharge, and return ready to conquer new challenges as a unified and inspired team.",
    priceMessage:
      "Price based on team of 10 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "winthrop-estate",
        location: {
          fullAddress: "101 Yokun Ave, Lenox, MA 01240",
          shortAddress: "Lenox, MA",
          lat: 42.36399,
          long: -73.29063,
        },
        experienceName: "Winthrop Estate",
        experienceThumbnail: require("./img/experiences/ElevateInnovate/Winthrope-estate-2537.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/ElevateInnovate/Winthrope-estate-2537.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/ElevateInnovate/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Return transportation from Manhattan",
      "Two nights stay in a beautiful estate with high end rooms and facilities",
      "Fine dining experience",
      "Team building and offsite facilitation",
      "Magnificent view of the Berkshires",
      "45 acres of privacy in a historical mansion",
      "Communal spaces for work",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: arrival and welcome breakfast",
          "Late morning: icebreaker and team introduction activities",
          "Lunch: gourmet picnic lunch on the estate grounds",
          "Afternoon: team-building activities (e.g., trust exercises, problem-solving challenges)",
          "Evening: dinner and fireside chat on team goals and expectations",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: breakfast buffet",
          "Late morning: learning and development workshops (e.g., leadership development, communication skills)",
          "Lunch: farm-to-table lunch with discussion groups",
          "Afternoon: outdoor adventure activities (e.g., hiking, canoeing)",
          "Evening: team-building cooking class and dinner",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: breakfast buffet",
          "Late morning: personal reflection time and goal setting sessions",
          "Lunch: farewell brunch with group reflections",
          "Afternoon: free time for exploring the estate or relaxation",
          "Departure in the afternoon",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Cooking class",
        description:
          "An on-site cooking experience with a talented chef.  This workshop includes selection from a variety of menus as well as preparing a full meal!",
      },
      {
        name: "Chef",
        description: "",
      },
      {
        name: "Team-building activity",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Offsite facilitation",
        img: require("./img/experiences/ElevateInnovate/zz1elMdeU4awtmCknPesW9q2fYxrT7CkmQaaeNvlay2g1DlwOvHnJYCsMv5IfrVke5.jpeg"),
        description:
          "Elevate your offsite experience with Pearl & Elmore, a boutique consulting firm focused on elevating team offsites. Lauren Pearl, a seasoned business strategist, and Dr. Josh Elmore, a renowned social-organizational psychology expert, focus on team building, problem solving, and leveraging skills on the team.",
      },
      {
        heading: "Hotel stay",
        img: require("./img/experiences/ElevateInnovate/257a70_37ea6c62530e485ebac45f7785b1e7ec~mv2.jpg"),
        description:
          "Discover the enchanting Winthrop Estate, nestled in the picturesque Berkshires. This historic retreat offers a tranquil escape amidst lush greenery and breathtaking landscapes. With luxurious accommodations and elegant surroundings, immerse yourself in a setting of timeless beauty and serenity. Whether strolling through the manicured gardens, relaxing by the shimmering pond, or exploring the charming estate grounds, every moment at Winthrop Estate is an invitation to unwind and reconnect with nature.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/ElevateInnovate/257a70_0c753c2ff1af4b3b937c4f8b1360c1ba~mv2.jpg"),
        description:
          "Enjoy indoor dining in the dining room or outdoor dining on the patio and deck. Cook with your team or bring in one of our chefs for a superior dining experience!",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer:
          "Yes, transportation from Manhattan to the estate and back is included in the package. This can be removed.",
      },
      {
        question: "What accommodation options are available at the estate?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals, or prepare their own food.",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can hike, engage in team-building activities, workshops, and outdoor games.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the estate?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes. In this estate, there are 11 rooms. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "executive-leadership-retreat-amenia-newyork",
    tagline: "There’s no better time and place for planning your next quarter",
    longDescription: [
      "Experience the ultimate leadership retreat designed exclusively for executive teams. Departing from NYC, venture to Benmarl Winery for a delightful stop amidst vineyard vistas, fostering team camaraderie over local wines.",
      "Arrive at Troutbeck Hotel, a sanctuary nestled in nature, for two nights of luxurious accommodation. Engage in strategy sessions, motivational talks, and skill-buildingworkshops during the leadership retreat, igniting passion and unity within the team.",
      "On the return trip, stop at Spins Hudson for an adrenaline-packed day of archery, laser tag, and team competitions, strengthening bonds and fostering collaboration. Retreat, recharge, and return with renewed motivation and readiness to conquer company’s targets together.",
    ],
    priceMessage:
      "Price per team of 10 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "troutbeck-hotel",
        location: {
          fullAddress: "515 Leedsville Rd, Amenia, New York",
          shortAddress: "Amenia, NY",
          lat: 41.85502,
          long: -73.51517,
        },
        experienceName: "Troutbeck Hotel",
        experienceThumbnail: require("./img/experiences/ExecutiveLeadershipRetreat/troutbeck_benton-house_exteriors_036.1400x1056.jpg"),
      },
      {
        id: "benmarl-winery",
        location: {
          fullAddress: "156 Highland Ave, Marlboro, NY 12542",
          shortAddress: "Marlboro, NY",
          lat: 41.59288,
          long: -73.98121,
        },
        experienceName: "Benmarl Winery",
        experienceThumbnail: require("./img/experiences/ExecutiveLeadershipRetreat/7086e4346131cd0fa18da81718c6f920.jpg"),
      },
      {
        id: "spins-hudson",
        location: {
          fullAddress: "5 John Walsh Blvd, Peekskill, NY 10566",
          shortAddress: "Peekskill, NY",
          lat: 41.27536,
          long: -73.942963,
        },
        experienceName: "Spins Hudson",
        experienceThumbnail: require("./img/experiences/ExecutiveLeadershipRetreat/5e243c_97c3e5c2bace4e86a6244e09da3de28cf000 (1).jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/ExecutiveLeadershipRetreat/manor_house_exterior_arrival.1800x1800 (1).jpg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/ExecutiveLeadershipRetreat/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Return transportation from Manhattan",
      "Private tour at The Benmarl Winery",
      "Two nights stay in a beautiful property with high end rooms and facilities",
      "Three chef prepared meals a day",
      "Pool and games",
      "Tennis court and yoga studio",
      "Communal spaces for work",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: departure from Manhattan",
          "Late morning: arrival at Benmarl Winery, guided tour, and wine tasting",
          "Afternoon: scenic drive to Troutbeck Hotel",
          "Evening: check-in at Troutbeck Hotel, welcome reception, and introductions",
          "Dinner: team dinner at Troutbeck Hotel's restaurant, introduction to retreat agenda",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: breakfast buffet at Troutbeck Hotel",
          "Late morning: Kickoff meeting part 1 - setting goals and strategy sessions",
          "Lunch: networking lunch at Troutbeck Hotel's outdoor terrace",
          "Afternoon: Kickoff meeting part 2 - motivational talks and skill-building workshops",
          "Evening: free time for team bonding activities or relaxation",
          "Dinner: group dinner at a local restaurant or Troutbeck Hotel's restaurant",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: breakfast buffet at Troutbeck Hotel",
          "Late morning: departure for Spins Hudson",
          "Afternoon: team-building activities at Spins Hudson (archery, laser tag, competitions)",
          "Lunch: casual lunch at Spins Hudson's cafe",
          "Late afternoon: return journey to Manhattan",
          "Evening: arrival in Manhattan",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
      {
        name: "Public Speaking workshop",
        description: "A facilitated workshop to level up presenting skills for the whole team.",
      },
      {
        name: "5 Disfunctions of a Team",
        description:
          "A deep dive into common patterns of team disfunction, as well as how to recognize and resolve them.",
      },
    ],
    highlights: [
      {
        heading: "Winery visit",
        img: require("./img/experiences/ExecutiveLeadershipRetreat/Barrel-Tasting3-300x184.jpg"),
        description:
          "Nestled amidst the picturesque Hudson Valley, Benmarl Winery invites you on a journey of discovery and delight. Explore the historic vineyard, where each grape tells a story of passion and craftsmanship. Indulge in a guided tour led by the knowledgeable staff, as you immerse yourself in the art of winemaking. ",
      },
      {
        heading: "Hotel stay",
        img: require("./img/experiences/ExecutiveLeadershipRetreat/agpcollective_plannersdiningclub-1635.jpg"),
        description:
          "Nestled in the serene beauty of the Hudson Valley, Troutbeck Hotel offers a tranquil escape amidst nature's embrace. Steeped in history and charm, the hotel boasts luxurious accommodations and modern amenities, providing a haven for relaxation and rejuvenation. Immerse yourself in the timeless elegance of our surroundings, where every detail reflects a commitment to comfort and sophistication.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/ExecutiveLeadershipRetreat/troutbeck_07_17_23_002_1.1242x1800.jpg"),
        description:
          "Savor the culinary delights at Troutbeck Hotel, where every dish is a celebration of local flavors and seasonal ingredients. The chefs craft exquisite menus inspired by the bounty of the Hudson Valley, ensuring each meal is a feast for the senses. From farm-fresh breakfast buffets to elegant dinners at our renowned restaurant, dining at Troutbeck Hotel is a journey of gastronomic discovery. ",
      },
      {
        heading: "Team building activities",
        img: require("./img/experiences/ExecutiveLeadershipRetreat/5e243c_fc8778ec030841aa83b2dbbfdbb62f00~mv2.jpg"),
        description:
          "At Spins Hudson, unleash your team's competitive spirit with adrenaline-pumping activities designed to foster camaraderie and collaboration. Engage in thrilling archery battles, test your tactical skills in laser tag showdowns, and compete in exciting challenges that ignite team synergy. With a range of action-packed games and immersive experiences, Spins Hudson offers the perfect backdrop for bonding and friendly competition. ",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "Yes, transportation is included in the package and can be removed.",
      },
      {
        question: "What accommodation options are available at the hote?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals in the restaurant or bring their own food.",
      },
      {
        question:
          "What activities are included besides the winery tour and the team building activity on day 3?",
        answer:
          "Guests can hike, explore the trails, enjoy swimming, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team needs. ",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the hotel?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "farmstay-retreat-wawarsing-newyork",
    tagline: "Perfect getaway for team of up to 13",
    longDescription:
      "Escape to a charming farm retreat with our Farm Stay experience, perfect for team-building and corporate escapes. Unwind with a guided tour of America's oldest winery and savor delicious wines. Enjoy a two-night stay in luxurious farm accommodations, complete with high-end rooms and facilities. Delight in chef-prepared meals or bond over cooking together. Relax by the fire pit or explore the picturesque apple, peach, and pear orchards. Make use of communal spaces for productive work sessions. Experience the perfect blend of relaxation and connection at this unforgettable farm retreat.",
    priceMessage:
      "Price based on team of 13 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "lundy-farm",
        location: {
          fullAddress: "895 Lundy Rd, Wawarsing, NY 12489",
          shortAddress: "Hudson Valley, NY",
          lat: 41.81069,
          long: -74.37713,
        },
        experienceName: "Lundy Farm",
        experienceThumbnail: require("./img/experiences/FarmstayRetreat/lundyhomepage15.jpg"),
      },
      {
        id: "brotherhood-winery",
        location: {
          fullAddress: "100 Brotherhood Plaza Drive, Washingtonville, NY 10992",
          shortAddress: "Washingtonville, NY",
          lat: 41.43226,
          long: -74.1623,
        },
        experienceName: "Brotherhood Winery",
        experienceThumbnail: require("./img/experiences/FarmstayRetreat/center-vault-facebook (1).jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/FarmstayRetreat/lundyhomepage15.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/FarmstayRetreat/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Return transportation from Manhattan",
      "Wine tour in America’s oldest winery",
      "Two nights stay in a beautiful farm with high end rooms and facilities",
      "Chef prepared meals (or cook your own meals!)",
      "BBQ and fire pit",
      "Apple, peach, and pear orchard",
      "Communal spaces for work including whiteboard and TV",
      "Pool",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning departure from NYC with transportation provided.",
          "Afternoon wine tour at America's oldest winery, indulging in tastings and vineyard strolls.",
          "Evening arrival at the farm retreat, settling into luxurious accommodations.",
          "Optional team dinner prepared by the chef or DIY BBQ experience at the fire pit.",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning exploration of the orchards, picking fresh fruits and connecting with nature.",
          "Midday brainstorming sessions or team workshops in communal workspaces.",
          "Afternoon team-building activities such as farm-to-table cooking classes or outdoor games.",
          "Evening relaxation around the fire pit with storytelling and s'mores.",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning yoga or meditation session amidst the tranquil surroundings.",
          "Final team meetings or discussions to summarize key takeaways and action plans.",
          "Farewell brunch featuring farm-fresh ingredients before departure back to the city.",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "5 Disfunctions of a Team",
        description:
          "A deep dive into common patterns of team disfunction, as well as how to recognize and resolve them.",
      },
      {
        name: "Cooking class",
        description:
          "An on-site cooking experience with a talented chef.  This workshop includes selection from a variety of menus as well as preparing a full meal!",
      },
    ],
    highlights: [
      {
        heading: "Winery visit",
        img: require("./img/experiences/FarmstayRetreat/center-vault-facebook (1).jpg"),
        description:
          "Established in 1839, Brotherhood Winery is the oldest continuously operating winery in the United States, offering a unique blend of history, stunning underground cellars, and award-winning wines. Enjoy a guided tour, wine tastings, and create lasting memories",
      },
      {
        heading: "Farm stay",
        img: require("./img/experiences/FarmstayRetreat/Bedroom+1 (1).jpg"),
        description:
          "A 62-acre property—2 hours north of NYC—surrounded by 30,000 acres of state forest in the Hudson Valley. The luxury 2-villa estate features a regenerative organic farm & greenhouse, many activities, and an expansive, natural landscape to roam. The perfect setting for team retreats!",
      },
      {
        heading: "Food",
        img: require("./img/experiences/FarmstayRetreat/Lundy+Farm+-+Picnic+Dinner (1).jpeg"),
        description:
          "The farm’s revitalization initiative centers around a regenerative organic farm, promoting biodiversity and soil health through small-scale sustainable agriculture. Chef & Farm Director design includes a soil-based glass greenhouse and a 4-acre field with over 150 plant varieties.",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer:
          "Yes, transportation from Manhattan to the farm retreat and back is included in the package. This can be removed.",
      },
      {
        question: "What accommodation options are available at the farm?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals or cook their own meals using the available facilities.",
      },
      {
        question: "What activities are included besides the wine tour?",
        answer:
          "In addition to the wine tour, guests can hike, explore the orchards, enjoy the pool or the tennis court, engage in team-building activities, workshops, and outdoor games.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the farm?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "leadership-escape-milton-newyork",
    tagline: "Retreat and recharge with your team",
    longDescription:
      "Unwind and recharge at the beautiful Buttermilk Falls Inn & Spa, located just 1.5 hours from NYC. This picturesque retreat offers a serene environment, perfect for leadership team building activities. Enjoy luxury accommodations, exquisite cuisine, and nature's beauty, ensuring your team returns inspired and rejuvenated.",
    priceMessage:
      "Price based on team of 10 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "buttermilk-falls-inn",
        location: {
          fullAddress: "220 N Rd, Milton, NY 12547, United States",
          shortAddress: "Milton, NY",
          lat: 41.66747085751032,
          long: -73.95720294251487,
        },
        experienceName: "Buttermilk Falls Inn & Spa",
        experienceThumbnail: require("./img/experiences/RevitalizeGetaway/BMF194-1-scaled.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/LeadershipEscape/Buttermilk-Falls-Inn-Grounds2.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/LeadershipEscape/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "One-night stay in a beautiful property with high end rooms and facilities",
      "Lunch & dinner on Day 1 and breakfast & lunch on day 2",
      "Meeting room in the barn",
      "Spectacular views",
      "Farm-to-table restaurant",
      "Guided painting experienced by a professional artist",
      "Communal spaces for work",
      "An orchard and an endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Lunch: Enjoy a farm-to-table meal in the inn's dining room or outdoors.",
          "Afternoon: Leadership development workshop - facilitated session focusing on communication styles, conflict resolution, and effective teamwork.",
          "Late Afternoon: Team-building activity - paint workshop with your team",
          "Evening: Free time to explore the inn's amenities -  relax by the heated outdoor pool, take a stroll through the gardens, or enjoy a spa treatment.",
          "Dinner: Gourmet dinner featuring local specialties and seasonal ingredients.",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: breakfast buffet ",
          "Late morning: Guided nature hike: Explore the picturesque hiking trails surrounding the inn and take in the beauty of the Hudson Valley.",
          "Late morning: Leadership development workshop continuation: Interactive session focusing on goal setting, decision-making, and building trust within the team.",
          "Lunch: Casual outdoor lunch, perhaps with a scenic picnic overlooking the Hudson River",
          "Afternoon: motivational talks ",
          "Late afternoon: return journey to Manhattan",
          "Evening: arrival in Manhattan ",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "5 Disfunctions of a Team",
        description:
          "A deep dive into common patterns of team disfunction, as well as how to recognize and resolve them.",
      },
      {
        name: "Design thinking",
        description: "A facilitated workshop to elevate product development focus within the team.",
      },
      {
        name: "Offsite facilitation",
        description:
          "Elevate your offsite experience with an offsite facilitator focused on creating transformational moments within team offsites. The facilitator will guide discussions around team building, problem solving, and leveraging skills on the team.",
      },
    ],
    highlights: [
      {
        heading: "Hotel",
        img: require("./img/experiences/LeadershipEscape/BMF213-scaled.jpg"),
        description:
          "Experience luxury and relaxation at Buttermilk Falls Inn & Spa. Enjoy elegant accommodations, farm-to-table cuisine, a full-service spa, and scenic outdoor spaces including a heated pool and picturesque hiking trails.",
      },
      {
        heading: "Rooms",
        img: require("./img/experiences/LeadershipEscape/BMF194-1-scaled.jpg"),
        description:
          "Retreat to comfort and style in the thoughtfully designed rooms at Buttermilk Falls Inn & Spa. Each room offers a serene escape with elegant furnishings, cozy linens, and picturesque views of the Hudson River or the lush gardens. Unwind in luxury with modern amenities.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/LeadershipEscape/159813235_782180859388021_8517296385296507327_n-5.png"),
        description:
          "Savor farm-to-table cuisine at Buttermilk Falls Inn & Spa, where every meal is a culinary delight. The local chefs expertly craft dishes using fresh, locally sourced ingredients, ensuring a delectable dining experience. Whether you're enjoying a gourmet meal in the elegant dining rooms or dining al fresco with views of the Hudson River, each bite is a taste of the Hudson Valley's finest flavors.",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "No, but can be added per request.",
      },
      {
        question: "What accommodation options are available at the estate?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals in the restaurant or bring their own food",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can hike, explore the trails, enjoy swimming, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team's needs.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the estate?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are 17 rooms. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "product-inspiration-coxsackie-newyork",
    tagline: "Ignite growth, inspire excellence, and transform your team",
    longDescription:
      "Retreat to Gather Greene, nestled in the serene Catskill Mountains, for an immersive experience in product and design thinking. Our rustic cabins provide the ideal backdrop for creative sessions and collaboration. Fuel creativity with farm-to-table meals and recharge in comfortable accommodations. Discover the power of design thinking in a relaxed and supportive environment. Retreat, innovate, and transform your team at Gather Greene.",
    priceMessage:
      "Price based on team of 10 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "gather-greene",
        location: {
          fullAddress: "176 Levett Rd, Coxsackie, NY 12051",
          shortAddress: "Coxsackie, NY",
          lat: 42.32452,
          long: -73.88159,
        },
        experienceName: "Gather Greene",
        experienceThumbnail: require("./img/experiences/ProductInspiration/Modern+cabins+in+dappled+sunlight+at+Gather+Greene+-+vertical_Mark+Spooner+Photography.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/ProductInspiration/Unobstructed+views+of+canopy+from+Gather+Greene+cabin.jpg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/ProductInspiration/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Return transportation from Manhattan",
      "Two nights stay in beautiful cabins with all the comforts of a hotel room, in the middle of the woods",
      "Fine dining experience",
      "Design thinking and product development sessions",
      "100 acres of immersion in nature",
      "Communal spaces for work and meetings equipment",
      "Campfires and S’mores",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: arrival and welcome breakfast",
          "Late morning: introduction to design thinking principles",
          "Lunch: casual networking lunch in the cabins",
          "Afternoon: product ideation session - brainstorming and concept generation",
          "Evening: dinner and fireside chat on design thinking in practice",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: breakfast buffet",
          "Late morning: design thinking workshop - prototyping and user feedback",
          "Lunch: farm-to-table lunch with small group discussions",
          "Afternoon: product development session - iteration and refinement",
          "Evening: team-building activity or outdoor exploration",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: breakfast buffet",
          "Late morning: presentation and sharing of product concepts",
          "Lunch: farewell brunch and wrap-up discussions",
          "Afternoon: free time for relaxation or exploring GatherGreene",
          "Departure in the afternoon",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Coaching",
        description: "",
      },
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Team-building activity",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Design thinking and product development sessions",
        img: require("./img/experiences/DesignThinkingOffsite/Moshe Mikanovsky Workshop 2.jpg"),
        description:
          "Moshe is a product management leader, specialized in lean iterative approach to develop products that exceed users’ expectations. He is a university professor, avid podcaster and an author, and leads design thinking and product management sessions. He will join this off-site to make the experience focused on elevating product development and thinking within the team",
      },
      {
        heading: "Cabins",
        img: require("./img/experiences/ProductInspiration/Modern+cabins+in+dappled+sunlight+at+Gather+Greene+-+vertical_Mark+Spooner+Photography.jpg"),
        description:
          "Gather Greene cabins are thoughtfully designed to offer guests total privacy and full immersion in nature, with all the comforts and amenities of a modern hotel room. Every guest room features a full en suite bathroom with walk-in rain shower, unobstructed picture window, and outdoor deck, as well as a king bed, under bed luggage storage, heating and air conditioning, mini fridge, closet with full-length mirror, and hair dryer.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/ProductInspiration/Open+fire+cooking+by+the+quarry_IntotheWild.jpg"),
        description:
          "Food + beverage packages can be tailored to individual dietary needs. Also, weather permitting, the team will be able to enjoy campfires and s’mores!",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer:
          "Yes, transportation from Manhattan to the hotel and back is included in the package. This can be removed.",
      },
      {
        question: "What accommodation options are available?",
        answer:
          "Guests will stay in high-end cabins with modern facilities to ensure comfort during their retreat. Each cabin has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals, or prepare their own food.",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can hike, engage in team-building activities, workshops, and outdoor games.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the camp?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes. In this camp, there are 17 cabins. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "revitalize-team-motivation-getaway-milton-newyork",
    tagline: "Time for some team rejuvenation!",
    longDescription:
      "Unwind and recharge at the stunning Buttermilk Falls Inn & Spa, nestled just 1.5 hours from NYC. This picturesque retreat provides the perfect backdrop for leadership team building activities and creative expression. In addition to luxury accommodations, exquisite cuisine, and nature's beauty, your team will enjoy an inspiring art session amidst the breathtaking surroundings. Immerse yourselves in the tranquility of nature as you paint and bond, followed by tailored team-building activities designed to strengthen connections and foster collaboration. Leave feeling inspired, rejuvenated, and united.",
    priceMessage:
      "Price based on team of 10 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "buttermilk-falls-inn",
        location: {
          fullAddress: "220 N Rd, Milton, NY 12547, United States",
          shortAddress: "Milton, NY",
          lat: 41.66747085751032,
          long: -73.95720294251487,
        },
        experienceName: "Buttermilk Falls Inn & Spa",
        experienceThumbnail: require("./img/experiences/RevitalizeGetaway/BMF194-1-scaled.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/RevitalizeGetaway/Revitalize.png"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/RevitalizeGetaway/",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "2 nights stay in a beautiful property with high end rooms and facilities",
      "Meals for the entire trip",
      "Transportation",
      "Meeting room in the barn",
      "Spectacular views",
      "Farm-to-table restaurant",
      "Guided painting experienced by a professional artist",
      "Communal spaces for work",
      "An orchard and an endless amount of hiking trails",
      "Optional team building activites",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Lunch: Enjoy a farm-to-table meal in the inn's dining room or outdoors.",
          "Afternoon: Leadership development workshop - facilitated session focusing on communication styles, conflict resolution, and effective teamwork.",
          "Late Afternoon: Team-building activity - 'Create + Cheers' paint workshop with your team.",
          "Evening: Free time to explore the inn's amenities - relax by the heated outdoor pool, take a stroll through the gardens, or enjoy a spa treatment.",
          "Dinner: Gourmet dinner featuring local specialties and seasonal ingredients.",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: Breakfast buffet.",
          "Late Morning: Guided nature hike: Explore the picturesque hiking trails surrounding the inn and take in the beauty of the Hudson Valley.",
          "Late Morning: Leadership development workshop continuation: Interactive session focusing on goal setting, decision-making, and building trust within the team.",
          "Lunch: Casual outdoor lunch, perhaps with a scenic picnic overlooking the Hudson River.",
          "Afternoon: Motivational talks and group reflection sessions.",
          "Evening: Dinner and a campfire!",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: Breakfast buffet to start the day.",
          "Late Morning: Optional morning activity or free time to explore the surroundings.",
          "Late Morning: Final team bonding activity or closing ceremony to reflect on the retreat experience and set goals for the future.",
          "Lunch: Farewell lunch before departure.",
          "Afternoon: Departure from Buttermilk Falls Inn & Spa, returning to Manhattan.",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "5 Disfunctions of a Team",
        description:
          "A deep dive into common patterns of team disfunction, as well as how to recognize and resolve them.",
      },
      {
        name: "Design thinking",
        description: "A facilitated workshop to elevate product development focus within the team.",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Hotel",
        img: require("./img/experiences/RevitalizeGetaway/BMF213-scaled.jpg"),
        description:
          "Experience luxury and relaxation at Buttermilk Falls Inn & Spa. Enjoy elegant accommodations, farm-to-table cuisine, a full-service spa, and scenic outdoor spaces including a heated pool and picturesque hiking trails.",
      },
      {
        heading: "Rooms",
        img: require("./img/experiences/RevitalizeGetaway/BMF194-1-scaled.jpg"),
        description:
          "Retreat to comfort and style in the thoughtfully designed rooms at Buttermilk Falls Inn & Spa. Each room offers a serene escape with elegant furnishings, cozy linens, and picturesque views of the Hudson River or the lush gardens. Unwind in luxury with modern amenities.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/RevitalizeGetaway/159813235_782180859388021_8517296385296507327_n-5.png"),
        description:
          "Savor farm-to-table cuisine at Buttermilk Falls Inn & Spa, where every meal is a culinary delight. The local chefs expertly craft dishes using fresh, locally sourced ingredients, ensuring a delectable dining experience. Whether you're enjoying a gourmet meal in the elegant dining rooms or dining al fresco with views of the Hudson River, each bite is a taste of the Hudson Valley's finest flavors.",
      },
      {
        heading: "Team-building activity",
        img: require("./img/experiences/RevitalizeGetaway/zzCreateCheers.png"),
        description:
          "Art session 'Create + Cheers' -  2 hour painting experience guided by a professional artist done in the beautiful outdoors, together with wine. What could be better?",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "Yes, transportation is included in the package and can be removed.",
      },
      {
        question: "What accommodation options are available at the estate?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals in the restaurant or bring their own food",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests can hike, explore the trails, enjoy swimming, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team's needs.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the estate?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are 17 rooms. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "sales-kickoff-oliverea-valley-newyork",
    tagline: "Kickstart your sales season!",
    longDescription: [
      "Embark on the ultimate Sales Kickoff journey tailored for your sales team, featuring well-planned stops to energize and unite.",
      "Departing from NYC, begin with an invigorating visit to Spins Hudson for an action-packed day of archery, laser tag, and team competitions, strengthening bonds and fostering collaboration.",
      "Retreat to the tranquil Eastwind Hotel, nestled in nature, for two nights of luxurious accommodations. Engage in strategy sessions, motivational talks, and skill-building workshops during the Sales Kickoff Meeting to ignite passion and unity within the team.",
    ],
    priceMessage:
      "Price based on team of 10 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "eastwind-hotel",
        location: {
          fullAddress: "5088 NY-23, Windham, NY 12496, United States",
          shortAddress: "Oliverea Valley, NY",
          lat: 42.30855,
          long: -74.23037,
        },
        experienceName: "Eastwind Hotel",
        experienceThumbnail: require("./img/experiences/SalesKickoff/510d16_9541195023f446ed9ac5af57df1597fc~mv2.jpg"),
      },
      {
        id: "benmarl-winery",
        location: {
          fullAddress: "156 Highland Ave, Marlboro, NY 12542",
          shortAddress: "Marlboro, NY",
          lat: 41.59288,
          long: -73.98121,
        },
        experienceName: "Benmarl Winery",
        experienceThumbnail: require("./img/experiences/SalesKickoff/7086e4346131cd0fa18da81718c6f920.jpg"),
      },
      {
        id: "spins-hudson",
        location: {
          fullAddress: "5 John Walsh Blvd, Peekskill, NY 10566",
          shortAddress: "Peekskill, NY",
          lat: 41.27536,
          long: -73.942963,
        },
        experienceName: "Spins Hudson",
        experienceThumbnail: require("./img/experiences/SalesKickoff/5e243c_52fae47717df40ff8c27048c45da9d60mv2.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/SalesKickoff/510d16_71058d5a500846a99bc70c0c1cb9b5b4~mv2.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/SalesKickoff/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Return transportation from Manhattan",
      "Private tour at The Benmarl Winery",
      "Team-building activities at Spins Hudson (archery, laser tag, competitions)",
      "Two nights stay in a beautiful property with high end rooms and facilities",
      "Locally-sourced food from the restaurant",
      "Pool and saunas",
      "Complimentary bikes",
      "Communal spaces for work",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: departure from Manhattan",
          "Late morning: team-building activities at Spins Hudson (archery, laser tag, competitions)",
          "Afternoon: scenic drive to Eastwind Hotel",
          "Evening: check-in at Eastwind Hotel, welcome reception, and introductions",
          "Dinner: team dinner at Eastwind Hotel's restaurant, introduction to Sales Kickoff agenda",
        ],
      },
      {
        name: "Day 2:",
        agendaItems: [
          "Morning: breakfast buffet at Eastwind Hotel",
          "Late morning: Sales Kickoff meeting part 1 - setting goals and strategy sessions",
          "Lunch: networking lunch at Eastwind Hotel's outdoor terrace",
          "Afternoon: Sales Kickoff meeting part 2 - motivational talks and skill-building workshops",
          "Evening: free time for team bonding activities or relaxation",
          "Dinner: group dinner at a local restaurant or Eastwind Hotel's restaurant",
        ],
      },
      {
        name: "Day 3:",
        agendaItems: [
          "Morning: breakfast buffet at Eastwind Hotel",
          "Late morning: departure for Benmarl winery",
          "Afternoon: arrival at Benmarl Winery, guided tour, and wine tasting",
          "Lunch: casual lunch at Benmarl winery",
          "Late afternoon: return journey to Manhattan",
          "Evening: arrival in Manhattan",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
      {
        name: "Public Speaking workshop",
        description: "A facilitated workshop to level up presenting skills for the whole team.",
      },
      {
        name: "Sales workshops",
        description:
          "Choose from facilitated workshops covering sales boot camp, negotiation, pitch makeovers, and more.",
      },
    ],
    highlights: [
      {
        heading: "Team building activities",
        img: require("./img/experiences/SalesKickoff/Copy of 5e243c_97c3e5c2bace4e86a6244e09da3de28cf000 (1).jpg"),
        description:
          "At Spins Hudson, unleash your team's competitive spirit with adrenaline-pumping activities designed to foster camaraderie and collaboration. Engage in thrilling archery battles, test your tactical skills in laser tag showdowns, and compete in exciting challenges that ignite team synergy. With a range of action-packed games and immersive experiences, Spins Hudson offers the perfect backdrop for bonding and friendly competition. ",
      },
      {
        heading: "Hotel stay",
        img: require("./img/experiences/SalesKickoff/510d16_8db10b653dfe4daea8b5e6fea5fd0ca9~mv2.jpg"),
        description:
          "Eastwind Hotel, in the scenic Hudson Valley, offers a tranquil escape with luxurious accommodations. Experience elegance, modern amenities, and nature's beauty at this charming retreat. Enjoy unforgettable dining at the onsite Dandelion Restaurant & Bar. Unwind in well-appointed rooms or unique 'glamping' cabins, reconnecting with nature. Discover the charm of the Hudson Valley at Eastwind Hotel.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/SalesKickoff/960x0.webp"),
        description:
          "The Eastwind Hotel's onsite restaurant, Dandelion Restaurant & Bar, offers a memorable dining experience in a cozy, welcoming atmosphere. Dandelion's menu showcases local ingredients and flavors, allowing guests to savor expertly prepared dishes and handcrafted cocktails while enjoying the picturesque natural surroundings of the Hudson Valley. With a focus on fresh, seasonal cuisine, Dandelion Restaurant & Bar is the perfect place to unwind and indulge in delectable fare during your stay at the Eastwind Hotel.",
      },
      {
        heading: "Winery visit",
        img: require("./img/experiences/SalesKickoff/Copy of Barrel-Tasting3-300x184.jpg"),
        description:
          "Nestled amidst the picturesque Hudson Valley, Benmarl Winery invites you on a journey of discovery and delight. Explore the historic vineyard, where each grape tells a story of passion and craftsmanship. Indulge in a guided tour led by the knowledgeable staff, as you immerse yourself in the art of winemaking. ",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "Yes, transportation is included in the package and can be removed.",
      },
      {
        question: "What accommodation options are available at the farm?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals in the restaurant or bring their own food.",
      },
      {
        question:
          "What activities are included besides the winery tour and the team building activity on day 3?",
        answer:
          "Guests can hike, explore the trails, enjoy swimming, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team needs. ",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the hotel?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "startup-summit-kenoza-lake-newyork",
    tagline: "Perfect getaway for the growing startup",
    longDescription:
      "Unwind and be inspired at our Startup Summit in historic Kenoza Hall. Connect with fellow colleagues while enjoying a guided tour of a nearby award-winning distillery. Stay in luxurious accommodations, indulge in chef-prepared meals, and strengthen your network during collaborative work sessions. Experience the perfect blend of professional growth and relaxation at this unforgettable countryside retreat.",
    priceMessage:
      "Price based on team of 20 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "kenoza-hall",
        location: {
          fullAddress: "5762 NY-52, Kenoza Lake, NY 12750",
          shortAddress: "Kenoza Lake, NY",
          lat: 41.74651,
          long: -74.956741,
        },
        experienceName: "Kenoza Hall",
        experienceThumbnail: require("./img/experiences/StartupSummit/2020-07-Kenoza-Hall-Lawrence-Braun-0016-DJI_0333+(2) (1).jpg"),
      },
      {
        id: "catskill-distilling-company",
        location: {
          fullAddress:
            "Catskill Distilling Company @ the Dancing Cat Saloon, 2037 NY-17B, Bethel, NY 12720",
          shortAddress: "Bethel, NY",
          lat: 41.68491,
          long: -74.8761,
        },
        experienceName: "Catskill Distilling Company ",
        experienceThumbnail: require("./img/experiences/StartupSummit/thedeck.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/StartupSummit/2020-07-Kenoza-Hall-Lawrence-Braun-0016-DJI_0333+(2) (1).jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/StartupSummit/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Return transportation from Manhattan",
      "Private tour at The Catskill Distilling company",
      "Two nights stay in a beautiful summer retreat with high end rooms and facilities",
      "Fine dining experience",
      "Outdoor lake view seating",
      "Canoe and spa adventures",
      "Communal spaces for work",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: Departure from Manhattan and travel to Catskill Distilling Company",
          "Late morning: Guided tour of the Catskill Distilling Company, sampling of spirits",
          "Lunch: Local eatery near Catskill Distilling Company",
          "Afternoon: Continue travel to Kenoza Hall",
          "Evening: Check-in at Kenoza Hall, welcome reception, and networking session",
          "Dinner: Fine dining experience at Kenoza Hall's restaurant",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: Breakfast buffet at Kenoza Hall",
          "Late morning: Interactive workshops and panel discussions in communal spaces",
          "Lunch: Farm-to-table picnic lunch at Kenoza Hall's outdoor seating area",
          "Afternoon: Team building activities or outdoor exploration (optional spa treatments available)",
          "Evening: Cocktail hour with tasting session of local spirits from the Catskill Distilling Company",
          "Dinner: Chef's table experience featuring a curated menu of locally sourced ingredients",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: Yoga and meditation session overlooking Kenoza Lake",
          "Late morning: Group hike or nature walk in the surrounding area",
          "Lunch: Farewell brunch at Kenoza Hall's dining room",
          "Afternoon: Reflection session and goal setting for future collaboration",
          "Departure: Check-out and transportation back to Manhattan",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Painting workshop",
        description:
          "A half-day workshop for painters of any experience level. Materials included.",
      },
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
    ],
    highlights: [
      {
        heading: "Distillery visit",
        img: require("./img/experiences/StartupSummit/barrels-wall1200.jpg"),
        description:
          "Elevate your Startup Summit experience with a visit to the Catskill Distilling Company. Immerse yourself in the art of distillation on an engaging tour of the historic facilities. Sample the handcrafted spirits and begin your summit journey with a taste of innovation at the distillery, where tradition meets entrepreneurship in every sip.",
      },
      {
        heading: "Hotel stay",
        img: require("./img/experiences/StartupSummit/FSH-2019-10-interiors-bar-living-restuarants-views-0084-LB010051.jpg"),
        description:
          "Ignite your Startup Summit adventure with a stay at Kenoza Hall. Nestled in the serene beauty of the Catskills, this historic hotel offers luxurious accommodations and breathtaking views of Kenoza Lake. Immerse yourself in comfort and elegance, setting the stage for productive meetings and rejuvenating relaxation. ",
      },
      {
        heading: "Food",
        img: require("./img/experiences/StartupSummit/057.jpg"),
        description:
          "Enjoy Indoor dining in the dining room or outdoor dining on the bluestone patio and deck. Sweeping lake views are always included! The culinary team offers classics such as Oysters Rockefeller, Cedar Grilled Salmon, Gnocchi Parisienne and the Kenoza Burger.",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer:
          "Yes, transportation from Manhattan to the distillery, the hotel and back is included in the package. This can be removed.",
      },
      {
        question: "What accommodation options are available at the hotel?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals, eat at the restaurant or buy their own food",
      },
      {
        question: "What activities are included besides the wine tour?",
        answer:
          "In addition to the tour, guests can hike, explore the spa, enjoy swimming or canoeing on the lake, engage in team-building activities, workshops, and outdoor games.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the hotel?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are 22 rooms in addition to several bungalows. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "team-bonding-windham-newyork",
    tagline: "Bring your team together!",
    longDescription: [
      "Embark on the ultimate team bonding journey tailored for your team, featuring well-planned stops to energize and unite.",
      "Departing from NYC, begin with an invigorating visit to Spins Hudson for an action-packed day of archery, laser tag, and team competitions, strengthening bonds and fostering collaboration.",
      "Retreat to the tranquil Eastwind Hotel, nestled in nature, for two nights of luxurious accommodations. Engage in strategy sessions, motivational talks, and skill-building workshops during the meeting to ignite passion and unity within the team.",
      "On your way back to the city, make a delightful stop at the picturesque Benmarl Winery, cultivating camaraderie over local wines amidst vineyard vistas. Return to NYC recharged and motivated, ready to conquer goals together as a cohesive, driven team.",
    ],
    priceMessage:
      "Price based on team of 10 people, subject to changes based on food menus, activities etc. Transportation included.",
    experienceLocations: [
      {
        id: "eastwind-hotel",
        location: {
          fullAddress: "5088 NY-23, Windham, NY 12496, United States",
          shortAddress: "Oliverea Valley, NY",
          lat: 42.30855,
          long: -74.23037,
        },
        experienceName: "Eastwind Hotel",
        experienceThumbnail: require("./img/experiences/SalesKickoff/510d16_9541195023f446ed9ac5af57df1597fc~mv2.jpg"),
      },
      {
        id: "benmarl-winery",
        location: {
          fullAddress: "156 Highland Ave, Marlboro, NY 12542",
          shortAddress: "Marlboro, NY",
          lat: 41.59288,
          long: -73.98121,
        },
        experienceName: "Benmarl Winery",
        experienceThumbnail: require("./img/experiences/SalesKickoff/7086e4346131cd0fa18da81718c6f920.jpg"),
      },
      {
        id: "spins-hudson",
        location: {
          fullAddress: "5 John Walsh Blvd, Peekskill, NY 10566",
          shortAddress: "Peekskill, NY",
          lat: 41.27536,
          long: -73.942963,
        },
        experienceName: "Spins Hudson",
        experienceThumbnail: require("./img/experiences/SalesKickoff/5e243c_52fae47717df40ff8c27048c45da9d60mv2.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/TeamBonding/9 (2).jpeg"),
    experienceImgs: importAll(
      require.context("./img/experiences/TeamBonding/", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Return transportation from Manhattan",
      "Private tour at The Benmarl Winery",
      "Team-building activities at Spins Hudson (archery, laser tag, competitions)",
      "Two nights stay in a beautiful property with high end rooms and facilities",
      "Locally-sourced food from the restaurant",
      "Pool and saunas",
      "Complimentary bikes",
      "Communal spaces for work",
      "Endless amount of hiking trails",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "Morning: departure from Manhattan",
          "Late morning: team-building activities at Spins Hudson (archery, laser tag, competitions)",
          "Afternoon: scenic drive to Eastwind Hotel",
          "Evening: check-in at Eastwind Hotel, welcome reception, and introductions",
          "Dinner: team dinner at Eastwind Hotel's restaurant",
        ],
      },
      {
        name: "Day 2",
        agendaItems: [
          "Morning: breakfast buffet at Eastwind Hotel",
          "Late morning: setting goals and strategy sessions",
          "Lunch: networking lunch at Eastwind Hotel's outdoor terrace",
          "Afternoon: motivational talks and skill-building workshops",
          "Evening: free time for team bonding activities or relaxation",
          "Dinner: group dinner at a local restaurant or Eastwind Hotel's restaurant",
        ],
      },
      {
        name: "Day 3",
        agendaItems: [
          "Morning: breakfast buffet at Eastwind Hotel",
          "Late morning: departure for Benmarl winery",
          "Afternoon: arrival at Benmarl Winery, guided tour, and wine tasting",
          "Lunch: casual lunch at Benmarl winery",
          "Late afternoon: return journey to Manhattan",
          "Evening: arrival in Manhattan ",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Guest speaker",
        description: "Choose a subject matter expert for the topic of your choice.",
      },
      {
        name: "Public Speaking workshop",
        description: "A facilitated workshop to level up presenting skills for the whole team.",
      },
      {
        name: "Product workshops",
        description:
          "Choose from facilitated workshops covering discovery, roadmapping, and goal-setting.",
      },
    ],
    highlights: [
      {
        heading: "Team building activities",
        img: require("./img/experiences/TeamBonding/5e243c_97c3e5c2bace4e86a6244e09da3de28cf000 (1).jpg"),
        description:
          "At Spins Hudson, unleash your team's competitive spirit with adrenaline-pumping activities designed to foster camaraderie and collaboration. Engage in thrilling archery battles, test your tactical skills in laser tag showdowns, and compete in exciting challenges that ignite team synergy. With a range of action-packed games and immersive experiences, Spins Hudson offers the perfect backdrop for bonding and friendly competition.",
      },
      {
        heading: "Hotel stay",
        img: require("./img/experiences/TeamBonding/510d16_8db10b653dfe4daea8b5e6fea5fd0ca9~mv2.jpg"),
        description:
          "Eastwind Hotel, in the scenic Hudson Valley, offers a tranquil escape with luxurious accommodations. Experience elegance, modern amenities, and nature's beauty at this charming retreat. Enjoy unforgettable dining at the onsite Dandelion Restaurant & Bar. Unwind in well-appointed rooms or unique 'glamping' cabins, reconnecting with nature. Discover the charm of the Hudson Valley at Eastwind Hotel.",
      },
      {
        heading: "Food",
        img: require("./img/experiences/TeamBonding/960x0.webp"),
        description:
          "The Eastwind Hotel's onsite restaurant, Dandelion Restaurant & Bar, offers a memorable dining experience in a cozy, welcoming atmosphere. Dandelion's menu showcases local ingredients and flavors, allowing guests to savor expertly prepared dishes and handcrafted cocktails while enjoying the picturesque natural surroundings of the Hudson Valley. With a focus on fresh, seasonal cuisine, Dandelion Restaurant & Bar is the perfect place to unwind and indulge in delectable fare during your stay at the Eastwind Hotel.",
      },
      {
        heading: "Winery visit",
        img: require("./img/experiences/TeamBonding/Barrel-Tasting3-300x184.jpg"),
        description:
          "Nestled amidst the picturesque Hudson Valley, Benmarl Winery invites you on a journey of discovery and delight. Explore the historic vineyard, where each grape tells a story of passion and craftsmanship. Indulge in a guided tour led by the knowledgeable staff, as you immerse yourself in the art of winemaking.",
      },
    ],
    FAQ: [
      {
        question: "Is transportation included in the package?",
        answer: "Yes, transportation is included in the package and can be removed.",
      },
      {
        question: "What accommodation options are available at the estate?",
        answer:
          "Guests will stay in high-end rooms with modern facilities to ensure comfort during their retreat. Each room has an en-suite bathroom.",
      },
      {
        question: "Can we choose our meals?",
        answer:
          "Yes, guests have the option to enjoy chef-prepared meals in the restaurant or bring their own food",
      },
      {
        question:
          "What activities are included besides the winery tour and the team building activity on day 3?",
        answer:
          "Guests can hike, explore the trails, enjoy swimming, engage in team-building activities, workshops, and outdoor games. We can also customize different activities based on the team needs.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, communal workspaces equipped with whiteboards and TVs are provided for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is Wi-Fi available at the estate?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their retreat.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The package can accommodate various team sizes. In this hotel, there are dozens of rooms. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What should we bring for the retreat?",
        answer:
          "Guests should bring comfortable clothing suitable for outdoor activities, any specific dietary preferences or restrictions, and a willingness to unwind and connect with colleagues in a natural setting.",
      },
      {
        question: "Is the retreat suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "hybrid-destination-toronto-ontario",
    tagline: "Elevate Team Productivity and Connectivity",
    longDescription:
      "Enjoy a heartwarming experience in Staples Studio! Enjoy unlimited tea and coffee, relax in reserved lounges, and utilize dedicated workspaces and meeting areas. Whether your team prefers solo work or group collaboration, we've got you covered. Plus, you can enjoy catered food together with the group or opt to have lunch separately. It's all here – productivity, comfort, and connection, all in one coworking Studio.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "staples-studio-university-toronto-ontario",
        location: {
          fullAddress: "375 University Ave #102, Toronto, ON M5G 2J5",
          shortAddress: "Toronto, ON",
          lat: 43.653726427411954,
          long: -79.38677968465785,
        },
        experienceName: "Staples Studio University",
        experienceThumbnail: require("./img/experiences/HybridDestination/4.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/HybridDestination/UNI-Staples_GreatHall_V1_VERT_Ppl_247.jpg"),
    experienceImgs: importAll(
      require.context("./img/experiences/HybridDestination", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: [
      "Access to essential amenities including AV equipment, fully stocked kitchen facilities, convenient proximity to nearby subway stations, located within a 5-minute walk from Toronto's finest corporate hotels.",
      "Opportunities for team bonding, collaboration, and individual productivity in our supportive environment throughout the day.",
      "Dedicated experience host, connecting with leaders onsite and ensuring a seamless event.",
      "Fully equipped workspace options, featuring phone booths, meeting rooms, and versatile event spaces.",
      "Choice of versatile meeting spaces designed for collaborative work and confidential discussions.",
      "Auditorium, perfect for hosting trade shows or engaging in lunch and learn sessions.",
      "Flexibility of hosting monthly office days, with the option to upgrade to a monthly membership and receive bundled discounts.",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 AM - 9:30 AM: Registration and welcome coffee",
          "9:30 AM - 10:30 AM: Introduction session",
          "10:30 AM - 12:30 PM: Strategic foresight and innovation session by CEO",
          "12:30 PM - 1:30 PM: lunch",
          "1:30 PM - 3:30 PM: Rotating Fireside chats with company leaders – Q&A and engagement with employees",
          "3:30 PM - 5:30 PM: Staples Spotlight Activity: Create a beautiful flower bouquet using watercolour pencils and markers (suggested)",
          "6:30 PM onwards: Optional Social – team building activities",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Marketing speaker",
        description: "",
      },
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Additional team building activity",
        description: "",
      },
    ],
    testimonials: [
      {
        testimonial:
          "I have had the pleasure of working with Elsa, Ferawan and Oksana, planning multiple corporate events, and they never disappoint. From the initial planning stages to the actual event day, their incredible eye for detail and support is unmatched. I never want to host an event anywhere else! AV is plug and play, clean facility and washrooms (cleaning staff work hard to keep the space sparkling), amazing perks like hot coffee and tea all day. Thank you, thank you, thank you!",
        image: "experiences/HybridDestination/testimonials/testimonial1.png",
        name: "Heather Rasberry",
        starRating: 5,
      },
      {
        testimonial:
          "Our team is distributed across Ontario and we use the Staples Studio to work together around every month or so. The location is central for all of us, near Union and subway lines. Love the huge windows, the high ceilings, the modern atmosphere. Well-equipped kitchen and GREAT coffee. We make use of hot desks which are super flexible.",
        image: "experiences/HybridDestination/testimonials/testimonial2.png",
        name: "Laurent Gérin",
        starRating: 5,
      },
    ],
    highlights: [
      {
        heading: "Staples Studio",
        img: require("./img/experiences/HybridDestination/UNI-20200307-staples-0449-Edit.jpg"),
        description:
          "Staples Studio University is a coworking space offering 4,500sq ft. of customizable workspace, a fully equipped event space with a projector, streaming device, and a premium A/V system It is perfect for hosting company functions of all sizes (5-70 guests).",
      },
      {
        heading: "Staples Spotlight Activity",
        img: require("./img/experiences/HybridDestination/UNI_CORK-spotlight-flower.jpg"),
        description:
          "Paint a beautiful bouquet of flowers and unleash your creativity. With our Staples Staedtler products, you'll embark on a fun painting activity. And the best part? You get to take home all the supplies and crafts to continue your artistic journey whenever inspiration strikes. Staples Studio and Spotlight are thrilled to introduce on-demand paint activities, offering you a delightful and effortless way to connect with others and treat yourself to a moment of inspiration during your busy workday.",
      },
      {
        heading: "Workshops",
        img: require("./img/experiences/HybridDestination/UNI-Spotlight.png"),
        description:
          "Imagine a Lunch and Learn session like no other, hosted in a versatile and inclusive space. Settle into the theatre-style seating, where every seat offers a perfect view of the action. Utilizing state-of-the-art audiovisual setup, including a projector, a live streaming device, teleconferencing capabilities, and strategically placed speakers, your presentation will be delivered with unparalleled clarity and impact. The Spotlight Space can accommodate up to 70 guests, providing ample room for networking and engagement. Whether it is a corporate training, an industry seminar, or an educational workshop, elevate your workshops to new heights with our exceptional venue.",
      },
      {
        heading: "Networking",
        img: require("./img/experiences/HybridDestination/UNI-imagekitchencounter.jpeg"),
        description:
          "As the workday wraps up, why not plan a social gathering for your colleagues to unwind and celebrate their accomplishments? the kitchen space features a double counter, perfect for setting up a refreshments bar and serving bite-sized treats creating the ideal setting for a fun and wholesome networking session.",
      },
    ],
    FAQ: [
      {
        question: "Is the Studio University space fully accessible?",
        answer:
          "Yes, Studio University is fully accessible and includes entrance ramps at both entrances, ADA door opener, and accessible restrooms.",
      },
      {
        question: "Is Wi-Fi available?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their sessions.",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, Staples Studio is equipped with whiteboards and TVs for productive meetings and brainstorming sessions.",
      },
      {
        question: "Is coffee, tea and water complementary?",
        answer: "There is no charge for coffee, tea, or water.",
      },
      {
        question: "What food options are available?",
        answer:
          "Guests can bring their own catering or ask about elevated, convenient and tested options.",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests will choose activities they want to include and our experience host will advise on adequate space, ensure proper setup, and coordinate logistics where necessary. ",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes. Staples Studio can accommodate up to 70 people comfortably in the Spotlight Space and up to 100 for networking events. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Full or partial buyouts require a minimum cancellation period of 7 business days. Following this period, the company has the option to receive credits for future rescheduling or choose a 50% partial refund.",
      },
    ],
  },

  {
    id: "indoor-outdoor-team-gathering-toronto-ontario",
    tagline: "Combining Indoor Productivity with Patio Relaxation",
    longDescription:
      "Step into the comfort of your own private retreat, equipped with a kitchen, meeting rooms, workspace, presentation area, and outdoor rooftop patio. Invite your team to a distraction-free environment where they can connect and collaborate in person. Enjoy all the tea and coffee you like, relax in the lounge areas, and make use of the different work and meeting spaces available. This plan is perfect for leaders who want to regularly review sales targets, have productive brainstorming sessions, foster relationships through group events, take advantage of face-to-face time with clients, and prioritize employee well-being for better retention.",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "staples-studio-corktown-toronto-ontario",
        location: {
          fullAddress: "517 Richmond St E, Toronto, ON M5A 2W7",
          shortAddress: "Toronto, ON",
          lat: 43.654157525050024,
          long: -79.3644767,
        },
        experienceName: "Staples Studio Corktown",
        experienceThumbnail: require("./img/experiences/IndoorOutdoorTeamGathering/CORK-TIA_STAPLES-OFFICE-5.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/IndoorOutdoorTeamGathering/CORK-TIA_STAPLES-OFFICE-5.jpg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/IndoorOutdoorTeamGathering",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Prime Studio loft is conveniently located within walking distance of the Distillery District, boasting stunning views of downtown skyscrapers.",
      "Wheelchair accessible, adhering to ADA guidelines for inclusivity. Dedicated experience host, connecting with leaders onsite and ensuring a seamless event.",
      "A versatile 1,050 sq ft. indoor loft can be used for presentations with different seating layouts. Includes a workspace featuring two phone booths, and one meeting room.",
      "Spacious 1,800 sq ft. patio area with various seating options including dining tables, high-tables, couch areas, and picnic tables.",
      "Enhanced entertainment features with high-performance speakers and two large TV screens for event activities.",
      "Community building and connections for teams or clients with the option to upgrade to a monthly meetup membership, with bundled discounts.",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "9:00 am - 9:30 am: check-in and welcome over freshly brewed coffee",
          "9:30 am - 10:30 am: empowering sales review and dynamic goal-setting session",
          "10:30 am - 12:30 pm: productive afternoon working session in the workspace with 1-1 meetings with the manager",
          "12:30 pm - 1:30 pm: lunch break with networking opportunities",
          "1:30 pm - 4:30 pm: Staples Spotlight Activity: Create a beautiful flower bouquet using watercolour pencils and markers",
          "4:30 pm - 6:30 pm: relaxed social gathering and networking event on the inviting patio with refreshments",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Marketing speaker",
        description: "",
      },
      {
        name: "Wellness workshop",
        description:
          "A half-day facilitated workshop focusing on wellness, mindfulness, and overall health.",
      },
      {
        name: "Additional team building activity",
        description: "",
      },
    ],

    testimonials: [
      {
        testimonial:
          "We are very happy that we chose Staples Studio for our two-day seminar organization. Not only us, but also our team of 30 people who were with us during the seminar gave positive feedback about the wonderful environment. The indoor space and the fact that you can reach the outdoor space in seconds is a great opportunity. All the technological tools you will use in your presentation (projector, outdoor screens, sound system...) work smoothly and with high quality. Thanks to Staples store that is attached to this office, all the office supplies and services you may use during the training or seminar are just a step away.",
        image: "experiences/IndoorOutdoorTeamGathering/testimonials/testimonial1.png",
        name: "Uğur UZUNER",
        starRating: 5,
      },
      {
        testimonial:
          "I used this space to host a social event and a workshop for my Crypto & Web3 education program, taking advantage of both the beautiful outdoor patio and the modern indoor work space. Elsa and her staff were very helpful and supportive with both events and I look forward to growing this long term partnership even more! Thank you!!",
        image: "experiences/IndoorOutdoorTeamGathering/testimonials/testimonial2.png",
        name: "Ashley J Wright (The Wright Success)",
        starRating: 5,
      },
      {
        testimonial:
          "Had the most amazing experience at Staple Studios Corktown! There is no reason why I would not recommend this place to host an offsite event at your company or just to go and work with your teammates in a different place! The rooftop patio is so comfortable and nice! The indoor space also was perfect to play some games on the big projector. Everything was spotless. Elsa is the best hostess ever, always around to help and with so much charisma! Thanks to Staples Team but special Thanks to Elsa who helped us every step of the way!",
        image: "experiences/IndoorOutdoorTeamGathering/testimonials/testimonial3.png",
        name: "Pamela Enciso",
        starRating: 5,
      },
    ],

    highlights: [
      {
        heading: "Staples Studio",
        img: require("./img/experiences/IndoorOutdoorTeamGathering/CORK-TIA_STAPLES-OFFICE-16-2.jpg"),
        description:
          "Studio Corktown's indoor space is a versatile and multifunctional area that provides you with the freedom to design and set up the space as you see fit. The space is ideal for a variety of events such as masterclasses, lunch and learns, board meetings, quarterly reviews, networking and team socials. The space is fully customizable and can be tailored to your specific requirements, making it an ideal choice for businesses and organizations that require flexibility. Perfect for 10-30 people, Studio Corktown's indoor space can accommodate various needs.",
      },
      {
        heading: "Staples Spotlight Activity",
        img: require("./img/experiences/IndoorOutdoorTeamGathering/UNI_CORK-spotlight-flower.jpg"),
        description:
          "Paint a beautiful bouquet of flowers and unleash your creativity. With Staples Staedtler products, you'll embark on a fun painting activity. And the best part? You get to take home all the supplies and crafts to continue your artistic journey whenever inspiration strikes. Staples Studio and Spotlight are thrilled to introduce on-demand paint activities, offering you a delightful and effortless way to connect with others and treat yourself to a moment of inspiration during your busy workday.",
      },
      {
        heading: "Training sessions",
        img: require("./img/experiences/IndoorOutdoorTeamGathering/CORK-Screenon.jpg"),
        description:
          "Organize your training session with ease with all amenities conveniently nearby for a seamless transition throughout the day. From the workday grind to engaging presentations, and finally, to a satisfying lunch break, everything is within reach. Ensure a smooth flow of activities without interruptions, allowing participants to focus solely on their learning and development goals. With our comprehensive setup, your training session will be both productive and hassle-free.",
      },
      {
        heading: "Patio Social",
        img: require("./img/experiences/IndoorOutdoorTeamGathering/CORK-IMG_4902.jpg"),
        description:
          "Elevate your next social gathering by hosting it on the expansive rooftop patio, offering views of downtown and conveniently situated just a short walk from the iconic Distillery District. With seamless indoor-outdoor flow, there's plenty of space to mingle and unwind. It is perfect for hosting corporate parties and social gatherings. The patio is equipped with A/V equipment, making it possible to host outdoor panel events and short presentations. The patio is furnished with high-end designer furniture, offering a range of seating options from dining-style tables to high-tables, cozy couch areas, and picnic tables. Let the day wrap up with a celebration of achievements and camaraderie under the open sky. ",
      },
    ],
    FAQ: [
      {
        question: "Is the Studio Corktown space fully accessible?",
        answer:
          "Yes, Studio University is fully accessible including entrance ramps at both entrances and to the rooftop patio, ADA door opener, and accessible restrooms, and an elevator to the second floor (where the Studio is located).  ",
      },
      {
        question: "Is Wi-Fi available?",
        answer:
          "Yes, complimentary Wi-Fi is available to ensure guests can stay connected during their sessions. ",
      },
      {
        question: "Are there any work-friendly amenities available?",
        answer:
          "Yes, Staples Studio is equipped with whiteboards and TVs that are provided for productive meetings and brainstorming sessions. ",
      },
      {
        question: "Is Coffee, tea and water complementary?",
        answer: "There is no charge for coffee, tea, or water. ",
      },
      {
        question: "What food options are available?",
        answer:
          "Guests can bring their own catering or ask about elevated, convenient and tested options. ",
      },
      {
        question: "What activities are included?",
        answer:
          "Guests will choose activities they want to include and our experience host will advise on adequate space, ensure proper setup, and coordinate  logistics where necessary.  ",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes. Staples Studio can accommodate up to 75 people comfortably in the Spotlight Space and up to 100 for networking events. Please contact us for specific inquiries regarding large groups. ",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Full or partial buyouts require a minimum cancellation period of 7 business days. Following this period, the company has the option to receive credits for future rescheduling or choose a 50% partial refund. ",
      },
    ],
  },
  {
    id: "team-sushioke-toronto-ontario",
    tagline: "Eat, sing and dance with your team",
    longDescription:
      "Get ready for an unforgettable evening with Sushioke! Imagine the perfect blend of delicious sushi and high-energy karaoke. You and your team will enjoy expertly crafted sushi rolls while taking turns belting out your favorite tunes. It’s a unique experience that combines great food, laughter, and music, fostering team bonding and creating lasting memories. Join us for a night of fun, flavor, and fantastic performances!",
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "roll-this-way-sushi-toronto-ontario",
        location: {
          fullAddress: "14 Logan Ave A, Toronto, ON M4M 2M8",
          shortAddress: "Toronto, ON",
          lat: 43.654645031084584,
          long: -79.34034675767109,
        },
        experienceName: "Roll This Way Sushi",
        experienceThumbnail: require("./img/experiences/Sushioke/JP_005_DSC055541.jpg"),
      },
    ],
    experienceMainImg: require("./img/experiences/Sushioke/JP_025_DSC05632.jpg"),

    experienceImgs: importAll(
      require.context("./img/experiences/Sushioke", false, /\.\/.*\.(png|jpe?g|svg|webp)$/)
    ),
    features: ["Buffet style all-you-can-eat sushi", "Karaoke night", "Sake bombs!"],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "7:00 PM - Welcome and overview",
          "7:15 PM - All-You-Can-Eat sushi",
          "8:00 PM - Karaoke performances begin",
          "9:45 PM - Final performances and closing remarks",
          "10:00 PM - Event ends",
        ],
      },
    ],
    experienceOptions: [
      {
        name: "Meeting venue",
        description:
          "Either or both of our versatile spaces are equipped to host your team meetings before the fun begins.",
      },
      {
        name: "Team building activity",
        description: "",
      },
      {
        name: "Accommodation",
        description: "",
      },
    ],
    highlights: [
      {
        heading: "Sushi",
        img: require("./img/experiences/Sushioke/JP_005_DSC055541.jpg"),
        description:
          "Indulge in an all-you-can-eat sushi experience like no other! Savor an endless variety of freshly made sushi rolls, nigiri, and sashimi, crafted with the finest ingredients by the skilled chefs. From classic favorites to innovative creations, there's something to delight every palate. Enjoy unlimited servings in a vibrant and welcoming atmosphere, perfect for sushi lovers looking to satisfy their cravings. Come hungry and leave happy with this ultimate sushi feast!",
      },
      {
        heading: "Karaoke",
        img: require("./img/experiences/Sushioke/JP_075_DSC05666.jpg"),
        description:
          "Unleash your inner superstar with our exhilarating karaoke experience! Step onto the stage and sing your heart out to your favorite songs, surrounded by friends and colleagues. A state-of-the-art sound system and extensive song library ensure that every performance is a hit. Whether you're a seasoned performer or a first-time singer, the fun and supportive atmosphere will make you feel like a rock star. Get ready for an evening of music, laughter, and unforgettable memories!",
      },
    ],
    FAQ: [
      {
        question: "What is Sushioke?",
        answer:
          "Sushioke is a fun event combining an all-you-can-eat sushi feast with karaoke performances. Enjoy great food and entertainment with your team!",
      },
      {
        question: "Do I need to bring anything?",
        answer: "Just bring yourself and your enthusiasm! Everything else will be provided.",
      },
      {
        question: "What if I don’t want to sing?",
        answer:
          "No problem! You can still enjoy the sushi, cheer on your colleagues, and have a great time.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes from 20 to 70 people. Please contact us for specific inquiries regarding smaller or larger groups.",
      },
      {
        question:
          "Is the experience suitable for individuals with dietary restrictions or allergies?",
        answer:
          "Yes, we accommodate dietary restrictions and allergies. Please inform us in advance, and the chef will ensure suitable meal options are available.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
  {
    id: "communication-funday-toronto-ontario",
    tagline: "A day of building teams’ communication skills",
    longDescription: [
      "It’s time to work on your employees’ communication skills.",
      "Throughout a hands-on day full of activities we will learn new communication skills and sharpen our existing ones.",
      "The day will start with an active listening workshop during which the participants will take part in group and pairs’ exercises to master their listening abilities.",
      "Following that, your team will use the Toast Exercise to learn a simple yet effective way to share their knowledge, while finding gaps and different point-of-views in understanding in the same domain. ",
      "Lastly, we will connect all dots into a combined session with both facilitators. Using a playful activity, team members will practice both skills - active listening and sharing their knowledge - to use these new toolkits into their team dynamics. ",
      "Outcomes of the day:",
      "* Become a great listener and by that gain a stronger and more meaningful connection with others",
      "* Feel comfortable during meetings, even if you are unfamiliar with the topic or speakers",
      "* Unveil the gold people store (creative ideas, objections or criticism) by enabling them to comfortably share",
      "* Understand different point-of-views and how they impact each individual’s emphasis on a common goal",
      "* Gain empathy across team members for different ways of thinking, diversity of experiences, and the words used",
      "* Access a set of simple tools to improve overall team communication skills",
    ],
    priceMessage: "Cost is subject to changes based on food menus, activities etc.",
    experienceLocations: [
      {
        id: "sorry-studio-toronto-ontario",
        location: {
          fullAddress: "219 Dufferin St Unit 207B, Toronto, ON M6K 3J1",
          shortAddress: "Toronto, ON",
          lat: 43.63685493015649,
          long: -79.4263043031508,
        },
        experienceName: "Sorry Studio",
        experienceThumbnail: require("./img/experiences/UrbanStartupSummit/NDK3259.jpeg"),
      },
      {
        id: "tovit-neizer",
        location: {
          fullAddress: "Toronto, ON",
          shortAddress: "Toronto, ON",
          lat: 43.6532,
          long: -79.3832,
        },
        experienceName: "Tovit Neizer",
        experienceThumbnail: require("./img/experiences/CommunicationFunday/Tovit_on stage at a conference Europe.jpeg"),
      },
    ],
    experienceMainImg: require("./img/experiences/CommunicationFunday/Tovit_workshop AMAN.jpeg"),
    experienceImgs: importAll(
      require.context(
        "./img/experiences/CommunicationFunday",
        false,
        /\.\/.*\.(png|jpe?g|svg|webp)$/
      )
    ),
    features: [
      "Active Listening session",
      "Aligning Our Journeys session",
      "Lunch and coffee",
      "Beautiful venue",
    ],
    agenda: [
      {
        name: "Day 1",
        agendaItems: [
          "8:30 - 9:00 Gathering and Coffee",
          "9:00 - 10:30 Morning session with Tovit Neizer,  Active Listening - we’ll learn how to become great listeners and gain a stronger and more meaningful connection with others",
          "10:30 - 11:00 Coffee break",
          "11:00 - 12:30 Noon session with Moshe Mikanovsky, Aligning our journeys, one toast at a time",
          "12:30 - 14:00 Lunch break",
          "14:00 - 15:30 Afternoon session with Tovit and Moshe - active listening and sharing",
          "15:30 - team building activity of your choice",
        ],
      },
    ],
    experienceOptions: [],
    testimonials: [
      {
        testimonial:
          "Tovit assisted in extracting the goals and core messages. Tovit's expertise in crafting compelling narratives is unparalleled and she truly knows how to captivate an audience. I had the pleasure of working with her on a major conference presentation and her guidance was invaluable. Tovit's skills and personality helped me ensure my presentation was not only informative but also entertaining. I will not hesitate working with her again!",
        image: "experiences/CommunicationFunday/testimonials/testimonial1.jpg",
        name: "Tal Koren, Head of Global Division, Lotem Management Consulting",
        starRating: 5,
      },
      {
        testimonial:
          "Tovit definitely knows what she’s talking about and knows how to speak in front of an audience. She brings with her a wealth of experience in the topics she covers and is a talented lecturer, attuned to the audience’s needs and requests and appraised by her listeners.",
        image: "experiences/CommunicationFunday/testimonials/testimonial2.jpg",
        name: "Daphna Sternfeld, Deputy CEO, Head of The Export Institute College",
        starRating: 5,
      },
    ],
    highlights: [
      {
        heading: "Active listening workshop",
        img: require("./img/experiences/CommunicationFunday/Tovit at a workshop.jpeg"),
        description:
          "You sit in meetings most of the day and your career, but how much do you really listen to the room? And how much attention do you pay to the other side? It's time to learn how to really listen and not just nod on autopilot. Tools, practices and a lot of experience that will make you an ultimate listener who aces meetings. Tovit Neizer, a former journalist and a business storyteller, will guide participants through the journalist toolkit that will assist you during meetings. You will learn the principles of active listening, the art of asking questions and the power of silence.",
      },
      {
        heading: "Aligning our journeys, one toast at a time",
        img: require("./img/experiences/CommunicationFunday/Moshe Mikanovsky Workshop 1 (2).png"),
        description:
          "Tired of finding out that you and your colleague have different ways to understand the same process? Do you need an effective way to come up quickly with a unified user journey for a diverse group of users? Veteran product builder, coach, author and MBA instructor Moshe Mikanovsky will walk you through a fun and interactive process to engage with your users or team members to come up with a unified process that covers many different perspectives. You will discover the complexity of people's points of view, a way to find common grounds and edge cases, and leave with a useful tool that you will be able to start using with your team and users right away. And you might even get to go home with a new recipe for a toast!",
      },
      {
        heading: "Sorry Studio",
        img: require("./img/experiences/CommunicationFunday/_MIP5509 (1).jpg"),
        description:
          "Immerse yourself in the creative energy of Sorry Studio, your premier destination for collaboration and innovation in Downtown Toronto. With its modern design and versatile space, Sorry Studio provides the perfect backdrop for productive workshops, brainstorming sessions, and team gatherings. Whether you're seeking inspiration, fostering connections, or unleashing creativity, Sorry Studio offers the ideal environment to make your ideas come to life. Join us at Sorry Studio and elevate your team's potential in a dynamic and vibrant setting.",
      },
    ],
    FAQ: [
      {
        question: "Is accommodation included in the package?",
        answer: "No, but can be added upon request.",
      },
      {
        question: "Is food included in the package?",
        answer: "Yes, but can be removed upon request.",
      },
      {
        question: "What activities are included?",
        answer:
          "Additional team building activities are optional such as cooking class, art class and more. Please enquire for more details.",
      },
      {
        question: "Are there any restrictions on team sizes for booking?",
        answer:
          "The experience can accommodate various team sizes up to 20 people. Please contact us for specific inquiries regarding large groups.",
      },
      {
        question: "What is the cancellation policy?",
        answer:
          "Please refer to our cancellation policy outlined in the terms and conditions of booking. Cancellation policies may vary depending on the time of booking and the proximity to the retreat date.",
      },
    ],
  },
];

export { eventTypes, experienceDetails, experiences };

export type { experienceDetailsType, experienceIndexType, locationType, serviceLocationType };
