import { Link } from "react-router-dom";

import { useGlobalContext } from "../../App";

import { Grid } from "@mui/material";

import AnimatedBackgroundImage from "../../components/animatedBackgroundImage";
import { breakpoints } from "../../theme/breakpoints";

export default function Hero() {
  const setContactQuizActive = useGlobalContext().setContactQuizActive;
  const isMobile = useGlobalContext().isMobile;

  return (
    <>
      {window.innerWidth >= breakpoints.values.md && (
        <Grid
          container
          minHeight={window.innerWidth >= breakpoints.values.md ? "600px" : ""}
          style={{
            backgroundColor: "var(--smoke-green-transparent)",
          }}
        >
          <Grid
            item
            className="landing-div"
            xs={6}
            height="inherit"
            textAlign={isMobile ? "center" : "left"}
            p={isMobile ? "0 4% 1rem 4%" : "4rem 4rem 1rem 4%"}
          >
            <div>
              <h3 style={{ margin: isMobile ? "1rem 0 1rem 0" : "" }}>Bring your team together</h3>
            </div>

            <div>
              <h5
                style={{
                  color: "dimgray",
                  textAlign: "left",
                  margin: isMobile ? "2rem 0 2rem 0" : "",
                }}
              >
                <p style={{ marginBottom: ".5rem" }}>Townish is where great events take place.</p>
                <p>
                  From sourcing the vendors, booking with ease, suggesting agendas, and making it
                  all work perfectly.
                </p>
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: window.innerWidth <= breakpoints.values.xl ? "column" : "row",
                margin: isMobile ? "2rem 0 2rem 0" : "3rem 0 0 0",
              }}
            >
              <div>
                <Link to={"/pages/findExperience"}>
                  <button
                    className="button-forest"
                    style={{
                      marginRight: "1rem",
                      marginTop: ".5rem",
                    }}
                  >
                    <span className="button-text button-text-forest" style={{ fontSize: "18px" }}>
                      Try something new
                    </span>
                  </button>
                </Link>
              </div>

              <div>
                <button
                  className="button-grey"
                  style={{
                    marginRight: "1rem",
                    marginTop: ".5rem",
                  }}
                  onClick={() => setContactQuizActive(true)}
                >
                  <span className="button-text button-text-grey" style={{ fontSize: "18px" }}>
                    Plan with us
                  </span>
                </button>
              </div>
            </div>
          </Grid>

          <Grid
            item
            className="landing-div"
            xs={6}
            height="inherit"
            style={{ padding: "1rem 1rem 1rem 1rem" }}
          >
            <div
              style={{
                overflow: "hidden",
                borderRadius: "3px",
                height: "100%",
              }}
            >
              <AnimatedBackgroundImage />
            </div>
          </Grid>
        </Grid>
      )}
      {window.innerWidth < breakpoints.values.md && (
        <Grid
          container
          minHeight={window.innerWidth >= breakpoints.values.md ? "600px" : ""}
          style={{
            backgroundColor: "var(--smoke-green-transparent)",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              borderRadius: "3px 3px 0 0",
              width: "100%",
              height: "250px",
            }}
          >
            <AnimatedBackgroundImage />
          </div>

          <Grid
            item
            className="landing-div"
            xs={12}
            height="inherit"
            textAlign={"left"}
            p={"0 4% 1rem 4%"}
          >
            <div>
              <h3 style={{ margin: "1rem 0 1rem 0" }}>Bring your team together</h3>
            </div>

            <div>
              <h5
                style={{
                  color: "dimgray",
                  textAlign: "left",
                  margin: "2rem 0 2rem 0",
                }}
              >
                <p style={{ marginBottom: ".5rem" }}>Townish is where great events happen.</p>
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: isMobile ? "center" : "left",
                margin: isMobile ? "2rem 0 2rem 0" : "3rem 0 0 0",
              }}
            >
              <Link to={"/pages/findExperience"}>
                <button
                  className="button-forest"
                  style={{
                    marginRight: "1rem",
                    marginTop: ".5rem",
                  }}
                >
                  <span className="button-text button-text-forest" style={{ fontSize: "18px" }}>
                    Try something new
                  </span>
                </button>
              </Link>

              <div>
                <Link to="/pages/contactQuiz">
                  <button
                    className="button-grey"
                    style={{
                      marginRight: "1rem",
                      marginTop: ".5rem",
                    }}
                  >
                    <span className="button-text button-text-grey" style={{ fontSize: "18px" }}>
                      Plan with us
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}
