import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../App";

import { ReactComponent as Logo } from "../../img/townish_logo_forest.svg";

import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";

import ContactQuizOverlay from "../ContactQuizOverlay";
import "./navbar.css";

const navHeight = ["calc(90px - .75rem)", "calc(220px - .75rem)"];

export default function Navbar(props: {
  float?: boolean; // navbar should scroll with page
  section?: boolean; // navbar should occupy space at the top of the page
  title?: string | undefined;
  contactQuizOpen?: boolean;
  setContactQuizOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  backgroundColor?: string | undefined;
}) {
  const float = props.float ? props.float : false;
  const section = props.section ? props.section : false;
  let [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [currentNavHeight, setCurrentNavHeight] = useState(navHeight[0]);
  // const [experiencesOpen, setExperiencesOpen] = useState(false);
  //const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const setContactQuizActive = useGlobalContext().setContactQuizActive;
  /*
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
*/
  function ToggleQuiz() {
    setContactQuizActive(true);
  }

  const navItems = [
    {
      to: "/pages/costEstimator",
      name: "Budget Estimator",
    },
    {
      to: "/pages/findExperience",
      name: "Experiences",
    },
    {
      ...(!useGlobalContext().isMobile && { onClick: ToggleQuiz }),
      ...(useGlobalContext().isMobile && { to: "/pages/contactQuiz" }),
      name: "Get in Touch",
    },
    {
      to: "/pages/about",
      name: "About",
    },
  ];

  useEffect(() => {
    setCurrentNavHeight(navHeight[Number(mobileNavOpen)]);
  }, [mobileNavOpen]);

  function populateNavItems() {
    return navItems.map((nav) => {
      if (nav.to) {
        return (
          <Grid item key={"nav-grid-" + nav.name}>
            <Link className="link-text link-text-forest" to={nav.to} key={"nav-link-" + nav.name}>
              {nav.name}
            </Link>
          </Grid>
        );
      } else if (nav.onClick) {
        return (
          <Grid item key={"nav-grid-" + nav.name}>
            <span
              className="link-text link-text-forest"
              key={"nav-span-" + nav.name}
              onClick={nav.onClick}
              style={{ cursor: "pointer" }}
            >
              {nav.name}
            </span>
          </Grid>
        );
      } else {
        console.log("unknown navItem in populateNavItems()");
        return <></>;
      }
    }); /*
      .concat(
        <Grid item key={"nav-grid-signin"}>
          <Link className="link-text link-text-forest" to="" key={"nav-link-signin"}>
            Sign In
          </Link>
        </Grid>
      );*/
  }

  return (
    <section
      className="landing-section"
      style={
        section
          ? {
              height: "flex",
              paddingTop: ".5rem",
              position: "relative",
            }
          : { padding: 0, position: "relative" }
      }
    >
      <div
        className="landing-div"
        style={{
          height: float ? currentNavHeight : section ? currentNavHeight : 0,
          position: "relative",
          width: "inherit",
          display: "flex",
          justifyContent: "center",
          zIndex: 11,
        }}
      >
        <Grid
          container
          width={{ xs: "90%", lg: "84%" }}
          justifyContent="center"
          style={{
            backgroundColor: "white",
            borderRadius: "30px",
            marginTop: ".5rem",
            padding: ".5rem 1rem",
            zIndex: 10,
            position: float ? "fixed" : "absolute",

            left: "auto",
          }}
        >
          <Grid container item xs={2.5} justifyContent={"left"}>
            <Link to={"/"} style={{ height: "43px" }}>
              <Logo style={{ filter: "brightness(0%)" }} />
            </Link>
          </Grid>
          <Grid container item xs={9.5} justifyContent="right">
            {!useGlobalContext().isMobile && (
              <Grid container item justifyContent="right" alignItems="center" spacing={"1.5rem"}>
                {populateNavItems()}
                <Grid item>
                  <Link to={"/pages/findExperience/"}>
                    <button className="button-small button-lime">
                      <span className="button-text-lime small-button-text">Get started</span>
                    </button>
                  </Link>
                </Grid>
              </Grid>
            )}
            {useGlobalContext().isMobile && (
              <Grid
                container
                item
                justifyContent="right"
                alignItems="center"
                height={mobileNavOpen ? "50px" : 0}
              >
                <button
                  onClick={(e: any) => setMobileNavOpen(!mobileNavOpen)}
                  style={{
                    background: "transparent",
                    border: "none",
                    marginLeft: "auto",
                  }}
                >
                  {!mobileNavOpen && (
                    <MenuIcon style={{ color: "var(--forest-green)", fontSize: 43 }} />
                  )}
                  {mobileNavOpen && (
                    <CloseIcon style={{ color: "var(--forest-green)", fontSize: 43 }} />
                  )}
                </button>
              </Grid>
            )}
            {mobileNavOpen && (
              <Grid
                container
                direction="column"
                justifyContent="right"
                textAlign="right"
                spacing={1}
                pr=".5rem"
                pb=".5rem"
              >
                {populateNavItems()}
              </Grid>
            )}
          </Grid>
        </Grid>
        {useGlobalContext().contactQuizActive && <ContactQuizOverlay />}
      </div>
    </section>
  );
}

/*
  return (
    <section
      className="landing-section"
      style={section ? { height: "150px", width: "100%", paddingTop: ".5rem" } : { padding: 0 }}
    >
      <div
        className="landing-div"
        style={{
          display: "flex",
          alignItems: "center",
          height: section ? "8.55rem" : 0,
        }}
      >
        <div
          style={
            float
              ? {
                  position: "fixed",
                  width: "100%",
                  zIndex: 100,
                  display: "flex",
                  alignItems: "center",
                }
              : {}
          }
        >
          <Grid
            container
            m={{ xs: "auto 5% auto 5%", lg: "2rem 8% 4rem 8%" }}
            width={{ xs: "90%", lg: "84%" }}
            position="absolute"
            alignItems={"center"}
            style={{
              backgroundColor: "rgba(255, 255, 255, .8)",
              borderRadius: "30px",
              padding: ".5rem 1rem .5rem 1rem",
              zIndex: 10,
            }}
          >
            <Grid container item xs={2.5} justifyContent={"left"} alignItems="center">
              <Link to={"/"}>
                <Logo style={{ filter: "brightness(0%)" }} />
              </Link>
            </Grid>
            <Grid container item xs={9.5} justifyContent="right" alignItems="center">
              {!useGlobalContext().isMobile && props.title && (
                <h4 style={{ color: "var(--forest-green)", margin: "0 1rem 0 0" }}>
                  {props.title}
                </h4>
              )}
              {!useGlobalContext().isMobile && !props.title && (
                <Grid container item justifyContent="right" alignItems="center" spacing={"2rem"}>
                  {populateNavItems()}
                  <Grid item>
                    <button
                      className="button-lime button-small"
                      onClick={(e) => setContactQuizActive(true)}
                    >
                      <span className="small-button-text button-text-lime">Get started</span>
                    </button>
                  </Grid>
                </Grid>
              )}
              {useGlobalContext().isMobile && !mobileNavOpen && (
                <Grid container item justifyContent="right" alignItems="center">
                  <button
                    onClick={(e: any) => setMobileNavOpen(!mobileNavOpen)}
                    style={{ background: "transparent", border: "none", marginLeft: "auto" }}
                  >
                    <MenuIcon style={{ color: "var(--forest-green)", fontSize: 50 }} />
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Popper
            id={"navbar-experiences-popper"}
            placement={"bottom"}
            open={experiencesOpen}
            anchorEl={anchorEl}
            style={{ zIndex: 11 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px solid",
                borderColor: "var(--forest-green)",
                borderRadius: "5px",
                padding: "1rem",
                marginTop: "1.5rem",
                backgroundColor: "var(--almost-white)",
              }}
            >
              {eventTypes.map((et) => {
                return (
                  <Link
                    className="link-text link-text-forest"
                    to={"/pages/findExperience/" + encodeURIComponent(et.name) + "/any"}
                    style={{ marginTop: "1rem" }}
                    key={"navbar-link-" + et.name}
                  >
                    {et.name}
                  </Link>
                );
              })}
            </div>
          </Popper>
          {useGlobalContext().contactQuizActive && <ContactQuizOverlay />}
        </div>
      </div>
    </section>
  );
}
*/
