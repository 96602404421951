import { Link } from "react-router-dom";
import { useGlobalContext } from "../../App";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ExperienceFAQ, { FAQType } from "../experienceDetail/experienceFAQ/experienceFAQ";
import { Avatar, Grid } from "@mui/material";

const FAQ: FAQType[] = [
  {
    question: "How does the pricing work?",
    answer:
      "Our service partners offer preferred rates when events are booked through Townish. We add 10% to make our planning & logistical support possible, but this is usually offset by our exclusive rates with vendors saving you money overall!",
  },
  {
    question: "What if I want something different than the experiences listed?",
    answer:
      "No problem at all! Click the Get Started button above to get in touch with us and we’ll be able to help you.",
  },
  {
    question: "Are there any hidden fees?",
    answer: "No. Your cost for the event is the price listed on Townish.",
  },
  {
    question: "How are the experiences curated?",
    answer:
      "Our curators select geographies with the ideal combination of offerings and create experiences that combine fun, work, and team building. Once we've worked with a vendor, we expand the set of experiences offered through them.",
  },
];

export default function HowItWorks() {
  const setContactQuizActive = useGlobalContext().setContactQuizActive;
  const isMobile = useGlobalContext().isMobile;

  return (
    <>
      <Navbar section />
      <section className="landing-section">
        <div
          className="landing-div"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <div
            style={{
              width: isMobile ? "100%" : "80%",
              margin: "0 auto 0 auto",
            }}
          >
            <h3>Book your next experience with Townish</h3>

            <Grid container direction="row" justifyContent={"center"}>
              <Grid
                container
                item
                xs={12}
                lg={4}
                direction="column"
                alignItems={"center"}
                padding="1rem"
              >
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    backgroundColor: "var(--forest-green)",
                    mb: "2rem",
                  }}
                >
                  <h3 style={{ fontFamily: "Roboto", color: "white" }}>1</h3>
                </Avatar>
                <span
                  className="desktop-body"
                  style={{ fontWeight: "bold", fontSize: 24, marginBottom: "1rem" }}
                >
                  Browse Experiences
                </span>
                <span className="desktop-body" style={{ textAlign: "left" }}>
                  Check out the list of experiences and use the filters to find one that fits your
                  needs. Feel free to reach out if you have any questions.
                </span>
              </Grid>

              <Grid
                container
                item
                xs={12}
                lg={4}
                direction="column"
                alignItems={"center"}
                padding="1rem"
              >
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    backgroundColor: "var(--forest-green)",
                    mb: "2rem",
                  }}
                >
                  <h3 style={{ fontFamily: "Roboto", color: "white" }}>2</h3>
                </Avatar>
                <span
                  className="desktop-body"
                  style={{ fontWeight: "bold", fontSize: 24, marginBottom: "1rem" }}
                >
                  Request a quote
                </span>
                <span className="desktop-body" style={{ textAlign: "left" }}>
                  We'll confirm availability and any customizations, and then finalize the pricing.
                  There's no commitment at this stage.
                </span>
              </Grid>

              <Grid
                container
                item
                xs={12}
                lg={4}
                direction="column"
                alignItems={"center"}
                padding="1rem"
              >
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    backgroundColor: "var(--forest-green)",
                    mb: "2rem",
                  }}
                >
                  <h3 style={{ fontFamily: "Roboto", color: "white" }}>3</h3>
                </Avatar>
                <span
                  className="desktop-body"
                  style={{ fontWeight: "bold", fontSize: 24, marginBottom: "1rem" }}
                >
                  Enjoy
                </span>
                <span className="desktop-body" style={{ textAlign: "left" }}>
                  You’re all set! We’ll be responsible for speaking with the vendors and making sure
                  the experience will be perfect.
                </span>
              </Grid>
            </Grid>

            {/*
              <Grid
                container
                item
                xs={12}
                lg={4}
                direction="column"
                alignItems={"center"}
                padding="1rem"
                height="100%"
              >

                <span
                  className="desktop-body"
                  style={{ fontWeight: "bold", fontSize: 24, marginBottom: "1rem" }}
                >
                  Browse Experiences
                </span>
                <span className="desktop-body">
                  Check out the list of experiences and use the filters to find one that fits your
                  needs.
                </span>
              </Grid>

              <Grid
                container
                item
                xs={12}
                lg={4}
                direction="column"
                alignItems={"center"}
                padding="1rem"
              >
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    backgroundColor: "#FFFFFF",
                    mb: "2rem",
                    border: "3px solid #1A73E8",
                  }}
                >
                  <h3 style={{ fontFamily: "Roboto", color: "#1A73E8" }}>2</h3>
                </Avatar>
                <span
                  className="desktop-body"
                  style={{ fontWeight: "bold", fontSize: 24, marginBottom: "1rem" }}
                >
                  Request a quote
                </span>
                <span className="desktop-body">
                  We'll confirm availability and any customizations that you'd like, and then
                  finalize the pricing (which usually lands close to the price listed on Townish).
                  There's no commitment at this stage.
                </span>
              </Grid>

              <Grid
                container
                item
                xs={12}
                lg={4}
                direction="column"
                alignItems={"center"}
                padding="1rem"
              >
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    backgroundColor: "#FFFFFF",
                    mb: "2rem",
                    border: "3px solid #1A73E8",
                  }}
                >
                  <h3 style={{ fontFamily: "Roboto", color: "#1A73E8" }}>3</h3>
                </Avatar>
                <span
                  className="desktop-body"
                  style={{ fontWeight: "bold", fontSize: 24, marginBottom: "1rem" }}
                >
                  Enjoy
                </span>
                <span className="desktop-body">
                  You’re all set! We’ll be responsible for speaking with the vendors and making sure
                  the experience will be perfect.
                </span>
                </Grid> </div>
            </Grid>*/}
          </div>
        </div>
      </section>
      <section className="landing-section">
        <div
          className="landing-div"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem",
          }}
        >
          <div
            style={{
              width: isMobile ? "100%" : "80%",
              margin: "0 auto 0 auto",
            }}
          >
            <h4>Townish is where great team experiences take place.</h4>
            {isMobile && (
              <Link to="/pages/contactQuiz">
                <button className="button-lime">
                  <span className="button-text button-text-lime">Get Started</span>
                </button>
              </Link>
            )}
            {!isMobile && (
              <button className="button-lime" onClick={() => setContactQuizActive(true)}>
                <span className="button-text button-text-lime">Get Started</span>
              </button>
            )}

            <ExperienceFAQ FAQ={FAQ} />
          </div>
        </div>
      </section>
      <section className="landing-section">
        <div className="landing-div">
          <Footer />
        </div>
      </section>
    </>
  );
}
