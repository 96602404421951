/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getService = /* GraphQL */ `query GetService($id: ID!) {
  getService(id: $id) {
    id
    serviceName
    serviceLocation
    serviceImgPath
    serviceMainImg
    serviceHeading
    serviceSubheading
    servicePrice
    servicePriceType
    serviceAdditionalInfo
    serviceIdealOccupancy
    serviceMaxOccupancy
    serviceDescription
    serviceVendorEmail
    serviceURL
    showCategoryImage
    WorkFunTeam
    amenities {
      items {
        id
        serviceId
        amenityId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        serviceId
        subCategoryId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    packages {
      items {
        id
        serviceId
        packageId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    tags {
      items {
        id
        serviceId
        tagId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceQueryVariables,
  APITypes.GetServiceQuery
>;
export const listServices = /* GraphQL */ `query ListServices(
  $id: ID
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listServices(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      serviceName
      serviceLocation
      serviceImgPath
      serviceMainImg
      serviceHeading
      serviceSubheading
      servicePrice
      servicePriceType
      serviceAdditionalInfo
      serviceIdealOccupancy
      serviceMaxOccupancy
      serviceDescription
      serviceVendorEmail
      serviceURL
      showCategoryImage
      WorkFunTeam
      amenities {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      packages {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesQueryVariables,
  APITypes.ListServicesQuery
>;
export const getMainCategory = /* GraphQL */ `query GetMainCategory($id: ID!) {
  getMainCategory(id: $id) {
    id
    categoryName
    categoryDescription
    categoryImg
    categoryIcon
    categorySequence
    columns
    rows
    collapse
    matchmaking
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMainCategoryQueryVariables,
  APITypes.GetMainCategoryQuery
>;
export const listMainCategories = /* GraphQL */ `query ListMainCategories(
  $id: ID
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMainCategories(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      categoryName
      categoryDescription
      categoryImg
      categoryIcon
      categorySequence
      columns
      rows
      collapse
      matchmaking
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMainCategoriesQueryVariables,
  APITypes.ListMainCategoriesQuery
>;
export const getSubCategory = /* GraphQL */ `query GetSubCategory($id: ID!) {
  getSubCategory(id: $id) {
    id
    parentCategoryID
    subCategoryName
    subCategoryImg
    matchmaking
    WorkFunTeam
    services {
      items {
        id
        serviceId
        subCategoryId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    bookables {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubCategoryQueryVariables,
  APITypes.GetSubCategoryQuery
>;
export const listSubCategories = /* GraphQL */ `query ListSubCategories(
  $id: ID
  $filter: ModelSubCategoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSubCategories(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      parentCategoryID
      subCategoryName
      subCategoryImg
      matchmaking
      WorkFunTeam
      services {
        nextToken
        __typename
      }
      bookables {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubCategoriesQueryVariables,
  APITypes.ListSubCategoriesQuery
>;
export const getPackage = /* GraphQL */ `query GetPackage($id: ID!) {
  getPackage(id: $id) {
    id
    packageName
    packageLocation
    packageImgs
    packageVibe
    packageVenueTheme
    packageInterestingCategories
    packageDescription
    packageSubheading
    PackageOccupancy
    packageNumNights
    packagePrice
    packagePriceType
    packageCategoryID
    WorkFunTeam
    packageSchedule
    services {
      items {
        id
        serviceId
        packageId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    tags {
      items {
        id
        packageId
        tagId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPackageQueryVariables,
  APITypes.GetPackageQuery
>;
export const listPackages = /* GraphQL */ `query ListPackages(
  $id: ID
  $filter: ModelPackageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPackages(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      packageName
      packageLocation
      packageImgs
      packageVibe
      packageVenueTheme
      packageInterestingCategories
      packageDescription
      packageSubheading
      PackageOccupancy
      packageNumNights
      packagePrice
      packagePriceType
      packageCategoryID
      WorkFunTeam
      packageSchedule
      services {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPackagesQueryVariables,
  APITypes.ListPackagesQuery
>;
export const getAmenity = /* GraphQL */ `query GetAmenity($id: ID!) {
  getAmenity(id: $id) {
    id
    amenityName
    amenityIcon
    services {
      items {
        id
        serviceId
        amenityId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    vendors {
      items {
        id
        amenityId
        vendorVendorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAmenityQueryVariables,
  APITypes.GetAmenityQuery
>;
export const listAmenities = /* GraphQL */ `query ListAmenities(
  $id: ID
  $filter: ModelAmenityFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAmenities(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      amenityName
      amenityIcon
      services {
        nextToken
        __typename
      }
      vendors {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAmenitiesQueryVariables,
  APITypes.ListAmenitiesQuery
>;
export const getTag = /* GraphQL */ `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    tagName
    tagSubheading
    tagImg
    services {
      items {
        id
        serviceId
        tagId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    packages {
      items {
        id
        packageId
        tagId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagQueryVariables, APITypes.GetTagQuery>;
export const listTags = /* GraphQL */ `query ListTags(
  $id: ID
  $filter: ModelTagFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTags(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tagName
      tagSubheading
      tagImg
      services {
        nextToken
        __typename
      }
      packages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTagsQueryVariables, APITypes.ListTagsQuery>;
export const getServiceBooking = /* GraphQL */ `query GetServiceBooking($id: ID!) {
  getServiceBooking(id: $id) {
    id
    parentEvent {
      id
      eventName
      eventDescription
      eventStartDateTime
      eventDuration
      eventNumAttendees
      eventOrganization
      eventCreator
      eventCreatorEmail
      eventCreatorID
      eventShow24h
      eventTimezone
      eventServices {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    bookingServiceID
    bookingName
    bookingDescription
    bookingType
    bookingBackgroundColor
    bookingStartDateTime
    bookingDuration
    bookingDurationUnit
    bookingStatus
    bookingAudit {
      items {
        id
        bookingAction
        bookingActor
        bookingAuditDetails
        createdAt
        updatedAt
        serviceBookingBookingAuditId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    eventEventServicesId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceBookingQueryVariables,
  APITypes.GetServiceBookingQuery
>;
export const listServiceBookings = /* GraphQL */ `query ListServiceBookings(
  $id: ID
  $filter: ModelServiceBookingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listServiceBookings(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      parentEvent {
        id
        eventName
        eventDescription
        eventStartDateTime
        eventDuration
        eventNumAttendees
        eventOrganization
        eventCreator
        eventCreatorEmail
        eventCreatorID
        eventShow24h
        eventTimezone
        createdAt
        updatedAt
        __typename
      }
      bookingServiceID
      bookingName
      bookingDescription
      bookingType
      bookingBackgroundColor
      bookingStartDateTime
      bookingDuration
      bookingDurationUnit
      bookingStatus
      bookingAudit {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventEventServicesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServiceBookingsQueryVariables,
  APITypes.ListServiceBookingsQuery
>;
export const getServiceBookingAudit = /* GraphQL */ `query GetServiceBookingAudit($id: ID!) {
  getServiceBookingAudit(id: $id) {
    id
    parentBooking {
      id
      parentEvent {
        id
        eventName
        eventDescription
        eventStartDateTime
        eventDuration
        eventNumAttendees
        eventOrganization
        eventCreator
        eventCreatorEmail
        eventCreatorID
        eventShow24h
        eventTimezone
        createdAt
        updatedAt
        __typename
      }
      bookingServiceID
      bookingName
      bookingDescription
      bookingType
      bookingBackgroundColor
      bookingStartDateTime
      bookingDuration
      bookingDurationUnit
      bookingStatus
      bookingAudit {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventEventServicesId
      __typename
    }
    bookingAction
    bookingActor
    bookingAuditDetails
    createdAt
    updatedAt
    serviceBookingBookingAuditId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServiceBookingAuditQueryVariables,
  APITypes.GetServiceBookingAuditQuery
>;
export const listServiceBookingAudits = /* GraphQL */ `query ListServiceBookingAudits(
  $id: ID
  $filter: ModelServiceBookingAuditFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listServiceBookingAudits(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      parentBooking {
        id
        bookingServiceID
        bookingName
        bookingDescription
        bookingType
        bookingBackgroundColor
        bookingStartDateTime
        bookingDuration
        bookingDurationUnit
        bookingStatus
        createdAt
        updatedAt
        eventEventServicesId
        __typename
      }
      bookingAction
      bookingActor
      bookingAuditDetails
      createdAt
      updatedAt
      serviceBookingBookingAuditId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServiceBookingAuditsQueryVariables,
  APITypes.ListServiceBookingAuditsQuery
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    eventName
    eventDescription
    eventStartDateTime
    eventDuration
    eventNumAttendees
    eventOrganization
    eventCreator
    eventCreatorEmail
    eventCreatorID
    eventShow24h
    eventTimezone
    eventServices {
      items {
        id
        bookingServiceID
        bookingName
        bookingDescription
        bookingType
        bookingBackgroundColor
        bookingStartDateTime
        bookingDuration
        bookingDurationUnit
        bookingStatus
        createdAt
        updatedAt
        eventEventServicesId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $id: ID
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEvents(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      eventName
      eventDescription
      eventStartDateTime
      eventDuration
      eventNumAttendees
      eventOrganization
      eventCreator
      eventCreatorEmail
      eventCreatorID
      eventShow24h
      eventTimezone
      eventServices {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const getRSVP = /* GraphQL */ `query GetRSVP($id: ID!) {
  getRSVP(id: $id) {
    id
    eventID
    RSVPStatus
    RSVPName
    RSVPEmail
    RSVPNotes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRSVPQueryVariables, APITypes.GetRSVPQuery>;
export const listRSVPS = /* GraphQL */ `query ListRSVPS(
  $id: ID
  $filter: ModelRSVPFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRSVPS(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      eventID
      RSVPStatus
      RSVPName
      RSVPEmail
      RSVPNotes
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRSVPSQueryVariables, APITypes.ListRSVPSQuery>;
export const getVendor = /* GraphQL */ `query GetVendor($vendorID: ID!) {
  getVendor(vendorID: $vendorID) {
    vendorID
    vendorName
    vendorSubheading
    vendorDescription
    vendorImgPath
    vendorMainImg
    vendorVendorEmail
    amenities {
      items {
        id
        amenityId
        vendorVendorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    bookables {
      items {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookablePrice
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        __typename
      }
      nextToken
      __typename
    }
    vendorExperiences
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetVendorQueryVariables, APITypes.GetVendorQuery>;
export const listVendors = /* GraphQL */ `query ListVendors(
  $vendorID: ID
  $filter: ModelVendorFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVendors(
    vendorID: $vendorID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      vendorID
      vendorName
      vendorSubheading
      vendorDescription
      vendorImgPath
      vendorMainImg
      vendorVendorEmail
      amenities {
        nextToken
        __typename
      }
      bookables {
        nextToken
        __typename
      }
      vendorExperiences
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVendorsQueryVariables,
  APITypes.ListVendorsQuery
>;
export const getBookable = /* GraphQL */ `query GetBookable($bookableID: ID!) {
  getBookable(bookableID: $bookableID) {
    bookableID
    parentVendor {
      vendorID
      vendorName
      vendorSubheading
      vendorDescription
      vendorImgPath
      vendorMainImg
      vendorVendorEmail
      amenities {
        nextToken
        __typename
      }
      bookables {
        nextToken
        __typename
      }
      vendorExperiences
      createdAt
      updatedAt
      __typename
    }
    bookableName
    bookableSubheading
    bookableDescription
    bookableLocation
    bookableImgPath
    bookableMainImg
    bookablePrice
    bookableDuration
    bookableMinOccupancy
    bookableMaxOccupancy
    categories {
      items {
        id
        subCategoryId
        bookableBookableID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    vendorBookablesVendorID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookableQueryVariables,
  APITypes.GetBookableQuery
>;
export const listBookables = /* GraphQL */ `query ListBookables(
  $bookableID: ID
  $filter: ModelBookableFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBookables(
    bookableID: $bookableID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      bookableID
      parentVendor {
        vendorID
        vendorName
        vendorSubheading
        vendorDescription
        vendorImgPath
        vendorMainImg
        vendorVendorEmail
        vendorExperiences
        createdAt
        updatedAt
        __typename
      }
      bookableName
      bookableSubheading
      bookableDescription
      bookableLocation
      bookableImgPath
      bookableMainImg
      bookablePrice
      bookableDuration
      bookableMinOccupancy
      bookableMaxOccupancy
      categories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vendorBookablesVendorID
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookablesQueryVariables,
  APITypes.ListBookablesQuery
>;
export const getServicesAmenities = /* GraphQL */ `query GetServicesAmenities($id: ID!) {
  getServicesAmenities(id: $id) {
    id
    serviceId
    amenityId
    service {
      id
      serviceName
      serviceLocation
      serviceImgPath
      serviceMainImg
      serviceHeading
      serviceSubheading
      servicePrice
      servicePriceType
      serviceAdditionalInfo
      serviceIdealOccupancy
      serviceMaxOccupancy
      serviceDescription
      serviceVendorEmail
      serviceURL
      showCategoryImage
      WorkFunTeam
      amenities {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      packages {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    amenity {
      id
      amenityName
      amenityIcon
      services {
        nextToken
        __typename
      }
      vendors {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServicesAmenitiesQueryVariables,
  APITypes.GetServicesAmenitiesQuery
>;
export const listServicesAmenities = /* GraphQL */ `query ListServicesAmenities(
  $filter: ModelServicesAmenitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  listServicesAmenities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      serviceId
      amenityId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      amenity {
        id
        amenityName
        amenityIcon
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesAmenitiesQueryVariables,
  APITypes.ListServicesAmenitiesQuery
>;
export const getServicesCategories = /* GraphQL */ `query GetServicesCategories($id: ID!) {
  getServicesCategories(id: $id) {
    id
    serviceId
    subCategoryId
    service {
      id
      serviceName
      serviceLocation
      serviceImgPath
      serviceMainImg
      serviceHeading
      serviceSubheading
      servicePrice
      servicePriceType
      serviceAdditionalInfo
      serviceIdealOccupancy
      serviceMaxOccupancy
      serviceDescription
      serviceVendorEmail
      serviceURL
      showCategoryImage
      WorkFunTeam
      amenities {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      packages {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    subCategory {
      id
      parentCategoryID
      subCategoryName
      subCategoryImg
      matchmaking
      WorkFunTeam
      services {
        nextToken
        __typename
      }
      bookables {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServicesCategoriesQueryVariables,
  APITypes.GetServicesCategoriesQuery
>;
export const listServicesCategories = /* GraphQL */ `query ListServicesCategories(
  $filter: ModelServicesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  listServicesCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      subCategoryId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        matchmaking
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesCategoriesQueryVariables,
  APITypes.ListServicesCategoriesQuery
>;
export const getServicesPackages = /* GraphQL */ `query GetServicesPackages($id: ID!) {
  getServicesPackages(id: $id) {
    id
    serviceId
    packageId
    service {
      id
      serviceName
      serviceLocation
      serviceImgPath
      serviceMainImg
      serviceHeading
      serviceSubheading
      servicePrice
      servicePriceType
      serviceAdditionalInfo
      serviceIdealOccupancy
      serviceMaxOccupancy
      serviceDescription
      serviceVendorEmail
      serviceURL
      showCategoryImage
      WorkFunTeam
      amenities {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      packages {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    package {
      id
      packageName
      packageLocation
      packageImgs
      packageVibe
      packageVenueTheme
      packageInterestingCategories
      packageDescription
      packageSubheading
      PackageOccupancy
      packageNumNights
      packagePrice
      packagePriceType
      packageCategoryID
      WorkFunTeam
      packageSchedule
      services {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServicesPackagesQueryVariables,
  APITypes.GetServicesPackagesQuery
>;
export const listServicesPackages = /* GraphQL */ `query ListServicesPackages(
  $filter: ModelServicesPackagesFilterInput
  $limit: Int
  $nextToken: String
) {
  listServicesPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      serviceId
      packageId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      package {
        id
        packageName
        packageLocation
        packageImgs
        packageVibe
        packageVenueTheme
        packageInterestingCategories
        packageDescription
        packageSubheading
        PackageOccupancy
        packageNumNights
        packagePrice
        packagePriceType
        packageCategoryID
        WorkFunTeam
        packageSchedule
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesPackagesQueryVariables,
  APITypes.ListServicesPackagesQuery
>;
export const getServicesTags = /* GraphQL */ `query GetServicesTags($id: ID!) {
  getServicesTags(id: $id) {
    id
    serviceId
    tagId
    service {
      id
      serviceName
      serviceLocation
      serviceImgPath
      serviceMainImg
      serviceHeading
      serviceSubheading
      servicePrice
      servicePriceType
      serviceAdditionalInfo
      serviceIdealOccupancy
      serviceMaxOccupancy
      serviceDescription
      serviceVendorEmail
      serviceURL
      showCategoryImage
      WorkFunTeam
      amenities {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      packages {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    tag {
      id
      tagName
      tagSubheading
      tagImg
      services {
        nextToken
        __typename
      }
      packages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetServicesTagsQueryVariables,
  APITypes.GetServicesTagsQuery
>;
export const listServicesTags = /* GraphQL */ `query ListServicesTags(
  $filter: ModelServicesTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  listServicesTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      serviceId
      tagId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        tagName
        tagSubheading
        tagImg
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListServicesTagsQueryVariables,
  APITypes.ListServicesTagsQuery
>;
export const getBookablesCategories = /* GraphQL */ `query GetBookablesCategories($id: ID!) {
  getBookablesCategories(id: $id) {
    id
    subCategoryId
    bookableBookableID
    subCategory {
      id
      parentCategoryID
      subCategoryName
      subCategoryImg
      matchmaking
      WorkFunTeam
      services {
        nextToken
        __typename
      }
      bookables {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    bookable {
      bookableID
      parentVendor {
        vendorID
        vendorName
        vendorSubheading
        vendorDescription
        vendorImgPath
        vendorMainImg
        vendorVendorEmail
        vendorExperiences
        createdAt
        updatedAt
        __typename
      }
      bookableName
      bookableSubheading
      bookableDescription
      bookableLocation
      bookableImgPath
      bookableMainImg
      bookablePrice
      bookableDuration
      bookableMinOccupancy
      bookableMaxOccupancy
      categories {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      vendorBookablesVendorID
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookablesCategoriesQueryVariables,
  APITypes.GetBookablesCategoriesQuery
>;
export const listBookablesCategories = /* GraphQL */ `query ListBookablesCategories(
  $filter: ModelBookablesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookablesCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subCategoryId
      bookableBookableID
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        matchmaking
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      bookable {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookablePrice
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookablesCategoriesQueryVariables,
  APITypes.ListBookablesCategoriesQuery
>;
export const getPackagesTags = /* GraphQL */ `query GetPackagesTags($id: ID!) {
  getPackagesTags(id: $id) {
    id
    packageId
    tagId
    package {
      id
      packageName
      packageLocation
      packageImgs
      packageVibe
      packageVenueTheme
      packageInterestingCategories
      packageDescription
      packageSubheading
      PackageOccupancy
      packageNumNights
      packagePrice
      packagePriceType
      packageCategoryID
      WorkFunTeam
      packageSchedule
      services {
        nextToken
        __typename
      }
      tags {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    tag {
      id
      tagName
      tagSubheading
      tagImg
      services {
        nextToken
        __typename
      }
      packages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPackagesTagsQueryVariables,
  APITypes.GetPackagesTagsQuery
>;
export const listPackagesTags = /* GraphQL */ `query ListPackagesTags(
  $filter: ModelPackagesTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  listPackagesTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      packageId
      tagId
      package {
        id
        packageName
        packageLocation
        packageImgs
        packageVibe
        packageVenueTheme
        packageInterestingCategories
        packageDescription
        packageSubheading
        PackageOccupancy
        packageNumNights
        packagePrice
        packagePriceType
        packageCategoryID
        WorkFunTeam
        packageSchedule
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        tagName
        tagSubheading
        tagImg
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPackagesTagsQueryVariables,
  APITypes.ListPackagesTagsQuery
>;
export const getVendorsAmenities = /* GraphQL */ `query GetVendorsAmenities($id: ID!) {
  getVendorsAmenities(id: $id) {
    id
    amenityId
    vendorVendorID
    amenity {
      id
      amenityName
      amenityIcon
      services {
        nextToken
        __typename
      }
      vendors {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    vendor {
      vendorID
      vendorName
      vendorSubheading
      vendorDescription
      vendorImgPath
      vendorMainImg
      vendorVendorEmail
      amenities {
        nextToken
        __typename
      }
      bookables {
        nextToken
        __typename
      }
      vendorExperiences
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVendorsAmenitiesQueryVariables,
  APITypes.GetVendorsAmenitiesQuery
>;
export const listVendorsAmenities = /* GraphQL */ `query ListVendorsAmenities(
  $filter: ModelVendorsAmenitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  listVendorsAmenities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amenityId
      vendorVendorID
      amenity {
        id
        amenityName
        amenityIcon
        createdAt
        updatedAt
        __typename
      }
      vendor {
        vendorID
        vendorName
        vendorSubheading
        vendorDescription
        vendorImgPath
        vendorMainImg
        vendorVendorEmail
        vendorExperiences
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVendorsAmenitiesQueryVariables,
  APITypes.ListVendorsAmenitiesQuery
>;
export const eventsByEventCreatorID = /* GraphQL */ `query EventsByEventCreatorID(
  $eventCreatorID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByEventCreatorID(
    eventCreatorID: $eventCreatorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventName
      eventDescription
      eventStartDateTime
      eventDuration
      eventNumAttendees
      eventOrganization
      eventCreator
      eventCreatorEmail
      eventCreatorID
      eventShow24h
      eventTimezone
      eventServices {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EventsByEventCreatorIDQueryVariables,
  APITypes.EventsByEventCreatorIDQuery
>;
export const rSVPSByEventID = /* GraphQL */ `query RSVPSByEventID(
  $eventID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelRSVPFilterInput
  $limit: Int
  $nextToken: String
) {
  rSVPSByEventID(
    eventID: $eventID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventID
      RSVPStatus
      RSVPName
      RSVPEmail
      RSVPNotes
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RSVPSByEventIDQueryVariables,
  APITypes.RSVPSByEventIDQuery
>;
export const rSVPSByRSVPEmail = /* GraphQL */ `query RSVPSByRSVPEmail(
  $RSVPEmail: String!
  $sortDirection: ModelSortDirection
  $filter: ModelRSVPFilterInput
  $limit: Int
  $nextToken: String
) {
  rSVPSByRSVPEmail(
    RSVPEmail: $RSVPEmail
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventID
      RSVPStatus
      RSVPName
      RSVPEmail
      RSVPNotes
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RSVPSByRSVPEmailQueryVariables,
  APITypes.RSVPSByRSVPEmailQuery
>;
export const servicesAmenitiesByServiceId = /* GraphQL */ `query ServicesAmenitiesByServiceId(
  $serviceId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServicesAmenitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesAmenitiesByServiceId(
    serviceId: $serviceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      amenityId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      amenity {
        id
        amenityName
        amenityIcon
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServicesAmenitiesByServiceIdQueryVariables,
  APITypes.ServicesAmenitiesByServiceIdQuery
>;
export const servicesAmenitiesByAmenityId = /* GraphQL */ `query ServicesAmenitiesByAmenityId(
  $amenityId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServicesAmenitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesAmenitiesByAmenityId(
    amenityId: $amenityId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      amenityId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      amenity {
        id
        amenityName
        amenityIcon
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServicesAmenitiesByAmenityIdQueryVariables,
  APITypes.ServicesAmenitiesByAmenityIdQuery
>;
export const servicesCategoriesByServiceId = /* GraphQL */ `query ServicesCategoriesByServiceId(
  $serviceId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServicesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesCategoriesByServiceId(
    serviceId: $serviceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      subCategoryId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        matchmaking
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServicesCategoriesByServiceIdQueryVariables,
  APITypes.ServicesCategoriesByServiceIdQuery
>;
export const servicesCategoriesBySubCategoryId = /* GraphQL */ `query ServicesCategoriesBySubCategoryId(
  $subCategoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServicesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesCategoriesBySubCategoryId(
    subCategoryId: $subCategoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      subCategoryId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        matchmaking
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServicesCategoriesBySubCategoryIdQueryVariables,
  APITypes.ServicesCategoriesBySubCategoryIdQuery
>;
export const servicesPackagesByServiceId = /* GraphQL */ `query ServicesPackagesByServiceId(
  $serviceId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServicesPackagesFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesPackagesByServiceId(
    serviceId: $serviceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      packageId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      package {
        id
        packageName
        packageLocation
        packageImgs
        packageVibe
        packageVenueTheme
        packageInterestingCategories
        packageDescription
        packageSubheading
        PackageOccupancy
        packageNumNights
        packagePrice
        packagePriceType
        packageCategoryID
        WorkFunTeam
        packageSchedule
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServicesPackagesByServiceIdQueryVariables,
  APITypes.ServicesPackagesByServiceIdQuery
>;
export const servicesPackagesByPackageId = /* GraphQL */ `query ServicesPackagesByPackageId(
  $packageId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServicesPackagesFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesPackagesByPackageId(
    packageId: $packageId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      packageId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      package {
        id
        packageName
        packageLocation
        packageImgs
        packageVibe
        packageVenueTheme
        packageInterestingCategories
        packageDescription
        packageSubheading
        PackageOccupancy
        packageNumNights
        packagePrice
        packagePriceType
        packageCategoryID
        WorkFunTeam
        packageSchedule
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServicesPackagesByPackageIdQueryVariables,
  APITypes.ServicesPackagesByPackageIdQuery
>;
export const servicesTagsByServiceId = /* GraphQL */ `query ServicesTagsByServiceId(
  $serviceId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServicesTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesTagsByServiceId(
    serviceId: $serviceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      tagId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        tagName
        tagSubheading
        tagImg
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServicesTagsByServiceIdQueryVariables,
  APITypes.ServicesTagsByServiceIdQuery
>;
export const servicesTagsByTagId = /* GraphQL */ `query ServicesTagsByTagId(
  $tagId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelServicesTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesTagsByTagId(
    tagId: $tagId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serviceId
      tagId
      service {
        id
        serviceName
        serviceLocation
        serviceImgPath
        serviceMainImg
        serviceHeading
        serviceSubheading
        servicePrice
        servicePriceType
        serviceAdditionalInfo
        serviceIdealOccupancy
        serviceMaxOccupancy
        serviceDescription
        serviceVendorEmail
        serviceURL
        showCategoryImage
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        tagName
        tagSubheading
        tagImg
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ServicesTagsByTagIdQueryVariables,
  APITypes.ServicesTagsByTagIdQuery
>;
export const bookablesCategoriesBySubCategoryId = /* GraphQL */ `query BookablesCategoriesBySubCategoryId(
  $subCategoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookablesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  bookablesCategoriesBySubCategoryId(
    subCategoryId: $subCategoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subCategoryId
      bookableBookableID
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        matchmaking
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      bookable {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookablePrice
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookablesCategoriesBySubCategoryIdQueryVariables,
  APITypes.BookablesCategoriesBySubCategoryIdQuery
>;
export const bookablesCategoriesByBookableBookableID = /* GraphQL */ `query BookablesCategoriesByBookableBookableID(
  $bookableBookableID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBookablesCategoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  bookablesCategoriesByBookableBookableID(
    bookableBookableID: $bookableBookableID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subCategoryId
      bookableBookableID
      subCategory {
        id
        parentCategoryID
        subCategoryName
        subCategoryImg
        matchmaking
        WorkFunTeam
        createdAt
        updatedAt
        __typename
      }
      bookable {
        bookableID
        bookableName
        bookableSubheading
        bookableDescription
        bookableLocation
        bookableImgPath
        bookableMainImg
        bookablePrice
        bookableDuration
        bookableMinOccupancy
        bookableMaxOccupancy
        createdAt
        updatedAt
        vendorBookablesVendorID
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookablesCategoriesByBookableBookableIDQueryVariables,
  APITypes.BookablesCategoriesByBookableBookableIDQuery
>;
export const packagesTagsByPackageId = /* GraphQL */ `query PackagesTagsByPackageId(
  $packageId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPackagesTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  packagesTagsByPackageId(
    packageId: $packageId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      packageId
      tagId
      package {
        id
        packageName
        packageLocation
        packageImgs
        packageVibe
        packageVenueTheme
        packageInterestingCategories
        packageDescription
        packageSubheading
        PackageOccupancy
        packageNumNights
        packagePrice
        packagePriceType
        packageCategoryID
        WorkFunTeam
        packageSchedule
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        tagName
        tagSubheading
        tagImg
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PackagesTagsByPackageIdQueryVariables,
  APITypes.PackagesTagsByPackageIdQuery
>;
export const packagesTagsByTagId = /* GraphQL */ `query PackagesTagsByTagId(
  $tagId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPackagesTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  packagesTagsByTagId(
    tagId: $tagId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      packageId
      tagId
      package {
        id
        packageName
        packageLocation
        packageImgs
        packageVibe
        packageVenueTheme
        packageInterestingCategories
        packageDescription
        packageSubheading
        PackageOccupancy
        packageNumNights
        packagePrice
        packagePriceType
        packageCategoryID
        WorkFunTeam
        packageSchedule
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        tagName
        tagSubheading
        tagImg
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PackagesTagsByTagIdQueryVariables,
  APITypes.PackagesTagsByTagIdQuery
>;
export const vendorsAmenitiesByAmenityId = /* GraphQL */ `query VendorsAmenitiesByAmenityId(
  $amenityId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelVendorsAmenitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  vendorsAmenitiesByAmenityId(
    amenityId: $amenityId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amenityId
      vendorVendorID
      amenity {
        id
        amenityName
        amenityIcon
        createdAt
        updatedAt
        __typename
      }
      vendor {
        vendorID
        vendorName
        vendorSubheading
        vendorDescription
        vendorImgPath
        vendorMainImg
        vendorVendorEmail
        vendorExperiences
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VendorsAmenitiesByAmenityIdQueryVariables,
  APITypes.VendorsAmenitiesByAmenityIdQuery
>;
export const vendorsAmenitiesByVendorVendorID = /* GraphQL */ `query VendorsAmenitiesByVendorVendorID(
  $vendorVendorID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelVendorsAmenitiesFilterInput
  $limit: Int
  $nextToken: String
) {
  vendorsAmenitiesByVendorVendorID(
    vendorVendorID: $vendorVendorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amenityId
      vendorVendorID
      amenity {
        id
        amenityName
        amenityIcon
        createdAt
        updatedAt
        __typename
      }
      vendor {
        vendorID
        vendorName
        vendorSubheading
        vendorDescription
        vendorImgPath
        vendorMainImg
        vendorVendorEmail
        vendorExperiences
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VendorsAmenitiesByVendorVendorIDQueryVariables,
  APITypes.VendorsAmenitiesByVendorVendorIDQuery
>;
