interface agendaDayType {
  name: string;
  agendaItems: string[];
}

export default function ExperienceAgenda(props: { agenda: agendaDayType[] }) {
  return (
    <>
      {props.agenda.map((ad) => {
        return (
          <div key={"div" + ad.name}>
            <h5
              style={{ color: "var(--forest-green)", textAlign: "left", margin: "2rem 0 0 1rem" }}
              key={"h5" + ad.name}
            >
              {ad.name}
            </h5>
            <ul style={{ listStyle: "square", marginTop: ".5rem" }} key={"ul" + ad.name}>
              {ad.agendaItems.map((ai) => {
                return (
                  <li key={"li" + ai}>
                    <span className="desktop-body" style={{ fontSize: 18 }} key={"span" + ai}>
                      {ai}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
}

export type { agendaDayType };
