import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { experienceIndexType } from "../../experienceIndex";
import { findExperienceByID, findExperienceDetailsByID } from "../../experienceUtils";
import ExperienceLayout from "../../experiences/ExperienceLayout";

import mixpanel from "mixpanel-browser";

export default function ExperienceDetail() {
  const experienceID = useParams().experienceID;
  const [loading, setLoading] = useState(true);
  const [exp, setExp] = useState<experienceIndexType | undefined>();

  const fetchExperience = (experienceID: string) => {
    setExp(findExperienceByID(experienceID));
    setLoading(false);
  };

  useEffect(() => {
    if (experienceID) {
      fetchExperience(experienceID);
    }

    mixpanel.track("Experience viewed", {
      Experience: experienceID,
    });
  }, [experienceID]);

  useEffect(() => {
    if (experienceID && exp) {
      let expDetails = findExperienceDetailsByID(experienceID);

      document.title =
        "Townish | Corporate and Team events | " +
        expDetails?.experienceLocations[0].experienceName +
        " | " +
        exp.location.shortAddress;
    }
  }, [exp]);

  /* this was intended to optimize for link previews.  the page title update takes effect but meta tag updates are not applied as the index.html values aren't refreshed until SSR is implemented
  const ExperienceDetailLinkPreview = (props: { experienceID: string }) => {
    let expDetails = findExperienceDetailsByID(props.experienceID);

    if (!exp || !expDetails) {
      console.log("unable to find experience details in ExperienceDetailLinKPreview");
      return <></>;
    }

    let imgURL =
      "https://www.townish.ca/.netlify/images?url=" +
      encodeURIComponent(exp.experienceThumbnail) +
      "&fit=cover&w=1200&h=630";

    
    return (
      <Helmet defer={false}>
        <title>{exp?.experienceName + " | Townish"}</title>
        <meta property="og:title" content={exp?.experienceName + " | Townish"} />
        <meta
          property="og:description"
          content={
            typeof expDetails?.longDescription === "string"
              ? expDetails?.longDescription.substring(0, 125) + "..."
              : expDetails?.longDescription[0].substring(0, 125) + "..."
          }
        />
        <meta property="og:url" content="https://www.townish.ca" />
        <meta property="og:image" itemProp="image" content={imgURL} />
        <meta property="og:image:secure_url" itemProp="image" content={imgURL} />
      </Helmet>
    );
  };
*/

  if (loading || !exp) {
    return <></>;
  } else if (!exp.element) {
    return <ExperienceLayout exp={exp} />;
  } else {
    return exp.element;
  }
}
