import { Grid } from "@mui/material";
import { useGlobalContext } from "../../App";

export default function ProblemStatement() {
  return (
    <section
      className="value-prop-background"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "100px",
        paddingBottom: "50px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <span className="overline value-prop-overline" style={{ textAlign: "left", fontSize: 20 }}>
          THE PROBLEM
        </span>
        <hr className="value-prop-hr" />
        <h4 className="problem-h4" style={{ width: useGlobalContext().isMobile ? "95%" : "80%" }}>
          Organizing team events can take more than 50 hours!
        </h4>
        {!useGlobalContext().isMobile && (
          <span className="desktop-body problem-desktop-body" style={{ width: "67%" }}>
            Picture investing numerous hours in sourcing event providers, crafting agendas, and
            coordinating details — only to receive mediocre feedback. Townish streamlines the entire
            process, saving you valuable time and ensuring exceptional team events.
          </span>
        )}
      </div>

      <Grid container justifyContent={"center"} spacing={"1rem"} mt={"2rem"}>
        <Grid
          container
          item
          xs={12}
          sm={3.5}
          direction="column"
          textAlign={{ sm: "center", md: "left" }}
        >
          <h3 className="problem-h3">15+ hours</h3>
          <span className="desktop-body problem-desktop-body" style={{ minHeight: "60px" }}>
            researching and sourcing service providers
          </span>
          <hr
            className="problem-hr"
            style={{
              width: "70%",
              marginRight: "auto",
              marginLeft: useGlobalContext().isMobile ? "auto" : 0,
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={3.5}
          direction="column"
          textAlign={{ sm: "center", md: "left" }}
        >
          <h3 className="problem-h3">30+ hours</h3>
          <span className="desktop-body problem-desktop-body" style={{ minHeight: "60px" }}>
            developing the agenda and activities
          </span>
          <hr
            className="problem-hr"
            style={{
              width: "70%",
              marginRight: "auto",
              marginLeft: useGlobalContext().isMobile ? "auto" : 0,
            }}
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={3.5}
          direction="column"
          textAlign={{ sm: "center", md: "left" }}
        >
          <h3 className="problem-h3">15+ hours</h3>
          <span className="desktop-body problem-desktop-body" style={{ minHeight: "60px" }}>
            creating materials and coordinating logistics
          </span>
          <hr
            className="problem-hr"
            style={{
              width: "70%",
              marginRight: "auto",
              marginLeft: useGlobalContext().isMobile ? "auto" : 0,
            }}
          />
        </Grid>
      </Grid>
    </section>
  );
}
