import CheckIcon from "@mui/icons-material/Check";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

export default function ExperienceFeatures(props: { features: string[] }) {
  return (
    <List>
      {props.features.map((ft) => {
        return (
          <ListItem disablePadding key={"ListItem" + ft}>
            <ListItemIcon key={"ListItemIcon" + ft}>
              <CheckIcon key={"CheckIcon" + ft} />
            </ListItemIcon>
            <ListItemText key={"ListItemText" + ft}>
              <span className="desktop-body" style={{ fontSize: 16 }} key={"textSpan" + ft}>
                {ft}
              </span>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}
