import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Grid, Icon } from "@mui/material";
import { breakpoints } from "../../theme/breakpoints";

const cardContent = [
  {
    text: "easy to book",
    icon: (
      <SelfImprovementIcon
        style={{
          fontSize: 64,
          color: "white",
          marginTop: "2rem",
        }}
      />
    ),
  },
  {
    text: "best prices",
    icon: "paid",
  },
  {
    text: "vetted vendors",
    icon: (
      <TravelExploreIcon
        style={{
          fontSize: 64,
          color: "white",
          marginTop: "2rem",
        }}
      />
    ),
  },
  {
    text: "single invoice",
    icon: "receipt",
  },
  {
    text: "event support",
    icon: "supportAgent",
  },
  {
    text: "tailored agendas",
    icon: "eventNote",
  },
];

export default function TrustBar() {
  return (
    <Grid
      container
      direction="row"
      className="landing-div"
      justifyContent={"space-between"}
      style={{
        backgroundColor: "var(--forest-green)",
      }}
    >
      {cardContent.map((card) => {
        return (
          <Grid
            container
            item
            xs={6}
            lg={2}
            height={"150px"}
            direction="column"
            alignItems="center"
            key={"div" + card.text}
          >
            {typeof card.icon === "string" && (
              <Icon
                style={{
                  fontSize: 64,
                  color: "white",
                  marginTop: "2rem",
                }}
                key={"Icon" + card.text}
              >
                {card.icon}
              </Icon>
            )}
            {typeof card.icon === "object" && card.icon}
            <span
              className="desktop-body"
              style={{
                marginTop: "auto",
                marginBottom: ".5rem",
                color: "white",
                fontSize: window.innerWidth <= breakpoints.values.md ? "16px" : "",
              }}
              key={"text" + card.text}
            >
              {card.text}
            </span>
          </Grid>
        );
      })}
    </Grid>
  );
}
