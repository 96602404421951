import { Link } from "react-router-dom";
import { useState } from "react";

import { Grid, Popper } from "@mui/material";
import { useGlobalContext } from "../../App";

import { SvgIcon } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const socials = [
  {
    icon: <InstagramIcon fontSize="large" style={{ color: "var(--forest-green)" }} />,
    link: "https://www.instagram.com/jointownish/",
  },
  {
    icon: <XIcon fontSize="large" style={{ color: "var(--forest-green)" }} />,
    link: "https://twitter.com/JoinTownish",
  },
  {
    icon: <FacebookIcon fontSize="large" style={{ color: "var(--forest-green)" }} />,
    link: "https://www.facebook.com/JoinTownish",
  },

  {
    icon: <LinkedInIcon fontSize="large" style={{ color: "var(--forest-green)" }} />,
    link: "https://www.linkedin.com/company/townish/",
  },
];

export default function Footer() {
  const [productOpen, setProductOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const setContactQuizActive = useGlobalContext().setContactQuizActive;
  const isMobile = useGlobalContext().isMobile;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <section
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "2rem",
        paddingBottom: "1rem",
      }}
    >
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        textAlign={"center"}
        justifyContent={"center"}
        alignItems="center"
        width={"80%"}
        mx={isMobile ? "5%" : "10%"}
      >
        {!isMobile && (
          <Grid item xs={12} md={2} textAlign={"left"}>
            <img
              src={require("../../img/Townish_logo_inverted_circle.png")}
              alt="vendor array"
              style={{ width: "80%", maxWidth: "60px", marginTop: "3rem" }}
            />
          </Grid>
        )}
        <Grid container item xs={8} md={2} mt="3rem" direction="row">
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={(e) => {
              handleClick(e);
              setCompanyOpen(false);
              setProductOpen(!productOpen);
            }}
          >
            <span className="desktop-body" style={{ width: "67%", color: "var(--forest-green)" }}>
              Product
            </span>

            <SvgIcon
              component={productOpen ? KeyboardArrowDownIcon : KeyboardArrowUpIcon}
              fontSize="large"
              style={{ color: "var(--forest-green)" }}
            />
          </div>
          {productOpen && (
            <Popper
              id={"product-footer-popper"}
              placement={isMobile ? "right" : "top"}
              open={productOpen}
              anchorEl={anchorEl}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  border: "2px solid",
                  borderColor: "var(--forest-green)",
                  borderRadius: "5px",
                  padding: "1rem",
                }}
              >
                <Link className="link-text link-text-forest" to="/pages/howItWorks">
                  How it Works
                </Link>
                <Link
                  className="link-text link-text-forest"
                  to="/pages/findExperience"
                  style={{ marginTop: "1rem" }}
                >
                  Experiences
                </Link>
                <>
                  {isMobile && (
                    <Link
                      className="link-text link-text-forest"
                      to="/pages/contactQuiz"
                      style={{ marginTop: "1rem" }}
                    >
                      Get in Touch
                    </Link>
                  )}
                  {!isMobile && (
                    <span
                      className="link-text link-text-forest"
                      onClick={() => setContactQuizActive(true)}
                      style={{ cursor: "pointer", marginTop: "1rem" }}
                    >
                      Get in touch
                    </span>
                  )}
                </>
                <Link
                  className="link-text link-text-forest"
                  to="/pages/costEstimator"
                  style={{ marginTop: "1rem" }}
                >
                  Budget Estimator
                </Link>
              </div>
            </Popper>
          )}
        </Grid>
        <Grid container item xs={12} md={2} mt={isMobile ? "1rem" : "3rem"} direction="row">
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={(e) => {
              handleClick(e);
              setProductOpen(false);
              setCompanyOpen(!companyOpen);
            }}
          >
            <span className="desktop-body" style={{ width: "67%", color: "var(--forest-green)" }}>
              Company
            </span>
            <SvgIcon
              component={companyOpen ? KeyboardArrowDownIcon : KeyboardArrowUpIcon}
              fontSize="large"
              style={{ color: "var(--forest-green)" }}
            />
          </div>
          {companyOpen && (
            <Popper
              id={"company-footer-popper"}
              placement={isMobile ? "right" : "top"}
              open={companyOpen}
              anchorEl={anchorEl}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  border: "2px solid",
                  borderColor: "var(--forest-green)",
                  borderRadius: "5px",
                  padding: "1rem",
                }}
              >
                <Link className="link-text link-text-forest" to="/pages/about">
                  About
                </Link>
                <Link
                  className="link-text link-text-forest"
                  to="/pages/privacy"
                  style={{ marginTop: "1rem" }}
                >
                  Privacy
                </Link>
                <Link
                  className="link-text link-text-forest"
                  to="/pages/terms"
                  style={{ marginTop: "1rem" }}
                >
                  Terms of Service
                </Link>
                <Link
                  className="link-text link-text-forest"
                  to="/pages/contact"
                  style={{ marginTop: "1rem" }}
                >
                  Contact
                </Link>
                <Link
                  className="link-text link-text-forest"
                  to="https://townish.team/blog"
                  style={{ marginTop: "1rem" }}
                >
                  Blog
                </Link>
              </div>
            </Popper>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={2}
          mt={isMobile ? "1rem" : "3rem"}
          pt={isMobile ? "" : "3rem"}
          direction="column"
          textAlign={isMobile ? "start" : "end"}
        >
          <span className="desktop-body" style={{ color: "var(--forest-green)" }}>
            Social media
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: isMobile ? "start" : "end",
              marginTop: ".5rem",
            }}
          >
            {socials.map((social, i) => (
              <Link
                key={social.link}
                to={social.link}
                style={{ marginLeft: i === 0 ? "" : "1rem" }}
              >
                {social.icon}
              </Link>
            ))}
          </div>
        </Grid>
        {isMobile && (
          <Grid item xs={12} md={2}>
            <img
              src={require("../../img/Townish_logo_inverted_circle.png")}
              alt="townish logo"
              style={{ width: "80%", maxWidth: "80px", marginTop: "3rem" }}
            />
          </Grid>
        )}
      </Grid>

      <div style={{ marginTop: "4rem", color: "var(--forest-green)" }}>
        <span className="b2">Copyright © 2024 by Townish.</span>
      </div>
    </section>
  );
}

export { socials };
