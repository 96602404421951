import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

import "./landingpage.css";
import { ElementType, ReactElement } from "react";

interface testimonialType {
  testimonial: string;
  image: string;
  name: string;
  starRating?: number; // out of 5
}

const landingPageTestimonials: testimonialType[] = [
  {
    testimonial:
      "Townish has been a lifesaver in helping me to plan and organize our team off-sites.",
    image: "testimonials/MelissaCash.jpg",
    name: "Melissa Cash, Founder & CEO, Pok Pok",
  },
  {
    testimonial:
      "Townish takes the hassle out of planning an event. Highly recommended for any team lead!",
    image: "testimonials/RazZohar.jpg",
    name: "Raz Zohar, VP of Sales, SimpliHome",
  },
  {
    testimonial:
      "Thanks to Townish, we were able to find creative team building ideas, get better prices, and assemble a few activities for the day quickly and easily. Highly recommended!",
    image: "testimonials/MotiWisenbergBarak.jpeg",
    name: "Moti Wisenberg-Barak, CEO, Sorso",
  },
  {
    testimonial:
      "I was in search of a creative activity to do with my team coming into town for a few days. I shared the minimal criteria with Townish and was delighted to receive multiple spot-on options to consider.  We just showed up and had an absolute blast!",
    image: "testimonials/MidyaTsoy.jpeg",
    name: "Midya Tsoy, Director of Growth, Compass Digital",
  },
  {
    testimonial:
      "We’ve done multiple events with Townish and it was a perfect experience - accommodation and food were great and we really had someone we can trust. Townish was perfect for our needs.",
    image: "testimonials/JennWincey.jpg",
    name: "Jennifer Wincey, VP People & Culture, Crowdriff",
  },
  {
    testimonial:
      "Townish made our summit an unforgettable success. Their expert venue recommendations and seamless booking process took the stress out of planning. Their dedication to every detail ensured a smooth experience from start to finish. Highly recommended for anyone seeking exceptional event support!",
    image: "testimonials/TrangNguyen.jpg",
    name: "Trang Nguyen, Head of Operations, Soft Rains",
  },
  {
    testimonial:
      "The Townish team took care of everything - from finding a unique venue to making sure that everyone was well-fed and caffeinated through the day. Thank you for taking care of all the details so that I could focus on facilitating a meaningful offsite.",
    image: "testimonials/RamyNassar.png",
    name: "Ramy Nassar, AI & Strategic Foresight Speaker",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1 },
    items: 1,
  },
};

export default function Testimonials(props: { testimonials?: testimonialType[] }) {
  let testimonials: testimonialType[] = props.testimonials
    ? props.testimonials
    : landingPageTestimonials;

  function StarIcons(props: { rating: number }) {
    let icons: ReactElement[] = [];

    for (let i = 1; i <= 5; i++) {
      if (i <= props.rating) icons.push(<StarIcon key={"star" + i} style={{ color: "gold" }} />);
      else if (i > props.rating)
        icons.push(<StarOutlineIcon key={"starOutline" + i} style={{ color: "gray" }} />);
    }

    return <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>{icons}</div>;
  }

  return (
    <section
      className="landing-div cta-background"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "100px",
        paddingBottom: "50px",
      }}
    >
      <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={false}
        showDots={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={6000}
      >
        {testimonials.map((tstmn) => {
          return (
            <div
              className="testimonial-card-div"
              style={{
                width: "80%",
                minHeight: "150px",
                marginLeft: "auto",
                marginRight: "auto",
                paddingTop: "30px",
                paddingBottom: "20px",
              }}
              key={"div" + tstmn.name}
            >
              <img
                className="testimonial-image"
                src={require("../../img/" + tstmn.image)}
                alt="testimonial headshot"
                key={"img" + tstmn.name}
              />

              <h5
                className="testimonial-font-color"
                style={{ textAlign: "center", marginBottom: "10px" }}
                key={"h5" + tstmn.name}
              >
                {tstmn.testimonial}
              </h5>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {tstmn.starRating && <StarIcons rating={tstmn.starRating} />}
                <span
                  className="desktop-body testimonial-font-color"
                  style={{ textAlign: "center" }}
                  key={"span" + tstmn.name}
                >
                  {tstmn.name}
                </span>
              </div>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
}

export type { testimonialType };
