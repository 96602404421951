import { useState } from "react";

import { Divider, Grid, Popper } from "@mui/material";
import { useGlobalContext } from "../../../App";
import { experienceDetailsType } from "../../../experienceIndex";
import ExperienceRequestQuote from "../experienceRequestQuote";

export default function PricingBar(props: {
  expDetails: experienceDetailsType | undefined;
  selectedOption: string;
  duration: number;
  price: number | string;
  message?: string;
}) {
  const [expandQuoteRequest, setExpandQuoteRequest] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useGlobalContext().isMobile;

  return (
    <>
      {!isMobile && (
        <Grid
          container
          direction="row"
          style={{ background: "var(--smoke-grey)", width: "100vw" }}
          justifyContent={"center"}
          py={".5rem"}
        >
          <Grid container item xs={1.5} direction="column">
            <Grid item>
              <span className="desktop-body">Days</span>
            </Grid>
            <Grid item>
              <span className="h6a" style={{ color: "var(--forest-green", fontWeight: "bold" }}>
                {props.duration}
              </span>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            style={{
              color: "var(--forest-green)",
              height: "50px",
              borderRightWidth: 4,
              margin: "auto 0 auto 0",
            }}
          />
          <Grid container item xs={2} direction="column">
            <Grid item>
              <span className="desktop-body">Price from</span>
            </Grid>
            <Grid item>
              <span className="h6a" style={{ color: "var(--forest-green", fontWeight: "bold" }}>
                ${props.price.toLocaleString()}
              </span>
            </Grid>
          </Grid>

          <Divider
            orientation="vertical"
            style={{
              color: "var(--forest-green)",
              height: "50px",
              borderRightWidth: 4,
              margin: "auto 0 auto 0",
            }}
          />
          <Grid item xs={4} my="auto">
            <span
              className="desktop-body"
              style={{ fontSize: 16, fontWeight: "300", padding: "0 1rem 0 1rem" }}
            >
              {props.message}
            </span>
          </Grid>
          <Divider
            orientation="vertical"
            style={{
              color: "var(--forest-green)",
              height: "50px",
              borderRightWidth: 4,
              margin: "auto 0 auto 0",
            }}
          />
          <Grid container item xs={2} justifyContent={"center"} alignItems="center">
            <button
              className={"button-small button-lime"}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setExpandQuoteRequest(!expandQuoteRequest);
              }}
            >
              <span className="button-text button-text-lime">Request a quote</span>
            </button>
          </Grid>
        </Grid>
      )}
      <Popper
        id={"request-quote-popper"}
        placement={"top"}
        open={expandQuoteRequest}
        anchorEl={anchorEl}
      >
        <ExperienceRequestQuote
          expDetails={props.expDetails}
          setExpand={setExpandQuoteRequest}
          selectedOption={props.selectedOption}
        />
      </Popper>
      {isMobile && (
        <Grid
          container
          direction="row"
          style={{ background: "var(--smoke-grey)", width: "100vw" }}
          justifyContent={"left"}
          py={".5rem"}
        >
          <Grid container item direction="row">
            <Grid container item xs={3} justifyContent={"center"}>
              <span className="h6b" style={{ color: "var(--forest-green" }}>
                {props.duration}
                {props.duration === 1 ? " day" : " days"}
              </span>
            </Grid>
            <Grid container item xs={1} alignItems="center" justifyContent={"center"}>
              <Divider
                orientation="vertical"
                style={{
                  color: "var(--forest-green)",
                  height: "20px",
                  borderRightWidth: 4,
                  margin: "auto 0 auto 0",
                }}
              />
            </Grid>
            <Grid container item xs={8} justifyContent={"center"}>
              <span className="h6b" style={{ color: "var(--forest-green" }}>
                From ${props.price.toLocaleString()}
              </span>
            </Grid>
          </Grid>

          <Grid container item justifyContent={"center"} alignItems="center" marginTop={".5rem"}>
            <button
              className={"button-lime"}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setExpandQuoteRequest(!expandQuoteRequest);
              }}
            >
              <span className="button-text button-text-lime">Request a quote</span>
            </button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
