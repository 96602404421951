import { Link } from "react-router-dom";

import { Grid } from "@mui/material";

import { experienceIndexType } from "../../experienceIndex";
import { internalVendor, s3ImgParams } from "../../ServiceUtils";

/*

  location: locationType;


*/

export default function VendorCard(props: { vendor: internalVendor }) {
  return (
    <Link
      to={"/pages/vendorDetail/" + props.vendor.vendorID}
      style={{ textDecoration: "none", color: "var(--forest-green)" }}
      key={"Link" + props.vendor.vendorID}
    >
      <div
        className="basic-div"
        style={{
          minHeight: "400px",
          borderRadius: "8px",
        }}
        key={"div" + props.vendor.vendorID}
      >
        <Grid container direction="column" key={"Grid-cont" + props.vendor.vendorID}>
          <Grid item key={"Grid-item" + props.vendor.vendorID}>
            <div
              style={{
                backgroundColor: "var(--smoke-grey)",
                width: "100%",
                borderRadius: "8px",
                display: "block",
              }}
            >
              <img
                src={
                  s3ImgParams.s3ImgPath +
                  props.vendor.vendorImgPath +
                  encodeURIComponent(props.vendor.vendorMainImg)
                }
                alt={props.vendor.vendorName + " thumbnail"}
                style={{
                  width: "100%",
                  aspectRatio: 1,
                  objectFit: "cover",
                  borderRadius: "8px",
                  display: "block",
                }}
                key={"Img" + props.vendor.vendorID}
              />
            </div>
          </Grid>
          <Grid container item key={"Grid-cont2" + props.vendor.vendorID}>
            <h5
              style={{
                margin: ".1rem 0 0 .5rem",
                color: "var(--forest-green)",
                overflow: "hidden",
                textAlign: "left",
              }}
              key={"name" + props.vendor.vendorID}
            >
              {props.vendor.vendorName}
            </h5>
          </Grid>

          <Grid container direction="row">
            <Grid container item xs={12}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  margin: "0 0 0 .5rem",
                }}
                key={"Grid-item2" + props.vendor.vendorID}
              >
                <span
                  className="desktop-body2"
                  style={{
                    fontSize: 16,
                    textAlign: "left",
                    maxHeight: "1.5rem",
                  }}
                  key={"address" + props.vendor.vendorID}
                >
                  {props.vendor.vendorSubheading}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Link>
  );
}
